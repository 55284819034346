/* External libraries */
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../../history";
import httpClient from "../../../lib/HttpClient";

import security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import TableView from "../../Common/TableView/TableView";
import Paginator from "../../Common/Paginator/Paginator";
import NewModal from "../../Common/Modal";
import PreviewEmailModal from "./PreviewEmailModal/PreviewEmailModal";

/* UI Kit */
import { UikInput, UikButton, Uikon, UikDivider } from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

/* Papercurve Components */
import Header from "../../shared/Header/Header";
import ContactsLeftMenu from "../ContactsLeftMenu";

/* Assets */
import styles from "./Emails.module.scss";

/* Variables */

class Emails extends React.Component {
  constructor() {
    super();

    this.state = {
      emails: [],
      filteredEmails: [],
      selectedEmail: null,
      emailsSearchQuery: null,
      showNewEmailModal: false,
      emailCampaign: { name: "Emails" },
      emailsPerPage: 0,
      showEmailPreviewModal: false,
      showSendNowConfirmationModal: false,
      showStopCampaignConfirmationModal: false,
    };
  }

  componentWillMount = () => {
    this.loadEmailCampaign();
    this.loadEmails(1);
  };

  handleEmailPreview = (email) => {
    this.setState({
      showEmailPreviewModal: true,
      selectedEmail: email,
    });
  };

  closeEmailPreview = () => {
    this.setState({
      showEmailPreviewModal: false,
    });
  };

  loadEmailCampaign = () => {
    const selectedEmailCampaignId = this.props.match.params.id;

    httpClient
      .get(`/email_campaigns/${selectedEmailCampaignId}.json`)
      .then((response) => {
        this.setState({
          emailsPerPage: Math.ceil(response.data.number_of_emails / 100),
          emailCampaign: response.data,
        });
      });
  };

  loadEmails = (page) => {
    const selectedEmailCampaignId = this.props.match.params.id;

    httpClient
      .get(`/email_campaigns/${selectedEmailCampaignId}/emails/${page}.json`)
      .then((response) => {
        let filteredEmails = [];

        this.setState({ emails: response.data });

        response.data.map((email_campaign, index) => {
          filteredEmails.push(email_campaign);
        });

        this.setState({ filteredEmails: filteredEmails });

        //response.data.map((email_campaign, index) => {
        //  if (email_campaign.id == selectedEmailCampaignId) {
        //    this.setState({ selectedEmail: email_campaign });
        //  }
        //});
      });
  };

  componentWillReceiveProps = (nextProps) => {
    const selectedEmailId = nextProps.match.params.id;
    this.state.email_campaigns.map((email_campaign, index) => {
      if (email_campaign.id == selectedEmailId) {
        this.setState({ selectedEmail: email_campaign });
      }
    });
  };

  renderEmailsList = () => {
    return (
      <div></div>
      //<div>
      //  {this.state.filteredEmails.map((email_campaign, index) => {
      //    const letter = email_campaign.name.charAt(0).toUpperCase();
      //    const headerExists = letterHeaders.includes(letter);

      //    if (!headerExists) {
      //      letterHeaders.push(letter);
      //    }

      //    return (
      //      <div key={email_campaign.id}>
      //        <EmailListItem email_campaign={email_campaign} selectedEmailId={selectedEmailId} />
      //      </div>
      //    );
      //  })}
      //</div>
    );
  };

  renderEmailEditForm = () => {
    return (
      <div></div>
      //<div>
      //  {this.state.selectedEmailId != "create" &&
      //    this.state.selectedEmail && (
      //      <EmailEditForm
      //        key={this.state.selectedEmail.id}
      //        reloadEmails={() => this.loadEmails()}
      //        onDelete={() => this.setState({ selectedEmail: null })}
      //        email_campaign={this.state.selectedEmail}
      //      />
      //    )}
      //</div>
    );
  };

  handleEmailCreate = (email_campaignFormData) => {};

  renderEmailCreateForm = () => {
    return (
      <div></div>
      //<div>
      //  <EmailCreateForm handleEmailCreate={this.handleEmailCreate} />
      //</div>
    );
  };

  handleEmailSearch = (e) => {};

  handleNewVerifiedEmail = (e) => {
    this.setState({ showNewEmailModal: true });
  };

  handleBackLink = () => {
    history.push("/contacts/mailer");
  };

  setShowNewEmailModal = (visibility) => {
    let visible = visibility ? true : false;
    this.setState({ showNewEmailModal: visible });
  };

  sendNow = () => {
    this.setState({ showSendNowConfirmationModal: true });
  };

  confirmSendNow = () => {
    let updatedCampaign = { ...this.state.emailCampaign };
    updatedCampaign.status = "processing";
    updatedCampaign.send_date = moment.utc();
    httpClient
      .put(`/email_campaigns/${this.state.emailCampaign.id}/send_now.json`, {
        email_campaign: updatedCampaign,
      })
      .then((response) => {
        this.setState({ showSendNowConfirmationModal: false });
        this.loadEmailCampaign();
        this.loadEmails();
      });
  };

  cancelSendNow = () => {
    this.setState({ showSendNowConfirmationModal: false });
  };

  stopCampaign = () => {
    this.setState({ showStopCampaignConfirmationModal: true });
  };

  confirmStopCampaign = () => {
    let updatedEmailCampaign = { ...this.state.emailCampaign };

    updatedEmailCampaign["status"] = "cancelled";

    httpClient
      .put(`/email_campaigns/${this.state.emailCampaign.id}.json`, {
        email_campaign: updatedEmailCampaign,
      })
      .then((response) => {
        this.setState({ showStopCampaignConfirmationModal: false });
        this.loadEmailCampaign();
        this.loadEmails();
      });
  };

  cancelStopCampaign = () => {
    this.setState({ showStopCampaignConfirmationModal: false });
  };

  renderStatus = (currentStatus) => {
    let currentStatusStyle;

    if (currentStatus === "wait") {
      currentStatusStyle = styles.wait;
    } else if (currentStatus === "pending") {
      currentStatusStyle = styles.pending;
    } else if (currentStatus === "processing") {
      currentStatusStyle = styles.processing;
    } else if (currentStatus === "delivered") {
      currentStatusStyle = styles.delivered;
    } else if (currentStatus === "bounced") {
      currentStatusStyle = styles.bounced;
    } else if (currentStatus === "cancelled") {
      currentStatusStyle = styles.cancelled;
    }

    return (
      <div className={styles.currentStatusContainer}>
        <div className={currentStatusStyle}>{currentStatus}</div>
      </div>
    );
  };

  renderContact = (contact) => {
    return (
      <div>
        <div className={styles.recipientName}>{contact["recipient_name"]}</div>
        <div>{contact["to"]}</div>
      </div>
    );
  };

  render() {
    let campaignTitle = this.state.emailCampaign.name || "Emails";

    return (
      <Container fluid className={styles.emailsScreen}>
        <Header />
        {this.state.showEmailPreviewModal && (
          <PreviewEmailModal
            selectedEmail={this.state.selectedEmail}
            selectedCampaign={this.state.emailCampaign}
            closeEmailPreview={this.closeEmailPreview}
          />
        )}
        <Row>
          <ContactsLeftMenu />
          <Col>
            <div className={styles.emailsHeaderControls}>
              <div>&nbsp;</div>
            </div>
            <div className={styles.emailsTableContainer}>
              <TableView
                label={campaignTitle}
                items={this.state.emails}
                itemFields={[
                  "status",
                  "to",
                  "sent_at",
                  "clicks",
                  "downloads",
                  "preview",
                ]}
                itemClickFunction={this.handleEmailPreview}
                largeColumns={["to"]}
                dateColumns={["sent_at"]}
                statusColumns={["status"]}
                contactColumns={["to"]}
                customContactStyler={this.renderContact}
                customStatusStyler={this.renderStatus}
                backLink={true}
                handleBackLink={this.handleBackLink}
                headerButtonAction={
                  this.state.emailCampaign.manual_send &&
                  this.state.emailCampaign.status == "manual_send"
                    ? this.sendNow
                    : null
                }
                headerActionButtonText={
                  this.state.emailCampaign.manual_send &&
                  this.state.emailCampaign.status == "manual_send"
                    ? "Send Now"
                    : null
                }
                headerButtonDestructiveAction={
                  this.state.emailCampaign.manual_send &&
                  this.state.emailCampaign.status === "processing"
                    ? this.stopCampaign
                    : null
                }
                headerDestructiveActionText={
                  this.state.emailCampaign.manual_send &&
                  this.state.emailCampaign.status === "processing"
                    ? "Stop Manual Send"
                    : null
                }
                headerMessage={
                  this.state.emailCampaign.manual_send &&
                  this.state.emailCampaign.status === "completed" &&
                  this.state.emailCampaign.send_date
                    ? `Manually sent on ${moment(
                        this.state.emailCampaign.send_date
                      ).format("MMM DD, YYYY")}`
                    : null
                }
              />
              {this.state.emailsPerPage > 1 && (
                <Paginator
                  handlePageChange={this.loadEmails}
                  pageCount={this.state.emailsPerPage}
                />
              )}
            </div>
            <NewModal
              visible={this.state.showSendNowConfirmationModal}
              type="alert"
            >
              <div className="heading">Confirm Send Now</div>
              <UikDivider />
              <div className="body">
                {`Are you sure you want to this email campaign now? ${this.state.emails.length} emails will be sent.`}
              </div>
              <UikDivider />
              <div className="buttons">
                <UikButton onClick={this.confirmSendNow} primary>
                  Confirm
                </UikButton>
                <UikButton onClick={this.cancelSendNow}>Cancel</UikButton>
              </div>
            </NewModal>
            <NewModal
              visible={this.state.showStopCampaignConfirmationModal}
              type="alert"
            >
              <div className="heading">Confirm Stop Campaign</div>
              <UikDivider />
              <div className="body">
                {`Are you sure you want to stop this campaign? This will stop any emails that have not already been sent.`}
              </div>
              <UikDivider />
              <div className="buttons">
                <UikButton onClick={this.confirmStopCampaign} primary>
                  Confirm
                </UikButton>
                <UikButton onClick={this.cancelStopCampaign}>Cancel</UikButton>
              </div>
            </NewModal>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Emails;
