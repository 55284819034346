import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./Comment.module.scss";
import { useSelector } from "react-redux";
import { Uikon, UikDropdown, UikDropdownItem } from "@uik";

import deleteIcon from "../../images/icons/16px/delete2.svg";
import emailIcon from "../../images/icons/svg/email-icon.svg";
import menuIcon from "../../images/icons/svg/three-dot-vertical.svg";
import pencilIcon from "../../images/icons/svg/pencil-black.svg";
import trashIcon from "../../images/icons/svg/trash-black.svg";

import videoService from "../../services/Video";

const Comment = ({
  comment,
  currentUserId,
  onDeleteComment,
  canDelete,
  editComment,
  editingComment,
}) => {
  const actionsDropDown = ({ onClick }) => {
    return (
      <div
        // className={styles.documentActionsDropDownContainer}
        onClick={onClick}
      >
        <img className={styles.menuIcon} src={menuIcon} />
      </div>
    );
  };

  const mention = new RegExp("{{(.*?)}}", "gi");
  const search = useSelector((state) => {
    if (state.commentSearchResults) {
      return state.commentSearchResults.searchTerm;
    }
  });

  const getCommentName = (comment) => {
    if (comment.id === editingComment) {
      return styles.editingComment;
    } else {
      if (currentUserId === comment.user.id) {
        return styles.commentFromMe;
      } else {
        return styles.commentFromOthers;
      }
    }
  };

  const formatHighlights = (comment) => {
    let parts = comment.body ? comment.body.split(mention) : [];

    if (parts.length === 1) {
      parts = comment.body
        ? comment.body.split(new RegExp(`(${search})`, "gi"))
        : [];
    }
    const names = comment.body.match(mention);

    return (
      <span className={styles.text}>
        {" "}
        {parts.map((part, i) => {
          const found = names && names.find((name) => name === `{{${part}}}`);
          if (part.match(new RegExp(`(${search})`, "gi"))) {
            let subsections = part.split(new RegExp(`(${search})`, "gi"));
            return subsections.map((section) => {
              if (
                search &&
                section.trim().toLowerCase() === search.toLowerCase()
              ) {
                return <span className={styles.highlight}>{section}</span>;
              } else {
                const higlightType =
                  currentUserId === comment.user.id
                    ? styles.myCommentHighlight
                    : styles.othersCommentHighlight;
                return (
                  <span key={i} className={found ? higlightType : {}}>
                    {section}
                  </span>
                );
              }
            });
          }
          if (search && part.trim().toLowerCase() === search.toLowerCase()) {
            return (
              <span key={i} className={styles.highlight}>
                {part}
              </span>
            );
          } else {
            const higlightType =
              currentUserId === comment.user.id
                ? styles.myCommentHighlight
                : styles.othersCommentHighlight;
            return (
              <span key={i} className={found ? higlightType : {}}>
                {part}
              </span>
            );
          }
        })}{" "}
      </span>
    );
  };

  return (
    <div className={canDelete ? " " + styles.commentCanDelete : ""}>
      <div className={styles.commentHeader}>
        <div
          className={
            styles.commentNamePlateContainer +
            (!comment.user.active ? " " + styles.commentNamePlateDeactive : "")
          }
        >
          {comment.user.guest && (
            <span className={styles.commentNamePlate}>
              <img src={emailIcon} />
            </span>
          )}
          {!comment.user.guest && (
            <span className={styles.commentNamePlate}>
              {comment.user.first_name.charAt(0)}
              {comment.user.last_name.charAt(0)}
            </span>
          )}
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.commentName}>
            {comment.user.guest && <span>{comment.user.email}</span>}
            {!comment.user.guest && (
              <span>
                {comment.user.first_name} {comment.user.last_name}
              </span>
            )}
            {!comment.user.active && (
              <span>
                <br />
                (Deactivated)
              </span>
            )}
          </div>
        </div>
        <div className={styles.commentHeaderRight}>
          {comment.edited && <p className={styles.editedLabel}>EDITED</p>}
          <div className={styles.commentDate}>
            {comment.edited
              ? moment(comment.updated_at).format("MMM DD, hh:mm a")
              : moment(comment.commented_at).format("MMMM Do")}
          </div>
        </div>
      </div>
      <div className={getCommentName(comment)}>
        {formatHighlights(comment)}
        {comment.video_timestamp != null && (
          <div
            className={styles.timestamp}
            onClick={() => {
              videoService.setCurrentTime(comment.video_timestamp);
            }}
          >
            at{" "}
            {moment()
              .startOf("day")
              .seconds(comment.video_timestamp)
              .format("H:mm:ss")}
          </div>
        )}
        {comment && (comment.editable || comment.deletable) && (
          <UikDropdown
            DisplayComponent={actionsDropDown}
            position="bottomRight"
            className="commentMenu"
          >
            {comment.editable && (
              <UikDropdownItem
                onClick={() => {
                  editComment(comment.id, comment.body);
                }}
              >
                <div className={styles.dropdownItemContainer}>
                  <div className={styles.dropdownItemLeft}>
                    <img className={styles.editIcon} src={pencilIcon} />
                    <span className={styles.downloadText}>Edit</span>
                  </div>
                  <div className={styles.helpText}>
                    <p>Can't edit after 12 hours</p>
                  </div>
                </div>
              </UikDropdownItem>
            )}
            {comment.deletable && (
              <UikDropdownItem
                onClick={() => {
                  onDeleteComment(comment.id);
                }}
              >
                <div className={styles.dropdownItemContainer}>
                  <div className={styles.dropdownItemLeft}>
                    <img className={styles.editIcon} src={trashIcon} />
                    <span className={styles.downloadText}>Delete</span>
                  </div>
                  <div className={styles.helpText}>
                    <p>Can't delete after 12 hours</p>
                  </div>
                </div>
              </UikDropdownItem>
            )}
          </UikDropdown>
        )}
      </div>
    </div>
  );
};

Comment.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default Comment;
