import React from "react";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../../history";
import styles from "./DraftUpdate.module.scss";

import httpClient from "../../../lib/HttpClient";

import security from "../../../services/Security";

/* Papercurve Components */
import Header from "../../shared/Header/Header";
import DraftVideoUpdateForm from "./DraftVideoUpdateForm";

/* Variables */

class DraftVideoUpdate extends React.Component {
  constructor() {
    super();

    this.state = {
      document: {},
      autoApprovable: false,
    };
  }

  componentWillMount = () => {
    const currentUserRole = security.getUserRole();
    const docId = this.props.match.params.id;

    // Only Authors and Admins can upload and create drafts.
    if (currentUserRole !== "author" && currentUserRole !== "admin") {
      history.push("/dashboard");
    }

    httpClient.get(`/documents/${docId}.json`).then((response) => {
      let autoApprovable = true;

      response.data.approvals.map((approval) => {
        if (
          !approval.observer &&
          approval.status !== "approved" &&
          approval.status !== "approve_with_changes"
        ) {
          autoApprovable = false;
        }
      });

      this.setState({
        document: response.data,
        autoApprovable,
      });
    });
  };

  render() {
    return (
      <Container fluid className={styles.draftUpload}>
        <Header />
        <Row>
          <Col sm={12}>
            <div className={styles.draftUploadHeading}>
              <h1 className={styles.mainHeadline}>Upload a new version</h1>
              <span>
                Replace the current version of {this.state.document.title} with
                a new version.
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <DraftVideoUpdateForm
              docId={this.props.match.params.id}
              autoApprovable={this.state.autoApprovable}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DraftVideoUpdate;
