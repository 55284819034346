import React, { useEffect, useState } from "react";
import { Col } from "react-grid-system";
import LibrarySelect from "../../Libraries/LibrarySelect";
import Select from "react-select";
import Button from "../../Common/Buttons/ButtonPrimary";
import notificationService from "../../../services/Notifications";

import styles from "./EditForm.module.scss";

export default (props) => {
  const { dateReminder, updateDateReminder, setShowDeleteModal } = props;

  const [daysBefore, setDaysBefore] = useState(dateReminder.days_before);

  useEffect(() => {
    setDaysBefore(dateReminder.days_before);
  }, [dateReminder]);

  const onSave = () => {
    if (daysBefore <= 0 || daysBefore > 999) {
      notificationService.addNotification(
        "Invalid input",
        "Invalid input! Enter a number 1-999",
        "warning"
      );
    } else {
      updateDateReminder(dateReminder.id, daysBefore);
    }
  };

  return (
    <Col className={styles.editFormContainer} sm={12} md={5}>
      <div className={styles.editForm}>
        <div className={styles.header}>
          <div className={styles.title}>Edit Reminder</div>
          <div className={styles.description}>Edit your email reminder</div>
        </div>
        <div className={styles.body}>
          <div className={styles.libraryDropdown}>
            <LibrarySelect
              readOnly={true}
              library_name={dateReminder.library_name}
              library_id={dateReminder.library_id}
            />
          </div>
          <div className={styles.dateFieldsDropdown}>
            <div className="uik-content-title__wrapper">Date field</div>
            <Select
              value={{
                value: dateReminder.id,
                label: dateReminder.document_field_label,
              }}
              isDisabled={true}
            />
          </div>
          <div className={styles.setReminder}>
            <div className={styles.textBeforeInput}>Remind me</div>
            <div className={styles.numberInput}>
              <input
                type="number"
                onChange={(e) => setDaysBefore(e.target.value)}
                value={daysBefore}
              />
            </div>
            <div className={styles.textAfterInput}>
              days before if I am the owner or a reviewer
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.cancelButton}>
            <Button
              transparent
              text="Delete"
              onClick={() => setShowDeleteModal(true)}
            />
          </div>
          <div className={styles.saveButton}>
            <Button
              original
              text="Save"
              disabled={!daysBefore}
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </Col>
  );
};
