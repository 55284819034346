import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import resetSearchClaimsResults from "../../../../actions/document/resetSearchClaimsResults";
import searchClaims from "../../../../actions/document/searchClaims";
import useDebouncer from "../../../../hooks/useDebouncer";

import CloseSVG from "../../../../images/icons/svg/close-grey.svg";

import { UikInput } from "@uik";

import styles from "./LinkClaimsSearch.module.scss";

export default (props) => {
  const { libraryId, setSearchResults, setSearchActive, setSearchTerm } = props;

  const dispatch = useDispatch();

  const boundActionCreators = bindActionCreators(
    {
      resetSearchClaimsResults,
      searchClaims,
    },
    dispatch
  );

  const [searchValue, setSearchValue] = useState("");

  const debouncedInputValue = useDebouncer(searchValue);

  useEffect(() => {
    if (searchValue.length > 0) {
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }

    boundActionCreators
      .searchClaims(libraryId, debouncedInputValue)
      .then((results) => {
        setSearchResults(results);
      });
  }, [debouncedInputValue]);

  const onSearchChange = (value) => {
    setSearchValue(value);
    setSearchTerm(value);
  };

  return (
    <div className={styles.searchPanelContainer}>
      <div
        className={
          searchValue.length === 0
            ? styles.searchInputContainer
            : styles.searchInputContainerActive
        }
      >
        <div className={styles.InputContainer}>
          <UikInput
            id="comment-search"
            placeholder={"Search Product Claims"}
            className={
              searchValue.length === 0
                ? styles.searchInputFieldEmpty
                : styles.searchInputFieldActive
            }
            onChange={(e) => onSearchChange(e.target.value)}
            value={searchValue}
          ></UikInput>
        </div>
        <span
          className={
            searchValue.length === 0
              ? styles.hideCloseComment
              : styles.showCloseComment
          }
          onClick={() => {
            setSearchValue("");
            setSearchActive(false);
            boundActionCreators.resetSearchClaimsResults();
          }}
        >
          <img src={CloseSVG} />
        </span>
      </div>
    </div>
  );
};
