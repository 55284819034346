import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import styles from "./DocumentVersionsPanel.module.scss";
import Button from "../Common/Buttons/ButtonPrimary";
import history from "../../history";

/* Papercurve Components */
import DocumentVersion from "./DocumentVersion";
import externalLinkIcon from "../../images/icons/svg/external-link-icon-white.svg";
import externalLinkIconGrey from "../../images/icons/svg/external-link-icon-grey.svg";

/* UI Kit */
import {
  Uikon,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikDivider,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import Security from "../../services/Security";

/* Variables */
const cookies = new Cookies();

class DocumentVersionsPanel extends React.Component {
  constructor(props) {
    super();

    this.resizeTimeout = null;

    const token = cookies.get("jwt");
    const user = jwtDecode(token);

    this.state = {
      currentUserId: user.user_id,
      docId: props.docId,
      versionId: props.versionId,
    };
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    window.addEventListener("resize", this.onResize);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  renderVersions = () => {
    return this.props.versions.map((i, j) => (
      <div key={i.id} className={styles.versions}>
        <DocumentVersion
          version={i}
          index={this.props.versions.length - j}
          currentUserId={this.state.currentUserId}
          docId={this.state.docId}
          versionId={this.props.versionId}
          onClickedVersion={this.props.onClickedVersion}
        />
      </div>
    ));
  };

  compareVersions = (e) => {
    let versionsToCompare = [];
    this.props.versions.map((version) => {
      if (!version.is_video) {
        if (versionsToCompare.length < 2) {
          versionsToCompare.push(version.id);
        }
      }
    });

    let compareVersions = window.open(
      `${
        window.location.protocol +
        "//" +
        window.location.host +
        `/compare/${versionsToCompare[1]}/${versionsToCompare[0]}`
      }`
    );
  };

  getCompareButtonStyle = () => {
    let buttonStyle = styles.compareButton;
    if (Security.getUserRole() === "viewer") {
      buttonStyle = styles.viewerCompareButton;
    }
    return buttonStyle;
  };

  disableCompareButton = () => {
    let disable = true;
    if (this.props.versions.length > 1) {
      if (this.props.nonVideoVersionsExist) {
        disable = false;
      }
    }
    return disable;
  };

  render() {
    return (
      <div id="versionsPanel" className={styles.versionsPanel}>
        <div className={styles.versionsListHeader} id="versionslistheader">
          <span className={styles.sidePanelHeadingTitle}>Versions</span>
          <span
            className={styles.closeVersions}
            onClick={() => this.props.onClickCloseVersions()}
          >
            &times;
          </span>
        </div>
        <div className={styles.versionsList} id="versionsList">
          <div>{this.props.versions.length > 0 && this.renderVersions()}</div>
        </div>
        {!this.props.approved && (
          <div className={styles.uploadNewVersion}>
            {Security.getUserRole() !== "viewer" && (
              <Button
                className={styles.uploadButton}
                original
                text="Upload New"
                onClick={this.props.uploadNewVersion}
              />
            )}
            <UikButton
              className={this.getCompareButtonStyle()}
              onClick={(e) => this.compareVersions(e)}
              disabled={this.disableCompareButton()}
            >
              Compare
              <img
                className={styles.newTab}
                src={
                  this.disableCompareButton()
                    ? externalLinkIconGrey
                    : externalLinkIcon
                }
              />
            </UikButton>
          </div>
        )}
      </div>
    );
  }
}

DocumentVersionsPanel.propTypes = {
  closeVersions: PropTypes.func,
  docId: PropTypes.number,
};

export default DocumentVersionsPanel;
