import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { createForm, formShape } from "rc-form";

/* UI Kit */
import { UikFormInputGroup, UikSelect, UikAvatar } from "@uik";
import "@uik/styles.css";

import styles from "./DropDownField.module.scss";

/* Variables */
const cookies = new Cookies();

class DropDownFieldBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: null,
    };
  }

  handleInputChange = (e) => {
    const { name, values } = this.props;

    values[name] = e.value;
    this.setState({
      selected: e.label,
    });
  };

  render() {
    const {
      label,
      name,
      placeholder,
      required,
      defaultValue,
      values,
      options,
    } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;
    const optionsUsers = options.map((option, idx) => {
      if (this.state.selected === option.label) {
        return {
          label: (
            <UikAvatar
              sm
              imgUrl="/assets/svg/Check_thick.svg"
              name={option.label}
            />
          ),
          value: idx,
        };
      } else {
        return option;
      }
    });

    return (
      <div>
        <UikSelect
          className={"styles.select" + "styles.metadataField"}
          label={`${label}`}
          placeholder={`${placeholder || ""}`}
          defaultValue={defaultValue}
          options={optionsUsers}
          defaultOption={optionsUsers[0]}
          onChange={(e) => this.handleInputChange(e)}
        />
      </div>
    );
  }
}

DropDownFieldBuild.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
  values: PropTypes.object,
};

const DropDownField = createForm()(DropDownFieldBuild);
export default DropDownField;
