import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import resetSearchClaimsResults from "../../../../actions/document/resetSearchClaimsResults";
import searchClaims from "../../../../actions/document/searchClaims";
import useDebouncer from "../../../../hooks/useDebouncer";

import CloseSVG from "../../../../images/icons/svg/close-grey.svg";

import { UikInput } from "@uik";

import styles from "./ClaimsSearch.module.scss";

export default (props) => {
  const { setSearchResults, setSearchTerm } = props;

  const [searchValue, setSearchValue] = useState("");

  const claims = useSelector((state) => {
    return state.claims;
  });

  const documentClaims = useSelector((state) => {
    return state.documentClaims;
  });

  const onSearchChange = (value) => {
    setSearchTerm(value);
    searchClaimsInDoc(value);
    setSearchValue(value);
    if (value === "") {
      setSearchResults(null);
    }
  };

  const searchClaimsInDoc = () => {
    const allClaimsInDoc = [...claims, ...documentClaims];
    let PATTERN = searchValue.toUpperCase();
    let filtered = allClaimsInDoc.filter((claim) => {
      if (claim.linked) {
        if (claim.claim.caption.toUpperCase().includes(PATTERN)) {
          return claim;
        } else if (claim.claim.label.toUpperCase().includes(PATTERN)) {
          return claim;
        }
      } else {
        if (claim.caption.toUpperCase().includes(PATTERN)) {
          return claim;
        } else if (claim.label.toUpperCase().includes(PATTERN)) {
          return claim;
        }
      }
    });
    setSearchResults(filtered);
  };

  return (
    <div className={styles.searchPanelContainer}>
      <div
        className={
          searchValue.length === 0
            ? styles.searchInputContainer
            : styles.searchInputContainerActive
        }
      >
        <div className={styles.InputContainer}>
          <UikInput
            id="comment-search"
            placeholder={"Search Product Claims in this Document"}
            className={
              searchValue.length === 0
                ? styles.searchInputFieldEmpty
                : styles.searchInputFieldActive
            }
            onChange={(e) => onSearchChange(e.target.value)}
            value={searchValue}
          ></UikInput>
        </div>
        <span
          className={
            searchValue.length === 0
              ? styles.hideCloseComment
              : styles.showCloseComment
          }
          onClick={() => {
            setSearchValue("");
            setSearchResults(null);
          }}
        >
          <img src={CloseSVG} />
        </span>
      </div>
    </div>
  );
};
