/* External libraries */
import React from "react";
import PropTypes from "prop-types";
import { createForm, formShape } from "rc-form";
import RadioButton from "../Common/RadioButton";
import Button from "../Common/Buttons/ButtonPrimary";

import "../../animate.css";

import httpClient from "../../lib/HttpClient";
import security from "../../services/Security";

import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikDivider,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

import styles from "./LibraryEditForm.module.scss";

/* Variables */

class LibraryEditFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor(props) {
    super(props);

    const currentUserRole = security.getUserRole();

    this.state = {
      currentUser: {
        role: currentUserRole,
      },
      documentFieldGroups: null,
      selectedFieldGroup: props.library.document_fields_group.id,
    };
  }

  componentDidMount = () => {
    httpClient.get("/document_fields_groups.json").then((response) => {
      this.setState({
        documentFieldGroups: response.data,
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.library.id !== this.props.library.id) {
      this.setState({
        selectedFieldGroup: this.props.library.document_fields_group.id,
      });
    }
  };

  handleContentPropertiesChange = (e) => {
    this.setState({
      selectedFieldGroup: parseInt(e.target.value),
    });
  };

  handleSave(e) {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const libraryFormData = {
          library: {
            name: values[`name${this.props.library.id}`],
          },
        };

        if (
          this.state.selectedFieldGroup !==
          this.props.library.document_fields_group.id
        ) {
          libraryFormData.library.document_fields_group_id =
            this.state.selectedFieldGroup;
        }

        if (this.state.role) {
          libraryFormData.user.role = this.state.role;
          console.log(libraryFormData);
        }

        httpClient
          .put(`/libraries/${this.props.library.id}.json`, libraryFormData)
          .then((response) => {
            console.log("saved!");

            notificationService.addNotification(
              "Library Saved",
              "Your changes to this library has been saved",
              "success"
            );

            this.props.onUpdate(response.data);
          });
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleDelete(e) {
    e.preventDefault();
    const { onDelete } = this.props;
    if (!window.confirm("Are you sure you want to delete this library?")) {
      return false;
    }
    httpClient
      .delete(`/libraries/${this.props.library.id}.json`)
      .then((response) => {
        notificationService.addNotification(
          "Library Deleted",
          "Library has been deleted",
          "danger"
        );
        onDelete(this.props.library);
      })
      .catch((error) => {
        notificationService.addNotification(
          "Library Cannot Be Deleted",
          "Libraries containing documents cannot be deleted",
          "danger"
        );
      });
  }

  render() {
    const { library } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.libraryEditContainer}>
        <UikWidget>
          <div className={styles.headerContainer}>
            <div className={styles.header}>Edit library</div>
            <div className={styles.description}>
              Make changes to this library.
            </div>
          </div>
          <UikDivider />
          <div className={styles.bodyContainer}>
            <div className={styles.libraryName}>
              <UikFormInputGroup>
                {getFieldDecorator(`name${library.id}`, {
                  initialValue: library.name,
                  rules: [
                    {
                      required: true,
                      message: "Please enter a library name.",
                    },
                  ],
                })(
                  <UikInput
                    label="Library Name"
                    errorMessage={(
                      getFieldError(`name${library.id}`) || []
                    ).join(", ")}
                  />
                )}
              </UikFormInputGroup>
            </div>
            <div className={styles.contentPropertiesContainer}>
              <div className={styles.contentPropertiesTitle}>
                content properties
              </div>
              {library.can_update_document_fields_group &&
                this.state.documentFieldGroups &&
                this.state.documentFieldGroups.length > 1 && (
                  <div className={styles.contentPropertiesList}>
                    {this.state.documentFieldGroups.map(
                      (documentFieldGroup) => (
                        <div className={styles.contentProperty}>
                          <RadioButton
                            checked={
                              this.state.selectedFieldGroup ===
                              documentFieldGroup.id
                            }
                            name="documentFieldGroup"
                            value={documentFieldGroup.id}
                            onChange={this.handleContentPropertiesChange}
                          />
                          <div className={styles.contentPropertyDetails}>
                            <div className={styles.contentPropertyLabel}>
                              {documentFieldGroup.label}
                            </div>
                            <div className={styles.contentPropertyFields}>
                              {documentFieldGroup.document_fields
                                .map((documentField) => documentField.label)
                                .join(", ")}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              {(!library.can_update_document_fields_group ||
                (this.state.documentFieldGroups &&
                  this.state.documentFieldGroups.length === 1)) && (
                <div className={styles.lockedContentProperty}>
                  <div className={styles.lockedContentPropertyLabel}>
                    {library.document_fields_group.label} &#8212;{" "}
                  </div>
                  <div className={styles.lockedContentPropertyFields}>
                    {library.document_fields_group.document_fields
                      .map((documentField) => documentField.label)
                      .join(", ")}
                  </div>
                </div>
              )}
            </div>
          </div>
          <UikDivider />
          <div className={styles.buttonsContainer}>
            <Button text="Save" original onClick={(e) => this.handleSave(e)} />
            <Button
              text="Delete"
              original
              onClick={(e) => this.handleDelete(e)}
            />
          </div>
        </UikWidget>
      </div>
    );
  }
}

LibraryEditFormBuild.propTypes = {
  library: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onNotify: PropTypes.func.isRequired,
};

const LibraryEditForm = createForm()(LibraryEditFormBuild);
export default LibraryEditForm;
