import React, { useState, useEffect } from "react";
import CloseSVG from "../../../../images/icons/svg/close.svg";
import CloseSVGBlack from "../../../../images/icons/svg/close-black-large.svg";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import NewModal from "../../../Common/Modal";
import TableView from "../../../Common/TableView/TableView";
import searchStructuredContents from "../../../../actions/structuredContents/search";

import Button from "../../../Common/Buttons/ButtonPrimary";
import fetchDocumentDynamicFields from "../../../../actions/document/fetchDocumentDynamicFields";
import Dropzone from "react-dropzone";
import { loadDocumentUrl } from "../../../../lib/axios-client";
import { Line } from "rc-progress";
import notificationService from "../../../../services/Notifications";

import greenCheck from "../../../../images/icons/svg/greenCheck.svg";
import deleteImage from "../../../../images/icons/svg/delete-image.svg";
import structuredContentIcon from "../../../../images/icons/svg/structured-content-icon.svg";
import SearchIcon from "../../../../images/icons/svg/search.svg";
import CrossIcon from "../../../../images/icons/svg/cross-light-grey.svg";
import BackArrowIcon from "../../../../images/icons/svg/back-arrow-grey.svg";
import Lottie from "react-lottie";
import loadingAnimation from "../../../../images/lotties/loading.json";

import axios from "../../../../lib/axios-client";
import httpClient from "../../../../lib/HttpClient";
import security from "../../../../services/Security";
import useDebouncer from "../../../../hooks/useDebouncer";

import styles from "./Template.module.scss";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";

export default (props) => {
  const [dynamicFieldData, setDynamicFieldData] = useState({});

  const [activeImageField, setActiveImageField] = useState(null);

  const [activeStructuredField, setActiveStructuredField] = useState(null);

  const [dynamicImageFields, setDynamicImageFields] = useState([]);

  const [structuredFieldDimensions, setStructuredFieldDimensions] = useState(
    {}
  );

  const [imageLoading, setImageLoading] = useState({});

  const [progress, setProgress] = useState(0);

  const [imageDimension, setImageDimension] = useState(null);

  const [structuredContentCategories, setStructuredContentCategories] =
    useState([]);

  const [searchStructuredContentsValue, setSearchStructuredContentsValue] =
    useState({});

  const [structuredContentsResults, setStructuredContentsResults] = useState(
    []
  );

  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedStructuredContents, setSelectedStructuredContents] = useState(
    {}
  );

  const [selectedMultiPropertySCCardData, setSelectedMultiPropertySCCardData] =
    useState({});

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [selectedFieldName, setSelectedFieldName] = useState(null);

  const [unselectedStructuredContents, setUnselectedStructuredContents] =
    useState({});

  const [allSCForCategory, setAllSCForCategory] = useState([]);

  const [sCCategorySearchResults, setSCCategorySearchResults] = useState([]);

  const [chosenContent, setChosenContent] = useState(null);

  const [groupedTemplateFields, setGroupedTemplateFields] = useState([]);

  const [showStructuredContentModal, setShowStructuredContentModal] =
    useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const debouncedInputValue = useDebouncer(imageDimension);

  const debouncedSearchStructuredContentsValue = useDebouncer(
    searchStructuredContentsValue[selectedFieldName]
  );

  const documentDynamicFields = useSelector((state) => {
    return state.documentDynamicFields;
  });

  useEffect(() => {
    handleStructuredContentSearch(debouncedSearchStructuredContentsValue);
  }, [debouncedSearchStructuredContentsValue]);

  useEffect(() => {
    debouncedInputValue && changeImageDimension();
  }, [debouncedInputValue]);

  useEffect(() => {
    if (documentDynamicFields && documentDynamicFields.length !== 0) {
      prepareMultiPropertSCFieldCardData(establishSelectedStructuredContent());
    }
  }, [documentDynamicFields]);

  useEffect(() => {
    httpClient.get(`/structured_content_categories.json`).then((response) => {
      setStructuredContentCategories(response.data);
    });
  }, []);

  const {
    viewingCurrentVersion,
    closePanel,
    templateFields,
    templateKeysSchema,
    docId,
    readOnly,
  } = props;

  const onClose = () => {
    closePanel();
  };

  const dispatch = useDispatch();

  const boundActionCreators = bindActionCreators(
    {
      searchStructuredContents,
    },
    dispatch
  );

  const searchStructuredContentsResults = useSelector((state) => {
    return null;
    //if (state.commentSearchResults) {
    //  return state.commentSearchResults.searchResults;
    //}
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fieldNameNoSpaces = (fieldName) => {
    let withoutSpaces = fieldName.split(" ").join("");
    return withoutSpaces;
  };

  const stopSelectingSCbyProperties = () => {
    resetStructuredSearchValue(selectedFieldName);
    setAllSCForCategory([]);
    setStructuredContentsResults([]);
    setSCCategorySearchResults([]);
    setGroupedTemplateFields([]);
    setShowStructuredContentModal(false);
    setSelectedFieldName(null);
    setSelectedCategory(null);
    setChosenContent(null);
  };

  const prepareMultiPropertSCFieldCardData = (
    allSelectedStructuredContents
  ) => {
    // Preparing multi-property selected structured content for being rendered in the template panel as one representative card

    let selectedMultiPropertySCFields = {};
    Object.keys(allSelectedStructuredContents).map((ssc) => {
      let fieldNameComponents = allSelectedStructuredContents[ssc].field_name
        ? allSelectedStructuredContents[ssc].field_name.split(".")
        : ssc.split(".");
      if (
        allSelectedStructuredContents[ssc].is_multi_property_sc_field ||
        fieldNameComponents.length === 4
      ) {
        let multiPropertyGroupIdentifier;
        multiPropertyGroupIdentifier = [
          fieldNameComponents[0],
          fieldNameComponents[1],
          fieldNameComponents[2],
        ].join(".");
        if (!selectedMultiPropertySCFields[multiPropertyGroupIdentifier]) {
          selectedMultiPropertySCFields[multiPropertyGroupIdentifier] = {};
          selectedMultiPropertySCFields[multiPropertyGroupIdentifier][
            "displayValue"
          ] = allSelectedStructuredContents[ssc].display_sc_property_value
            ? allSelectedStructuredContents[ssc].display_sc_property_value
            : allSelectedStructuredContents[ssc].field_value;
          selectedMultiPropertySCFields[multiPropertyGroupIdentifier][
            "propertyNames"
          ] = [ssc];
          selectedMultiPropertySCFields[multiPropertyGroupIdentifier][
            "structuredContentLabel"
          ] = allSelectedStructuredContents[ssc].structured_content_label;
        } else {
          selectedMultiPropertySCFields[multiPropertyGroupIdentifier][
            "propertyNames"
          ].push(ssc);
        }
      }
    });
    setSelectedMultiPropertySCCardData(selectedMultiPropertySCFields);
  };

  const establishSelectedStructuredContent = () => {
    let initialSelectedStructuredContent = {};

    documentDynamicFields.forEach((ddf) => {
      let structuredContentProductClaimsRegex = /structured.product_claims/i;
      let sc = ddf.structured_content;
      if (sc && sc.id > 0) {
        // Set the selected structured content.
        initialSelectedStructuredContent[ddf.field_name] = {
          ddf_id: ddf.id,
          field_name: ddf.field_name,
          field_value: ddf.field_value,
          document_id: ddf.document_id,
          structured_content_id: sc.id,
          structured_content_label: sc.label,
          structured_content_type: sc.content_type,
          is_multi_property_sc_field: ddf.is_multi_property_sc_field,
          display_sc_property_value: ddf.display_sc_property_value,
          structured_content_image: sc.image_url
            ? sc.image_url
            : ddf.structured_content_image_url,
          image_width: ddf.image_width,
          image_height: ddf.image_height,
        };
      } else if (
        ddf.field_name.match(structuredContentProductClaimsRegex) &&
        ddf.field_value
      ) {
        initialSelectedStructuredContent[ddf.field_name] = {
          ddf_id: ddf.id,
          field_value: ddf.field_value,
          document_id: ddf.document_id,
          structured_content_id: null,
          structured_content_label: null,
          structured_content_type: null,
          structured_content_image: null,
          image_width: null,
          image_height: null,
        };
      }
    });

    setSelectedStructuredContents(initialSelectedStructuredContent);

    return initialSelectedStructuredContent;
  };

  const triggerStructuredContentSearch = (categoryLookup, row) => {
    const category = structuredContentCategories.find((scc) => {
      if (scc.lookup === categoryLookup) {
        return true;
      } else {
        return false;
      }
    });

    setSelectedFieldName(categoryLookup);
    setSelectedRow(row);

    // Now using template Keys schema to deduce the grouped fields instead

    if (
      templateKeysSchema.keys.structured.properties[categoryLookup].properties[
        row
      ].properties
    ) {
      setGroupedTemplateFields(
        Object.keys(
          templateKeysSchema.keys.structured.properties[categoryLookup]
            .properties[row].properties
        )
      );
    } else {
      setGroupedTemplateFields([`structured.${categoryLookup}.${row}`]);
    }

    if (category && category.column_type === "text") {
      setSelectedCategory(category);
      setShowStructuredContentModal(true);
      setShowLoader(true);
      httpClient
        .get(`/structured_contents/category/${category.id}.json`)
        .then((res) => {
          setShowLoader(false);
          setAllSCForCategory(res.data);
        });
    }
  };

  const saveDocumentDynamicFields = () => {
    const fieldData = new FormData();
    const fill_datetime = moment().unix();
    let structuredContentProductClaimsRegex = /structured.product_claims/i;

    if (documentDynamicFields.length === 0) {
      if (templateFields.length > 0) {
        templateFields.map((field) => {
          let structuredContentRegex = /structured./i;

          if (field === "image") {
            // Field is an image
            Object.keys(templateKeysSchema.keys[field].properties).map((f) => {
              let filledImageField = dynamicImageFields.find(
                (imageField) => `${imageField.fieldName}` === `image.${f}`
              );
              let fieldName = `image.${f}`;
              if (filledImageField) {
                fieldData.append(`fields[${fieldName}][field_name]`, fieldName);
                fieldData.append(`fields[${fieldName}][document_id]`, docId);
                fieldData.append(
                  `fields[${fieldName}][fill_datetime]`,
                  fill_datetime
                );
                fieldData.append(
                  `fields[${fieldName}][image_file]`,
                  filledImageField.imageFile
                );
                fieldData.append(
                  `fields[${fieldName}][image_width]`,
                  filledImageField.width
                );
                fieldData.append(
                  `fields[${fieldName}][image_height]`,
                  filledImageField.height
                );
              } else {
                fieldData.append(`fields[${fieldName}][document_id]`, docId);
                fieldData.append(
                  `fields[${fieldName}][fill_datetime]`,
                  fill_datetime
                );
              }
            });
          } else if (field.match(structuredContentProductClaimsRegex)) {
            // Field is a structured field.
            //
            // Apply structured content to field.
            //
            // We have to not only apply the caption of a structured content
            //  but as well remember which was selected
            if (
              selectedStructuredContents &&
              selectedStructuredContents.hasOwnProperty(field)
            ) {
              fieldData.append(
                `fields[${field}][field_value]`,
                selectedStructuredContents[field]["field_value"]
              );
              fieldData.append(
                `fields[${field}][document_id]`,
                selectedStructuredContents[field]["document_id"]
              );
              fieldData.append(`fields[${field}][structured_content_id]`, null);
              fieldData.append(
                `fields[${field}][fill_datetime]`,
                fill_datetime
              );
              fieldData.append(
                `fields[${field}][image_width]`,
                selectedStructuredContents[field]["width"]
              );
              fieldData.append(
                `fields[${field}][image_height]`,
                selectedStructuredContents[field]["height"]
              );
            } else {
              fieldData.append(`fields[${field}][structured_content_id]`, null);
              fieldData.append(`fields[${field}][document_id]`, docId);
              fieldData.append(
                `fields[${field}][fill_datetime]`,
                fill_datetime
              );
            }
          } else if (
            field.match(structuredContentRegex) &&
            !field.match(structuredContentProductClaimsRegex)
          ) {
            // Field is a structured field.
            //
            // Apply structured content to field.
            //
            // We have to not only apply the caption of a structured content
            //  but as well remember which was selected
            if (
              selectedStructuredContents &&
              selectedStructuredContents.hasOwnProperty(field)
            ) {
              fieldData.append(
                `fields[${field}][field_value]`,
                selectedStructuredContents[field]["field_value"]
              );
              fieldData.append(
                `fields[${field}][document_id]`,
                selectedStructuredContents[field]["document_id"]
              );
              fieldData.append(
                `fields[${field}][structured_content_id]`,
                selectedStructuredContents[field]["structured_content_id"]
              );
              fieldData.append(
                `fields[${field}][fill_datetime]`,
                fill_datetime
              );
              fieldData.append(
                `fields[${field}][image_width]`,
                selectedStructuredContents[field]["image_width"]
              );
              fieldData.append(
                `fields[${field}][image_height]`,
                selectedStructuredContents[field]["image_height"]
              );
            } else {
              fieldData.append(`fields[${field}][field_value]`, null);
              fieldData.append(`fields[${field}][structured_content_id]`, null);
              fieldData.append(`fields[${field}][document_id]`, docId);
              fieldData.append(
                `fields[${field}][fill_datetime]`,
                fill_datetime
              );
            }
          } else {
            const structuredContentRegex = /structured/i;
            if (!field.match(structuredContentRegex)) {
              if (!dynamicFieldData[field]) {
                fieldData.append(`fields[${field}][document_id]`, docId);
                fieldData.append(
                  `fields[${field}][fill_datetime]`,
                  fill_datetime
                );
              } else {
                fieldData.append(
                  `fields[${field}][field_value]`,
                  dynamicFieldData[field]["field_value"]
                );
                fieldData.append(
                  `fields[${field}][document_id]`,
                  dynamicFieldData[field]["document_id"]
                );
                fieldData.append(
                  `fields[${field}][fill_datetime]`,
                  fill_datetime
                );
              }
            }
          }
        });
      }

      //Iterate over all template fields and identify the fieldNames of the structured fields (this is only done the first time the user applies fields)
      let allstructuredFields = [];

      templateFields.map((field, index) => {
        if (field === "structured") {
          let structuredCategories = Object.keys(
            templateKeysSchema.keys["structured"].properties
          );

          structuredCategories.map((structuredCategory, index) => {
            let structuredSchemaProperties =
              templateKeysSchema.keys[field].properties;
            let structuredSchemaRows = Object.keys(
              structuredSchemaProperties[structuredCategory].properties
            );

            structuredSchemaRows.map((row, index) => {
              if (
                structuredSchemaProperties[structuredCategory].properties[
                  row
                ].hasOwnProperty("properties")
              ) {
                Object.keys(
                  structuredSchemaProperties[structuredCategory].properties[row]
                    .properties
                ).map((property) => {
                  const fieldName = [
                    field,
                    structuredCategory,
                    row,
                    property,
                  ].join(".");
                  allstructuredFields.push(fieldName);
                });
              } else {
                const fieldName = [field, structuredCategory, row].join(".");
                allstructuredFields.push(fieldName);
              }
            });
          });
        }
      });

      // Append all structured fields to field data
      allstructuredFields.map((sf, index) => {
        fieldData.append(`fields[${sf}][document_id]`, docId);
        fieldData.append(`fields[${sf}][fill_datetime]`, fill_datetime);
      });

      // Overwrite the field data of those structured content fields for which structured content has been selected.
      Object.keys(selectedStructuredContents).map((ssc) => {
        if (!templateFields.find((tf) => tf === ssc)) {
          fieldData.append(
            `fields[${ssc}][field_value]`,
            selectedStructuredContents[ssc]["field_value"]
          );
          fieldData.append(
            `fields[${ssc}][document_id]`,
            selectedStructuredContents[ssc]["document_id"]
          );
          fieldData.append(
            `fields[${ssc}][structured_content_id]`,
            selectedStructuredContents[ssc]["structured_content_id"]
          );
          fieldData.append(`fields[${ssc}][fill_datetime]`, fill_datetime);
          fieldData.append(
            `fields[${ssc}][image_width]`,
            selectedStructuredContents[ssc]["image_width"]
          );
          fieldData.append(
            `fields[${ssc}][image_height]`,
            selectedStructuredContents[ssc]["image_height"]
          );
        }
      });
    } else {
      Object.keys(dynamicFieldData).map((field) => {
        fieldData.append(
          `fields[${field}][field_value]`,
          dynamicFieldData[field]["field_value"]
        );
        fieldData.append(
          `fields[${field}][document_id]`,
          dynamicFieldData[field]["document_id"]
        );
        fieldData.append(`fields[${field}][fill_datetime]`, fill_datetime);
      });

      // Allowing user to supply a new image for a non-structred image field, when documentDynamicFields.length > 0
      if (dynamicImageFields.length > 0) {
        dynamicImageFields.map((field) => {
          let fieldName = field.fieldName;
          fieldData.append(`fields[${fieldName}][field_name]`, fieldName);
          fieldData.append(`fields[${fieldName}][document_id]`, docId);
          fieldData.append(
            `fields[${fieldName}][fill_datetime]`,
            fill_datetime
          );
          fieldData.append(`fields[${fieldName}][image_file]`, field.imageFile);
          fieldData.append(`fields[${fieldName}][image_width]`, field.width);
          fieldData.append(`fields[${fieldName}][image_height]`, field.height);
        });
      }

      // Structured Content
      if (Object.keys(unselectedStructuredContents).length > 0) {
        console.log(
          "unselectedStructuredContents",
          unselectedStructuredContents
        );
        // Clear structured content data.
        Object.keys(unselectedStructuredContents).map((field) => {
          fieldData.append(`fields[${field}][structured_content_id]`, null);
          fieldData.append(`fields[${field}][document_id]`, docId);
          fieldData.append(`fields[${field}][fill_datetime]`, fill_datetime);
        });
      }

      if (Object.keys(selectedStructuredContents).length > 0) {
        console.log("selectedStructuredContents", selectedStructuredContents);
        // If there is a no ddf_id, it means that we need to send this fieldData.
        Object.keys(selectedStructuredContents).map((field) => {
          // if (!selectedStructuredContents[field].ddf_id) {
          fieldData.append(
            `fields[${field}][field_value]`,
            selectedStructuredContents[field]["field_value"]
          );
          fieldData.append(
            `fields[${field}][document_id]`,
            selectedStructuredContents[field]["document_id"]
          );
          fieldData.append(`fields[${field}][fill_datetime]`, fill_datetime);
          if (
            selectedStructuredContents[field].structured_content_type ===
            "image"
          ) {
            fieldData.append(
              `fields[${field}][image_width]`,
              selectedStructuredContents[field]["image_width"]
            );
            fieldData.append(
              `fields[${field}][image_height]`,
              selectedStructuredContents[field]["image_height"]
            );
          }

          if (!field.match(structuredContentProductClaimsRegex)) {
            fieldData.append(
              `fields[${field}][structured_content_id]`,
              selectedStructuredContents[field]["structured_content_id"]
            );
          }
          // }
        });
      }
    }

    const token = security.getToken();

    const postConfig = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .post("/document_dynamic_fields.json", fieldData, postConfig)
      .then((res) => {
        notificationService.addNotification(
          "Template updated and new version created",
          "Template updated and new version created",
          "success"
        );
        setStructuredFieldDimensions({});
        dispatch(fetchDocumentDynamicFields(docId)).then((response) => {
          renderDocumentDynamicFields(response);
        });
      })
      .catch((error) => {});
  };

  const onSearchStructuredContentsChanged = (value, fieldName) => {
    setActiveStructuredField(fieldName);
    let sscv = { ...searchStructuredContentsValue };
    sscv[fieldName] = value;

    setSearchStructuredContentsValue(sscv);
    setStructuredContentsResults([]);
  };

  const handleStructuredContentSearch = () => {
    if (
      !debouncedSearchStructuredContentsValue ||
      debouncedSearchStructuredContentsValue === ""
    ) {
      return null;
    } else {
      setShowLoader(true);

      boundActionCreators
        .searchStructuredContents(
          debouncedSearchStructuredContentsValue,
          selectedFieldName
        )
        .then((scr) => {
          setShowLoader(false);
          if (scr.length > 0) {
            let scResults = structuredContentsResults || {};

            scResults[selectedFieldName] = scr;
            setStructuredContentsResults(scResults);
          }
          setSCCategorySearchResults(scr);
        });
    }
  };

  const onChangeFieldValue = (e, isImageField, parameter) => {
    if (isImageField) {
      let modifiedImageField = dynamicImageFields.find(
        (f) => f.fieldName === e.target.name
      );

      modifiedImageField[parameter] = e.target.value;

      let modifiedImageFieldIndex = [...dynamicImageFields].indexOf(
        modifiedImageField
      );

      let updatedDocumentImageFields = [...dynamicImageFields];

      updatedDocumentImageFields[modifiedImageFieldIndex] = modifiedImageField;

      setDynamicImageFields(updatedDocumentImageFields);
    } else {
      setDynamicFieldData({
        ...dynamicFieldData,
        [e.target.name]: {
          field_value: e.target.value,
          document_id: docId,
        },
      });
    }
  };

  const onChangeStructuredImageField = (e, parameter, field) => {
    onUpdateImageFieldValue(e.target.value, field, parameter);
    let updated = { ...structuredFieldDimensions };

    if (parameter === "image_height") {
      updated[field] = {
        ...updated[field],
        image_height: e.target.value,
      };

      setStructuredFieldDimensions(updated);
    } else {
      updated[field] = {
        ...updated[field],
        image_width: e.target.value,
      };

      setStructuredFieldDimensions(updated);
    }

    let selectedStructuredContentsCopy = { ...selectedStructuredContents };
    selectedStructuredContentsCopy[field][parameter] = parseInt(e.target.value);

    setSelectedStructuredContents(selectedStructuredContentsCopy);
  };

  const returnFieldValue = (field) => {
    if (dynamicFieldData[field]) {
      return dynamicFieldData[field].field_value;
    } else {
      const documentDynamicField = documentDynamicFields.find(
        (f) => f.field_name === field
      );
      if (documentDynamicField) {
        if (documentDynamicField.field_value === "null") {
          return "";
        } else {
          return documentDynamicField.field_value;
        }
      } else {
        return "";
      }
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const returnUpdatedImageFieldValue = (field, dimension) => {
    if (
      imageDimension &&
      imageDimension.field === field &&
      imageDimension.dimension === dimension
    ) {
      return imageDimension.value;
    } else {
      const documentDynamicField = documentDynamicFields.find(
        (f) => f.field_name === field
      );

      return documentDynamicField ? documentDynamicField[dimension] : null;
    }
  };

  const onUpdateImageFieldValue = (value, field, dimension) => {
    setImageDimension({
      value,
      field,
      dimension,
    });
  };

  const changeImageDimension = () => {
    const { value, field, dimension } = imageDimension;

    let fieldName = field;

    const documentDynamicField = documentDynamicFields.find(
      (f) => f.field_name === fieldName
    );

    let replacedSCImage = unselectedStructuredContents[fieldName];

    if (
      (documentDynamicField &&
        documentDynamicField.has_attached_image &&
        !replacedSCImage) ||
      (documentDynamicField &&
        documentDynamicField.structured_content &&
        documentDynamicField.structured_content.content_type === "image" &&
        !replacedSCImage)
    ) {
      let fields = {
        id: documentDynamicField.id,
        dimension: dimension,
        [dimension]: parseInt(value),
      };

      httpClient
        .put(`/document_dynamic_fields/${documentDynamicField.id}.json`, {
          fields,
        })
        .then((res) => {
          let updatedFields = [];
          dispatch(fetchDocumentDynamicFields(docId)).then((response) => {
            renderDocumentDynamicFields(response);
          });
        });
    }
  };

  const renderLoadingState = () => {
    const placeholderArray = [1, 2, 3, 4, 5, 6, 7, 8];
    return placeholderArray.map(() => {
      return (
        <div>
          <div className={styles.labelPlaceHolder}></div>
          <div className={styles.inputPlaceHolder}></div>
        </div>
      );
    });
  };

  const renderProgress = () => {
    let prevPercentage = 0;
    setTimeout(() => {
      while (prevPercentage < 100) {
        setProgress(prevPercentage + 20);
        prevPercentage += 20;
      }
    }, 500);

    setTimeout(() => {
      setImageLoading(null);
      setActiveImageField(null);
    }, 1000);
  };

  const renderTemplateFields = () => {
    let imageFields = [];

    return templateFields
      .sort(function (a, b) {
        if (a.field_name < b.field_name) {
          return -1;
        }
        if (a.field_name > b.field_name) {
          return 1;
        }
        return 0;
      })
      .map((field) => {
        if (field === "image") {
          return Object.keys(templateKeysSchema.keys[field].properties).map(
            (imagefield) => {
              imageFields.push(imagefield);
              let fieldName = `image.${imagefield}`;

              let filledField = documentDynamicFields.find(
                (f) => f.field_name === fieldName && f.has_attached_image
              );

              let imageUploaded = dynamicImageFields.find(
                (f) => f.fieldName === fieldName
              );

              let currentFieldActive =
                activeImageField && activeImageField.field === field.field;

              return (
                <div
                  className={styles.imageFieldInput}
                  onDragEnter={() => {
                    setActiveImageField(`image.${imagefield}`);
                  }}
                >
                  <div className={styles.inputHeader}>
                    {capitalizeFirstLetter(fieldName)}
                  </div>
                  {!imageUploaded && !filledField && (
                    <Dropzone
                      accept="image/*"
                      onDrop={onImageFileDrop}
                      multiple={true}
                      disabled={!viewingCurrentVersion || readOnly}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div
                            {...getRootProps()}
                            className={styles.emptyStateDropzone}
                          >
                            <input {...getInputProps()} />
                            <div className={styles.uploaderContainer}>
                              <span
                                className={
                                  styles.emptyStateUploaderIconContainer
                                }
                              ></span>
                              <div className={styles.uploadButtonContainer}>
                                <Button
                                  disabled={!viewingCurrentVersion || readOnly}
                                  original
                                  text={"Upload"}
                                  onClick={() => {
                                    setActiveImageField(`image.${imagefield}`);
                                  }}
                                />
                                <span className={styles.uploadCaption}>
                                  or drop file here to upload
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  )}
                  {(imageUploaded || filledField) && (
                    <div className={styles.progressContainer}>
                      <div className={styles.fileDetailsContainer}>
                        <div className={styles.fileName}>
                          {imageUploaded
                            ? capitalizeFirstLetter(
                                imageUploaded.fileName.replace(/\.[^/.]+$/, "")
                              )
                            : capitalizeFirstLetter(
                                filledField.image_file_name.replace(
                                  /\.[^/.]+$/,
                                  ""
                                )
                              )}
                        </div>
                        <div className={styles.fileType}>
                          {imageUploaded
                            ? imageUploaded.fileType
                                .replace(/image\//, "")
                                .toUpperCase()
                            : filledField.mime_type
                                .replace(/image\//, "")
                                .toUpperCase()}
                        </div>
                        {imageLoading && currentFieldActive && (
                          <Line
                            className={styles.uploadProgress}
                            percent={progress}
                            strokeWidth="1"
                            strokeColor="#1b8c96"
                            strokeLinecap="square"
                          />
                        )}
                      </div>
                      {(currentFieldActive && !imageLoading) ||
                        (!currentFieldActive && (
                          <img
                            className={
                              !viewingCurrentVersion || readOnly
                                ? styles.successIconApproved
                                : styles.successIcon
                            }
                            src={greenCheck}
                          />
                        ))}
                      {filledField && viewingCurrentVersion && !readOnly && (
                        <img
                          className={styles.deleteImageField}
                          src={deleteImage}
                          onClick={() => {
                            deleteDynamicImageField(fieldName);
                          }}
                        />
                      )}
                    </div>
                  )}
                  {(currentFieldActive && !imageLoading) ||
                    (!currentFieldActive && imageUploaded && (
                      <div className={styles.imageDimensionsInputContainer}>
                        <div className={styles.dimensionInputContainer}>
                          <div className={styles.inputHeader}>{"Width"}</div>
                          <UikInput
                            disabled={!viewingCurrentVersion || readOnly}
                            placeholder={"Value"}
                            className={styles.searchInputField}
                            name={`${fieldName}`}
                            onChange={(e) =>
                              onChangeFieldValue(e, true, "width")
                            }
                            // value={returnFieldValue("width")}
                          ></UikInput>
                        </div>
                        <div className={styles.dimensionInputContainer}>
                          <div className={styles.inputHeader}>{"Height"}</div>
                          <UikInput
                            disabled={!viewingCurrentVersion || readOnly}
                            placeholder={"Value"}
                            className={styles.searchInputField}
                            name={`${fieldName}`}
                            onChange={(e) =>
                              onChangeFieldValue(e, true, "height")
                            }
                            // value={returnFieldValue("width")}
                          ></UikInput>
                        </div>
                      </div>
                    ))}
                  {filledField && (
                    <div className={styles.imageDimensionsInputContainer}>
                      <div className={styles.dimensionInputContainer}>
                        <div className={styles.inputHeader}>{"Width"}</div>
                        <UikInput
                          disabled={!viewingCurrentVersion || readOnly}
                          placeholder={"Value"}
                          className={styles.searchInputField}
                          name={`${fieldName}`}
                          onChange={(e) =>
                            onUpdateImageFieldValue(
                              e.target.value,
                              fieldName,
                              "image_width"
                            )
                          }
                          value={returnUpdatedImageFieldValue(
                            fieldName,
                            "image_width"
                          )}
                        ></UikInput>
                      </div>
                      <div className={styles.dimensionInputContainer}>
                        <div className={styles.inputHeader}>{"Height"}</div>
                        <UikInput
                          disabled={!viewingCurrentVersion || readOnly}
                          placeholder={"Value"}
                          className={styles.searchInputField}
                          name={`${fieldName}`}
                          onChange={(e) =>
                            onUpdateImageFieldValue(
                              e.target.value,
                              fieldName,
                              "image_height"
                            )
                          }
                          value={returnUpdatedImageFieldValue(
                            fieldName,
                            "image_height"
                          )}
                        ></UikInput>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          );
        } else if (field !== "structured" && field !== "image") {
          return (
            <div className={styles.inputContainer}>
              <div className={styles.inputHeader}>
                {capitalizeFirstLetter(field)}
              </div>
              <UikInput
                disabled={!viewingCurrentVersion || readOnly}
                placeholder={"Value"}
                className={styles.searchInputField}
                name={field}
                onChange={(e) => onChangeFieldValue(e)}
                value={returnFieldValue(field)}
              ></UikInput>
            </div>
          );
        }
      });
  };

  const renderDocumentDynamicFields = (updatedDocumentDynamicFields) => {
    let options = { structured: {} };

    updatedDocumentDynamicFields.map((field) => {
      // Rendering fields that have corresponding saved SC field values in the DB
      if (field.field_value && field.field_value !== "null") {
        if (field.structured_content) {
          if (field.has_attached_structured_content_image) {
            const categoryLookup = field.field_name.split(".")[1];
            const selectedRow = field.field_name.split(".")[2];

            if (options.structured.hasOwnProperty(categoryLookup)) {
              if (
                options.structured[categoryLookup].hasOwnProperty(selectedRow)
              ) {
                options.structured[categoryLookup][selectedRow] = {
                  image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                    field.id
                  }`,
                  width: field.image_width ? parseInt(field.image_width) : 64,
                  height: field.image_height
                    ? parseInt(field.image_height)
                    : 64,
                };
              } else {
                options.structured[categoryLookup][selectedRow] = {};
                options.structured[categoryLookup][selectedRow] = {
                  image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                    field.id
                  }`,
                  width: field.image_width ? parseInt(field.image_width) : 64,
                  height: field.image_height
                    ? parseInt(field.image_height)
                    : 64,
                };
              }
            } else {
              options.structured[categoryLookup] = {};
              options.structured[categoryLookup][selectedRow] = {};
              options.structured[categoryLookup][selectedRow] = {
                image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                  field.id
                }`,
                width: field.image_width ? parseInt(field.image_width) : 64,
                height: field.image_height ? parseInt(field.image_height) : 64,
              };
            }
          } else {
            // Rendering structured content fields that have multiple grouped properties
            if (field.field_name.split(".").length === 4) {
              const categoryLookup = field.field_name.split(".")[1];
              const selectedRow = field.field_name.split(".")[2];
              const name = field.field_name.split(".")[3];
              if (options.structured.hasOwnProperty(categoryLookup)) {
                if (
                  options.structured[categoryLookup].hasOwnProperty(selectedRow)
                ) {
                  options.structured[categoryLookup][selectedRow][name] =
                    field.field_value;
                } else {
                  options.structured[categoryLookup][selectedRow] = {};
                  options.structured[categoryLookup][selectedRow][name] =
                    field.field_value;
                }
              } else {
                options.structured[categoryLookup] = {};
                options.structured[categoryLookup][selectedRow] = {};
                options.structured[categoryLookup][selectedRow][name] =
                  field.field_value;
              }
              // Rendering structured content fields that DO NOT have multiple grouped properties
            } else if (field.field_name.split(".").length === 3) {
              const categoryLookup = field.field_name.split(".")[1];
              const selectedRow = field.field_name.split(".")[2];
              if (options.structured.hasOwnProperty(categoryLookup)) {
                if (
                  options.structured[categoryLookup].hasOwnProperty(selectedRow)
                ) {
                  options.structured[categoryLookup][selectedRow] =
                    field.field_value;
                } else {
                  options.structured[categoryLookup][selectedRow] = {};
                  options.structured[categoryLookup][selectedRow] =
                    field.field_value;
                }
              } else {
                options.structured[categoryLookup] = {};
                options.structured[categoryLookup][selectedRow] = {};
                options.structured[categoryLookup][selectedRow] =
                  field.field_value;
              }
            } else {
              const categoryLookup = field.field_name;
              options[categoryLookup] = {};
              options[categoryLookup] = field.field_value;
            }
          }
        } else {
          if (field.field_name.split(".").length === 1) {
            // Handling the rendering of non-structured template fields
            options[field.field_name] = field.field_value;
          } else {
            // Handling the rendering of structured_product_claims
            const categoryLookup = field.field_name.split(".")[1];
            const selectedRow = field.field_name.split(".")[2];

            if (options.structured.hasOwnProperty(categoryLookup)) {
              if (
                options.structured[categoryLookup].hasOwnProperty(selectedRow)
              ) {
                options.structured[categoryLookup][selectedRow] =
                  field.field_value;
              } else {
                options.structured[categoryLookup][selectedRow] = {};
                options.structured[categoryLookup][selectedRow] =
                  field.field_value;
              }
            } else {
              options.structured[categoryLookup] = {};
              options.structured[categoryLookup][selectedRow] = {};
              options.structured[categoryLookup][selectedRow] =
                field.field_value;
            }
          }
        }
      } else {
        // Rendering non-structured image fields
        if (field.field_name.split(".")[0] === "image") {
          const imageIdentifier = field.field_name.split(".")[1];
          if (field.has_attached_image) {
            if (options.hasOwnProperty("image")) {
              if (options.image.hasOwnProperty(imageIdentifier)) {
                options.image[imageIdentifier] = {
                  image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                    field.id
                  }`,
                  width: field.image_width ? parseInt(field.image_width) : 64,
                  height: field.image_height
                    ? parseInt(field.image_height)
                    : 64,
                };
              } else {
                options.image[imageIdentifier] = {};
                options.image[imageIdentifier] = {
                  image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                    field.id
                  }`,
                  width: field.image_width ? parseInt(field.image_width) : 64,
                  height: field.image_height
                    ? parseInt(field.image_height)
                    : 64,
                };
              }
            } else {
              options["image"] = {};
              options["image"][imageIdentifier] = {};
              options["image"][imageIdentifier] = {
                image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                  field.id
                }`,
                width: field.image_width ? parseInt(field.image_width) : 64,
                height: field.image_height ? parseInt(field.image_height) : 64,
              };
            }
          } else {
            if (options.hasOwnProperty("image")) {
              options["image"][imageIdentifier] = `{{${field.field_name}}}`;
            } else {
              options["image"] = {};
              options["image"][imageIdentifier] = `{{${field.field_name}}}`;
            }
          }
        }

        // Rendering fields that DO NOT have corresponding saved SC field values in the DB
        // Rendering structured content fields that have multiple grouped properties
        if (field.field_name.split(".").length === 4) {
          const categoryLookup = field.field_name.split(".")[1];
          const selectedRow = field.field_name.split(".")[2];
          const name = field.field_name.split(".")[3];
          if (options.structured.hasOwnProperty(categoryLookup)) {
            if (
              options.structured[categoryLookup].hasOwnProperty(selectedRow)
            ) {
              options.structured[categoryLookup][selectedRow][
                name
              ] = `{{${field.field_name}}}`;
            } else {
              options.structured[categoryLookup][selectedRow] = {};
              options.structured[categoryLookup][selectedRow][
                name
              ] = `{{${field.field_name}}}`;
            }
          } else {
            options.structured[categoryLookup] = {};
            options.structured[categoryLookup][selectedRow] = {};
            options.structured[categoryLookup][selectedRow][
              name
            ] = `{{${field.field_name}}}`;
          }
          // Rendering structured content fields that DO NOT have multiple grouped properties
        } else if (field.field_name.split(".").length === 3) {
          const categoryLookup = field.field_name.split(".")[1];
          const selectedRow = field.field_name.split(".")[2];
          if (options.structured.hasOwnProperty(categoryLookup)) {
            if (
              options.structured[categoryLookup].hasOwnProperty(selectedRow)
            ) {
              options.structured[categoryLookup][
                selectedRow
              ] = `{{${field.field_name}}}`;
            } else {
              options.structured[categoryLookup][selectedRow] = {};
              options.structured[categoryLookup][
                selectedRow
              ] = `{{${field.field_name}}}`;
            }
          } else {
            options.structured[categoryLookup] = {};
            options.structured[categoryLookup][selectedRow] = {};
            options.structured[categoryLookup][
              selectedRow
            ] = `{{${field.field_name}}}`;
          }
        } else {
          const categoryLookup = field.field_name;
          options[categoryLookup] = {};
          options[categoryLookup] = `{{${field.field_name}}}`;
        }
      }
    });

    setDynamicFieldData({});
    setUnselectedStructuredContents({});
    const webViewerEl = document.getElementById("webViewer");
    window.WebViewer.getInstance(webViewerEl)
      .Core.documentViewer.getDocument()
      .applyTemplateValues(options);
    setDynamicImageFields([]);
  };

  const deleteDynamicImageField = (field) => {
    const documentDynamicField = documentDynamicFields.find(
      (f) => f.field_name === field
    );

    let fields = {
      id: documentDynamicField.id,
    };
    httpClient
      .delete(`/document_dynamic_fields/${documentDynamicField.id}.json`, {
        fields,
      })
      .then((res) => {
        dispatch(fetchDocumentDynamicFields(docId)).then((response) => {
          renderDocumentDynamicFields(response);
        });
      });
  };

  const triggerStructuredProductClaimSearch = (fieldName, row) => {
    setSelectedFieldName(fieldName);
    setSelectedRow(row);
  };

  const renderTemplateNonStructuredContent = () => {
    return (
      <div className={styles.claimsFormContainer}>
        <div className={styles.claimsForm}>
          <div className={styles.formContainer}>
            {templateFields.length > 0
              ? renderTemplateFields()
              : renderLoadingState()}
          </div>
        </div>
      </div>
    );
  };

  const composeNonSelectedSCFieldCard = (structuredField) => {
    let structuredCategory = structuredField.split(".")[1];
    let row = structuredField.split(".")[2];
    let field = structuredField.split(".")[0];
    let isProductClaim = structuredCategory === "product_claims";

    let scEl = <div></div>;

    return (scEl = (
      <div className={styles.scInputContainer}>
        <div className={styles.inputHeader}>{structuredField}</div>
        <div
          className={styles.containerStructuredContent}
          onChange={(e) => {
            isProductClaim &&
              onSearchStructuredContentsChanged(
                e.target.value,
                structuredCategory
              );
          }}
          onClick={() => {
            !isProductClaim
              ? triggerStructuredContentSearch(structuredCategory, row)
              : triggerStructuredProductClaimSearch(structuredCategory, row);
          }}
        >
          <UikInput
            disabled={!viewingCurrentVersion || readOnly}
            placeholder={`Search`}
            className={styles.searchInputStructured}
            name={field}
            onChange={(e) =>
              onSearchStructuredContentsChanged(
                e.target.value,
                structuredCategory
              )
            }
            // onBlur={() => {
            //   resetStructuredSearchValue(fieldName);
            // }}
            // value={searchVal}
          ></UikInput>
          {structuredField ===
            [field, selectedFieldName, selectedRow].join(".") &&
            renderStructuredContentSearchResults(structuredCategory)}
        </div>
      </div>
    ));
  };

  const composeSelectedNonImageSCFieldCard = (
    selectedStructuredContentField
  ) => {
    // selectedStructuredContents[selectedStructuredContentField]
    let scEl = <div></div>;
    return (scEl = (
      <div className={styles.scInputContainer}>
        <div className={styles.inputHeader}>
          {selectedStructuredContentField}
        </div>
        <div className={styles.containerStructuredContent}>
          <ul className={styles.selectedStructuredContent}>
            <li className={styles.selectedStructuredContentCard}>
              <div className={styles.structuredContentCardBody}>
                <div className={styles.structuredContentCardCaption}>
                  {
                    selectedStructuredContents[selectedStructuredContentField][
                      "field_value"
                    ]
                  }
                </div>
                {viewingCurrentVersion && !readOnly && (
                  <div
                    className={styles.closeIcon}
                    onClick={(e) => {
                      !props.readOnly &&
                        unselectStructuredContent(
                          e,
                          selectedStructuredContentField
                        );
                    }}
                  >
                    <img src={CloseSVG} />
                  </div>
                )}
              </div>
              {selectedStructuredContents[selectedStructuredContentField]
                .structured_content_id && (
                <div className={styles.structuredContentCardLabel}>
                  <img
                    src={structuredContentIcon}
                    className={styles.structuredContentIcon}
                  />
                  {
                    selectedStructuredContents[selectedStructuredContentField][
                      "structured_content_label"
                    ]
                  }
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    ));
  };

  const composeSelectedImageSCFieldCard = (
    selectedStructuredContentField,
    fieldName
  ) => {
    let scEl = <div></div>;

    return (scEl = (
      <div className={styles.scInputContainer}>
        <div className={styles.inputHeader}>
          {selectedStructuredContentField}
        </div>
        <div className={styles.containerStructuredContent}>
          <div>
            <div className={styles.imageHeading}>
              <p>{selectedStructuredContentField["field_value"]}</p>
              {!props.readOnly && (
                <img
                  className={styles.closeIcon}
                  src={CloseSVG}
                  onClick={() => {
                    !props.readOnly &&
                      unselectStructuredContent(
                        null,
                        selectedStructuredContentField
                      );
                  }}
                />
              )}
            </div>
            <img
              className={styles.imageSelected}
              src={`${loadDocumentUrl()}/${
                selectedStructuredContents[selectedStructuredContentField]
                  .structured_content_image
              }`}
            />
            <div className={styles.imageDimensionsInputContainer}>
              <div className={styles.dimensionInputContainer}>
                <div className={styles.inputHeader}>{"Width"}</div>
                <UikInput
                  disabled={!viewingCurrentVersion || readOnly}
                  placeholder={"Value"}
                  className={styles.searchInputFieldStructured}
                  name={`${selectedStructuredContentField}`}
                  onChange={(e) =>
                    onChangeStructuredImageField(
                      e,
                      "image_width",
                      selectedStructuredContentField
                    )
                  }
                  value={
                    structuredFieldDimensions[selectedStructuredContentField]
                      ? structuredFieldDimensions[
                          selectedStructuredContentField
                        ].image_width
                      : selectedStructuredContents[
                          selectedStructuredContentField
                        ].image_width
                  }
                ></UikInput>
              </div>
              <div className={styles.dimensionInputContainer}>
                <div className={styles.inputHeader}>{"Height"}</div>
                <UikInput
                  disabled={!viewingCurrentVersion || readOnly}
                  placeholder={"Value"}
                  className={styles.searchInputFieldStructured}
                  name={`${selectedStructuredContentField}`}
                  onChange={(e) =>
                    onChangeStructuredImageField(e, "image_height", fieldName)
                  }
                  value={
                    structuredFieldDimensions[selectedStructuredContentField]
                      ? structuredFieldDimensions[
                          selectedStructuredContentField
                        ].image_height
                      : selectedStructuredContents[
                          selectedStructuredContentField
                        ].image_height
                  }
                ></UikInput>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const composeSelectedMultiPropertySCFieldCard = (
    selectedMultiPropertySCField,
    fieldName
  ) => {
    let scEl = <div></div>;

    return (scEl = (
      <div className={styles.scInputContainer}>
        <div className={styles.inputHeader}>{fieldName}</div>
        <div className={styles.containerStructuredContent}>
          <ul className={styles.selectedStructuredContent}>
            <li className={styles.selectedStructuredContentCard}>
              <div className={styles.structuredContentCardBody}>
                <div className={styles.structuredContentCardCaption}>
                  {
                    selectedMultiPropertySCCardData[
                      selectedMultiPropertySCField
                    ]["displayValue"]
                  }
                </div>
                {viewingCurrentVersion && !readOnly && (
                  <div
                    className={styles.closeIcon}
                    onClick={(e) => {
                      !props.readOnly &&
                        unselectMultiPropertyStructuredContent(
                          e,
                          selectedMultiPropertySCCardData[
                            selectedMultiPropertySCField
                          ]["propertyNames"]
                        );
                    }}
                  >
                    <img src={CloseSVG} />
                  </div>
                )}
              </div>
              {
                <div className={styles.structuredContentCardLabel}>
                  <img
                    src={structuredContentIcon}
                    className={styles.structuredContentIcon}
                  />
                  {
                    selectedMultiPropertySCCardData[
                      selectedMultiPropertySCField
                    ].structuredContentLabel
                  }
                </div>
              }
            </li>
          </ul>
        </div>
      </div>
    ));
  };

  const renderTemplateStructuredContent = () => {
    // Identifying all structured template fields
    let allStructuredFields = [];
    if (templateFields.length > 0) {
      templateFields.map((field, index) => {
        if (field === "structured") {
          let structuredCategories = Object.keys(
            templateKeysSchema.keys["structured"].properties
          );

          structuredCategories.map((structuredCategory, index) => {
            // For each structured category + row pairing, we need ONE structured field element (scEl)
            let structuredSchemaProperties =
              templateKeysSchema.keys[field].properties;
            let structuredSchemaRows = [];
            if (structuredSchemaProperties[structuredCategory].properties) {
              structuredSchemaRows = Object.keys(
                structuredSchemaProperties[structuredCategory].properties
              );
            }

            structuredSchemaRows.map((row, index) => {
              allStructuredFields.push(
                [field, structuredCategory, row].join(".")
              );
            });
          });
        }
      });
    }

    // Preparing structured field cards for the template panel

    let structuredFieldElements = [];

    if (templateFields.length > 0) {
      allStructuredFields.map((structuredField) => {
        // Check if there is a multi-property structured content selected for this field
        let selectedMultiPropertySCField = Object.keys(
          selectedMultiPropertySCCardData
        ).find((fieldName) => fieldName === structuredField);

        // Check if there is a structured content selected for this field
        let selectedStructuredContentField = Object.keys(
          selectedStructuredContents
        ).find((selectedField) => structuredField === selectedField);

        // Composing field cards based on field type and selection status
        if (selectedMultiPropertySCField) {
          // Composing multi-property sc field card
          structuredFieldElements.push(
            composeSelectedMultiPropertySCFieldCard(
              selectedMultiPropertySCField,
              structuredField
            )
          );
        } else if (selectedStructuredContentField) {
          // Composing non-multi-property sc field card
          if (
            selectedStructuredContents[selectedStructuredContentField]
              .structured_content_type === "image"
          ) {
            // Compose image type structured_content card
            structuredFieldElements.push(
              composeSelectedImageSCFieldCard(
                selectedStructuredContentField,
                structuredField
              )
            );
          } else {
            // Render non-image structured_content card
            structuredFieldElements.push(
              composeSelectedNonImageSCFieldCard(selectedStructuredContentField)
            );
          }
        } else {
          // Composing input fields for empty (non-selected) structured contents

          structuredFieldElements.push(
            composeNonSelectedSCFieldCard(structuredField)
          );
        }
      });
    }

    return structuredFieldElements;
  };

  const properties = () => {
    return selectedCategory && selectedCategory.all_property_labels;
  };

  const resetStructuredSearchValue = (fieldName) => {
    setActiveStructuredField(null);

    let sscv = { ...searchStructuredContentsValue };
    sscv[fieldName] = "";

    setSearchStructuredContentsValue(sscv);
  };

  const selectSCByProperties = (selectedContent) => {
    let selectedSC = allSCForCategory.find((sc) => {
      if (sc.label === selectedContent.Label) {
        return true;
      } else {
        return false;
      }
    });

    if (selectedSC) {
      setChosenContent(selectedSC);
    }
  };

  const confirmSCSelection = () => {
    selectStructuredContent(null, chosenContent, selectedFieldName);
    stopSelectingSCbyProperties();
  };

  const establishGroupedTemplateFields = (fieldName) => {
    const regex = new RegExp(`${fieldName}`);

    let groupedFields = [];

    if (templateFields.length > 0) {
      templateFields.map((tf) => {
        if (tf.match(regex)) {
          groupedFields.push(tf);
        }
      });
    }

    if (!groupedFields.find((field) => field === `structured.${fieldName}`)) {
      groupedFields.push(`structured.${fieldName}`);
    }

    setGroupedTemplateFields(groupedFields);
  };

  const selectStructuredContent = (e, sc, fieldName) => {
    let sscv = { ...searchStructuredContentsValue };
    let categoryLookup = sc.structured_content_category.lookup;
    console.log("fieldName", fieldName);
    console.log("sc", sc);
    console.log("sc.scc", sc.structured_content_category);
    console.log("categoryLookup", categoryLookup);

    let updatedSelectedStructuredContents = {
      ...selectedStructuredContents,
    };

    // const regex = new RegExp(`${categoryLookup}`);

    // let groupedTemplateFields = [];

    // templateFields.map((tf) => {
    //   if (tf.match(regex)) {
    //     groupedTemplateFields.push(tf);
    //   }
    // });

    groupedTemplateFields.map((templateFieldLookup) => {
      let matchedPropertyValue;

      if (sc.structured_content_property_values) {
        matchedPropertyValue = sc.structured_content_property_values.find(
          (scpv) => {
            const regex = new RegExp(`${scpv.structured_content_property_key}`);
            if (templateFieldLookup.match(regex)) {
              return true;
            } else {
              return false;
            }
          }
        );
      } else {
        matchedPropertyValue = null;
      }

      if (matchedPropertyValue) {
        const fieldName = `structured.${sc.structured_content_category.lookup}.${selectedRow}.${templateFieldLookup}`;

        updatedSelectedStructuredContents[fieldName] = {
          field_value: matchedPropertyValue.value,
          document_id: docId,
          structured_content_id: sc.id,
          structured_content_label: sc.label,
          structured_content_type: sc.content_type,
          structured_content_image: sc.image_url,
          display_sc_property_value: sc.display_sc_property_value,
          image_width: structuredFieldDimensions[fieldName]
            ? structuredFieldDimensions[fieldName].image_width
            : null,
          image_height: structuredFieldDimensions[fieldName]
            ? structuredFieldDimensions[fieldName].image_height
            : null,
        };
      }
    });
    // Set the selected structured content.
    if (
      templateKeysSchema.keys.structured.properties[
        categoryLookup ? categoryLookup : selectedFieldName
      ].properties[selectedRow].hasOwnProperty("properties")
    ) {
      updatedSelectedStructuredContents[`structured.${categoryLookup}`] = {
        field_value: sc.caption,
        document_id: docId,
        structured_content_id: sc.id,
        structured_content_label: sc.label,
        structured_content_type: sc.content_type,
        structured_content_image: sc.image_url,
        image_width: structuredFieldDimensions[fieldName]
          ? structuredFieldDimensions[fieldName].image_width
          : null,
        image_height: structuredFieldDimensions[fieldName]
          ? structuredFieldDimensions[fieldName].image_height
          : null,
      };
    } else {
      updatedSelectedStructuredContents[
        `structured.${
          categoryLookup ? categoryLookup : selectedFieldName
        }.${selectedRow}`
      ] = {
        field_value: sc.caption,
        document_id: docId,
        structured_content_id: sc.id,
        structured_content_label: sc.label,
        structured_content_type: sc.content_type,
        structured_content_image: sc.image_url,
        image_width: structuredFieldDimensions[fieldName]
          ? structuredFieldDimensions[fieldName].image_width
          : null,
        image_height: structuredFieldDimensions[fieldName]
          ? structuredFieldDimensions[fieldName].image_height
          : null,
      };
    }

    prepareMultiPropertSCFieldCardData(updatedSelectedStructuredContents);

    setSelectedStructuredContents(updatedSelectedStructuredContents);
    sscv[sc.structured_content_category.lookup] = "";
    setSearchStructuredContentsValue(sscv);
    setStructuredContentsResults([]);
  };

  const renderGroupedTemplateFields = () => {
    return groupedTemplateFields.map((tf) => {
      if (tf === `structured.${selectedCategory.lookup}.${selectedRow}`) {
        return (
          <div className={styles.itemContainer}>
            <div className={styles.groupedTemplateFieldItem}></div>
            <div className={styles.contentPropertyItemContainer}>
              <div className={styles.templateString}>{`{{${tf}}}`}</div>
              <div className={styles.propertyValue}>
                {chosenContent.caption}
              </div>
            </div>
          </div>
        );
      } else {
        let matchedPropertyValue =
          chosenContent.structured_content_property_values.find((scpv) => {
            const regex = new RegExp(`${scpv.structured_content_property_key}`);
            if (tf.match(regex)) {
              return true;
            } else {
              return false;
            }
          });
        return (
          <div className={styles.itemContainer}>
            <div className={styles.groupedTemplateFieldItem}></div>
            <div className={styles.contentPropertyItemContainer}>
              <div
                className={styles.templateString}
              >{`{{structured.${selectedCategory.lookup}.${selectedRow}.${tf}}}`}</div>
              <div className={styles.propertyValue}>
                {matchedPropertyValue
                  ? matchedPropertyValue.value
                  : "Property value not found, please verify that your placeholder contains the correct property name."}
              </div>
            </div>
          </div>
        );
      }
    });
  };

  const unselectMultiPropertyStructuredContent = (e, fieldGroup) => {
    let ssc = { ...selectedStructuredContents };

    let unselectedSc = {
      ...unselectedStructuredContents,
    };

    fieldGroup.map((field) => {
      unselectedSc[field] = {
        ddf_id: ssc[field].ddf_id,
        field_value: ssc[field].caption,
        document_id: ssc[field].document_id,
        structured_content_id: ssc[field].id,
        structured_content_label: ssc[field].label,
      };

      delete ssc[field];
    });
    prepareMultiPropertSCFieldCardData(ssc);

    setUnselectedStructuredContents(unselectedSc);
    setSelectedStructuredContents(ssc);
  };

  const unselectStructuredContent = (e, field) => {
    let sscv = { ...searchStructuredContentsValue };
    let ssc = { ...selectedStructuredContents };

    let groupedTemplateFields = [];

    const regex = new RegExp(`${field}`);

    if (templateFields.length > 0) {
      templateFields.map((tf) => {
        if (tf.match(regex)) {
          groupedTemplateFields.push(tf);
        }
      });
    }

    if (!groupedTemplateFields.find((f) => f === field)) {
      groupedTemplateFields.push(field);
    }

    if (groupedTemplateFields.length === 1) {
      // If the selected structured content has a ddf_id value > 0
      // It means we need to remove the document dynamic field value in the database so it doesn't persist.
      if (ssc[field].ddf_id > 0) {
        setUnselectedStructuredContents({
          ...unselectedStructuredContents,
          [field]: {
            ddf_id: ssc[field].ddf_id,
            field_value: ssc[field].caption,
            document_id: ssc[field].document_id,
            structured_content_id: ssc[field].id,
            structured_content_label: ssc[field].label,
          },
        });
      }

      delete ssc[field];
    } else {
      let unselectedStructuredContentsCopy = {
        ...unselectedStructuredContents,
      };
      let selectedSc = ssc[field];
      groupedTemplateFields.map((field) => {
        unselectedStructuredContentsCopy[field] = {
          ddf_id: selectedSc.ddf_id,
          field_value: selectedSc.field_value,
          document_id: selectedSc.document_id,
          structured_content_id: selectedSc.id,
          structured_content_label: selectedSc.label,
        };

        delete ssc[field];
      });
      setUnselectedStructuredContents(unselectedStructuredContentsCopy);
    }

    setSelectedStructuredContents(ssc);
  };

  const renderStructuredContentSearchResults = (fieldName) => {
    if (
      structuredContentsResults &&
      structuredContentsResults[fieldName] &&
      structuredContentsResults[fieldName].length > 0
    ) {
      return (
        <ul className={styles.structuredContentSearchResults}>
          {structuredContentsResults[fieldName].map((sc, index) => {
            return (
              <li
                key={sc.id}
                className={styles.structuredContentCard}
                onClick={(e) => {
                  selectStructuredContent(e, sc, fieldName);
                }}
              >
                <div className={styles.structuredContentCardBody}>
                  <div className={styles.cardBodyContainer}>
                    <div className={styles.structuredContentCardCaption}>
                      {sc.caption}
                    </div>
                    {sc.display_sc_property_value && (
                      <div className={styles.structuredContentCardCaption}>
                        {sc.display_sc_property_value}
                      </div>
                    )}
                  </div>
                </div>
                {sc.content_type === "image" && (
                  <img
                    className={styles.image}
                    src={`${loadDocumentUrl()}/${sc.image_url}`}
                  />
                )}
                <div className={styles.structuredContentCardLabel}>
                  <img
                    src={structuredContentIcon}
                    className={styles.structuredContentIcon}
                  />
                  {sc.label}
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      if (structuredContentsResults && fieldName === activeStructuredField) {
        return (
          <ul className={styles.structuredContentSearchResults}>
            <p className={styles.emptyState}>
              No structured content matches your search
            </p>
          </ul>
        );
      } else {
        return <ul></ul>;
      }
    }
  };

  const undoSelection = () => {
    setChosenContent(null);
  };

  const getContentProperties = () => {
    let structuredContent;

    if (
      Object.keys(searchStructuredContentsValue).length === 0 ||
      !debouncedSearchStructuredContentsValue
    ) {
      structuredContent = allSCForCategory;
    } else {
      if (sCCategorySearchResults.length > 0) {
        structuredContent = sCCategorySearchResults;
      } else {
        structuredContent = [];
      }
    }

    let propertiesByStructuredContent = [];
    structuredContent.forEach((sc) => {
      let propertyObj = {};

      propertyObj["Caption"] = sc.caption;
      propertyObj["Label"] = sc.label;

      if (sc.structured_content_property_values) {
        sc.structured_content_property_values.map((scpv) => {
          propertyObj[scpv.structured_content_property_label] = scpv.value;
        });
      }

      propertiesByStructuredContent.push(propertyObj);
    });
    return propertiesByStructuredContent;
  };

  const onImageFileDrop = (acceptedFile) => {
    setImageLoading({
      fieldName: activeImageField,
      fileName: acceptedFile[0].name,
      fileType: acceptedFile[0].type,
    });
    renderProgress();

    let fieldName = activeImageField;

    let updatedImageField = {
      fieldName: fieldName,
      imageFile: acceptedFile[0],
      fileName: acceptedFile[0].name,
      fileType: acceptedFile[0].type,
      width: 64,
      height: 64,
    };

    let updatedImageFields = [...dynamicImageFields, updatedImageField];

    setDynamicImageFields(updatedImageFields);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.text}>Editable Fields</div>
        <div className={styles.closeIcon}>
          <img src={CloseSVG} onClick={onClose} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.dynamicFieldsContainer}>
          {renderTemplateStructuredContent()}
          {renderTemplateNonStructuredContent()}
        </div>
      </div>
      {viewingCurrentVersion && (
        <div className={styles.formButtonContainer}>
          <div className={styles.formButton}>
            <Button
              disabled={!viewingCurrentVersion || readOnly}
              original
              text={templateFields.length > 0 ? "Apply Fields" : ""}
              onClick={(e) => {
                saveDocumentDynamicFields(e);
              }}
            />
          </div>
        </div>
      )}
      <NewModal
        visible={showStructuredContentModal}
        type="templatePreviewModal"
      >
        <div className={styles.categoryName}>
          <div className={styles.leftContainer}>
            {chosenContent && (
              <img
                onClick={() => {
                  undoSelection();
                }}
                className={styles.backArrow}
                src={BackArrowIcon}
              />
            )}
            <h2>{selectedCategory && selectedCategory.name}</h2>
          </div>
          <img
            className={styles.closeIcon}
            src={CloseSVGBlack}
            onClick={() => {
              stopSelectingSCbyProperties();
            }}
          />
        </div>
        <UikDivider />
        {debouncedSearchStructuredContentsValue &&
        debouncedSearchStructuredContentsValue.length > 0
          ? !chosenContent && (
              <div className={styles.searchInputContainer}>
                <img className={styles.searchIcon} src={SearchIcon} />
                <input
                  type="text"
                  placeholder={"Search"}
                  onChange={(e) =>
                    onSearchStructuredContentsChanged(
                      e.target.value,
                      selectedFieldName
                    )
                  }
                  value={
                    searchStructuredContentsValue
                      ? searchStructuredContentsValue[selectedFieldName]
                      : ""
                  }
                />
              </div>
            )
          : !showLoader &&
            !chosenContent && (
              <div className={styles.searchInputContainer}>
                <img className={styles.searchIcon} src={SearchIcon} />
                <input
                  type="text"
                  placeholder={"Search"}
                  onChange={(e) =>
                    onSearchStructuredContentsChanged(
                      e.target.value,
                      selectedFieldName
                    )
                  }
                  value={
                    searchStructuredContentsValue
                      ? searchStructuredContentsValue[selectedFieldName]
                      : ""
                  }
                />
                {/* {searchStructuredContentsValue && (
              <img
                className={styles.crossIcon}
                src={CrossIcon}
                onClick={() => clearSearch()}
              />
            )} */}
              </div>
            )}
        {showLoader && (
          <div className={styles.loaderContainer}>
            <Lottie options={defaultOptions} height={92} width={82} />
          </div>
        )}
        {!showLoader && !chosenContent && (
          <div className={styles.tableContainer}>
            <TableView
              label={""}
              items={getContentProperties()}
              itemFields={properties()}
              itemClickFunction={selectSCByProperties}
              largeColumns={
                selectedCategory
                  ? [selectedCategory.display_sc_property_label]
                  : null
              }
            />
          </div>
        )}
        {chosenContent && (
          <div className={styles.selectedContentCardContainer}>
            <h3>
              {chosenContent.display_sc_property_label
                ? chosenContent.display_sc_property_label
                : "Caption"}
            </h3>
            <div className={styles.selectedContentCard}>
              <p>
                {chosenContent.display_sc_property_value
                  ? chosenContent.display_sc_property_value
                  : chosenContent.caption}
              </p>
              <div className={styles.structuredContentCardLabel}>
                <img
                  src={structuredContentIcon}
                  className={styles.structuredContentIcon}
                />
                {chosenContent.label}
              </div>
            </div>
          </div>
        )}
        {chosenContent && (
          <div className={styles.groupedTemplateFieldsContainer}>
            <h3 className={styles.affectedContent}>affected content</h3>
            {renderGroupedTemplateFields()}
          </div>
        )}
        <div className="clear" />
        <UikDivider />
        <div className="buttons">
          {chosenContent && (
            <Button onClick={confirmSCSelection} original text="Confirm" />
          )}
          <Button
            onClick={() => {
              stopSelectingSCbyProperties();
            }}
            transparent
            text="Cancel"
          />
        </div>
      </NewModal>
    </div>
  );
};
