import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "../Common/Buttons/ButtonPrimary";

import styles from "./ReferenceDocumentPanel.module.scss";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikFormInputGroup,
  UikInput,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Assets */
import linkIcon from "../../images/icons/svg/link-icon.svg";

import dimpleSVG from "../../images/icons/svg/toolbox-dimple-blue.svg";

/* Variables */

class DocumentReferencePanel extends React.Component {
  constructor(props) {
    super();
    this.resizeTimeout = null;

    this.state = {
      conversation: {},
    };
  }

  renderDocumentReference = (ref, idx) => {
    return (
      <div key={ref.id} className={styles.referenceContentUploading}>
        <div className={styles.attachmentReferenceIdContainer}>
          <span
            className={
              ref.reference_document_label
                ? styles.attachmentReferenceId
                : styles.emptyAttachmentReferenceId
            }
          >
            {ref.reference_document_label
              ? ref.reference_document_label
              : "No Label"}
          </span>
        </div>
        <div>
          <div className={styles.attachmentDocumentTitle}>{ref.title}</div>
          <div className={styles.attachmentDocumentDescription}>
            {ref.description}
          </div>
        </div>
        <div className={styles.attachmentCardFooter}>
          <Link
            to={{
              pathname: `/references/${ref.id}/main`,
              state: {
                readOnly: false,
                docId: this.props.docId,
                versionId: this.props.versionId,
                versionNumber: this.props.versionNumber,
                originalDocumentId: this.props.originalDocumentId,
              },
            }}
            className={styles.attachmentDocumentLink}
          >
            View Document
          </Link>
          <div className={styles.annotationCountContainer}>
            <span className={styles.annotationCount}>
              {ref.annotation_count}
            </span>
            <div className={styles.descriptionBox}>
              <img className={styles.descriptionBoxImage} src={dimpleSVG} />
              <div className={styles.descriptionText}>
                {"Number of annotations in this reference document"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const actionsDropDown = ({ onClick }) => {
      return <div onClick={onClick}>&#9679;&#9679;&#9679;</div>;
    };

    return (
      <div
        id="documentAttachmentPanel"
        className={styles.documentReferencePanel}
      >
        <div id="sidePanelAttachmentHeader" className={styles.sidePanelHeader}>
          <span className={styles.sidePanelHeadingTitle}>
            Reference Documents
          </span>
          <span
            className={styles.closeComment}
            onClick={() => this.props.close()}
          >
            &times;
          </span>
        </div>
        {this.props.referenceDocuments && (
          <div
            id="sidePanelAttachmentOptions"
            className={
              this.props.referenceDocuments.length > 0
                ? styles.sidePanelOptions
                : styles.emptySidePanelOptions
            }
          >
            {this.props.referenceDocuments.length === 0 && (
              <div className={styles.emptyStateMessage}>
                <img src={linkIcon}></img>
                <span className={styles.emptyStateMessageLineOne}>
                  There are no references.
                </span>
                <span className={styles.emptyStateMessageLineTwo}>
                  To create one, highlight some text and select the link icon.
                </span>
              </div>
            )}
            {this.props.referenceDocuments.map((attachment, idx) => {
              return this.renderDocumentReference(attachment, idx);
            })}
          </div>
        )}
        {this.props.referenceDocuments &&
          this.props.referenceDocuments.length > 0 && (
            <div className={styles.addReferenceDocumentButton}>
              <Button
                original
                text="Add Reference Document"
                onClick={this.props.showReferencesLibrary}
              />
            </div>
          )}
      </div>
    );
  }
}

DocumentReferencePanel.propTypes = {
  close: PropTypes.func,
  selectConversation: PropTypes.func,
  selectReference: PropTypes.func,
  referenceBlockId: PropTypes.string,
  referenceId: PropTypes.number,
  docId: PropTypes.number,
  pageNumber: PropTypes.string,
};

export default DocumentReferencePanel;
