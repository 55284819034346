/* External libraries */
import React from "react";
import Select from "react-select";
import "./AudienceSelect.scss";

/* Variables */

class AudienceSelect extends React.Component {
  constructor(props) {
    super(props);

    let selectedOptions = null;

    if (this.props.fieldType === "multi_select_options") {
      selectedOptions = this.props.defaultValue
        ? this.props.options.filter((option) =>
            this.props.defaultValue.includes(option.value)
          )
        : null;
    } else {
      selectedOptions = this.props.options.find(
        (option) => option.value === this.props.defaultValue
      );
    }

    if (!selectedOptions) {
      // See if there is a default for the select options and set it at that
      selectedOptions = this.props.options.find(
        (option) => option.default === true
      );

      if (selectedOptions) {
        const { name, values } = this.props;
        values[name] = selectedOptions.value;
      }
    }

    this.state = {
      selected: selectedOptions,
      loaded: false,
    };
  }

  onChange = (audiences) => {
    const { name, values } = this.props;

    if (this.props.fieldType === "multi_select_options") {
      values[name] = audiences
        ? audiences.map((audience) => audience.value)
        : null;
    } else {
      values[name] = audiences.value;
    }

    this.setState({
      selected: audiences,
    });
  };

  render() {
    const { values, name, options, label } = this.props;

    if (!this.state.loaded) {
      let selectedOptions = null;

      if (this.props.fieldType === "multi_select_options") {
        selectedOptions = this.props.defaultValue
          ? this.props.options.filter((option) =>
              this.props.defaultValue.includes(option.value)
            )
          : null;
      } else {
        selectedOptions = this.props.options.find(
          (option) => option.value === this.props.defaultValue
        );
      }

      if (!selectedOptions) {
        // See if there is a default for the select options and set it at that
        selectedOptions = this.props.options.find(
          (option) => option.default === true
        );

        if (selectedOptions) {
          values[name] = selectedOptions.value;
        }
      }

      this.setState({ selected: selectedOptions, loaded: true });
    }

    return (
      <div className={"audience_select"}>
        <span className="uik-content-title__wrapper">{label}</span>
        <Select
          placeholder={`Select ${label}`}
          value={this.state.selected}
          options={options}
          onChange={(e) => this.onChange(e)}
          isMulti={this.props.fieldType === "multi_select_options"}
          styles={{
            control: (styles) => ({
              ...styles,
              borderColor: "#D6DCE8",
              fontSize: "14px",
            }),
          }}
        />
      </div>
    );
  }
}

export default AudienceSelect;
