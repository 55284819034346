import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "rc-progress";

import Close from "../../../../images/icons/svg/close.svg";
import claimsEmptyIcon from "../../../../images/icons/svg/claims-empty-icon.svg";
import linkIcon from "../../../../images/icons/svg/link-icon.svg";
import AIRobot from "../../../../images/gifs/ai-robot.gif";
import Button from "../../../Common/Buttons/ButtonPrimary";
import Confidence from "../../../Common/Confidence";
import Tooltip from "../../../Common/Tooltip";
import httpClient from "../../../../lib/HttpClient";
import LinkClaimsSearch from "./LinkClaimsSearch";
import PaigeClaimsAnimation from "../../../../images/lotties/paige-claims-animation.json";
import Lottie from "react-lottie";
import CloseSVG from "../../../../images/icons/svg/close.svg";
import backArrow from "../../../../images/icons/svg/back-arrow.svg";
import notificationService from "../../../../services/Notifications";
import ClaimCard from "../Claims/ClaimCard.js";

import fetchClaims from "../../../../actions/document/fetchClaims";
import fetchDocumentClaims from "../../../../actions/document/fetchDocumentClaims";

import styles from "./PaigeClaims.module.scss";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikFormInputGroup,
  UikInput,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
} from "@uik";

export default (props) => {
  const {
    library,
    linkExistingAnnotation,
    linkManually,
    claimXfdfHighlightId,
    claimData,
    libraryId,
    setcreatedClaim,
    docId,
    closePanel,
    setClaimToLink,
    readOnly,
  } = props;

  const [loading, setLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState(0);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [suggestedClaims, setSuggestedClaims] = useState([]);
  const [creatingClaim, setCreatingClaim] = useState(false);
  const [hidePanelContents, setHidePanelContents] = useState(false);
  const [claimLabel, setClaimLabel] = useState(null);
  const [claimCaption, setClaimCaption] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchActive, setSearchActive] = useState(false);
  const [labelTaken, setLabelTaken] = useState(false);
  const [captionEmpty, setCaptionEmpty] = useState(false);
  const [labelEmpty, setLabelEmpty] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PaigeClaimsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const loadingStages = [
    {
      text1: "Hold Tight!",
      text2: "Paige is searching for relevant product claims",
    },
    {
      text1: "Only a Few More Seconds",
      text2: "Paige is almost done finding product claims",
    },
  ];

  const dispatch = useDispatch();

  const onClose = () => {
    closePanel();
  };

  const onChangeClaimCaption = (e) => {
    setClaimCaption(e.target.value);
    setCaptionEmpty(false);
  };

  const onChangeClaimLabel = (e) => {
    setClaimLabel(e.target.value);
    setLabelTaken(false);
    setLabelEmpty(false);
  };

  const saveClaim = () => {
    if (claimCaption && claimLabel) {
      const updatedClaimData = { ...claimData };
      updatedClaimData.label = claimLabel;
      updatedClaimData.caption = claimCaption;

      const claimParams = {
        claim: updatedClaimData,
      };
      httpClient
        .post("/claims.json", claimParams)
        .then((res) => {
          notificationService.addNotification(
            "New product claim created.",
            "New product claim created.",
            "success"
          );

          setcreatedClaim(res.data);
          dispatch(fetchClaims(docId));
          dispatch(fetchDocumentClaims(docId));
          onClose();
        })
        .catch((error) => {
          setLabelTaken(true);
        });
    } else if (!claimCaption && claimLabel) {
      setCaptionEmpty(true);
    } else if (!claimLabel && claimCaption) {
      setLabelEmpty(true);
    } else if (!claimLabel && !claimCaption) {
      setLabelEmpty(true);
      setCaptionEmpty(true);
    }
  };

  const linkClaim = (claim) => {
    setClaimToLink(claim);

    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const selectedTextQuads =
      instance.Core.documentViewer.getSelectedTextQuads()[
        claimData.page_number
      ];

    const highlight = new instance.Core.Annotations.TextSquigglyAnnotation();
    highlight.PageNumber = claimData.page_number;
    highlight.Id = claimData.xfdf_highlight_id;
    highlight.StrokeColor = new instance.Core.Annotations.Color(255, 0, 0);
    highlight.FillColor = new instance.Core.Annotations.Color(255, 0, 0);
    highlight.Quads = selectedTextQuads;
    highlight.Opacity = 0.5;
    highlight.BlendMode = "normal";

    // Custom xfdf data for the highlight
    highlight.setCustomData("highlight-type", "document_claim");

    highlight.Author = instance.Core.annotationManager.getCurrentUser();

    instance.Core.annotationManager.addAnnotation(highlight);
    instance.Core.annotationManager.drawAnnotations(highlight.PageNumber);
  };

  const initiateCreatingClaim = () => {
    setCreatingClaim(true);
    httpClient.get(`/next_claim_label.json`).then((res) => {
      setClaimLabel(res.data.next_claim_label);
      setClaimCaption(claimData.caption);
    });
  };

  const renderCreateClaimForm = () => {
    return (
      <div
        className={
          hidePanelContents ? styles.linkingClaimHidden : styles.linkingClaim
        }
      >
        <div
          className={
            hidePanelContents
              ? styles.creatingClaimHeaderHidden
              : styles.creatingClaimHeader
          }
        >
          <span className={styles.closeClaimPanel} onClick={() => onClose()}>
            <img src={CloseSVG} />
          </span>
          <div className={styles.linkClaimHeader}>
            <img
              src={backArrow}
              onClick={() => onClose()}
              className={styles.backArrow}
            />
            Link New Product Claim
          </div>
        </div>
        <div
          className={
            hidePanelContents ? styles.claimsFormHidden : styles.claimsForm
          }
        >
          <div className={styles.formContainer}>
            <div className={styles.inputContainer}>
              <div className={styles.inputHeader}>Product Claim Label</div>
              <UikInput
                placeholder={"Type a label..."}
                className={
                  labelTaken || labelEmpty
                    ? styles.searchInputFieldError
                    : styles.searchInputField
                }
                onChange={(e) => onChangeClaimLabel(e)}
                value={claimLabel}
              ></UikInput>
              {labelTaken && (
                <div className={styles.errorMessage}>
                  This product claim ID is taken
                </div>
              )}
              {labelEmpty && (
                <div className={styles.errorMessage}>
                  Type a product claim label to continue
                </div>
              )}
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputHeader}>Product Claim Text</div>
              <UikInput
                placeholder={"Type a product claim..."}
                className={
                  captionEmpty
                    ? styles.searchInputFieldError
                    : styles.searchInputField
                }
                onChange={(e) => onChangeClaimCaption(e)}
                value={claimCaption}
              ></UikInput>
              {captionEmpty && (
                <div className={styles.errorMessage}>
                  Type a product claim to continue
                </div>
              )}
            </div>
          </div>
          <div className={styles.formButton}>
            <Button
              original
              className={styles.formButtonBtn}
              text={"Create Product Claim"}
              onClick={() => {
                saveClaim();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderNoSearchResultsEmptyState = () => {
    return (
      <div className={styles.noSearchResultsEmptyStateContainer}>
        <div className={styles.titleText}>We could not find any results</div>
        <div className={styles.text}>
          Try checking your spelling or entering anouther input
        </div>
      </div>
    );
  };

  const renderSearchResults = () => {
    return searchResults.map((result, index) => {
      return (
        <div className={styles.searchResult}>
          <div className={styles.dividerContainer} />
          <ClaimCard
            claim={result}
            setSelectedClaim={setcreatedClaim}
            paigeSuggestedClaim={false}
            searchActive={true}
            linkClaim={linkClaim}
            inCommentPanel={false}
            readOnly={readOnly}
            searchTerm={searchTerm}
          />
          {index === searchResults.length - 1 && (
            <div className={styles.dividerContainer} />
          )}
        </div>
      );
    });
  };

  if (!readOnly) {
    useEffect(() => {
      setLoading(true);
      setLoadingPercentage(0);
      setLoadingStage(0);

      httpClient
        .post("/paige/recommend_claims.json", {
          document: claimData.caption,
          library_id: libraryId,
        })
        .then((res) => {
          setSuggestedClaims(res.data);
        })
        .catch((err) => {
          setSuggestedClaims([]);
        });
    }, [claimData]);
  }

  if (!readOnly) {
    useEffect(() => {
      //Fake Loading
      setTimeout(() => {
        setLoadingPercentage((prevPercentage) => {
          if (prevPercentage === 60) {
            setLoadingStage(1);
          } else if (prevPercentage === 100 && suggestedClaims) {
            setLoading(false);
          }
          if (prevPercentage !== 100) {
            return prevPercentage + 20;
          }
        });
      }, 300);
    }, [loadingPercentage]);
  }

  const renderSuggestedClaims = () => {
    return suggestedClaims.map((result, index) => {
      return (
        <div>
          {result.confidence > 0.5 && (
            <div>
              <div className={styles.dividerContainer} />
              <ClaimCard
                claim={result}
                setSelectedClaim={setcreatedClaim}
                searchActive={false}
                linkClaim={linkClaim}
                paigeSuggestedClaim={true}
                inCommentPanel={false}
                readOnly={readOnly}
              />
              {index === suggestedClaims.length - 1 && (
                <div className={styles.dividerContainer} />
              )}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={creatingClaim ? styles.headerHidden : styles.header}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Product Claim</div>
            <div className={styles.closeIcon} onClick={() => onClose()}>
              <img src={Close} />
            </div>
          </div>
          {
            <LinkClaimsSearch
              libraryId={libraryId}
              setSearchResults={setSearchResults}
              setSearchActive={setSearchActive}
              setSearchTerm={setSearchTerm}
            />
          }
        </div>
        {!loading &&
          !creatingClaim &&
          (searchResults.length > 0 || suggestedClaims.length > 0) && (
            <div className={styles.subheading}>
              <div className={styles.text}>
                {searchResults.length > 0
                  ? "search results"
                  : "suggested product claims by paige"}
              </div>
              {searchResults.length === 0 && (
                <div className={styles.tooltip}>
                  <Tooltip type="paige-description">
                    <div className={styles.body}>
                      <div className={styles.question}>Who is Paige?</div>
                      <div className={styles.description}>
                        Paige is the Papercurve AI. She suggests annotations
                        based on your content library and previous annotations.
                      </div>
                      <div className={styles.link}>
                        <a
                          target="_blank"
                          href="https://help.papercurve.com/knowledge-base/paige-ai"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        {loading && !readOnly && library && library.paige_claims_enabled ? (
          <div className={styles.loadingState}>
            <div className={styles.aiRobot}>
              {/* <img src={AIRobot} /> */}
              <Lottie options={defaultOptions} height={92} width={82} />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.text1}>
                {loadingStages[loadingStage].text1}
              </div>
              <div className={styles.text2}>
                {loadingStages[loadingStage].text2}
              </div>
            </div>
            <Line
              className={styles.progressBar}
              percent={loadingPercentage}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="round"
            />
          </div>
        ) : suggestedClaims &&
          suggestedClaims.length > 0 &&
          searchResults.length === 0 &&
          !creatingClaim ? (
          <React.Fragment>
            <div className={styles.suggestionsState}>
              <div className={styles.suggestionsContainer}>
                {suggestedClaims && renderSuggestedClaims()}
              </div>
            </div>
            <div className={styles.manualLinkingButton}>
              <Button
                original
                text={"Create Product Claim"}
                onClick={() => {
                  initiateCreatingClaim();
                }}
              />
            </div>
          </React.Fragment>
        ) : creatingClaim ? (
          renderCreateClaimForm()
        ) : searchResults.length > 0 ? (
          <div className={styles.searchResultsContainer}>
            {renderSearchResults()}
          </div>
        ) : searchResults.length === 0 && searchActive ? (
          renderNoSearchResultsEmptyState()
        ) : (
          suggestedClaims &&
          suggestedClaims.length === 0 && (
            <div className={styles.emptyState}>
              <div className={styles.linkIcon}>
                <img src={claimsEmptyIcon} />
              </div>
              <div className={styles.text}>
                There are no suggested annotations.
              </div>
              <div className={styles.manualLinkingButton}>
                <Button
                  original
                  text={"Create Product Claim"}
                  onClick={() => {
                    initiateCreatingClaim();
                  }}
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
