import React from "react";
import moment from "moment";
import Modal from "../../Common/Modal";

import eyeIcon from "../../../images/icons/svg/grey-eye.svg";
import greyDownload from "../../../images/icons/svg/grey-download.svg";
import closeBlack from "../../../images/icons/svg/close-black.svg";

import { UikDivider } from "@uik";

import styles from "./ShareLinkAnalyticsModal.module.scss";

export default (props) => {
  const { link, showActivityModal, setShowActivityModal } = props;

  const convertMillisecondsToMinutes = (millisecondVal) => {
    let minuteVal = millisecondVal / 60000;
    minuteVal = Math.ceil(minuteVal);
    if (minuteVal > 1) {
      return `Viewed for ${minuteVal} minutes`;
    } else if (minuteVal === 1) {
      return `Viewed for ${minuteVal} minute`;
    } else if (minuteVal === 0) {
      return `Viewed for under a minute`;
    }
  };

  const generateShareLinkAnalyticLog = (analyticsData) => {
    let allLinkEvents = [];

    analyticsData.views.map((dataPoint) => {
      allLinkEvents.push(dataPoint);
    });

    analyticsData.downloads.map((dataPoint) => {
      allLinkEvents.push(dataPoint);
    });

    allLinkEvents.sort(function compare(a, b) {
      var dateA = new Date(a.created_at);
      var dateB = new Date(b.created_at);
      return dateA - dateB;
    });

    return allLinkEvents.map((event) => {
      return (
        <div className={styles.eventDetails}>
          <div className={styles.eventType}>
            {event.event_type === "view" ? (
              <img className={styles.eventTypeIcon} src={eyeIcon} />
            ) : (
              <img className={styles.eventTypeIcon} src={greyDownload} />
            )}
            {convertMillisecondsToMinutes(event.duration)}
          </div>
          <div className={styles.eventDate}>
            {moment(event.updated_at).format("MMM DD, YYYY [at] h:mma")}
          </div>
        </div>
      );
    });
  };

  return (
    <Modal
      id="modal"
      visible={showActivityModal}
      type={"activityLogModal"}
      onClickAway={() => setShowActivityModal(false)}
    >
      <div>
        <div className={styles.activityLogHeaderContainer}>
          <h1 className={styles.activityLogHeading}>Share Link Activity Log</h1>

          <img
            className={styles.closeButton}
            onClick={() => setShowActivityModal(false)}
            src={closeBlack}
          />
        </div>
        <div className={styles.labelContainer}>
          <div className={styles.linkLabel}>{link && link.label}</div>
          <div className={styles.linkDocumentName}>
            - {link && link.document_name}
          </div>
        </div>
        <UikDivider />
        <div className={styles.activityLogSubheadingContainer}>
          <div className={styles.activityLogSubheadingOne}>activity</div>
          <div className={styles.activityLogSubheadingTwo}>{"date & time"}</div>
        </div>
        <UikDivider />
        <div>
          <div>{generateShareLinkAnalyticLog(link.share_link_analytics)}</div>
        </div>
      </div>
    </Modal>
  );
};
