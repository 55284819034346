import React, { useState } from "react";

import CloseSVG from "../../../../images/icons/svg/close.svg";
import Button from "../../../Common/Buttons/ButtonPrimary";

import styles from "./CreateModal.module.scss";

const CreateModal = (props) => {
  const { closeModal, createLink } = props;

  const [label, setLabel] = useState("");

  const onLabelChange = (e) => {
    setLabel(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.shadedAreaContainer} onClick={closeModal}></div>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.title}>New Share Link</div>
          <div className={styles.closeButton} onClick={closeModal}>
            <img src={CloseSVG} />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.labelInput}>
            <div className={styles.text}>link label</div>
            <input
              placeholder="Input Label"
              value={label}
              onChange={onLabelChange}
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button
            disabled={!label || label.match(/^\s+$/)}
            original
            text="Create Share Link"
            onClick={() => createLink(label)}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
