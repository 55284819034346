/* External libraries */
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../history";
import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";
import notificationService from "../../services/Notifications";

/* UI Kit */
import { UikInput, UikButton, Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import ContactsLeftMenu from "./ContactsLeftMenu";
import ContactListItem from "./ContactListItem";
import ContactListItemsHeader from "./ContactListItemsHeader";
import ContactEditForm from "./ContactEditForm";
import ContactCreateForm from "./ContactCreateForm";
//import UserReactivateForm from "./UserReactivateForm";

/* Assets */
import styles from "./Contacts.module.scss";

/* Variables */

class Contacts extends React.Component {
  constructor() {
    super();

    this.state = {
      contacts: [],
      filteredContacts: [],
      selectedContact: null,
      contactSearchQuery: null,
    };
  }

  componentWillMount = () => {
    this.loadContacts();
  };

  loadContactSegments = () => {
    httpClient.get(`/contact_segments.json`).then((res) => {
      this.setState({ contactSegments: res.data });

      let list = [];

      res.data.map((contactSegment, idx) => {
        let contactSegmentNameplate = `Segment: ${contactSegment.name}`;

        list.push({
          value: contactSegment.id,
          label: contactSegmentNameplate,
        });
      });

      this.setState({ contactSegmentsSelectList: list });
    });
  };

  loadContacts = () => {
    const selectedContactId = this.props.match.params.id;

    this.loadContactSegments();

    httpClient.get("/contacts.json").then((response) => {
      let filteredContacts = [];

      this.setState({ contacts: response.data });

      response.data.map((contact, index) => {
        filteredContacts.push(contact);
      });

      this.setState({ filteredContacts: filteredContacts });

      response.data.map((contact, index) => {
        if (contact.id == selectedContactId) {
          this.setState({ selectedContact: contact });
        }
      });
    });
  };

  handleContactSearch = async (e) => {
    e.preventDefault();
    this.setState({ selectedContact: null });
    let contacts = this.state.contacts;

    let contactSearchQuery = e.target.value.toLowerCase();
    if (contactSearchQuery.trim() === "") {
      this.setState({ filteredContacts: this.state.contacts });
      return;
    }
    contacts = contacts.filter(function (contact) {
      let matchingContact = false;
      if (
        contact.full_name.toLowerCase().indexOf(contactSearchQuery) != -1 ||
        contact.email.toLowerCase().indexOf(contactSearchQuery) != -1
      ) {
        matchingContact = true;
      }
      return matchingContact;
    });

    this.setState({ filteredContacts: contacts });
  };

  componentWillReceiveProps = (nextProps) => {
    const selectedContactId = nextProps.match.params.id;
    this.state.contacts.map((contact, index) => {
      if (contact.id == selectedContactId) {
        this.setState({ selectedContact: contact });
      }
    });
  };

  renderContactList = () => {
    const selectedContactId = this.props.match.params.id;
    const letterHeaders = [];
    return (
      <div>
        {this.state.filteredContacts.map((contact, index) => {
          const letter = contact.first_name.charAt(0).toUpperCase();
          const headerExists = letterHeaders.includes(letter);

          if (!headerExists) {
            letterHeaders.push(letter);
          }

          return (
            <div key={contact.id}>
              {!headerExists && <ContactListItemsHeader letter={letter} />}
              <ContactListItem
                contact={contact}
                selectedContactId={selectedContactId}
              />
            </div>
          );
        })}
      </div>
    );
  };

  renderContactEditForm = () => {
    return (
      <div>
        {this.state.selectedContactId != "create" &&
          this.state.selectedContact && (
            <ContactEditForm
              key={this.state.selectedContact.id}
              reloadContacts={() => this.loadContacts()}
              onDelete={() => this.setState({ selectedContact: null })}
              contact={this.state.selectedContact}
              contactSegments={this.state.contactSegments}
              contactSegmentsSelectList={this.state.contactSegmentsSelectList}
            />
          )}
      </div>
    );
  };

  handleContactCreate = (contactFormData) => {
    httpClient
      .post("/contacts.json", contactFormData)
      .then((response) => {
        console.log("created!", response);
        history.push("/contacts");
        this.loadContacts();
        notificationService.addNotification(
          "Contact created",
          "New contact has been added.",
          "success"
        );
      })
      .catch((e) => {
        let message = "";
        if (e.response && e.response.data) {
          for (let key in e.response.data) {
            message += `${key.charAt(0).toUpperCase() + key.slice(1)}  ${
              e.response.data[key]
            }`;
          }
        }
        notificationService.addNotification("Error", message, "danger");
      });
  };

  renderContactCreateForm = () => {
    return (
      <div>
        <ContactCreateForm
          handleContactCreate={this.handleContactCreate}
          contactSegments={this.state.contactSegments}
          contactSegmentsSelectList={this.state.contactSegmentsSelectList}
        />
      </div>
    );
  };

  handleUserSearch = (e) => {
    //e.preventDefault();
    //let users = this.state.filteredContacts;
    //let userSearchQuery = e.target.value.toLowerCase();
    //if (userSearchQuery.trim() === "") {
    //  this.setSelectedUserTab(this.state.selectedContactTab);
    //  return;
    //}
    //users = users.filter(function (user) {
    //  let matchingUser = false;
    //  if (
    //    user.full_name.toLowerCase().indexOf(userSearchQuery) != -1 ||
    //    user.email.toLowerCase().indexOf(userSearchQuery) != -1
    //  ) {
    //    matchingUser = true;
    //  }
    //  return matchingUser;
    //});
    //this.setState({ filteredContacts: });
  };

  render() {
    return (
      <Container fluid className="users-screen">
        <Header />
        <Row className="users-screen__container">
          <ContactsLeftMenu />
          <Col sm={12} md={5} className="users-screen-user-list-container">
            <div className="users-screen-user-list">
              <div className="users-screen-user-search">
                <Uikon>search_left</Uikon>
                <div className="user-search-input-container">
                  <UikInput
                    className="users-screen-user-search-input"
                    placeholder="Search Contact"
                    onChange={(e) => this.handleContactSearch(e)}
                  />
                </div>
                <div className="user-invite-button-container">
                  <Link to="/contacts/create">
                    <UikButton>Create Contact</UikButton>
                  </Link>
                </div>
              </div>
              {this.renderContactList()}
            </div>
          </Col>
          <Col sm={12} md={5} className="users-screen-user-form-container">
            {this.props.match.params.id == "create"
              ? this.renderContactCreateForm()
              : this.renderContactEditForm()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Contacts;
