import DocumentActionTypes from "../../actions/document/types";

export default (referenceDocuments = null, action) => {
  switch (action.type) {
    case DocumentActionTypes.FETCH_REFERENCE_DOCUMENTS:
      return action.payload;
    case DocumentActionTypes.RESET_DOCUMENT_STATE:
      return null;
    default:
      return referenceDocuments;
  }
};
