import React, { useState, useEffect } from "react";

import moment from "moment";

import styles from "./SingleClaimCard.module.scss";

export default (props) => {
  const { claim } = props;

  return (
    <div className={styles.conversation}>
      <div className={styles.metadata}>
        <div className={styles.labelContainer}>
          {claim.linked ? claim.claim.label : claim.label}
        </div>
      </div>
      <div className={styles.claimCaption}>
        {claim.linked ? claim.claim.caption : claim.caption}
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.nameDate}>
          <div className={styles.name}>
            {claim.linked
              ? claim.claim_created_by_user_name
              : claim.created_by_user_name}
          </div>
          <div className={styles.dotSeparator}>&#9679;</div>
          <div className={styles.date}>
            <div className={styles.fullDateTimeContainer}>
              <div className={styles.fullDateTime}>
                {moment(
                  claim.linked ? claim.claim.created_at : claim.created_at
                ).format("MMM D")}
              </div>
              <div className={styles.bottomArrow} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
