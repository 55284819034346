import React, { useState, useEffect } from "react";
import { Col } from "react-grid-system";
import LibrarySelect from "../../Libraries/LibrarySelect";
import Button from "../../Common/Buttons/ButtonPrimary";
import Select from "react-select";
import httpClient from "../../../lib/HttpClient";
import notificationService from "../../../services/Notifications";

import styles from "./CreateForm.module.scss";

export default (props) => {
  const { createDateReminder, setShowCancelModal } = props;

  const [selectedLibrary, setSelectedLibrary] = useState(null);
  const [libraryDateFields, setLibraryDateFields] = useState(null);
  const [selectedDateField, setSelectedDateField] = useState(null);
  const [daysBefore, setDaysBefore] = useState("");

  useEffect(() => {
    const fetchLibraryDateFields = async (id) => {
      const response = await httpClient.get(
        `/libraries/${id}/date_fields.json`
      );
      setLibraryDateFields(response.data);
    };
    if (selectedLibrary) {
      fetchLibraryDateFields(selectedLibrary.value);
    }
  }, [selectedLibrary]);

  const onLibraryChange = (e) => {
    setSelectedLibrary(e);
    setSelectedDateField(null);
  };

  const onSave = () => {
    if (daysBefore <= 0 || daysBefore > 999) {
      notificationService.addNotification(
        "Invalid input",
        "Invalid input! Enter a number 1-999",
        "warning"
      );
    } else {
      createDateReminder({
        library_id: selectedLibrary.value,
        document_field_id: selectedDateField.value,
        days_before: daysBefore,
      });
    }
  };

  return (
    <Col className={styles.createFormContainer} sm={12} md={5}>
      <div className={styles.createForm}>
        <div className={styles.header}>
          <div className={styles.title}>Create Reminder</div>
          <div className={styles.description}>
            Create date reminder for content where you are the owner or a
            reviewer
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.libraryDropdown}>
            <LibrarySelect onLibraryChange={onLibraryChange} />
          </div>
          {libraryDateFields && (
            <div className={styles.dateFieldsDropdown}>
              <div className="uik-content-title__wrapper">Date field</div>
              <Select
                placeholder="Select Date Field"
                options={libraryDateFields.map((libraryDateField) => {
                  return {
                    value: libraryDateField.id,
                    label: libraryDateField.label,
                  };
                })}
                onChange={(e) => {
                  setSelectedDateField(e);
                }}
                value={selectedDateField}
              />
            </div>
          )}
          {selectedDateField && (
            <div className={styles.setReminder}>
              <div className={styles.textBeforeInput}>Remind me</div>
              <div className={styles.numberInput}>
                <input
                  type="number"
                  onChange={(e) => setDaysBefore(e.target.value)}
                  value={daysBefore}
                />
              </div>
              <div className={styles.textAfterInput}>
                days before if I am the owner or a reviewer
              </div>
            </div>
          )}
        </div>
        <div className={styles.buttons}>
          <div className={styles.cancelButton}>
            <Button
              transparent
              text="Cancel"
              onClick={() => setShowCancelModal(true)}
            />
          </div>
          <div className={styles.saveButton}>
            <Button
              original
              text="Save"
              disabled={!daysBefore}
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </Col>
  );
};
