class Notifications {
  constructor() {}
  addNotification = (title, message, type) => {
    document.dispatchEvent(
      new CustomEvent("notification_add", {
        detail: { title: title, message: message, type: type },
      })
    );
  };
}

export default new Notifications();
