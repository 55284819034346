import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import history from "../../../history";

import EmailIcon from "../../../images/icons/svg/email-icon-grey.svg";
import PencilIcon from "../../../images/icons/svg/pencil-grey.svg";
import LinkIcon from "../../../images/icons/svg/external-link-icon.svg";
import InfoIcon from "../../../images/icons/svg/info-tooltip-active.svg";
import ArrowUpIcon from "../../../images/icons/svg/sorting-arrow-up.svg";
import ArrowDownIcon from "../../../images/icons/svg/sorting-arrow-down.svg";
import BackArrowIcon from "../../../images/icons/svg/back-arrow-grey.svg";
import accountSetting from "../../../services/AccountSetting";

import previewHover from "../../../images/icons/svg/eye-dark-gray.svg";
import { UikButton } from "@uik";

import styles from "./TableView.module.scss";

export default (props) => {
  const {
    label,
    items,
    itemFields,
    sort,
    sortingColumnIndex,
    sortColumn,
    sortOrder,
    customContactStyler,
    itemClickFunction,
    secondaryClickFunction,
    largeColumns,
    dateColumns,
    statusColumns,
    contactColumns,
    verifiedEmailActionColumn,
    customStatusStyler,
    backLink,
    handleBackLink,
    headerButtonAction,
    headerActionButtonText,
    headerMessage,
    headerButtonDestructiveAction,
    headerDestructiveActionText,
  } = props;

  const renderEmptyState = () => {
    return (
      <div className={styles.tableViewContainer}>
        <div className={styles.emptyStateContainer}>
          <div className={styles.emptyMessageContainer}>
            <img className={styles.InfoIcon} src={InfoIcon} />
            No content found
          </div>
        </div>
      </div>
    );
  };

  const renderItem = (item, itemField, ifIndex) => {
    if (item.content_type === "image" && itemField === "image") {
      return (
        <td key={`table-item-attr-${ifIndex}`}>
          <img
            className={styles.image}
            src={`${process.env.REACT_APP_API_URL}/${item.image_url}`}
          />
        </td>
      );
    } else if (isContactColumn(itemField)) {
      return (
        <td key={`table-item-attr-${ifIndex}`}>
          {item[itemField] ? customContactStyler(item) : "-"}
        </td>
      );
    } else if (isDateColumn(itemField)) {
      return (
        <td key={`table-item-attr-${ifIndex}`}>
          {moment(item[itemField]).format("MMM DD, YYYY") || "-"}
        </td>
      );
    } else if (isStatusColumn(itemField)) {
      return (
        <td key={`table-item-attr-${ifIndex}`}>
          {customStatusStyler(item.status) || "-"}
        </td>
      );
    } else if (item.display_sc_property_value && !item[itemField]) {
      return (
        <td key={`table-item-attr-${ifIndex}`}>
          {item.display_sc_property_value || "-"}
        </td>
      );
    } else if (isVerifiedEmailActionColumn(itemField)) {
      let itemId = item.id;
      return (
        <td key={`table-item-attr-${ifIndex}`}>
          <img
            className={styles.actionIcon}
            src={EmailIcon}
            onClick={() => {
              secondaryClickFunction(itemId);
            }}
          />
          {item.editable && (
            <img
              className={styles.actionIcon}
              src={PencilIcon}
              onClick={() => {
                itemClickFunction && itemClickFunction(item);
              }}
            />
          )}
        </td>
      );
    } else if (itemField === "preview") {
      return (
        <td>
          <img className={styles.previewHoverState} src={previewHover} />
        </td>
      );
    } else {
      return (
        <td key={`table-item-attr-${ifIndex}`}>{item[itemField] || "-"}</td>
      );
    }
  };

  const getColumnStyle = (column) => {
    if (largeColumns) {
      if (largeColumns.find((col) => col === column)) {
        return styles.colLarge;
      } else {
        return styles.col;
      }
    } else {
      return styles.col;
    }
  };

  const formatColumnName = (name) => {
    if (name === "preview") {
      return;
    } else {
      return name.split("_").join(" ");
    }
  };

  const isDateColumn = (column) => {
    if (dateColumns) {
      if (dateColumns.find((col) => col === column)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isContactColumn = (column) => {
    if (contactColumns) {
      if (contactColumns.find((col) => col === column)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isStatusColumn = (column) => {
    if (statusColumns) {
      if (statusColumns.find((col) => col === column)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isVerifiedEmailActionColumn = (column) => {
    if (verifiedEmailActionColumn) {
      if (verifiedEmailActionColumn === column) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const renderTableView = () => {
    return (
      <div className={styles.tableViewContainer}>
        <div className={styles.subContainer}>
          {label && (
            <div className={styles.tableViewHeader}>
              <div className={styles.leftContainer}>
                {backLink && (
                  <div
                    onClick={() => handleBackLink()}
                    className={styles.backLink}
                  >
                    <img src={BackArrowIcon} />
                  </div>
                )}
                <div className={styles.title}>{label}</div>
              </div>
              <div className={styles.rightContainer}>
                {headerButtonAction && (
                  <UikButton onClick={(e) => headerButtonAction(e)} primary>
                    {headerActionButtonText}
                  </UikButton>
                )}
                {headerButtonDestructiveAction && (
                  <UikButton
                    onClick={(e) => headerButtonDestructiveAction(e)}
                    error
                  >
                    {headerDestructiveActionText}
                  </UikButton>
                )}
                {headerMessage && (
                  <div className={styles.manualSendBadge}>
                    {<span>{headerMessage}</span>}
                  </div>
                )}
              </div>
            </div>
          )}
          <table className={styles.titleTableContainer}>
            <colgroup>
              {itemFields.map((column, index) => (
                <col key={index} span="1" className={getColumnStyle(column)} />
              ))}
            </colgroup>
            <thead className={styles.header}>
              <tr>
                {itemFields.map((itemField, index) => (
                  <th className={styles.columnName}>
                    <div className={styles.container}>
                      <div
                        className={`${styles.text} ${
                          sortColumn === "title" && styles.textBold
                        }`}
                      >
                        {isVerifiedEmailActionColumn(itemField) && (
                          <span>&nbsp;</span>
                        )}
                        {!isVerifiedEmailActionColumn(itemField) &&
                          formatColumnName(itemField)}
                      </div>
                      <div className={styles.icon}>
                        <img
                          className={
                            sortColumn === "title" && sortOrder === "desc"
                              ? styles.show
                              : ""
                          }
                          src={ArrowDownIcon}
                        />
                        <img
                          className={`${
                            sortColumn === "title" ? styles.arrowUpShow : ""
                          } ${
                            sortColumn === "title" && sortOrder === "asc"
                              ? styles.show
                              : ""
                          }`}
                          src={ArrowUpIcon}
                        />
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={styles.body}>
              {items.map((item, index) => (
                <tr
                  onClick={() => {
                    itemClickFunction && itemClickFunction(item);
                  }}
                  key={`table-item-${item.id}`}
                  name={item.id}
                >
                  {itemFields.map((itemField, ifIndex) =>
                    renderItem(item, itemField, ifIndex)
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {items.length > 0 ? renderTableView() : renderEmptyState()}
    </div>
  );
};
