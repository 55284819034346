import ActionTypes from "./types";
import httpClient from "../../lib/HttpClient";

export default (libraryId, search) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const searchData = {
        library_id: parseInt(libraryId),
        caption: {
          caption_cont: search,
        },
        label: {
          label_cont: search,
        },
      };

      const response = await httpClient.post("/claims/search.json", searchData);

      dispatch({
        type: ActionTypes.SEARCH_CLAIMS,
        payload: response.data,
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
