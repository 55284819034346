import DocumentActionTypes from "../../actions/document/types";

export default (documentDynamicFields = null, action) => {
  switch (action.type) {
    case DocumentActionTypes.FETCH_DOCUMENT_DYNAMIC_FIELDS:
      return action.payload;
    default:
      return documentDynamicFields;
  }
};
