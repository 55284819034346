/* External libraries */
import React from "react";
import Cookies from "universal-cookie";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import httpClient from "../../lib/HttpClient";
import security from "../../services/Security";
import Button from "../Common/Buttons/ButtonPrimary";

import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./LibraryCreateForm.module.css";
import RadioButton from "../Common/RadioButton";

/* Variables */
const cookies = new Cookies();

class LibraryCreateFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      invalidUserInviteForm: false,
      documentFieldGroups: null,
      selectedFieldGroup: null,
      currentUser: {
        role: currentUserRole,
      },
    };
  }

  componentDidMount = () => {
    httpClient.get("/document_fields_groups.json").then((response) => {
      let selectedFieldGroup = null;
      if (response.data.length === 1) {
        selectedFieldGroup = response.data[0].id;
      }
      this.setState({
        documentFieldGroups: response.data,
        selectedFieldGroup,
      });
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error && this.state.selectedFieldGroup) {
        const libraryFormData = {
          library: {
            name: values.name,
            document_fields_group_id: this.state.selectedFieldGroup,
          },
        };

        httpClient
          .post("/libraries.json", libraryFormData)
          .then((response) => {
            console.log("Created!", response);
            notificationService.addNotification(
              "Library Created",
              "The library has been created",
              "success"
            );

            history.push("/libraries");
            console.log("create response", response.data);
            this.props.onCreate(response.data);
          })
          .catch((err) => {
            const message = err.name
              ? "The library name has already been taken"
              : "An erorr occurred while creating your library";

            notificationService.addNotification(
              "An Error Occurred",
              message,
              "danger"
            );

            console.log("error", err);
          });
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleCancel = (e) => {
    history.push("/libraries");
  };

  handleContentPropertiesChange = (e) => {
    this.setState({
      selectedFieldGroup: e.target.value,
    });
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return this.state.documentFieldGroups ? (
      <div className={styles.libraryCreateContainer}>
        <UikWidget>
          <div className={styles.headerContainer}>
            <div className={styles.header}>Create library</div>
            <div className={styles.description}>
              Name and choose a content properties category for your library.
            </div>
          </div>
          <UikDivider />
          <div className={styles.bodyContainer}>
            <div className={styles.libraryName}>
              <UikFormInputGroup>
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter a library name.",
                    },
                  ],
                })(
                  <UikInput
                    label="LIBRARY NAME"
                    errorMessage={(getFieldError("name") || []).join(", ")}
                  />
                )}
              </UikFormInputGroup>
            </div>
            <div className={styles.contentPropertiesContainer}>
              <div className={styles.contentPropertiesTitle}>
                content properties
              </div>
              <div className={styles.contentPropertiesList}>
                {this.state.documentFieldGroups.length === 1 ? (
                  <div className={styles.singleContentProperty}>
                    <div className={styles.singleContentPropertyLabel}>
                      {this.state.documentFieldGroups[0].label} &#8212;{" "}
                    </div>
                    <div className={styles.singleContentPropertyFields}>
                      {this.state.documentFieldGroups[0].document_fields
                        .map((documentField) => documentField.label)
                        .join(", ")}
                    </div>
                  </div>
                ) : (
                  this.state.documentFieldGroups.map((documentFieldGroup) => (
                    <div className={styles.contentProperty}>
                      <RadioButton
                        name="documentFieldGroup"
                        value={documentFieldGroup.id}
                        onChange={this.handleContentPropertiesChange}
                      />
                      <div className={styles.contentPropertyDetails}>
                        <div className={styles.contentPropertyLabel}>
                          {documentFieldGroup.label}
                        </div>
                        <div className={styles.contentPropertyFields}>
                          {documentFieldGroup.document_fields
                            .map((documentField) => documentField.label)
                            .join(", ")}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <UikDivider />
          <div className={styles.buttonsContainer}>
            <Button
              text="Save"
              original
              disabled={!this.state.selectedFieldGroup}
              onClick={(e) => this.handleSubmit(e)}
            />
            <Button
              text="Cancel"
              transparent
              onClick={(e) => this.handleCancel(e)}
            />
          </div>
        </UikWidget>
      </div>
    ) : null;
  }
}

const LirbaryCreateForm = createForm()(LibraryCreateFormBuild);
export default LirbaryCreateForm;
