import React from "react";

import ReactNotification from "react-notifications-component";
import styles from "./Spinner.module.scss";

import loadingIcon from " ../../images/icons/svg/loading.svg";

class Spinner extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className={styles.spinnerContainer}>
        <img className={styles.spinner} src={loadingIcon} />
        <div>{this.props.text}</div>
      </div>
    );
  }
}

export default Spinner;
