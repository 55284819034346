import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./ContactListItem.module.css";

import security from "../../services/Security";

const ContactListItem = ({ contact, selectedContactId }) => (
  <Link to={`/contacts/${contact.id}`}>
    <div
      className={
        styles.contactListItem +
        (selectedContactId == contact.id ? " " + styles.selected : "") +
        (contact.active || contact.subscribed
          ? ""
          : " " + styles.deactiveContactListItemAvatar)
      }
    >
      <div className={styles.contactListItemAvatar}>
        <span className={styles.contactListItemInitials}>
          {contact.first_name.charAt(0)}
          {contact.last_name.charAt(0)}
        </span>
      </div>

      <div className={styles.contactListItemNameplate}>
        <span className={styles.contactListItemNameplateName}>
          {contact.first_name}
          &nbsp;
          {contact.last_name}
        </span>
        <br />
        <span className={styles.contactListItemNameplateTitle}>
          {contact.title}
          &nbsp;
        </span>
      </div>
    </div>
  </Link>
);

ContactListItem.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default ContactListItem;
