import React from "react";

import { Container, Row, Col } from "react-grid-system";
import history from "../../history";
import styles from "./Approvers.module.scss";
import httpClient from "../../lib/HttpClient";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import ApproverForm from "./ApproverForm";

import security from "../../services/Security";

/* Variables */

class Approvers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readOnly: this.props.location.state.readOnly,
      document: null,
    };
  }

  componentWillMount = () => {
    const currentUserRole = security.getUserRole();
    const { id } = this.props.match.params;

    // Only Authors and Admins can setup approvers.
    if (currentUserRole !== "author" && currentUserRole !== "admin") {
      this.props.history.push({
        pathname: "/drafts",
      });
    }

    httpClient.get(`/documents/${id}.json`).then((response) => {
      this.setState({ document: response.data });
    });
  };

  revoke = () => {
    this.setState({
      readOnly: false,
    });
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <Container fluid className={styles.approversContainer}>
        <Header />
        <Row>
          <Col sm={12}>
            <div className={styles.heading}>
              <h1 className={styles.mainHeadline}>Manage approval</h1>
              {this.state.document && (
                <span className={styles.mainContentTitle}>
                  {this.state.document.title}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ApproverForm
              revoke={this.revoke}
              docId={id}
              readOnly={this.state.readOnly}
              libraryReadOnly={this.props.location.state.libraryReadOnly}
              versionId={this.props.location.state.versionId}
              versionNumber={this.props.location.state.versionNumber}
              originalDocumentId={this.props.location.state.originalDocumentId}
              changeRequested={this.props.location.state.changeRequested}
              ownerId={this.props.location.state.ownerId}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Approvers;
