import DocumentActionTypes from "../../actions/document/types";

export default (documentConversations = null, action) => {
  switch (action.type) {
    case DocumentActionTypes.FETCH_DOCUMENT_CONVERSATIONS:
      return action.payload;
    default:
      return documentConversations;
  }
};
