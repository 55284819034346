import React from "react";
import PropTypes from "prop-types";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { withTranslation } from "react-i18next";

import styles from "./Drafts.module.scss";
import history from "../../history";
import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";

/* UI Kit */
import { UikButton } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import DraftListItem from "./DraftListItem";

/* Assets */
import plusIcon from "../../images/icons/svg/plus-grey.svg";

/* Variables */

class DraftsBuild extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      approvals: [],
      inProgress: [],
      currentUser: {
        role: currentUserRole,
      },
    };
  }

  componentWillMount = () => {
    const userId = security.getUserId();

    this.setState({ userId });

    httpClient.get(`/users/${userId}/pending_action.json`).then((response) => {
      this.setState({ approvals: response.data });
    });

    httpClient.get(`/users/${userId}/in_progress.json`).then((response) => {
      this.setState({ inProgress: response.data });
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Container fluid>
        <Header />
        <Row className={styles.draftsHome}>
          <Col sm={1} />
          <Col sm={5}>
            <div id="my_task_draft_heading" className={styles.draftsHeading}>
              <h1 className="main-headline">{t("common_phrases.my_tasks")}</h1>
              <span>{t("messaging.my_tasks_subheading")}</span>
            </div>
            <div className={styles.draftsHomeLists}>
              {this.state.approvals.map((approval, index) => {
                return <DraftListItem approval={approval} key={index} />;
              })}
              {this.state.approvals.length === 0 && (
                <em className={styles.empty}>
                  {t("common_phrases.no_current_tasks")}
                </em>
              )}
            </div>
          </Col>
          <Col sm={5}>
            <div className={styles.draftsHeading}>
              <div
                id="approvals_in_progress_draft_heading"
                className={styles.inProgressHeading}
              >
                <h1 className="main-headline">
                  {t("common_phrases.approvals_in_progress")}
                </h1>
                <span>{t("messaging.approvals_in_progress_subheading")}</span>
              </div>
              <div className="clear" />
            </div>
            <div className={styles.draftsHomeLists}>
              {this.state.inProgress.map((approval, index) => {
                return <DraftListItem approval={approval} key={index} />;
              })}
              {this.state.inProgress.length === 0 && (
                <em className={styles.empty}>
                  {t("common_phrases.no_current_approvals_in_progress")}
                </em>
              )}
            </div>
          </Col>
          <Col sm={1} />
        </Row>
      </Container>
    );
  }
}

const Drafts = withTranslation()(DraftsBuild);

export default Drafts;
