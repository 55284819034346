import React, { useState, useEffect } from "react";
import ConversationUnresolvedSVG from "../../../images/icons/svg/icn-comment-not-resolved.svg";
import ConversationResolvedSVG from "../../../images/icons/svg/icn-comment-resolved.svg";
import ConversationUnresolvedHoverSVG from "../../../images/icons/svg/icn-comment-not-resolved-hover.svg";

import styles from "./Checkmark.module.scss";

export const Checkmark = (props) => {
  const { status, onClick, disabled } = props;
  const [imgSrc, setImageSrc] = useState(null);

  useEffect(() => {
    setImageSrc(
      status === "resolved"
        ? ConversationResolvedSVG
        : ConversationUnresolvedSVG
    );
  }, [status]);

  const mouseEnter = () => {
    if (status === "unresolved" && !disabled) {
      setImageSrc(ConversationUnresolvedHoverSVG);
    }
  };

  const mouseLeave = () => {
    if (status === "unresolved") {
      setImageSrc(ConversationUnresolvedSVG);
    }
  };

  const onClickHandler = (e) => {
    e.stopPropagation();
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div
      className={`${styles.checkmarkContainer} ${
        disabled ? styles.checkmarkContainerDisabled : ``
      }`}
    >
      <img
        src={imgSrc}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onClick={onClickHandler}
      />
    </div>
  );
};
