import React, { useState, useRef } from "react";
import TooltipDefault from "../../../images/icons/svg/tooltip-default.svg";
import TooltipHover from "../../../images/icons/svg/tooltip-hover.svg";
import TooltipSelected from "../../../images/icons/svg/tooltip-selected.svg";
import ToolboxDimple from "../../../images/icons/svg/toolbox-dimple.svg";
import ToolboxDimpleBlue from "../../../images/icons/svg/toolbox-dimple-blue.svg";
import CheckmarkWhite from "../../../images/icons/svg/checkmark-white.svg";
import CrossGrey from "../../../images/icons/svg/cross-grey.svg";
import externalLinkIcon from "../../../images/icons/svg/external-link-icon.svg";

import "./Tooltip.scss";

// THIS COMPONENT NEEDS REWORK. WILL CREATE ANOTHER ONE
export const Tooltip = (props) => {
  const { type, subType, children, variable } = props;

  const [imgSrc, setImgSrc] = useState(TooltipDefault);
  const [active, setActive] = useState(false);
  const tooltipRef = useRef(null);

  const mouseLeave = () => {
    if (!active) {
      setImgSrc(TooltipDefault);
    }
  };

  const onClick = () => {
    if (type !== "paige-description") {
      tooltipRef.current.focus();
    } else {
      setActive((active) => {
        if (active) {
          setImgSrc(TooltipDefault);
        } else {
          setImgSrc(TooltipSelected);
        }
        return !active;
      });
    }
  };

  const onBlur = () => {
    const closeToolTip = () => {
      setActive(false);
      setImgSrc(TooltipDefault);
    };
    if (type !== "description" && type !== "paige-description") {
      setTimeout(closeToolTip, 100);
    }
  };

  const onFocus = () => {
    setActive(true);
    setImgSrc(TooltipSelected);
  };

  const containerClass = () => {
    if (type === "description") {
      if (subType === "autoApprove") {
        return autoApproveDescriptionContent;
      } else if (subType === "sequentialSuppression") {
        return sequentialDescriptionContent;
      } else {
        return descriptionContent;
      }
    } else if (type === "paige-description") {
      return descriptionContent;
    } else if (type === "approved-description") {
      return approvedContent;
    } else {
      return listContent;
    }
  };

  const dimpleClass = () => {
    if (type === "description") {
      return descriptionToolTipDimple;
    } else if (type === "paige-description") {
      return descriptionToolTipDimple;
    } else if (type === "approved-description") {
      return approvedContentDimple;
    } else {
      return listToolTipDimple;
    }
  };

  const mouseEnter = () => {
    if (!active) {
      setImgSrc(TooltipHover);
    }
  };

  const renderContent = () => {
    if (type === "permissions") {
      const { allowedPermissions } = props;
      const { disallowedPermissions } = props;
      return (
        <div className="list">
          {allowedPermissions.map((permission, index) => {
            return (
              <li key={index} className="item item-allowed">
                <img src={CheckmarkWhite} />
                <div className="text">{permission}</div>
              </li>
            );
          })}
          {disallowedPermissions.map((permission, index) => {
            return (
              <li key={index} className="item item-disallowed">
                <img src={CrossGrey} />
                <div className="text">{permission}</div>
              </li>
            );
          })}
        </div>
      );
    } else if (type === "description" && subType === "referenceId") {
      return (
        <div className="referenceIdDescription">
          <div className="headerContainer">
            <h3 className="header">What is a Reference ID?</h3>
            <span
              className="closeButton"
              onClick={() => {
                setActive(false);
                setImgSrc(TooltipDefault);
              }}
            >
              &times;
            </span>
          </div>
          <p className="descriptionText">
            {" "}
            A reference ID is a unique short name for your reference document.
            It could be the author and the year, or the title, or whatever you
            want.{" "}
            <a
              className="kbLink"
              href="https://help.papercurve.com/knowledge-base/reference-labels"
              target="_blank"
            >
              Learn More
            </a>{" "}
          </p>
        </div>
      );
    } else if (type === "description" && subType === "annotationId") {
      return (
        <div className="referenceIdDescription">
          <div className="headerContainer">
            <h3 className="header">What is an Annotation ID?</h3>
            <span
              className="closeButton"
              onClick={() => {
                setActive(false);
                setImgSrc(TooltipDefault);
              }}
            >
              &times;
            </span>
          </div>
          <p className="descriptionText">
            An Annotation ID is a unique name for a highlighted excerpt. The
            Papercurve default is the page number and a sequential letter for
            the section.{" "}
            <a
              className="kbLink"
              href="https://help.papercurve.com/knowledge-base/reference-labels"
              target="_blank"
            >
              Learn More
            </a>{" "}
          </p>
        </div>
      );
    } else if (type === "description" && subType === "autoApprove") {
      return (
        <div className="referenceIdDescription">
          <div className="headerContainer">
            <h3 className="header">{`What is Auto Approve?`}</h3>
            <span
              className="closeButton"
              onClick={() => {
                setActive(false);
                setImgSrc(TooltipDefault);
              }}
            >
              &times;
            </span>
          </div>
          <p className="descriptionText">
            {`The auto approve feature lets you upload content directly to an approved library.`}
            <a
              className="kbLinkSequential"
              href="https://help.papercurve.com/knowledge-base/upload-content"
              target="_blank"
            >
              Learn More
            </a>{" "}
          </p>
        </div>
      );
    } else if (type === "description" && subType === "sequentialSuppression") {
      return (
        <div className="referenceIdDescription">
          <div className="headerContainer">
            <h3 className="header">{`${variable}s can be disabled`}</h3>
            <span
              className="closeButton"
              onClick={() => {
                setActive(false);
                setImgSrc(TooltipDefault);
              }}
            >
              &times;
            </span>
          </div>
          <p className="descriptionText">
            {`If a ${variable} is not required for specific content, it can easily be toggled off.`}
            <a
              className="kbLinkSequential"
              href="https://help.papercurve.com/knowledge-base/job-id"
              target="_blank"
            >
              Learn More
            </a>{" "}
          </p>
        </div>
      );
    } else if (
      type === "approved-description" &&
      subType === "approvedContent"
    ) {
      return (
        <div className="referenceIdDescription">
          <p className="descriptionText">
            Admins and the Content Owner can see all details on this approved
            content, other reviewers only see the content properties.{" "}
            <a
              className="kbLink"
              href="https://help.papercurve.com/knowledge-base/user-roles-and-capabilities"
              target="_blank"
            >
              Learn More
              <img className={"externalLinkIcon"} src={externalLinkIcon} />
            </a>{" "}
          </p>
        </div>
      );
    }
  };

  let listContent = `content${!active ? ` hidden` : ``}`;
  let descriptionContent = `descriptionContent${!active ? ` hidden` : ``}`;
  let autoApproveDescriptionContent = `autoApproveDescriptionContent${
    !active ? ` hidden` : ``
  }`;

  let sequentialDescriptionContent = `sequentialDescriptionContent${
    !active ? ` hidden` : ``
  }`;
  let approvedContent = `approvedContent${!active ? ` hidden` : ``}`;
  let listToolTipDimple = `tooltip-dimple${!active ? ` hidden` : ``}`;
  let approvedContentDimple = `approved-dimple${!active ? ` hidden` : ``}`;
  let descriptionToolTipDimple = `tooltip-dimple-description${
    !active ? ` hidden` : ``
  }`;

  return (
    <div className="tooltip-container">
      <img
        className="tooltip"
        tabIndex="-1"
        ref={tooltipRef}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        src={imgSrc}
      />
      <img
        className={`${dimpleClass()}`}
        src={
          type === "description"
            ? ToolboxDimpleBlue
            : type === "paige-description"
            ? ToolboxDimpleBlue
            : ToolboxDimple
        }
      />
      <div className={`${containerClass()}`}>
        {type !== "paige-description" ? renderContent() : children}
      </div>
    </div>
  );
};
