import React, { useState } from "react";

import CloseSVG from "../../images/icons/svg/close.svg";
import DownChevron from "../../images/icons/svg/down-chevron.svg";
import { UikRadio, UikInput } from "@uik";
import Button from "../Common/Buttons/ButtonPrimary";
import notificationService from "../../services/Notifications";
import httpClient from "../../lib/HttpClient";

import styles from "./ApprovalCardMobile.module.scss";

const approvalStatuses = [
  {
    label: "Request new version",
    value: "submit_new_version",
    id: 0,
  },
  {
    label: "Approved",
    value: "approved",
    id: 1,
  },
  {
    label: "Approved with changes",
    value: "approve_with_changes",
    id: 2,
  },
  {
    label: "Submit your approval",
    value: "pending",
    id: 3,
  },
];

export default (props) => {
  const { approval, readOnly, updateApprovalsRemaining, updateApproval } =
    props;

  const [approvalInProgress, setApprovalInProgress] = useState(false);
  const [newApproval, setNewApproval] = useState(null);
  const [passwordInvalid, setPasswordInvalid] = useState(null);
  const [password, setPassword] = useState(null);

  const enterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleSubmit();
    }
  };

  const addApprovedNotification = () => {
    notificationService.addNotification(
      "Document Approved",
      "You have submitted your approval for this document.",
      "success"
    );
  };

  const addApproveWithChangesNotification = () => {
    notificationService.addNotification(
      "Document Approved",
      "You have submitted your approval with changes for this document.",
      "success"
    );
  };

  const addRejectNotification = () => {
    notificationService.addNotification(
      "Document Rejected",
      "You have requested a new version for approval for this document.",
      "success"
    );
  };

  const handleGuestSubmit = () => {
    const draftApprovalFormData = {
      approval: {
        status: newApproval,
      },
    };

    httpClient
      .put(
        `/approvals/guest/${approval.id}/${props.hashUrl}.json`,
        draftApprovalFormData
      )
      .then((response) => {
        props.setApprovalSubmitted(response.data);
        updateApproval(response.data);
        setApprovalInProgress(false);
      });
  };

  const handleSubmit = () => {
    const draftApprovalFormData = {
      approval: {
        status: newApproval,
      },
    };

    httpClient
      .post(`/users/validate`, {
        user: {
          password,
        },
      })
      .then((response) => {
        if (response.data) {
          httpClient
            .put(`/approvals/${approval.id}.json`, draftApprovalFormData)
            .then((response) => {
              updateApprovalsRemaining(
                response.data.document.pending_approvals
              );
              updateApproval(response.data);
              if (draftApprovalFormData.approval.status === "approved") {
                addApprovedNotification();
              } else if (
                draftApprovalFormData.approval.status === "approve_with_changes"
              ) {
                addApproveWithChangesNotification();
              } else {
                addRejectNotification();
              }
              setNewApproval(null);
              setPassword(null);
              setApprovalInProgress(false);
            });
        } else {
          setPasswordInvalid(true);
        }
      });
  };

  const passwordDisabled = !password || !newApproval;

  let containerColorClass = null;
  if (approvalInProgress) {
    containerColorClass = styles.approvalCardContainerTeal;
  } else if (approval.status === "pending") {
    containerColorClass = styles.approvalCardContainerOrange;
  } else {
    containerColorClass = styles.approvalCardContainerWhite;
  }

  const approvalSubmitted = approval.status !== "pending";

  return (
    <div className={`${styles.approvalCardContainer} ${containerColorClass}`}>
      <div className={styles.body}>
        {!approvalSubmitted && !approvalInProgress ? (
          <div className={styles.submitApproval}>
            <div
              onClick={() => setApprovalInProgress(true)}
              className={styles.nowButton}
            >
              Submit Approval Now
            </div>
          </div>
        ) : (
          <div className={styles.approvalContainer}>
            <div
              className={`${styles.approvalSelect} ${
                readOnly && styles.approvalSelectReadOnly
              }`}
              onClick={
                !readOnly
                  ? () => setApprovalInProgress((prevValue) => !prevValue)
                  : null
              }
            >
              <div className={styles.approvalLabel}>
                {
                  approvalStatuses.find(
                    (status) => status.value === approval.status
                  ).label
                }
              </div>
              <img className={styles.dropdownChevron} src={DownChevron} />
            </div>
            {approvalInProgress && (
              <div className={styles.approvalOptionsContainer}>
                <div className={styles.approvalSelectOptionsHeaderContainer}>
                  <p className={styles.approvalSelectOptionsHeader}>
                    Submit your approval
                  </p>
                  <img
                    src={CloseSVG}
                    onClick={() => {
                      setApprovalInProgress(false);
                    }}
                  />
                </div>
                <div className={styles.approvalSelectOptions}>
                  <div className={styles.approvalOption}>
                    <UikRadio
                      defaultChecked={approval.status == "approved"}
                      label="Approved"
                      value="approved"
                      name="status"
                      color="red"
                      disabled={readOnly}
                      onChange={(e) => setNewApproval(e.target.value)}
                    />
                  </div>
                  <div className={styles.approvalOption}>
                    <UikRadio
                      defaultChecked={approval.status == "approve_with_changes"}
                      label="Approved with changes"
                      value="approve_with_changes"
                      name="status"
                      color="red"
                      disabled={readOnly}
                      onChange={(e) => setNewApproval(e.target.value)}
                    />
                  </div>
                  <div className={styles.approvalOption}>
                    <UikRadio
                      defaultChecked={approval.status == "submit_new_version"}
                      label="Request new version"
                      value="submit_new_version"
                      name="status"
                      color="red"
                      disabled={readOnly}
                      onChange={(e) => setNewApproval(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.passwordConfirmation} ${
                    passwordInvalid ? `${styles.passwordInvalid}` : ``
                  }`}
                >
                  {!props.inGuestViewer && (
                    <div className={styles.confirmPasswordLabel}>
                      confirm password
                    </div>
                  )}
                  {!props.inGuestViewer && (
                    <UikInput
                      role="presentation"
                      autocomplete="off"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => setPasswordInvalid(false)}
                      onKeyPress={(e) => enterPressed(e)}
                    ></UikInput>
                  )}
                  {passwordInvalid && (
                    <div className={styles.passwordInvalidLabel}>
                      Password is invalid
                    </div>
                  )}
                  <Button
                    original
                    disabled={passwordDisabled && !props.inGuestViewer}
                    text="Submit"
                    onClick={
                      !props.inGuestViewer ? handleSubmit : handleGuestSubmit
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
