import React from "react";
import { Col } from "react-grid-system";
import styles from "./ChangePasswordForm.module.scss";
import { UikDivider, UikFormInputGroup, UikInput, Uikon } from "@uik";
import { createForm } from "rc-form";
import Button from "../../Common/Buttons/ButtonPrimary";
import axios from "../../../lib/axios-client";
import { Link } from "react-router-dom";
import Security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import httpClient from "../../../lib/HttpClient";

class ChangePasswordFormBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      invalidPassword: false,
      userId: Security.getUserId(),
    };
  }

  componentWillMount = () => {
    this.passwordDecorator = this.props.form.getFieldDecorator("password", {
      initialValue: "",
      rules: [
        {
          required: true,
          message: "Please enter your desired password.",
        },
        {
          min: 6,
          message: "Must be at least 6 characters.",
        },
      ],
    });
    this.passwordConfirmationDecorator = this.props.form.getFieldDecorator(
      "passwordConfirmation",
      {
        initialValue: "",
        rules: [
          {
            required: true,
            message: "Please re-enter your password.",
          },
          {
            min: 6,
            message: "Must be at least 6 characters.",
          },
          {
            validator: this.compareToFirstPassword,
          },
        ],
      }
    );
    this.currentPasswordDecorator = this.props.form.getFieldDecorator(
      "currentPassword",
      {
        initialValue: "",
        rules: [
          {
            required: true,
            message: "Please enter your current password.",
          },
          {
            min: 6,
            message: "Must be at least 6 characters.",
          },
        ],
      }
    );
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords must match.");
    } else {
      callback();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      this.setState({ invalidPassword: false });
      if (!error) {
        const { password, passwordConfirmation, currentPassword } = values;
        const passwordFormData = {
          user: {
            id: this.state.userId,
            password: password,
            password_confirmation: passwordConfirmation,
            current_password: currentPassword,
          },
        };
        httpClient
          .put(
            `/users/${this.state.userId}/update_password.json`,
            passwordFormData
          )
          .then((response) => {
            notificationService.addNotification(
              "Password Changed",
              "Your password has been successfully changed",
              "success"
            );
          })
          .catch((error) => {
            console.log("error", error.response);
            notificationService.addNotification(
              "Invalid Password",
              "The current password you entered doesn't match our records",
              "warning"
            );
          });
      } else {
        console.log("error", error, values);
      }
    });
  };

  render() {
    const { getFieldError } = this.props.form;
    return (
      <Col sm={12} md={5}>
        <div className={styles.backContainer}>
          <Uikon>
            <Link
              to={"/setupsecurity"}
              onClick={() => {
                this.props.renderSecurityMenu();
              }}
              className={styles.backLink}
            >
              arrow_left
            </Link>
          </Uikon>
          <p className={styles.screenSubHeading}>Security</p>
        </div>
        <div className={styles.block}>
          <div className={styles.content}>
            <div className={styles.heading}>
              <div>Set your password</div>
              <div className={styles.description}>
                All data you enter is kept secure and private.
              </div>
            </div>
          </div>
          <UikDivider />
          <div>
            <UikFormInputGroup className={styles.passwordChangeFormContainer}>
              {this.currentPasswordDecorator(
                <UikInput
                  label="CURRENT PASSWORD"
                  type="password"
                  errorMessage={(getFieldError("currentPassword") || []).join(
                    ", "
                  )}
                />
              )}
              {this.passwordDecorator(
                <UikInput
                  label="NEW PASSWORD"
                  type="password"
                  errorMessage={(getFieldError("password") || []).join(", ")}
                  maxlength="64"
                />
              )}
              {this.passwordConfirmationDecorator(
                <UikInput
                  label="CONFIRM NEW PASSWORD"
                  type="password"
                  errorMessage={(
                    getFieldError("passwordConfirmation") || []
                  ).join(", ")}
                  maxlength="64"
                />
              )}
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.buttonContainer}>
            <a className={styles.cancelButton} href="/setupsecurity">
              Cancel
            </a>
            <Button
              original
              text="Update"
              onClick={this.handleSubmit}
              className={styles.submitButton}
            />
          </div>
        </div>
      </Col>
    );
  }
}

export const ChangePasswordForm = createForm()(ChangePasswordFormBuild);
