import React, { useState, useEffect } from "react";
import chroma from "chroma-js";
import { createForm } from "rc-form";
import DatePicker from "react-datepicker";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";

import history from "../../../../history";
import httpClient from "../../../../lib/HttpClient";
import classNames from "classnames";
import security from "../../../../services/Security";
import notificationService from "../../../../services/Notifications";

import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikSelect,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";

import NewModal from "../../../Common/Modal";
import CloseIcon from "../../../../images/icons/svg/close-grey.svg";
import Button from "../../../Common/Buttons/ButtonPrimary";
import styles from "./NewItemModal.module.scss";

const animatedComponents = makeAnimated();

function NewItemModal(props) {
  const [newStucturedContent, setNewStucturedContent] = useState("");
  const [emailCampaign, setEmailCampaign] = useState(null);
  const [editEmailCampaign, setEditEmailCampaign] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [contactSegments, setContactSegments] = useState(null);
  const [contactsSelectList, setContactsSelectList] = useState(null);
  const [contactSegmentsSelectList, setContactSegmentsSelectList] =
    useState(null);
  const [manualSend, setManualSend] = useState(true);
  const [newSendDate, setNewSendDate] = useState(null);
  const [bodyEmpty, setBodyEmpty] = useState(false);

  useEffect(() => {
    httpClient.get(`/contacts.json`).then((res) => {
      setContacts(res.data);

      let list = [];

      res.data.map((contact, idx) => {
        let contactNameplate = `${contact.first_name} ${contact.last_name} <${contact.email}>`;
        list.push({
          value: contact.id,
          label: contactNameplate,
        });
      });

      setContactsSelectList(list);
    });

    httpClient.get(`/contact_segments.json`).then((res) => {
      setContactSegments(res.data);

      let list = [];

      res.data.map((contactSegment, idx) => {
        let contactSegmentNameplate = `Segment: ${contactSegment.name}`;

        list.push({
          value: contactSegment.id,
          label: contactSegmentNameplate,
        });
      });

      setContactSegmentsSelectList(list);
    });

    // Set default email signature if exists
    if(props.teamEmailSignatures.length > 0) {
      let ecProps = { ...emailCampaign };

      ecProps["email_signature_id"] = props.teamEmailSignatures[0].id;

      setEmailCampaign(ecProps);
    }
  }, []);

  const { getFieldError, getFieldDecorator, validateFields } = props.form;

  const toggleManualSend = () => {
    let ecProps = { ...emailCampaign };

    if (manualSend) {
      delete ecProps["status"];
      delete ecProps["manual_send"];

      setEmailCampaign(ecProps);
      setManualSend(false);
    } else {
      ecProps["status"] = "manual_send";
      ecProps["manual_send"] = true;

      setEmailCampaign(ecProps);
      setManualSend(true);
    }
  };

  const emailSignatureOptions = props.teamEmailSignatures.map(
    (signature, idx) => {
      return {
        label: signature.name,
        value: signature.id,
      };
    }
  );

  const renderForm = () => {
    return (
      <UikFormInputGroup className={styles.formContainer}>
        {[
          "Name",
          "Contact Segments",
          "Contacts",
          "From",
          "Subject",
          "Body",
        ].map((propertyLabel) => {
          return renderPropertyInput(propertyLabel);
        })}
        <div>
          {props.teamEmailSignatures.length > 0 &&
            renderPropertyInput("Email Signature")}
        </div>
        <div className={styles.toggleContainer}>
          <UikToggle checked={manualSend} onChange={() => toggleManualSend()} />
          <p className={styles.toggleLabel}>Send this email manually.</p>
        </div>
        <div>{!manualSend && renderPropertyInput("Send Date")}</div>
      </UikFormInputGroup>
    );
  };

  const handleClose = () => {
    props.setShowNewEmailCampaignModal(false);
    props.reloadEmailCampaigns();
  };

  const handlePropertyValueChange = (e, propertyId) => {
    if (propertyId === "body") {
      if (e.target.value) {
        setBodyEmpty(false);
      } else {
        setBodyEmpty(true);
      }
    }
    let ecProps = { ...emailCampaign };

    ecProps[propertyId] = e.target.value;

    setEmailCampaign(ecProps);
  };

  const handleEmailSignatureChange = (e) => {
    let ecProps = { ...emailCampaign };

    ecProps["email_signature_id"] = e.value;

    setEmailCampaign(ecProps);
  };

  const handleSendDateChange = (date, propertyId) => {
    let ecProps = { ...emailCampaign };

    let selectedSendDate = date ? date.toISOString() : null;

    ecProps[propertyId] = selectedSendDate;

    setEmailCampaign(ecProps);
    setNewSendDate(date);
  };

  const handleContactsSelection = (newValue: any, actionMeta: any) => {
    const tagsCheck = {};
    let tagsList = [];

    if (newValue) {
      let ecProps = { ...emailCampaign };

      newValue.forEach((tag) => {
        console.log("tag", tag);
        if (!tagsCheck[tag["value"]]) {
          // Add it to the tag list
          tagsList.push(tag.value);
          tagsCheck[tag["value"]] = true;
        }
      });

      ecProps["contacts"] = tagsList;

      setEmailCampaign(ecProps);
    }
    if (actionMeta.action === "remove-value") {
      let ecProps = { ...emailCampaign };
      let contactsList = ecProps["contacts"];

      ecProps["contacts"] = contactsList.filter(
        (contact) => contact !== actionMeta.removedValue.value
      );
      setEmailCampaign(ecProps);
    }
  };

  const handleContactSegmentsSelection = (newValue: any, actionMeta: any) => {
    const tagsCheck = {};
    let tagsList = [];

    if (newValue) {
      let ecProps = { ...emailCampaign };

      newValue.forEach((tag) => {
        if (!tagsCheck[tag["value"]]) {
          // Add it to the tag list
          tagsList.push(tag.value);
          tagsCheck[tag["value"]] = true;
        }
      });

      ecProps["contact_segments"] = tagsList;

      setEmailCampaign(ecProps);
    }

    if (actionMeta.action === "remove-value") {
      let ecProps = { ...emailCampaign };
      let segmentsList = ecProps["contact_segments"];

      ecProps["contact_segments"] = segmentsList.filter(
        (seg) => seg !== actionMeta.removedValue.value
      );

      setEmailCampaign(ecProps);
    }
  };

  const renderPropertyInput = (propertyLabel) => {
    let propertyId = propertyLabel.toLowerCase().replace(/ /, "_");

    if (propertyLabel === "Body") {
      return (
        <div className={styles.customInputContainer}>
          <div>
            <div className={styles.label}>
              <span className="uik-content-title__wrapper">Body</span>
            </div>
            <textarea
              className={
                bodyEmpty
                  ? "uik-input__input uik-input__errorHighlight"
                  : "uik-input__input"
              }
              style={{ width: "100%" }}
              rows="5"
              label="Body"
              placeholder="Enter message, utilize variables {{first_name}} {{last_name}}, generate unique share links with {{share|123|click here to see document}}"
              onChange={(e) => {
                handlePropertyValueChange(e, propertyId);
              }}
            ></textarea>
          </div>
          {bodyEmpty && (
            <p className={"uik-input__errorMessage"}>
              Please enter some body text.
            </p>
          )}
        </div>
      );
    } else if (propertyId === "send_date") {
      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Send Date
            </span>
          </div>
          <div className={styles.datePickerContainer}>
            <DatePicker
              className={`uik-input__input`}
              isClearable
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeSelect
              selected={newSendDate}
              onChange={(e) => {
                handleSendDateChange(e, propertyId);
              }}
            />
          </div>
        </div>
      );
    } else if (propertyId === "contacts") {
      const customCreatableSelectStyles = {
        control: (base) => ({
          ...base,
          maxHeight: 100,
          overflow: "auto",
          autoComplete: "off",
          role: "presentation",
        }),
      };

      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Contacts
            </span>
          </div>
          <Select
            isMulti
            isClearable={false}
            components={animatedComponents}
            styles={customCreatableSelectStyles}
            options={contactsSelectList}
            onChange={handleContactsSelection}
          />
        </div>
      );
    } else if (propertyId === "contact_segments") {
      const customCreatableSelectStyles = {
        control: (base) => ({
          ...base,
          maxHeight: 100,
          overflow: "auto",
          autoComplete: "off",
          role: "presentation",
        }),
      };

      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Segments
            </span>
          </div>
          <Select
            isMulti
            isClearable={false}
            components={animatedComponents}
            styles={customCreatableSelectStyles}
            options={contactSegmentsSelectList}
            onChange={handleContactSegmentsSelection}
          />
        </div>
      );
    } else if (propertyId === "from") {
      return (
        <div>
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Please enter an email.",
              },
              {
                type: "email",
                message: "Must be in the format name@company.com",
              },
            ],
          })(
            <UikInput
              label={`${propertyLabel}`}
              placeholder="name@company.com"
              errorMessage={(getFieldError("email") || []).join(", ")}
              onChange={(e) => {
                handlePropertyValueChange(e, propertyId);
              }}
            />
          )}
        </div>
      );
    } else if (propertyId === "name") {
      return (
        <div>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please enter the campaign name.",
              },
            ],
          })(
            <UikInput
              label={`${propertyLabel}`}
              placeholder={`${propertyLabel}`}
              disabled={false}
              errorMessage={(getFieldError("name") || []).join(", ")}
              onChange={(e) => {
                handlePropertyValueChange(e, propertyId);
              }}
            />
          )}
        </div>
      );
    } else if (propertyId === "subject") {
      return (
        <div>
          {getFieldDecorator("subject", {
            rules: [
              {
                required: true,
                message: "Please enter a subject.",
              },
            ],
          })(
            <UikInput
              label={`${propertyLabel}`}
              placeholder={`${propertyLabel}`}
              disabled={false}
              errorMessage={(getFieldError("subject") || []).join(", ")}
              onChange={(e) => {
                handlePropertyValueChange(e, propertyId);
              }}
            />
          )}
        </div>
      );
    } else if (propertyId === "email_signature") {
      return (
        <UikSelect
          className={"styles.select" + "styles.metadataField"}
          label={`Email Signature`}
          placeholder={`Select Signature`}
          defaultValue={props.teamEmailSignatures[0].id}
          options={emailSignatureOptions}
          onChange={(e) => handleEmailSignatureChange(e)}
        />
      );
    } else {
      return (
        <div>
          <UikInput
            label={`${propertyLabel}`}
            placeholder={`${propertyLabel}`}
            disabled={false}
            onChange={(e) => {
              handlePropertyValueChange(e, propertyId);
            }}
          />
        </div>
      );
    }
  };

  const handleSave = () => {
    let emailCampaignData = { ...emailCampaign };
    if (manualSend) {
      emailCampaignData["manual_send"] = manualSend;
      emailCampaignData["status"] = "manual_send";
    }

    let bodyTextNotEmpty =
      emailCampaignData.hasOwnProperty("body") && emailCampaignData["body"];

    validateFields((error, value) => {
      if (!error && bodyTextNotEmpty) {
        httpClient
          .post("/email_campaigns.json", { email_campaign: emailCampaignData })
          .then((response) => {
            console.log("response", response);
            handleClose();
          });
      } else {
        setBodyEmpty(true);
      }
    });
  };

  return (
    <NewModal visible={true} type="regular">
      <div className="heading">New Verified Email</div>
      <UikDivider />
      <div className="body">
        <div>{renderForm()}</div>
      </div>
      <div className="clear" />
      <div className="dividerContainer">
        <UikDivider />
      </div>
      <div className="buttons">
        <UikButton onClick={(e) => handleSave(e)} primary>
          Save
        </UikButton>
        <UikButton onClick={() => handleClose()}>Cancel</UikButton>
      </div>
    </NewModal>
  );
}

export default createForm()(NewItemModal);
