import React, { Component } from "react";
import VideoPlayer from "react-video-js-player";

import styles from "./VideoApp.module.scss";

import videoService from "../../services/Video";

class VideoApp extends React.Component {
  constructor(props) {
    super(props);
    const dimensions = this.setDimensions(null);
    this.state = {
      video: dimensions,
      playerContainerStyle: this.getContainerStyle(dimensions),
    };
  }

  componentDidMount = () => {};

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.doc.id != this.props.doc.id ||
      this.state.video.width != nextState.video.width ||
      this.state.video.height != nextState.video.height
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate = () => {
    const dimensions = this.setDimensions();
    this.dimensions = dimensions;
    this.playerContainerStyle = this.getContainerStyle(dimensions);
    this.reloadVideo();
  };

  reloadVideo = () => {
    this.player.width(this.dimensions && this.dimensions.width);
    this.player.height(this.dimensions && this.dimensions.height);
    this.player.src(this.props.doc.video_url);
  };

  getContainerStyle = (dimensions) => {
    return {
      width: dimensions.width,
      height: dimensions.height,
      margin: "0 auto",
    };
  };

  componentDidMount() {}

  setDimensions = (callback) => {
    const documentContainer = document.getElementById("document_container");
    const pageHeader = document.getElementById("usersScreenHeader");
    const documentViewHeader = document.getElementById("document_view_header");

    const dimensions = document
      .getElementById("document_container")
      .getBoundingClientRect();

    const containerWidth = dimensions.width;
    const containerHeight =
      documentContainer.offsetHeight -
      documentViewHeader.offsetHeight -
      pageHeader.offsetHeight +
      200;

    const videoWidth = this.props.doc.video_width;
    const videoHeight = this.props.doc.video_height;

    let width = 1024;
    let height = 768;

    const widthRatio = videoWidth / containerWidth;
    const heightRatio = videoHeight / containerHeight;

    const largerRatio = widthRatio >= heightRatio ? widthRatio : heightRatio;

    width = videoWidth / largerRatio;
    height = videoHeight / largerRatio;
    const newDimensions = { width: width, height: height };
    console.log("dimensions", newDimensions);
    return newDimensions;
  };

  onPlayerReady(player) {
    this.player = player;
    videoService.setVideo(this.player);
  }

  onVideoPlay(duration) {}

  onVideoPause(duration) {}

  onVideoTimeUpdate(duration) {}

  onVideoSeeking(duration) {}

  onVideoSeeked(from, to) {}

  onVideoEnd() {}

  render() {
    return (
      <div
        className={styles.videoPlayer}
        style={
          this.playerContainerStyle
            ? this.playerContainerStyle
            : this.state.playerContainerStyle
        }
      >
        <VideoPlayer
          controls={true}
          src={this.props.doc.video_url}
          poster=""
          width={
            this.dimensions ? this.dimensions.width : this.state.video.width
          }
          height={
            this.dimensions ? this.dimensions.height : this.state.video.height
          }
          onReady={this.onPlayerReady.bind(this)}
          onPlay={this.onVideoPlay.bind(this)}
          onPause={this.onVideoPause.bind(this)}
          onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
          onSeeking={this.onVideoSeeking.bind(this)}
          onSeeked={this.onVideoSeeked.bind(this)}
          onEnd={this.onVideoEnd.bind(this)}
        />
      </div>
    );
  }
}
export default VideoApp;
