import React, { useState } from "react";
import { Link } from "react-router-dom";

import contentPropertiesIcon from " ../../images/icons/svg/content-properties-icon.svg";
import contentPropertiesIconGreen from " ../../images/icons/svg/content-properties-icon-green.svg";
import styles from "./ContentPropertiesSection.module.scss";

/* UI Kit */
import { Uikon } from "@uik";

export default (props) => {
  const {
    documentDetails,
    showContentDetailsModal,
    renderOwnerName,
    renderDocumentFieldValues,
    renderDocumentTagValues,
    renderLibraryName,
    viewer,
  } = props;

  const [imgSrc, setImgSrc] = useState(contentPropertiesIcon);

  const mouseEnter = () => {
    setImgSrc(contentPropertiesIconGreen);
  };

  const mouseLeave = () => {
    setImgSrc(contentPropertiesIcon);
  };

  if (viewer) {
    return (
      <div className={styles.sectionContainer} id="contentProperties">
        <div className={styles.viewerSidePanelOptions}>
          <div className={styles.approvalsViewer}>
            <span>
              <img
                id={"content-properties"}
                className={styles.icon}
                src={contentPropertiesIcon}
              />
            </span>
            Content Properties
          </div>
        </div>
        <div className={styles.sidePanelOptionsList}>
          {renderLibraryName()}
          {renderOwnerName()}
          {renderDocumentFieldValues()}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.sectionContainer} id="contentProperties">
        <div
          className={styles.sidePanelOptions}
          onMouseOver={mouseEnter}
          onMouseOut={mouseLeave}
        >
          <Link
            className={styles.approvalsLink}
            onClick={(e) => showContentDetailsModal(e)}
            to={`/drafts/${documentDetails.id}/approvers`}
          >
            <div className={styles.approvals}>
              <span>
                <img
                  id={"content-properties"}
                  className={styles.icon}
                  src={imgSrc}
                />
              </span>
              Content Properties
              <span className={styles.approvalsRemaining}>Edit</span>
            </div>
          </Link>
        </div>
        <div className={styles.sidePanelOptionsList}>
          {renderLibraryName()}
          {renderOwnerName()}
          {renderDocumentFieldValues()}
          {documentDetails.tags_list &&
            documentDetails.tags_list.length > 0 &&
            renderDocumentTagValues(documentDetails)}
          <div className="clear" />
        </div>
      </div>
    );
  }
};
