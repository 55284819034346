import React from "react";
import PropTypes from "prop-types";
import styles from "./TagListItemsHeader.module.css";

const TagListItemsHeader = ({ letter }) => (
  <div className={styles.tagListLetterHeader}>{letter}</div>
);

TagListItemsHeader.propTypes = {
  letter: PropTypes.string.isRequired,
};

export default TagListItemsHeader;
