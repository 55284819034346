/* External libraries */
import React from "react";
import Select from "react-select";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

import Tooltip from "../../Common/Tooltip";
import check from "../../../images/icons/svg/Check_thick_gray.svg";
import security from "../../../services/Security";
import httpClient from "../../../lib/HttpClient";
import "../../../animate.css";
import notificationService from "../../../services/Notifications";

/* UI Kit */
import { UikButton, UikWidget, UikDivider } from "@uik";
import "@uik/styles.css";
import "../../../font.scss";
import styles from "./LanguageEditForm.module.scss";

/* Variables */

class LanguageEditFormBuild extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserId: security.getUserId(),
      currentUser: null,
      libraries: null,
      role: security.getUserRole(),
    };
  }

  componentWillMount = () => {
    httpClient.get("/users.json").then((response) => {
      response.data.map((user, index) => {
        if (user.id == this.state.currentUserId) {
          this.setState({ currentUser: user });
          this.setUserLibraries();
        }
      });
    });
  };

  setUserLibraries() {
    this.setState({
      libraries: this.state.currentUser.libraries.map(
        (library) => library.name
      ),
    });
  }

  handleSave(e) {
    e.preventDefault();
    i18n.changeLanguage(this.state.selectedLanguage);
  }

  onChange = (selectedLanguage) => {
    this.setState({ selectedLanguage: selectedLanguage.value });
  };

  render() {
    if (!this.state.currentUser) {
      return false;
    }
    const { t } = this.props;

    return (
      <div className={styles.editUserContainer}>
        <UikWidget className={styles.widget}>
          <div className={styles.editActionButtons}>
            <span className="uik-content-title__wrapper">
              {t("common_terms.library")}
            </span>
            <Select
              placeholder={t("common_phrases.select_a_language")}
              //value={
              //  this.state.selectedLibrary.value ? this.state.selectedLibrary : null
              //}
              options={[
                { value: "en", label: "English" },
                { value: "fr", label: "French" },
                { value: "cn", label: "Chinese" },
              ]}
              onChange={(e) => this.onChange(e)}
              //isDisabled={this.props.readOnly}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderColor: "#D6DCE8",
                  fontSize: "14px",
                }),
              }}
            />
          </div>
          <UikDivider className={styles.lastDivider} />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleSave(e)}
              primary
            >
              {t("common_terms.save")}
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

const LanguageEditForm = withTranslation()(LanguageEditFormBuild);

export default LanguageEditForm;
