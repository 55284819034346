import React from "react";
import PropTypes from "prop-types";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { createForm, formShape } from "rc-form";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Line } from "rc-progress";
import history from "../../../history";
import axios from "../../../lib/axios-client";
import httpClient from "../../../lib/HttpClient";
import Tooltip from "../../Common/Tooltip";

import referenceIcon from "../../../images/icons/16px/link.svg";
import Checkmark from "../../../images/icons/png/checkmark.png";
import Warning from "../../../images/icons/png/warning.svg";
import compare from "../../../images/icons/svg/compare-small.svg";
import externalLinkGreen from "../../../images/icons/svg/external-link-green.svg";
import CountPillGrey from "../../Common/CountPillGrey";

import security from "../../../services/Security";

/* UI Kit */
import { UikFormInputGroup, UikToggle, UikButton, Uikon, UikInput } from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

import Spinner from "../../Common/Spinner";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary";
import Checkbox from "../../Common/Checkbox";

import styles from "./DraftUpdateForm.module.scss";

/* Variables */

class DraftUpdateFormBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      mainDocumentPercent: 0,
      mainDocumentFilename: "",
      mainDocumentTitle: "",
      mainDocument: null,
      referenceFiles: [],
      errors: {},
      error: false,
      pdfOnlyMessage:
        "Only PDF, DOC(X), XLS(X) and PPT(X) files are supported at this time.",
      referenceDocumentError: false,
      notes: "",
      showNotes: true,
      copyReferences: false,
      autoApprove: false,
      autoApprovable: false,
      passwordInvalid: false,
      password: null,
      passwordAbsent: false,
      copyUnresolvedComments: false,
    };
  }

  componentWillMount = () => {
    let autoApprovable = true;

    this.props.approvals.map((approval) => {
      if (
        !approval.observer &&
        approval.status !== "approved" &&
        approval.status !== "approve_with_changes"
      ) {
        autoApprovable = false;
      }
    });
    this.setState({
      autoApprovable,
    });
  };

  setPasswordInvalid = (option) => {
    this.setState({ passwordInvalid: option });
  };

  setPasswordAbsent = (option) => {
    this.setState({ passwordAbsent: option });
  };

  setPassword = (password) => {
    this.setPasswordAbsent(false);
    this.setState({ password });
  };

  onMainDocumentDrop = (acceptedFiles, rejectedFiles) => {
    const token = security.getToken();
    const { docId } = this.props;

    const postConfig = {
      headers: {
        Authorization: "Bearer " + token,
      },
      onUploadProgress: (progressEvent) => {
        const mainDocumentPercent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (mainDocumentPercent >= 100) {
          this.setState({ mainDocumentPercent: 100 });
        } else {
          this.setState({ mainDocumentPercent });
        }
      },
    };

    const documentFormData = new FormData();
    documentFormData.append("document[document_file]", acceptedFiles[0]);

    this.setState({ mainDocumentPercent: 0 });
    this.setState({ mainDocumentFilename: acceptedFiles[0].name });

    axios
      .put(`/documents/${docId}.json`, documentFormData, postConfig)
      .then((response) => {
        this.setState({
          mainDocument: response.data,
          error: false,
          errors: {},
        });
      })
      .catch((error) => {
        security.checkAuth(error);
        console.log("error", error);

        this.setState({
          mainDocumentPercent: 0,
          mainDocument: {},
          errors: error.response.data,
          error: true,
        });
      });
  };

  renderMainDocumentPanel = () => {
    return (
      <div>
        <span className={styles.uploaderIcon}>
          <Uikon>cloud_up</Uikon>
        </span>
        <span className={styles.uploaderPlaceholder}>
          Drop files here to upload
        </span>
        <UikButton transparent className={styles.uploaderButton}>
          Browse
        </UikButton>
      </div>
    );
  };

  renderMainDocumentUploader = () => {
    return (
      <div className={styles.mainContentUploader}>
        <Dropzone onDrop={this.onMainDocumentDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop approval document here
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop files here to upload
                    </span>
                    <UikButton transparent className={styles.uploaderButton}>
                      Browse
                    </UikButton>
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  autoApproveContent = () => {
    if (this.state.autoApprove) {
      this.setState({
        autoApprove: false,
      });
    } else {
      this.setState({
        autoApprove: true,
      });
    }
  };

  copyComments = () => {
    if (this.state.copyUnresolvedComments) {
      this.setState({
        copyUnresolvedComments: false,
      });
    } else {
      this.setState({
        copyUnresolvedComments: true,
      });
    }
  };

  renderMainDocumentUploaderProgress = () => {
    return (
      <div className={styles.mainContentUploading}>
        <div className={styles.innerContentUploading}>
          <span className={styles.uploaderIcon}>
            <Uikon>attachment</Uikon>
          </span>
          {this.state.error && (
            <span className={styles.uploadError}>
              {this.state.pdfOnlyMessage}
            </span>
          )}

          <span className={styles.uploadingFilename}>
            {this.state.mainDocumentFilename}
          </span>
          {this.state.mainDocumentPercent == 100 && !this.state.error && (
            <span className={styles.uploaderIconCheck}>
              <img src={Checkmark} className={styles.checkMark} />
            </span>
          )}
          {this.state.error && (
            <span className={styles.uploaderIconError}>
              <img src={Warning} className={styles.warning} />
            </span>
          )}
          {this.state.mainDocumentPercent < 100 && (
            <Line
              className={styles.uploadProgress}
              percent={this.state.mainDocumentPercent}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="square"
            />
          )}
        </div>
      </div>
    );
  };

  goToDocument = (e) => {
    e.preventDefault();
    const { docId } = this.props;
    history.push(`/drafts/${docId}`);
  };

  goToNewDocument = (e) => {
    e.currentTarget.disabled = true;
    e.preventDefault();
    const { id } = this.state.mainDocument;
    if (this.state.autoApprove) {
      if (this.state.password) {
        const documentData = {
          copy_references: this.state.copyReferences,
          copy_unresolved_comments: this.state.copyUnresolvedComments,
          user: {
            password: this.state.password,
          },
          document: {
            notes: this.state.notes,
            approved: this.state.autoApprove,
          },
          approval: {
            document_id: this.state.mainDocument.id,
            user_id: security.getUserId(),
            observer: false,
          },
        };
        httpClient
          .post(`/documents/auto_approve.json`, documentData)
          .then((response) => {
            this.setPasswordInvalid(false);
            history.push(`/documents/${response.data.id}`);
          })
          .catch((error) => {
            if (error) {
              this.setPasswordInvalid(true);
            }
          });
      } else {
        this.setPasswordAbsent(true);
      }
    } else {
      if (this.state.showNotes) {
        httpClient
          .put(`/documents/${id}.json`, {
            send_notification_email: true,
            notify_reviewers: this.state.showNotes,
            copy_unresolved_comments: this.state.copyUnresolvedComments,
            copy_references: this.state.copyReferences,
            document: {
              notes: this.state.notes,
            },
          })
          .then((response) => {
            if (response.data.approved) {
              history.push(`/documents/${id}`);
            } else {
              history.push({
                pathname: `/drafts/${id}`,
                state: {
                  referencesMigrated: this.state.copyReferences,
                },
              });
            }
          });
      } else {
        httpClient
          .put(`/documents/${id}.json`, {
            send_notification_email: true,
            notify_reviewers: this.state.showNotes,
            copy_unresolved_comments: this.state.copyUnresolvedComments,
            copy_references: this.state.copyReferences,
            document: {
              notes: this.state.notes,
            },
          })
          .then((response) => {
            if (response.data.approved) {
              history.push(`/documents/${id}`);
            } else {
              history.push({
                pathname: `/drafts/${id}`,
                state: {
                  referencesMigrated: this.state.copyReferences,
                },
              });
            }
          });
      }
    }
  };

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  };

  handleCopyReferencesToggle = (e) => {
    this.setState({ copyReferences: e.target.checked });
  };

  compareVersions = () => {
    const baseURL = `${window.location.protocol + "//" + window.location.host}`;
    window.open(
      `${baseURL}/compare/${this.props.docId}/${this.state.mainDocument.id}`
    );
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div>
        <Container fluid className={styles.draftUploadForm}>
          <UikFormInputGroup>
            <div className={styles.header}>
              <div className={styles.leftContainer}>
                Upload your main content
              </div>
            </div>
            {this.state.error && this.renderMainDocumentUploader()}
            {!this.state.mainDocumentFilename &&
              this.renderMainDocumentUploader()}
            {this.state.mainDocumentFilename.length > 0 &&
              this.renderMainDocumentUploaderProgress()}
          </UikFormInputGroup>
        </Container>
        {this.state.mainDocument && (
          <Container fluid className={styles.autoApproveContainer}>
            <div>
              <div className={styles.approvalToggleContainer}>
                {this.props.convoCount > 0 && (
                  <div className={styles.approvalToggleContainer}>
                    <UikToggle
                      defaultChecked={this.state.autoApprove}
                      onChange={() => this.copyComments()}
                    />
                    <p className={styles.toggleLabel}>
                      Copy unresolved comments to new version
                    </p>
                    <CountPillGrey count={this.props.convoCount} />
                  </div>
                )}
              </div>
            </div>
            <div>
              {this.props.showCopyReferencesToggle && (
                <div className={styles.approvalToggleContainer}>
                  <UikToggle
                    checked={this.state.copyReferences}
                    onChange={this.handleCopyReferencesToggle}
                  />
                  <p className={styles.toggleLabel}>
                    Copy references to new version
                  </p>
                  <CountPillGrey count={this.props.referencesCount} />
                </div>
              )}
            </div>
            <div>
              {this.state.autoApprovable && (
                <div className={styles.approvalToggleContainer}>
                  <UikToggle
                    defaultChecked={this.state.autoApprove}
                    onChange={() => this.autoApproveContent()}
                  />
                  <p className={styles.approvalToggleLabel}>
                    Auto approve this content
                    <div className={styles.toolTipContainer}>
                      <div className={styles.annotIdTooltipBox}>
                        <Tooltip type="description" subType="autoApprove" />
                      </div>
                    </div>
                  </p>
                </div>
              )}
            </div>
            {this.state.autoApprove && (
              <div
                className={`${styles.passwordConfirmation} ${
                  this.state.passwordInvalid || this.state.passwordAbsent
                    ? `${styles.passwordInvalid}`
                    : ``
                }`}
              >
                <div className={styles.confirmPasswordLabel}>
                  confirm password for approval
                </div>
                <UikInput
                  role="presentation"
                  autocomplete="off"
                  type="password"
                  value={this.state.password}
                  onChange={(e) => this.setPassword(e.target.value)}
                  onFocus={() => this.setPasswordInvalid(false)}
                ></UikInput>
                {this.state.passwordInvalid && (
                  <div className={styles.passwordInvalidLabel}>
                    Password is invalid
                  </div>
                )}
                {this.state.passwordAbsent && (
                  <div className={styles.passwordAbsentLabel}>
                    Enter password to continue
                  </div>
                )}
              </div>
            )}
            <div>
              {this.props.showNotes && (
                <Row className={styles.notesInput}>
                  <Col sm={12}>
                    <div className={styles.toggleContainer}>
                      <UikToggle
                        defaultChecked={true}
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            ...prevState,
                            showNotes: !prevState.showNotes,
                          }))
                        }
                      />
                      <p className={styles.toggleLabel}>Notify reviewers</p>
                    </div>
                    {this.state.showNotes && (
                      <textarea
                        className={`uik-input__input ${styles.notesTextarea}`}
                        style={{ width: "100%" }}
                        rows="5"
                        value={this.state.notes}
                        onChange={(e) => this.handleNotesChange(e)}
                        placeholder="Type a message to reviewers."
                      ></textarea>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </Container>
        )}
        {this.state.mainDocument && this.state.mainDocumentPercent === 100 && (
          <Container fluid className={styles.draftUploadForm}>
            <div className={styles.versionsContainer}>
              <div className={styles.titleContainer}>
                <img src={compare} />
                <h1 className={styles.title}>Compare to Last Version</h1>
              </div>
              <p className={styles.description}>
                See the differences between the new version you uploaded
                compared to the last
              </p>
              <div
                className={styles.button}
                onClick={() => this.compareVersions()}
              >
                Compare
                <img className={styles.linkIcon} src={externalLinkGreen} />
              </div>
            </div>
          </Container>
        )}
        <Container className={styles.draftUploadFormActions}>
          {(this.state.mainDocument ||
            this.state.mainDocumentPercent != 100) && (
            <Row className={styles.buttonRow}>
              <ButtonPrimary
                original
                disabled={
                  !(
                    this.state.mainDocument &&
                    this.state.mainDocumentPercent == 100
                  )
                }
                text="Next"
                onClick={(e) => this.goToNewDocument(e)}
              />
              {!(
                this.state.mainDocument && this.state.mainDocumentPercent == 100
              ) && (
                <ButtonPrimary
                  transparent
                  text="Cancel"
                  onClick={(e) => this.goToDocument(e)}
                />
              )}
            </Row>
          )}
          {!this.state.mainDocument && this.state.mainDocumentPercent == 100 && (
            <div className={styles.processingContainer}>
              <Spinner text={"Processing..."} />
            </div>
          )}
        </Container>
      </div>
    );
  }
}

DraftUpdateFormBuild.propTypes = {
  docId: PropTypes.number,
};

const DraftUpdateForm = createForm()(DraftUpdateFormBuild);
export default DraftUpdateForm;
