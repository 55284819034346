import React, { useEffect, useState } from "react";
import Header from "../shared/Header/Header";
import Table from "./Table";
import httpClient from "../../lib/HttpClient";

import InfoIcon from "../../images/icons/svg/info-tooltip-active.svg";
import DownloadIcon from "../../images/icons/svg/download-green.svg";
import OopsImage from "../../images/icons/svg/oops-image.svg";
import { UikButton } from "@uik";
import download from "downloadjs";

import styles from "./AuditView.module.scss";

export default () => {
  const [sorting, setSorting] = useState("desc");
  const [sortingColumn, setSortingColumn] = useState(0);
  const [documents, setDocuments] = useState(null);
  const [columns, setColumns] = useState(null);
  const [error, setError] = useState(null);

  const fetchDocuments = async () => {
    const params = {};
    if (columns && sortingColumn !== null) {
      params.sort = `${columns[columns.length - 1 - sortingColumn]} ${sorting}`;
    }
    try {
      const response = await httpClient.post(
        "/documents/drafts/all.json",
        params
      );
      setDocuments(response.data.documents);

      // Add empty column for view link
      const tableColumns = response.data.columns;
      tableColumns.push("");
      setColumns(tableColumns);
      if (!columns) {
        setSortingColumn(2);
      }
    } catch (err) {
      setError(true);
    }
  };

  const downloadCSV = () => {
    const params = {};
    if (columns && sortingColumn !== null) {
      params.sort = `${columns[columns.length - 1 - sortingColumn]} ${sorting}`;
    }
    httpClient
      .post("/documents/drafts/all/csv.json", params)
      .then((response) => {
        download(response.data, `Audit-View.csv`, "text/csv");
      });
  };

  useEffect(() => {
    if (columns) {
      if (sortingColumn !== 1) {
        fetchDocuments();
      }
    } else {
      fetchDocuments();
    }
  }, [sorting, sortingColumn]);

  const sortColumns = (index) => {
    if (index === columns.length - 2) {
      const rightIndex = columns.length - 1 - index;
      if (sortingColumn !== rightIndex) {
        setSortingColumn(rightIndex);
        setSorting("asc");
        let documentsToSort = [...documents];
        const sorted = documentsToSort.sort(function (a, b) {
          if (a[columns.length - 2] < b[columns.length - 2]) {
            return -1;
          }
          if (a[columns.length - 2] > b[columns.length - 2]) {
            return 1;
          }
          return 0;
        });
        setDocuments(sorted);
      } else {
        if (sorting === "asc") {
          let documentsToSort = [...documents];
          const sorted = documentsToSort.sort(function (a, b) {
            if (a[columns.length - 2] < b[columns.length - 2]) {
              return 1;
            }
            if (a[columns.length - 2] > b[columns.length - 2]) {
              return -1;
            }
            return 0;
          });
          setDocuments(sorted);
          setSorting("desc");
        } else {
          setSortingColumn(null);
          setSorting(null);
        }
      }
    } else {
      const rightIndex = columns.length - 1 - index;
      if (sortingColumn !== rightIndex) {
        setSortingColumn(rightIndex);
        setSorting("asc");
      } else {
        if (sorting === "asc") {
          setSorting("desc");
        } else {
          setSortingColumn(null);
          setSorting(null);
        }
      }
    }
  };

  const openDraft = (id) => {
    window.open(`${window.location.origin}/drafts/${id}`, "_blank");
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.body}>
        <div className={styles.headerContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Audit View</div>
            <div className={styles.description}>
              All the draft content in your workspace.
            </div>
          </div>
          <div className={styles.downloadLinkContainer}>
            <UikButton
              className={styles.exportButton}
              onClick={() => {
                downloadCSV();
              }}
            >
              Download
              <img className={styles.externalLink} src={DownloadIcon} />
            </UikButton>
          </div>
        </div>
        {columns && documents && documents.length > 0 && (
          <div className={styles.table}>
            <Table
              columns={columns}
              rows={documents}
              sort={sortColumns}
              sortingColumn={sortingColumn}
              sorting={sorting}
              openDraft={openDraft}
            />
          </div>
        )}
        {columns && documents && documents.length === 0 && (
          <div className={styles.emptyState}>
            <div className={styles.infoIcon}>
              <img src={InfoIcon} />
            </div>
            <div className={styles.text}>
              There is no draft content in your workspace
            </div>
          </div>
        )}
        {error && (
          <div className={styles.unauthorizedState}>
            <div className={styles.text}>
              <div className={styles.header}>oops!</div>
              <div className={styles.text1}>
                Looks like you don't have permission to view this page
              </div>
              <div className={styles.text2}>
                Please ask your workspace admin to grant you permissions
              </div>
            </div>
            <div className={styles.image}>
              <img src={OopsImage} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
