import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Button from "../Common/Buttons/ButtonPrimary";
import { apiUrl } from "../../lib/axios-client";
import security from "../../services/Security";
import history from "../../history";
import axios from "axios";

import "./Setup2FA.scss";
import "react-phone-input-2/lib/bootstrap.css";

export default ({
  onSuccess,
  email,
  password,
  previousPhoneNumber = "",
  previousCountryCode = "1",
}) => {
  const [phoneNumber, setPhoneNumber] = useState(previousPhoneNumber);
  const [countryCode, setCountryCode] = useState(previousCountryCode);
  const [error, setError] = useState(null);

  const handleNumberChange = (value, country) => {
    if (error) {
      setError(null);
    }
    const regEx = new RegExp(`^${country.dialCode}`);
    setPhoneNumber(value.replace(regEx, ""));
    setCountryCode(country.dialCode);
  };

  const sendCode = () => {
    let valid = false;
    const parsedNumber = parsePhoneNumberFromString(
      `+${countryCode}${phoneNumber}`
    );
    if (parsedNumber) {
      if (parsedNumber.isValid()) {
        valid = true;
        axios
          .post(apiUrl + "/2fa/setup", {
            phone_number: phoneNumber,
            country_code: countryCode,
            user: {
              email,
              password,
            },
          })
          .then(() => {
            onSuccess(countryCode, phoneNumber);
          })
          .catch(() => {
            console.error("Could not setup 2FA!");
          });
      }
    }
    if (!valid) {
      setError("Phone number is invalid");
    }
  };

  // const setupLater = () => {
  //     security.setToken(jwt);
  //     history.push("/drafts");
  // }

  return (
    <div className={`setup2FA-container${error ? ` error` : ``}`}>
      <div className="label">Phone number</div>
      <PhoneInput
        country={"ca"}
        value={`${countryCode}${phoneNumber}`}
        onChange={handleNumberChange}
      />
      {error && <div className="invalid-number">{error}</div>}
      <Button original text="Send Verification" onClick={sendCode} />
      {/* <div className="setup-later">
                <button
                    onClick={setupLater}
                >
                    Setup Later
                </button>
            </div> */}
    </div>
  );
};
