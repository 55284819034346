import React from "react";
import PropTypes from "prop-types";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { createForm, formShape } from "rc-form";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Line } from "rc-progress";
import history from "../../../history";
import axios from "../../../lib/axios-client";
import httpClient from "../../../lib/HttpClient";
import sanitize from "sanitize-filename";
import Tooltip from "../../Common/Tooltip";

import referenceIcon from "../../../images/icons/16px/link.svg";
import Checkmark from "../../../images/icons/png/checkmark.png";
import Warning from "../../../images/icons/png/warning.svg";

import security from "../../../services/Security";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  Uikon,
  UikToggle,
  UikWidget,
  UikHeadline,
  UikHeadlineDesc,
  UikDivider,
  UikSelect,
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

import Spinner from "../../Common/Spinner";

import styles from "./DraftUpdateForm.module.scss";

/* Variables */

class DraftVideoUpdateFormBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      mainDocumentPercent: 0,
      mainDocumentFilename: "",
      mainDocumentTitle: "",
      mainDocument: null,
      referenceFiles: [],
      errors: {},
      error_message: "",
      error: false,
      pdfOnlyMessage:
        "Only PDF, DOC(X), XLS(X) and PPT(X) files are supported at this time.",
      referenceDocumentError: false,
      video: null,
      autoApprove: false,
      passwordInvalid: false,
      password: null,
      passwordAbsent: false,
    };
  }

  setPasswordInvalid = (option) => {
    this.setState({ passwordInvalid: option });
  };

  setPasswordAbsent = (option) => {
    this.setState({ passwordAbsent: option });
  };

  setPassword = (password) => {
    this.setPasswordAbsent(false);
    this.setState({ password });
  };

  autoApproveContent = () => {
    if (this.state.autoApprove) {
      this.setState({
        autoApprove: false,
      });
    } else {
      this.setState({
        autoApprove: true,
      });
    }
  };

  onMainVideoDrop = (acceptedFiles, rejectedFiles) => {
    if (!acceptedFiles[0].type.match("video.*")) {
      alert("Please upload a video file only");

      return;
    }
    const { docId } = this.props;
    httpClient.get("/aws/policy").then((response) => {
      const postConfig = {
        onUploadProgress: (progressEvent) => {
          const mainDocumentPercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (mainDocumentPercent >= 100) {
            this.setState({ mainDocumentPercent: 100 });
          } else {
            this.setState({ mainDocumentPercent });
          }
        },
      };

      this.setState({
        mainDocumentPercent: 0,
        mainDocumentFilename: acceptedFiles[0].name,
      });

      let data = new FormData();
      const timestamp = new Date().getTime();

      const fileName = sanitize(acceptedFiles[0].name).replace(/[{()}]/g, "");
      const fileDirectory = `${response.data.directory}/${timestamp}/`;
      const fileLocation = `${response.data.directory}/${timestamp}/${fileName}`;

      data.append("key", fileLocation);
      data.append("acl", "private");
      data.append(
        "Content-Type",
        acceptedFiles[0].type != ""
          ? acceptedFiles[0].type
          : "application/octet-stream"
      );
      data.append("X-Amz-Credential", response.data.x_amz_credential);
      data.append("X-Amz-Algorithm", response.data.x_amz_algorithm);
      data.append("X-Amz-Date", response.data.x_amz_date);
      data.append("Policy", response.data.encoded_policy);
      data.append("X-Amz-Signature", response.data.x_amz_signature);
      data.append("file", acceptedFiles[0]);

      const url = `https://${response.data.bucket}.${response.data.s3_region_endpoint}/`;

      axios
        .post(url, data, postConfig)
        .then((response) => {
          let videoData = {};
          videoData.s3_url = `${url}${fileLocation}`;
          videoData.file_location = fileDirectory;
          videoData.file_name = fileName;
          videoData.file_type = acceptedFiles[0].type;
          httpClient
            .post(`/aws/create_video/${docId}`, videoData)
            .then((response) => {
              this.setState(
                {
                  video: response.data,
                  mainDocumentFileUploaded: true,
                  error: false,
                  errors: {},
                  error_message: "",
                },
                () => {
                  const documentFormData = { video_id: this.state.video.id };
                  httpClient
                    .put(`/documents/${docId}/video.json`, documentFormData)
                    .then((response) => {
                      this.setState({
                        mainDocument: response.data,
                        error: false,
                        errors: {},
                        error_message: "",
                      });
                    })
                    .catch((error) => {
                      security.checkAuth(error);

                      this.setState({
                        mainDocumentPercent: 0,
                        mainDocument: {},
                        error_message:
                          "There was an error uploading your file.",
                        errors: error.response.data,
                        error: true,
                      });
                    });
                }
              );
            })
            .catch((error) => {
              console.log("error", error);
              this.setState({
                mainDocumentPercent: 0,
                mainDocument: null,
                error_message:
                  "There was an error uploading your file. Ensure it is a valid video",
                error: true,
              });
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.setState({
            mainDocumentPercent: 0,
            mainDocument: null,
            error_message: "There was an error uploading your file. ",
            errors: error.response.data,
            error: true,
          });
        });
    });
  };

  renderMainDocumentPanel = () => {
    return (
      <div>
        <span className={styles.uploaderIcon}>
          <Uikon>cloud_up</Uikon>
        </span>
        <span className={styles.uploaderPlaceholder}>
          Drop files here to upload
        </span>
        <UikButton transparent className={styles.uploaderButton}>
          Browse
        </UikButton>
      </div>
    );
  };

  renderMainDocumentUploader = () => {
    return (
      <div className={styles.mainContentUploader}>
        <Dropzone onDrop={this.onMainVideoDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop approval document here
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop files here to upload
                    </span>
                    <UikButton transparent className={styles.uploaderButton}>
                      Browse
                    </UikButton>
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderMainDocumentUploaderProgress = () => {
    return (
      <div className={styles.mainContentUploading}>
        <span className={styles.uploaderIcon}>
          <img src={referenceIcon} />
        </span>

        <div className={styles.innerContentUploading}>
          <span className={styles.uploaderIcon}>
            <Uikon>attachment</Uikon>
          </span>
          {this.state.error && (
            <span className={styles.uploadError}>
              {this.state.error_message
                ? this.state.error_message
                : this.state.pdfOnlyMessage}
            </span>
          )}

          <span className={styles.uploadingFilename}>
            {this.state.mainDocumentFilename}
          </span>
          {this.state.mainDocumentPercent == 100 && !this.state.error && (
            <span className={styles.uploaderIconCheck}>
              <img src={Checkmark} className={styles.checkMark} />
            </span>
          )}
          {this.state.error && (
            <span className={styles.uploaderIconError}>
              <img src={Warning} className={styles.warning} />
            </span>
          )}
          {this.state.mainDocumentPercent < 100 && (
            <Line
              className={styles.uploadProgress}
              percent={this.state.mainDocumentPercent}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="square"
            />
          )}
        </div>
      </div>
    );
  };

  goToDocument = (e) => {
    e.preventDefault();
    const { docId } = this.props;
    history.push(`/drafts/${docId}`);
  };

  goToNewDocument = (e) => {
    e.preventDefault();
    const { id } = this.state.mainDocument;
    if (this.state.autoApprove) {
      if (this.state.password) {
        const documentFormData = {
          user: {
            password: this.state.password,
          },
          document: {
            approved: this.state.autoApprove,
          },
          approval: {
            document_id: id,
            user_id: security.getUserId(),
            observer: false,
          },
        };
        httpClient
          .post(`/documents/auto_approve.json`, documentFormData)
          .then((response) => {
            this.setPasswordInvalid(false);
            history.push(`/documents/${response.data.id}`);
          })
          .catch((error) => {
            if (error) {
              this.setPasswordInvalid(true);
            }
          });
      } else {
        this.setPasswordAbsent(true);
      }
    } else {
      history.push(`/drafts/${id}`);
    }
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div>
        <Container fluid className={styles.draftUploadForm}>
          <UikFormInputGroup>
            <span>Upload your main content</span>
            {this.state.error && this.renderMainDocumentUploader()}
            {!this.state.mainDocumentFilename &&
              this.renderMainDocumentUploader()}
            {this.state.mainDocumentFilename.length > 0 &&
              this.renderMainDocumentUploaderProgress()}
          </UikFormInputGroup>
        </Container>
        {this.props.autoApprovable && (
          <Container fluid className={styles.autoApproveContainer}>
            <div className={styles.approvalToggleContainer}>
              <p className={styles.approvalToggleLabel}>
                Auto approve this content
                <div className={styles.toolTipContainer}>
                  <div className={styles.annotIdTooltipBox}>
                    <Tooltip type="description" subType="autoApprove" />
                  </div>
                </div>
              </p>
              <UikToggle
                defaultChecked={this.state.autoApprove}
                onChange={() => this.autoApproveContent()}
              />
            </div>
            {this.state.autoApprove && (
              <div
                className={`${styles.passwordConfirmation} ${
                  this.state.passwordInvalid || this.state.passwordAbsent
                    ? `${styles.passwordInvalid}`
                    : ``
                }`}
              >
                <div className={styles.confirmPasswordLabel}>
                  confirm password for approval
                </div>
                <UikInput
                  type="password"
                  value={this.state.password}
                  onChange={(e) => this.setPassword(e.target.value)}
                  onFocus={() => this.setPasswordInvalid(false)}
                ></UikInput>
                {this.state.passwordInvalid && (
                  <div className={styles.passwordInvalidLabel}>
                    Password is invalid
                  </div>
                )}
                {this.state.passwordAbsent && (
                  <div className={styles.passwordAbsentLabel}>
                    Enter password to continue
                  </div>
                )}
              </div>
            )}
          </Container>
        )}
        <Container fluid className={styles.draftUploadFormActions}>
          {this.state.mainDocumentPercent != 100 && (
            <UikButton
              transparent
              className={styles.cancelButton}
              onClick={(e) => this.goToDocument(e)}
            >
              Cancel
            </UikButton>
          )}
          {this.state.mainDocument && this.state.mainDocumentPercent == 100 && (
            <UikButton
              primary
              className={styles.nextButton}
              onClick={(e) => this.goToNewDocument(e)}
            >
              Next
            </UikButton>
          )}
          {!this.state.mainDocument && this.state.mainDocumentPercent == 100 && (
            <div className={styles.processingContainer}>
              <Spinner text={"Processing..."} />
            </div>
          )}
        </Container>
      </div>
    );
  }
}

DraftVideoUpdateFormBuild.propTypes = {
  docId: PropTypes.number,
};

const DraftVideoUpdateForm = createForm()(DraftVideoUpdateFormBuild);
export default DraftVideoUpdateForm;
