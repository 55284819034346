import React, { useState, useEffect } from "react";
import Select from "react-select";
import httpClient from "../../lib/HttpClient";

export default (props) => {
  const { libraryId, currentOwnerName, currentOwnerId, onOwnerChange } = props;

  const [libraryUsers, setLibraryUsers] = useState([]);
  const [owner, setOwner] = useState({
    value: null,
    label: currentOwnerName,
  });

  useEffect(() => {
    httpClient
      .get(`/libraries/${libraryId}/library_users.json`)
      .then(({ data }) => {
        setLibraryUsers(transformData(data));
      });
  }, []);

  const transformData = (data) => {
    let orderedLibraryUserNames = data
      .map((libraryUser) => libraryUser)
      .sort(function (a, b) {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      });
    let suggestedOwners = [];
    for (const user of orderedLibraryUserNames) {
      for (const libraryUser of data) {
        if (user.id === libraryUser.id && libraryUser.id !== currentOwnerId) {
          let libraryUserName = `${libraryUser.first_name} ${libraryUser.last_name}`;
          suggestedOwners.push({
            value: libraryUser.id,
            label: libraryUserName,
          });
        }
      }
    }
    return suggestedOwners;
  };

  const onChange = (selectedOwner) => {
    setOwner(selectedOwner);
    onOwnerChange(selectedOwner);
  };

  return (
    <div className="library_select" data-cy="librarySelect">
      <span className="uik-content-title__wrapper">Owner</span>
      <Select
        placeholder="Select an owner"
        value={owner ? owner : null}
        options={libraryUsers}
        onChange={(e) => onChange(e)}
        styles={{
          control: (styles) => ({
            ...styles,
            borderColor: "#D6DCE8",
            fontSize: "14px",
          }),
        }}
      />
    </div>
  );
};
