import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import CloseSVG from "../../../../images/icons/svg/close.svg";
import DocumentReferenceCard from "./DocumentReferenceCard.js";
import styles from "./DocumentReferencePanel.module.scss";
import loadingAnimation from "../../../../images/lotties/loader.json";
import httpClient from "../../../../lib/HttpClient";
import moreIcon from "../../../../images/icons/svg/more-white.svg";
import moveUpIcon from "../../../../images/icons/svg/move-up.svg";
import moveUpWhiteIcon from "../../../../images/icons/svg/move-up-white.svg";
import moveDownWhiteIcon from "../../../../images/icons/svg/move-down-white.svg";
import arrowIcon from "../../../../images/icons/svg/tan_arrow.svg";
import moveDownIcon from "../../../../images/icons/svg/move-down.svg";
import NewModal from "../../../Common/Modal";
import notificationService from "../../../../services/Notifications";
import fetchDocumentReferences from "../../../../actions/document/fetchDocumentReferences";
import linkIcon from "../../../../images/icons/svg/link-icon.svg";
import security from "../../../../services/Security";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikFormInputGroup,
  UikInput,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
} from "@uik";

export default (props) => {
  const {
    docId,
    closeDocumentReferencePanel,
    versionId,
    versionNumber,
    originalDocumentId,
    isApproved,
    readOnly,
    onAnnotationSelect,
    startEditingDocumentReferences,
    stopEditingDocumentReferences,
    editingDocumentReferences,
  } = props;

  const [totalPageCount, setTotalPageCount] = useState(0);
  const [searchResults, setSearchResults] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showShiftModal, setShowShiftModal] = useState(false);
  const [shiftStartPage, setShiftStartPage] = useState(null);
  const [shiftDirection, setShiftDirection] = useState(null);
  const [numberPagesToShift, setNumberPagesToShift] = useState(1);
  const [pageRangeToShift, setPageRangeToShift] = useState([]);
  const [documentReferencesByPage, setDocumentReferencesByPage] = useState({});
  const [documentReferencesToShift, setDocumentReferencesToShift] = useState(
    []
  );
  const [pageRangeError, setPageRangeError] = useState(false);
  const [groupedReferenceCitations, setGroupedReferenceCitations] = useState(
    {}
  );

  const documentReferences = useSelector((state) => {
    return state.documentReferences;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let documentReferencesObj = {};

    let groupedDocumentReferences = groupedReferences(
      documentReferences,
      "xfdf_highlight_id"
    );

    setGroupedReferenceCitations(groupedDocumentReferences);

    Object.keys(groupedDocumentReferences).forEach((xfdf_highlight_id) => {
      const group_representative_document_reference =
        groupedDocumentReferences[xfdf_highlight_id][0];

      const pageNumber = getPageNumber(group_representative_document_reference);

      if (!documentReferencesObj[pageNumber]) {
        documentReferencesObj[pageNumber] = [];
      }
      documentReferencesObj[pageNumber].push(
        group_representative_document_reference
      );
    });

    setDocumentReferencesByPage(documentReferencesObj);

    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const pageCount = instance.Core.documentViewer.getPageCount();

    setTotalPageCount(pageCount);
  }, [documentReferences]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const close = () => {
    if (editingDocumentReferences) {
      hideShiftReferencesModal();
      undoShiftChanges();
      stopEditingDocumentReferences();
      closeDocumentReferencePanel();
    } else {
      closeDocumentReferencePanel();
    }
  };

  const renderEmtpyState = () => {
    return (
      <div className={styles.emptyStateMessage}>
        <img src={linkIcon}></img>
        <span className={styles.emptyStateMessageLineOne}>
          There are no references.
        </span>
        <span className={styles.emptyStateMessageLineTwo}>
          To create one, highlight some text and select the link icon.
        </span>
      </div>
    );
  };

  const groupedReferences = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const getMaxPageShift = () => {
    let maxShift = 1;
    if (shiftDirection === "up") {
      maxShift = shiftStartPage - 1;
    } else {
      maxShift = totalPageCount - shiftStartPage;
    }

    return maxShift;
  };

  const validatePageRange = (value) => {
    setNumberPagesToShift(value);
    const numPagesToShift = parseInt(value);
    const exceedsLowerLimit =
      shiftStartPage - numPagesToShift < 1 && shiftDirection === "up";
    const exceedsUpperLimit =
      shiftStartPage + numPagesToShift > totalPageCount &&
      shiftDirection === "down";
    if (exceedsLowerLimit || exceedsUpperLimit) {
      setPageRangeError(true);
    } else {
      setPageRangeError(false);
    }
  };

  const actionsDropDown = ({ onClick }) => {
    return (
      <span onClick={onClick}>
        <img className={styles.moreIcon} src={moreIcon} />
      </span>
    );
  };

  const getPageNumber = (ref) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const annotation = instance.Core.annotationManager.getAnnotationById(
      ref.xfdf_highlight_id
    );

    if (annotation) {
      return annotation.getPageNumber();
    }
  };

  const showShiftReferencesModal = (page, direction) => {
    setShowShiftModal(true);
    setShiftStartPage(parseInt(page));
    setShiftDirection(direction);
  };

  const renderTransition = (key) => {
    let shiftToPage;

    if (shiftDirection === "up") {
      shiftToPage = parseInt(key) - parseInt(numberPagesToShift);
    } else {
      shiftToPage = parseInt(key) + parseInt(numberPagesToShift);
    }
    const outOfbounds = shiftToPage > totalPageCount;

    if (editingDocumentReferences) {
      let found = pageRangeToShift.find((page) => {
        return page === parseInt(key);
      });

      if (found) {
        if (outOfbounds) {
          return (
            <div className={styles.transitionState}>
              <p>{`page ${key}`}</p>
            </div>
          );
        } else {
          return (
            <div className={styles.transitionState}>
              <p>{`page ${key}`}</p>
              <img className={styles.arrow} src={arrowIcon} />
              <p className={styles.shiftToPage}>{`page ${shiftToPage}`}</p>
            </div>
          );
        }
      } else {
        return (
          <div className={styles.transitionState}>
            <p>{`page ${key}`}</p>
          </div>
        );
      }
    }
  };

  const hideShiftReferencesModal = () => {
    setNumberPagesToShift(1);
    setShowShiftModal(false);
    setShiftStartPage(null);
    setShiftDirection(null);
    setPageRangeError(false);
  };

  const range = (start, stop) => {
    var a = [start],
      b = start;
    while (b < stop) {
      a.push((b += 1));
    }
    return a;
  };

  const exportXFDF = async () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    let documentReferencesToUpdate = {
      document_references: [],
      updated_xfdf: null,
      document_id: docId,
    };

    await instance.Core.annotationManager
      .exportAnnotationCommand()
      .then((xfdf) => {
        documentReferencesToShift.map((xfdfHighlightId) => {
          documentReferencesToUpdate.document_references.push(xfdfHighlightId);
        });
        documentReferencesToUpdate.updated_xfdf = xfdf;
      });

    return documentReferencesToUpdate;
  };

  const updateDocumentReferences = async () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    const updateHash = await exportXFDF();

    const url = `shift_document_references`;
    httpClient
      .put(url, {
        document_references_to_update: updateHash,
      })
      .then(() => {
        stopEditingDocumentReferences();
        enablePDFtronTools();
        const annotationList =
          instance.Core.annotationManager.getAnnotationsList();
        instance.Core.annotationManager.drawAnnotationsFromList(annotationList);
        closeDocumentReferencePanel();
        notificationService.addNotification(
          "References have been shifted.",
          "References have been shifted.",
          "success"
        );
      });
  };

  const shiftReference = (annotation) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    const currentPage = annotation.getPageNumber();

    if (shiftDirection === "up") {
      annotation.setPageNumber(currentPage - parseInt(numberPagesToShift));
    } else {
      const shiftToPage = currentPage + parseInt(numberPagesToShift);
      if (shiftToPage > totalPageCount) {
        annotation.setPageNumber(totalPageCount);
      } else {
        annotation.setPageNumber(currentPage + parseInt(numberPagesToShift));
      }
    }

    instance.Core.annotationManager.drawAnnotations(currentPage);
    const copy = documentReferencesToShift;
    copy.push(annotation.Id);
    setDocumentReferencesToShift(copy);
    instance.Core.documentViewer.setCurrentPage(currentPage);
  };

  const reverseReferenceShift = (annotation) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    const currentPage = annotation.getPageNumber();

    if (shiftDirection === "up") {
      annotation.setPageNumber(currentPage + parseInt(numberPagesToShift));
    } else {
      annotation.setPageNumber(currentPage - parseInt(numberPagesToShift));
    }

    instance.Core.annotationManager.drawAnnotations(currentPage);
  };

  const undoShiftChanges = () => {
    let pageRangeToShift;

    if (shiftDirection == "up") {
      pageRangeToShift = range(shiftStartPage, totalPageCount);
    } else {
      pageRangeToShift = range(shiftStartPage, totalPageCount);
    }

    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    pageRangeToShift.map((page) => {
      const pageDocumentReferences = documentReferencesByPage[`${page}`];

      const unique = pageDocumentReferences && [
        ...new Set(
          pageDocumentReferences.map((item) => item.xfdf_highlight_id)
        ),
      ];

      unique &&
        unique.map((xfdf_highlight_id) => {
          const annotation =
            instance.Core.annotationManager.getAnnotationById(
              xfdf_highlight_id
            );
          reverseReferenceShift(annotation);
        });
    });

    const annotationList = instance.Core.annotationManager.getAnnotationsList();
    instance.Core.annotationManager.drawAnnotationsFromList(annotationList);

    enablePDFtronTools();
  };

  const undoDocumentReferenceShift = async () => {
    await undoShiftChanges();
    stopEditingDocumentReferences();

    hideShiftReferencesModal();
  };

  const bulkShiftReferences = () => {
    disablePDFtronTools();
    startEditingDocumentReferences();
    let pageRangeToShift;

    if (shiftDirection == "up") {
      pageRangeToShift = range(shiftStartPage, totalPageCount);
    } else {
      pageRangeToShift = range(shiftStartPage, totalPageCount);
    }

    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    setPageRangeToShift(pageRangeToShift);
    pageRangeToShift.map((page) => {
      const pageDocumentReferences = documentReferencesByPage[`${page}`];

      const unique = pageDocumentReferences && [
        ...new Set(
          pageDocumentReferences.map((item) => item.xfdf_highlight_id)
        ),
      ];

      unique &&
        unique.map((xfdf_highlight_id, index) => {
          const annotation =
            instance.Core.annotationManager.getAnnotationById(
              xfdf_highlight_id
            );
          shiftReference(annotation, index, unique.length);
        });
    });

    const annotationList = instance.Core.annotationManager.getAnnotationsList();
    instance.Core.annotationManager.drawAnnotationsFromList(annotationList);

    setShowShiftModal(false);
  };

  const disablePDFtronTools = () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    instance.UI.disableElements([
      "pinToolsGroupButton",
      "signatureToolButton",
      "shapesToolButton",
      "textPopup",
    ]);
  };

  const enablePDFtronTools = () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    instance.UI.enableElements([
      "pinToolsGroupButton",
      "signatureToolButton",
      "shapesToolButton",
      "textPopup",
    ]);
  };

  const renderDocumentReferenceList = () => {
    return (
      <div className={styles.conversationsContainer}>
        {Object.entries(documentReferencesByPage).map(
          ([key, documentReferences], index) => (
            <div key={index} className={styles.page}>
              <div className={styles.pageTitleContainer}>
                <div className={styles.title}>
                  {!editingDocumentReferences
                    ? key !== "undefined"
                      ? `page ${key}`
                      : "page out of bounds"
                    : renderTransition(key)}
                </div>
                {!readOnly &&
                  !editingDocumentReferences &&
                  totalPageCount > 1 &&
                  key !== "undefined" &&
                  security.getUserRole() !== "viewer" && (
                    <UikDropdown
                      DisplayComponent={actionsDropDown}
                      position="bottomRight"
                      onClick={(e) => e.stopPropagation()}
                      className={styles.referenceDocumentMenu}
                    >
                      {key !== "1" && (
                        <UikDropdownItem
                          onClick={() => showShiftReferencesModal(key, "up")}
                        >
                          <img className={styles.moveIcon} src={moveUpIcon} />
                          Shift Annotations Up
                        </UikDropdownItem>
                      )}
                      {key !== `${totalPageCount}` && (
                        <UikDropdownItem
                          onClick={() => showShiftReferencesModal(key, "down")}
                        >
                          <img className={styles.moveIcon} src={moveDownIcon} />
                          Shift Annotations Down
                        </UikDropdownItem>
                      )}
                    </UikDropdown>
                  )}
              </div>
              <div className={styles.dividerContainer} />
              {documentReferences.map((dr, idx) => (
                <div className={styles.cardContainer}>
                  <DocumentReferenceCard
                    referenceCitationCount={
                      groupedReferenceCitations[dr.xfdf_highlight_id].length
                    }
                    page={key}
                    index={idx}
                    docId={docId}
                    documentReference={dr}
                    versionId={versionId}
                    versionNumber={versionNumber}
                    originalDocumentId={originalDocumentId}
                    isApproved={isApproved}
                    readOnly={readOnly}
                    onAnnotationSelect={onAnnotationSelect}
                    editingDocumentReferences={editingDocumentReferences}
                  />
                  <div className={styles.dividerContainer} />
                </div>
              ))}
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <div
      className={
        props.showMobileSidePanel
          ? styles.mobileConversationsPanel
          : styles.conversationsPanel
      }
    >
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.title}>
              {editingDocumentReferences ? (
                shiftDirection === "up" ? (
                  <div className={styles.shiftingUpTitle}>
                    <img className={styles.moveIcon} src={moveUpWhiteIcon} />
                    Shifting Up
                  </div>
                ) : (
                  <div className={styles.shiftingUpTitle}>
                    <img className={styles.moveIcon} src={moveDownWhiteIcon} />
                    Shifting Down
                  </div>
                )
              ) : (
                "References"
              )}
            </div>
          </div>
          <div className={styles.rightContainer}>
            <div onClick={close} className={styles.closeButton}>
              <img className={styles.closeIcon} src={CloseSVG} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          editingDocumentReferences ? styles.editModeBody : styles.body
        }
      >
        {renderDocumentReferenceList(documentReferences)}
        {documentReferences &&
          documentReferences.length === 0 &&
          renderEmtpyState()}
      </div>
      <NewModal visible={showShiftModal} type="alert">
        <div className="heading">
          {shiftDirection === "up"
            ? "Shift references up"
            : "Shift references down"}
        </div>
        <UikDivider />
        <div className="body">
          {`Shift all annotations from page ${shiftStartPage} onwards ${
            shiftDirection === "up" ? "up" : "down"
          }`}
          <UikInput
            label="Number of pages"
            type="number"
            max={getMaxPageShift()}
            min="1"
            value={numberPagesToShift}
            className={pageRangeError ? styles.error : ""}
            onChange={(e) => validatePageRange(e.target.value)}
          />
          {pageRangeError && (
            <p className={styles.errorMessage}>
              Please ensure to shift within document's page range
            </p>
          )}
        </div>
        <UikDivider />
        <div className="buttons">
          <UikButton
            className={styles.reviewButton}
            disabled={pageRangeError}
            primary
            onClick={() => {
              bulkShiftReferences();
            }}
          >
            Review
          </UikButton>
          <UikButton
            onClick={() => {
              hideShiftReferencesModal();
            }}
          >
            Cancel
          </UikButton>
        </div>
      </NewModal>
      {showLoader && (
        <div className={styles.loaderContainer}>
          <Lottie options={defaultOptions} height={92} width={82} />
          <p className={styles.loaderText}>Looking For Comments ...</p>
        </div>
      )}
      {editingDocumentReferences && (
        <div className={styles.editControlsContainer}>
          <UikButton
            className={styles.cancelEditButton}
            onClick={() => {
              undoDocumentReferenceShift();
            }}
          >
            <img className={styles.cancelButton} src={CloseSVG} />
            Cancel
          </UikButton>
          <UikButton
            className={styles.applyChangesButton}
            primary
            onClick={() => {
              updateDocumentReferences();
            }}
          >
            Apply Changes
          </UikButton>
        </div>
      )}
    </div>
  );
};
