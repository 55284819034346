/* External libraries */
import React from "react";
import ReactNotification from "react-notifications-component";
import PropTypes from "prop-types";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../../history";

import Button from "../../Common/Buttons/ButtonPrimary";

import ContactListItem from "../ContactListItem";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox,
  UikToggle,
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";
import styles from "./SegmentEditForm.module.css";

/* Variables */

class SegmentEditFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    //
  };

  handleSaveButtonClicked = (e) => {
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const segmentFormData = {
          contact_segment: {
            name: values.name,
          },
        };

        this.props.handleSegmentEdit(segmentFormData);
      } else {
        console.log("error", error, values);
      }
    });
  };

  handleDeleteButtonClicked(e) {
    e.preventDefault();
    this.props.handleSegmentDelete();
  }

  render() {
    const { contact } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.editSegmentContainer}>
        <UikWidget>
          <div className={styles.editContainer}>
            <UikHeadline>Segment Contacts</UikHeadline>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator("name", {
                initialValue: this.props.segment.name,
                rules: [
                  {
                    required: true,
                    message: "Please enter a segment name.",
                  },
                ],
              })(
                <UikInput
                  label="NAME"
                  placeholder=""
                  errorMessage={(getFieldError("name") || []).join(", ")}
                />
              )}
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleSaveButtonClicked(e)}
              primary
            >
              Save
            </UikButton>
            <UikButton
              className="floatRight"
              onClick={(e) => this.handleDeleteButtonClicked(e)}
              error
            >
              Delete Segment
            </UikButton>
            <div className="clear" />
          </div>
          <UikDivider />
          <div className={styles.segmentContacts}>
            {this.props.segment.contacts.map((contact, idx) => {
              return (
                <div key={`contact-${contact.id}`}>
                  <ContactListItem contact={contact} />
                </div>
              );
            })}
          </div>
          <UikDivider />
        </UikWidget>
      </div>
    );
  }
}

SegmentEditFormBuild.propTypes = {
  segment: PropTypes.any.isRequired,
};

const SegmentEditForm = createForm()(SegmentEditFormBuild);
export default SegmentEditForm;
