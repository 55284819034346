import React from "react";
import PropTypes from "prop-types";
import styles from "./ConversationIndicators.module.scss";
import httpClient from "../../lib/HttpClient";

/* UI Kit */
import { Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Variables */

class ConversationIndicators extends React.Component {
  constructor() {
    super();

    this.state = {
      conversations: [],
      conversationBlockId: null,
    };
  }

  componentDidMount = () => {
    document.addEventListener("reload_comments", (e) => {
      if (e.detail.docId == this.props.docId) {
        this.loadConversations();
      }
    });
  };

  componentWillMount = () => {
    this.loadConversations();
  };

  loadConversations = () => {
    const { docId, pageNumber } = this.props;

    // Retrieve the document conversations.
    httpClient
      .get(`/documents/${docId}/conversations/${pageNumber}.json`)
      .then((response) => {
        this.setState({
          conversations: response.data,
        });
      });
  };

  shouldComponentUpdate() {
    return true;
  }

  highlightBlock = (blockId) => {
    const range = blockId.split("--");
    const firstElement = document.getElementById(range[0]);
    const lastElement = document.getElementById(range[range.length - 1]);

    let blockIdentifier = "";

    let collection = [];
    collection.push(firstElement);

    let currentElement = firstElement;

    if (currentElement !== null && currentElement.innerHTML) {
      let htmlstring = currentElement.innerHTML;
      htmlstring = htmlstring.trim
        ? htmlstring.trim()
        : htmlstring.replace(/^\s+/, "");

      if (htmlstring !== "") {
        currentElement.classList.add("commentedText");
        blockIdentifier = currentElement.id;
      }
    }

    // Loop through all elements until we reach the end and store it in our collection.
    while (
      currentElement !== null &&
      currentElement !== lastElement &&
      typeof currentElement.nextSibling !== "undefined"
    ) {
      if (currentElement !== null) {
        collection.push(currentElement.nextSibling);
        currentElement = currentElement.nextSibling;

        if (currentElement !== null && currentElement.innerHTML) {
          let htmlstring = currentElement.innerHTML;
          htmlstring = htmlstring.trim
            ? htmlstring.trim()
            : htmlstring.replace(/^\s+/, "");

          if (htmlstring !== "") {
            currentElement.classList.add("commentedText");
          }
        }
      }
    }
  };

  unHighlightBlock = (blockId) => {
    const conversationClosed =
      document.getElementById("conversationPanel") === null;

    if (conversationClosed || this.state.conversationBlockId !== blockId) {
      const range = blockId.split("--");
      const firstElement = document.getElementById(range[0]);
      const lastElement = document.getElementById(range[range.length - 1]);

      let blockIdentifier = "";

      let collection = [];
      collection.push(firstElement);

      let currentElement = firstElement;

      if (currentElement !== null && currentElement.innerHTML) {
        let htmlstring = currentElement.innerHTML;
        htmlstring = htmlstring.trim
          ? htmlstring.trim()
          : htmlstring.replace(/^\s+/, "");

        if (htmlstring !== "") {
          currentElement.classList.remove("commentedText");
          blockIdentifier = currentElement.id;
        }
      }

      // Loop through all elements until we reach the end and store it in our collection.
      while (
        currentElement !== null &&
        currentElement !== lastElement &&
        typeof currentElement.nextSibling !== "undefined"
      ) {
        if (currentElement !== null) {
          collection.push(currentElement.nextSibling);
          currentElement = currentElement.nextSibling;

          if (currentElement !== null && currentElement.innerHTML) {
            let htmlstring = currentElement.innerHTML;
            htmlstring = htmlstring.trim
              ? htmlstring.trim()
              : htmlstring.replace(/^\s+/, "");

            if (htmlstring !== "") {
              currentElement.classList.remove("commentedText");
            }
          }
        }
      }
    }
  };

  clickedBlock = (blockId) => {
    const unhighlightElements =
      document.getElementsByClassName("commentedText");

    while (unhighlightElements[0]) {
      unhighlightElements[0].classList.remove("commentedText");
    }

    this.highlightBlock(blockId);

    this.setState({ conversationBlockId: blockId });

    this.props.loadConversation(blockId);
  };

  render() {
    const conversations = this.state.conversations;

    return (
      <div className={styles.indicatorContainer}>
        {conversations &&
          conversations.map((conversation, index) => (
            <div key={conversation.id}>
              {conversation.block_identifier !=
                `${this.props.docId}-general` && (
                <div
                  className={styles.documentPageCommentIndicators}
                  onMouseOver={() =>
                    this.highlightBlock(conversation.block_identifier)
                  }
                  onMouseOut={() =>
                    this.unHighlightBlock(conversation.block_identifier)
                  }
                  onClick={() =>
                    this.clickedBlock(conversation.block_identifier)
                  }
                >
                  <div className={styles.conversationIndicatorContainer}>
                    <span className={styles.consersationIcon}>
                      <Uikon>message_2</Uikon>
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    );
  }
}

ConversationIndicators.propTypes = {
  docId: PropTypes.number,
  pageNumber: PropTypes.number,
  loadConversation: PropTypes.func,
};

export default ConversationIndicators;
