/* External libraries */
import React from "react";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import ReactNotification from "react-notifications-component";
import { Container, Row, Col } from "react-grid-system";
import history from "../../history";

import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";

/* UI Kit */
import { UikInput, UikButton, Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import SettingsLeftMenu from "../shared/SettingsLeftMenu/SettingsLeftMenu";
import LibraryCreateForm from "./LibraryCreateForm";
import LibraryListItem from "./LibraryListItem";
import LibraryEditForm from "./LibraryEditForm";

/* Assets */
import styles from "./Libraries.module.css";

/* Variables */
const cookies = new Cookies();

class Libraries extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      libraries: [],
      selectedLibrary: null,
      refreshPage: false,
      currentUser: {
        role: currentUserRole,
      },
      roles: [
        { value: "viewer", label: "Viewer" },
        { value: "author", label: "Author" },
        { value: "admin", label: "Admin" },
      ],
    };

    this.notificationDOMRef = React.createRef();
  }

  addNotification = ({ title, message, type }) => {
    this.notificationDOMRef.current.addNotification({
      title: title,
      message: message,
      type: type,
      container: "top-right",
      animationIn: ["animated", "flipInX"],
      animationOut: ["animated", "flipOutX"],
      dismiss: { duration: 5000 },
      dismissable: { click: true },
    });
  };

  componentDidMount = () => {
    const selectedLibraryId = this.props.match.params.id;
    const currentUserRole = security.getUserRole();

    // Only Authors and Admins can see libraries screen
    if (currentUserRole !== "author" && currentUserRole !== "admin") {
      history.push("/dashboard");
    }

    httpClient.get("/libraries.json").then((response) => {
      this.setState({
        libraries: response.data,
      });
      this.state.libraries.map((library, index) => {
        if (library.id == selectedLibraryId) {
          this.setState({ selectedLibrary: library });
        }
      });
    });
  };

  onCreate(library) {
    this.setState({
      libraries: [library, ...this.state.libraries],
    });
  }

  onSelectLibrary(library) {
    this.setState({ selectedLibrary: library });
  }

  onUpdateLibrary(updatedLibrary) {
    let indexToRemove;

    this.state.libraries.find((library, index) => {
      if (library.id == updatedLibrary.id) {
        indexToRemove = index;
      }
    });
    this.state.libraries.splice(indexToRemove, 1);
    this.setState({
      libraries: [updatedLibrary, ...this.state.libraries],
    });

    history.push("/libraries");
  }

  onDeleteLibrary(deletedLibrary) {
    let indexToRemove;

    this.state.libraries.find((library, index) => {
      if (library.id == deletedLibrary.id) {
        indexToRemove = index;
      }
    });
    this.state.libraries.splice(indexToRemove, 1);
    this.setState({
      libraries: this.state.libraries,
    });

    history.push("/libraries");
  }

  render() {
    if (this.state.currentUser.role === "admin") {
      return (
        <Container fluid className={styles.settings}>
          <Header />
          <Row className={styles.settingsContainer}>
            <SettingsLeftMenu />
            <ReactNotification ref={this.notificationDOMRef} />
            <Col sm={12} md={5} className={styles.listContainer}>
              <div>
                <div className={`${styles.createContainer}`}>
                  <div className={styles.buttonContainer}>
                    <Link to="/libraries/create">
                      <UikButton>Create library</UikButton>
                    </Link>
                  </div>
                </div>
                {this.state.libraries.map((library) => (
                  <LibraryListItem
                    library={library}
                    selectedId={this.props.match.params.id}
                    key={library.id}
                    onSelect={(library) => this.onSelectLibrary(library)}
                  />
                ))}
              </div>
            </Col>
            <Col sm={12} md={5}>
              {this.props.match.params.id == "create" && (
                <LibraryCreateForm
                  onCreate={(library) => this.onCreate(library)}
                  onNotify={(message) => this.addNotification(message)}
                />
              )}

              {this.props.match.params.id &&
                this.props.match.params.id != "create" &&
                this.state.selectedLibrary && (
                  <LibraryEditForm
                    library={this.state.selectedLibrary}
                    onUpdate={(library) => this.onUpdateLibrary(library)}
                    onDelete={(library) => this.onDeleteLibrary(library)}
                    onNotify={(message) => this.addNotification(message)}
                  />
                )}
            </Col>
          </Row>
        </Container>
      );
    } else {
      return <div>Access denied</div>;
    }
  }
}

export default Libraries;
