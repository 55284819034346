import React, { useState, useEffect } from "react";
import downChevronWhite from "../../../images/icons/svg/down-chevron-white.svg";
import downChevronGrey from "../../../images/icons/svg/down-chevron.svg";
import { useDispatch, useSelector } from "react-redux";
import setCommentFilters from "../../../actions/document/setCommentFilters";

import moment from "moment";

import styles from "./FilterPill.module.scss";

/* UI Kit */
import { UikDropdown, UikDropdownItem, Uikon } from "@uik";

export const FilterPill = (props) => {
  const {
    filterName,
    list,
    commentorUsers,
    executeSearch,
    sortResults,
    settingFilter,
    activeFilters,
    toggleForMe,
    forMeFilterActive,
    toggleFilters,
    deactivateFilter,
  } = props;

  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState(null);

  const [commentor, setCommentor] = useState(null);

  const [sort, setSort] = useState(null);

  const forMe = filterName === "For Me" ? true : false;

  const setCommentorFilter = (commentor) => {
    setCommentor(commentor);
    executeSearch(commentor, dateRange, sort, "commentor");
    dispatch(setCommentFilters(dateRange, commentor, sort, "commentor"));
  };

  const setDateFilter = (date) => {
    setDateRange(date);
    executeSearch(commentor, date, sort, "date");
    dispatch(setCommentFilters(date, commentor, sort, "date"));
  };

  const listDateOptions = () => {
    const dateOptions = {
      All: null,
      Today: moment().format("YYYY-MM-DD"),
      Yesterday: moment().subtract(1, "days").format("YYYY-MM-DD"),
      "Last 7 Days": moment().subtract(7, "days").format("YYYY-MM-DD"),
      "Last 30 Days": moment().subtract(1, "months").format("YYYY-MM-DD"),
      "last 3 Months": moment().subtract(3, "months").format("YYYY-MM-DD"),
      "Last 12 Months": moment().subtract(12, "months").format("YYYY-MM-DD"),
    };

    const set = (date) => {
      if (date === "All") {
        deactivateFilter("Date");
        toggleFilters(true);
      } else {
        settingFilter(filterName);
        setDateFilter(dateOptions[date]);
      }
    };
    return Object.keys(dateOptions).map((date) => {
      return (
        <UikDropdownItem>
          <div onClick={() => set(date)}>
            <span className={styles.downloadText}>{date}</span>
          </div>
        </UikDropdownItem>
      );
    });
  };

  const listSortOptions = () => {
    const sortOptionsLibrary = {
      NewestToOldest: "Newest ➝ Oldest",
      OldestToNewest: "Oldest ➝ Newest",
      Page: "Page",
      Status: "Resolved",
    };

    const set = (option) => {
      settingFilter(filterName);
      sortResults(option);
    };
    return Object.keys(sortOptionsLibrary).map((label) => {
      const option = sortOptionsLibrary[label];
      return (
        <UikDropdownItem>
          <div onClick={() => set(label)}>
            <span className={styles.downloadText}>{option}</span>
          </div>
        </UikDropdownItem>
      );
    });
  };

  const listCommentorUsers = () => {
    const set = (commentor) => {
      if (commentor === "Anyone") {
        toggleFilters(true);
        deactivateFilter("People");
      } else {
        settingFilter(filterName);
        setCommentorFilter(commentor.id);
      }
    };

    if (commentorUsers.length > 0) {
      return (
        <div>
          <UikDropdownItem>
            <div onClick={() => set("Anyone")}>
              <span className={styles.downloadText}>Anyone</span>
            </div>
          </UikDropdownItem>
          {commentorUsers
            .sort((a, b) =>
              a.first_name.toLowerCase() +
                a.last_name.toLowerCase() +
                a.email.toLowerCase() >
              b.first_name.toLowerCase() +
                b.last_name.toLowerCase() +
                a.email.toLowerCase()
                ? 1
                : -1
            )
            .map((commentor) => {
              let commentorListItem =
                commentor.guest === true ? (
                  <UikDropdownItem>
                    <div onClick={() => set(commentor)}>
                      <span
                        className={styles.downloadText}
                      >{`${commentor.email}`}</span>
                    </div>
                  </UikDropdownItem>
                ) : (
                  <UikDropdownItem>
                    <div onClick={() => set(commentor)}>
                      <span
                        className={styles.downloadText}
                      >{`${commentor.first_name} ${commentor.last_name}`}</span>
                    </div>
                  </UikDropdownItem>
                );
              return commentorListItem;
            })}
        </div>
      );
    } else {
      return (
        <UikDropdownItem>
          <div>
            <span className={styles.downloadText}>No one has commented</span>
          </div>
        </UikDropdownItem>
      );
    }
  };

  const pillAction = ({ onClick }) => {
    let pillClickFunction;
    if (forMe) {
      pillClickFunction = () => toggleForMe();
    } else {
      if (forMeFilterActive) {
        pillClickFunction = null;
      } else {
        pillClickFunction = onClick;
      }
    }
    let pillStyle;
    if (activeFilters.length > 0) {
      let currentFilterActive = activeFilters.find(
        (filter) => filter === filterName
      );
      if (currentFilterActive) {
        if (forMe) {
          if (forMeFilterActive) {
            pillStyle = styles.forMeActive;
          } else {
            pillStyle = styles.forMe;
          }
        } else {
          if (forMeFilterActive) {
            pillStyle = styles.pillDisabled;
          } else {
            if (filterName === "Sort") {
              pillStyle = styles.pill;
            } else {
              pillStyle = styles.pillActive;
            }
          }
        }
      } else {
        if (forMe) {
          pillStyle = styles.forMe;
        } else {
          if (forMeFilterActive) {
            pillStyle = styles.pillDisabled;
          } else {
            pillStyle = styles.pill;
          }
        }
      }
    } else {
      if (forMe) {
        pillStyle = styles.forMe;
      } else {
        pillStyle = styles.pill;
      }
    }
    return (
      <div className={styles.upgradeNotifierContainer}>
        <div className={styles.namePlate} onClick={pillClickFunction}>
          <div
            onClick={forMe ? () => settingFilter(filterName) : null}
            className={pillStyle}
          >
            {filterName}
            {list && (
              <img
                className={styles.downArrow}
                src={forMeFilterActive ? downChevronGrey : downChevronWhite}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={forMe ? {} : styles.pillContainer}>
      <UikDropdown
        class={
          commentorUsers && commentorUsers.length > 7 ? "filterDropdown" : ""
        }
        DisplayComponent={pillAction}
        position={filterName === "Sort" ? "bottomRight" : "bottomLeft"}
      >
        {filterName === "Date" && listDateOptions()}
        {filterName === "People" && listCommentorUsers()}
        {filterName === "Sort" && listSortOptions()}
      </UikDropdown>
    </div>
  );
};
