import React, { useState, useEffect } from "react";
import { Container } from "react-grid-system";
import httpClient from "../../lib/HttpClient";
import Header from "../shared/Header/Header";
import DocumentThumbnail from "../Documents/DocumentThumbnail";
import DashboardConversationPanel from "./DashboardConversationPanel";
import DashboardTable from "./DashboardTable";
import DashboardPreviewModal from "./DashboardPreviewModal";
import DashboardCommentCard from "./DashboardCommentCard";
import ArrowUpIcon from "../../images/icons/svg/sorting-arrow-up.svg";
import ArrowDownIcon from "../../images/icons/svg/sorting-arrow-down.svg";
import SearchIcon from "../../images/icons/svg/search.svg";
import SearchIconExpand from "../../images/icons/svg/search-icon-expand.svg";
import CrossIcon from "../../images/icons/svg/cross-light-grey.svg";
import useDebouncer from "../../hooks/useDebouncer";
import recentlyViewedEmptyState from "../../images/icons/svg/recently-viewed-empty-state.svg";
import recentlyApprovedEmptyState from "../../images/icons/svg/recently-approved-empty-state.svg";
import commentsEmptyState from "../../images/icons/svg/comments-empty-state.svg";
import history from "../../history";
import Lottie from "react-lottie";
import loadingAnimation from "../../images/lotties/loading.json";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import fetchDashboardData from "../../actions/Dashboard/fetchDashboardData";

import styles from "./Dashboard.module.scss";

export default (props) => {
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [recentlyApproved, setRecentlyApproved] = useState([]);
  const [selectedView, setSelectedView] = useState("recentlyViewed");
  const [documentsInReview, setDocumentsInReview] = useState([]);
  const [sortedDocs, setSortedDocs] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortingColumnIndex, setSortingColumnIndex] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [comments, setComments] = useState([]);
  const [showConversationPanel, setShowConversationPanel] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(true);
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [conversationPreview, setConversationPreview] = useState(null);
  const [showLoader, setShowLoader] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  const debouncedInputValue = useDebouncer(inputValue);

  const dispatch = useDispatch();

  const boundActionCreators = bindActionCreators(
    {
      fetchDashboardData,
    },
    dispatch
  );

  useEffect(() => {
    setShowLoader(true);
    boundActionCreators.fetchDashboardData().then((res) => {
      setShowLoader(false);
      setRecentlyViewed(sortByActivityDate(res.recently_viewed));
      setRecentlyApproved(res.recently_approved);
      setDocumentsInReview(sortByActivityDate(res.user_documents_in_review));
      setComments(res.user_comments_mentions);
      setNotifications(res.user_notifications);
    });
    let refreshDashboard;
    try {
      refreshDashboard = setInterval(() => {
        if (document.hidden) {
          return;
        }
        boundActionCreators.fetchDashboardData().then((res) => {
          setShowLoader(false);
          setRecentlyViewed(sortByActivityDate(res.recently_viewed));
          setRecentlyApproved(res.recently_approved);
          setDocumentsInReview(
            sortByActivityDate(res.user_documents_in_review)
          );
          setComments(res.user_comments_mentions);
          setNotifications(res.user_notifications);
        });
      }, 5000);
    } catch (e) {
      console.log(e);
    }

    return () => {
      clearInterval(refreshDashboard);
    };
  }, []);

  useEffect(() => {
    handleSearch(debouncedInputValue);
  }, [debouncedInputValue]);

  const onSearchChange = (value) => {
    setInputValue(value);
  };

  const sortByActivityDate = (documents) => {
    return documents.sort(function (a, b) {
      if (
        !a.document_has_notifications &&
        !b.document_has_notifications &&
        !a.favourite_id &&
        !b.favourite_id
      ) {
        if (a.tracked_activity.length > 0 && b.tracked_activity.length > 0) {
          return (
            new Date(b.tracked_activity[0].created_at) -
            new Date(a.tracked_activity[0].created_at)
          );
        } else {
          return new Date(b.updated_at) - new Date(a.updated_at);
        }
      }
    });
  };

  const toggleSearch = (option) => {
    setShowSearch(option);
  };

  const handleToggle = (option) => {
    setSelectedView(option);
  };

  const uploadDraft = (e) => {
    e.preventDefault();
    history.push("/drafts/upload");
  };

  const renderRecentlyViewed = () => {
    if (recentlyViewed.length === 0) {
      return (
        <div className={styles.recentlyViewedContainerEmpty}>
          <img src={recentlyViewedEmptyState} />
        </div>
      );
    } else {
      return recentlyViewed.map((doc, index) => {
        return (
          <div key={doc.id}>
            <DocumentThumbnail key={index} doc={doc} />
          </div>
        );
      });
    }
  };

  const renderRecentlyApproved = () => {
    if (recentlyApproved.length === 0) {
      return (
        <div className={styles.recentlyApprovedContainerEmpty}>
          <img src={recentlyApprovedEmptyState} />
          <div>
            <p>No recently approved content</p>
          </div>
        </div>
      );
    } else {
      return recentlyApproved.map((doc, index) => {
        return (
          <div key={doc.id}>
            <DocumentThumbnail key={index} doc={doc} />
          </div>
        );
      });
    }
  };

  const renderRecents = () => {
    const renderContent = () => {
      if (showLoader) {
        return (
          <div className={styles.loaderContainer}>
            <Lottie options={defaultOptions} height={92} width={82} />
          </div>
        );
      } else {
        if (selectedView === "recentlyViewed") {
          return (
            <div
              className={
                recentlyViewed.length > 0 ? styles.thumbnailContainer : ""
              }
            >
              {renderRecentlyViewed()}
            </div>
          );
        } else {
          return (
            <div
              className={
                recentlyApproved.length > 0 ? styles.thumbnailContainer : ""
              }
            >
              {renderRecentlyApproved()}
            </div>
          );
        }
      }
    };
    return (
      <div className={styles.recentlyViewedContainer}>
        <div className={styles.toggleContainer}>
          <div className={styles.toggle}>
            <div
              className={
                selectedView === "recentlyViewed"
                  ? styles.toggleButton
                  : styles.toggleButtonInactive
              }
              onClick={() => handleToggle("recentlyViewed")}
            >
              Recently Viewed
            </div>
            <div
              className={
                selectedView === "recentlyApproved"
                  ? styles.toggleButton
                  : styles.toggleButtonInactive
              }
              onClick={() => handleToggle("recentlyApproved")}
            >
              Recently Approved
            </div>
          </div>
        </div>
        {renderContent()}
      </div>
    );
  };

  const renderInReview = () => {
    return (
      <div
        className={
          documentsInReview.length === 0
            ? styles.inReviewContainerEmpty
            : styles.inReviewContainer
        }
      >
        {inputValue ? (
          <DashboardTable
            documentsInReview={filteredDocs}
            sortedDocs={sortedDocs}
            noSearchResults={filteredDocs.length === 0}
            setShowDashboardPreviewModal={setShowPreviewModal}
            setSelectedPreview={setSelectedPreview}
            sortingColumnIndex={sortingColumnIndex}
            setSortingColumnIndex={setSortingColumnIndex}
            sort={sort}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
        ) : (
          <DashboardTable
            showLoader={showLoader}
            documentsInReview={documentsInReview}
            sortedDocs={sortedDocs}
            setShowDashboardPreviewModal={setShowPreviewModal}
            setSelectedPreview={setSelectedPreview}
            sortingColumnIndex={sortingColumnIndex}
            setSortingColumnIndex={setSortingColumnIndex}
            sort={sort}
            sortOrder={sortOrder}
          />
        )}
      </div>
    );
  };

  const closeConversation = () => {
    setShowPreviewModal(false);
    setConversationPreview(null);
    setShowConversationPanel(false);
  };

  const renderCommentsPanel = () => {
    if (showConversationPanel) {
      return (
        <div className={styles.commentsPanelContainer}>
          <DashboardConversationPanel
            selectedComment={selectedComment}
            comments={comments}
            setComments={setComments}
            setSelectedComment={setSelectedComment}
            closeConversation={closeConversation}
            notifications={notifications}
            owner={selectedComment.document.uploaded_by_user}
          />
        </div>
      );
    } else {
      if (comments.length === 0 && !showLoader) {
        return (
          <div className={styles.commentsPanelEmptyContainer}>
            <div className={styles.subHeadingContainer}>
              <h2 className={styles.subHeadingComments}>Comments & Mentions</h2>
            </div>
            <div className={styles.commentsEmptyStateContainer}>
              <img src={commentsEmptyState} />
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.commentsPanelContainer}>
            <div className={styles.subHeadingContainer}>
              <h2 className={styles.subHeadingComments}>Comments & Mentions</h2>
            </div>
            <DashboardCommentCard
              showLoader={showLoader}
              comments={comments}
              setComments={setComments}
              setShowConversationPanel={setShowConversationPanel}
              setSelectedComment={setSelectedComment}
              setShowDashboardPreviewModal={setShowPreviewModal}
              setSelectedPreview={setSelectedPreview}
              setConversationPreview={setConversationPreview}
            />
          </div>
        );
      }
    }
  };

  const handleSearch = (e) => {
    setSortColumn(null);
    setSortOrder(null);
    setSortingColumnIndex(null);
    setSortedDocs([]);

    let filtered = documentsInReview.filter((document) => {
      if (
        document.title
          .trim()
          .toLowerCase()
          .includes(inputValue.trim().toLowerCase())
      ) {
        return document;
      }
    });

    setFilteredDocs(filtered);
  };

  const clearSearch = () => {
    setInputValue("");
    setFilteredDocs([]);
    toggleSearch(false);
  };

  const sort = (columnIdentifier, index) => {
    let documentsInReviewCopy = [...documentsInReview];
    let filteredDocsCopy = [...filteredDocs];
    let sortingCharacteristic;

    if (columnIdentifier === "progress") {
      if (columnIdentifier !== sortColumn) {
        setSortColumn(columnIdentifier);
        setSortOrder("asc");

        let documentsToSort;

        if (filteredDocs.length > 0) {
          documentsToSort = filteredDocsCopy;
        } else {
          documentsToSort = documentsInReviewCopy;
        }

        let sorted = documentsToSort.sort(function (a, b) {
          if (
            (a.total_approvals - a.pending_approvals) / a.total_approvals <
            (b.total_approvals - b.pending_approvals) / b.total_approvals
          ) {
            return 1;
          }
          if (
            (a.total_approvals - a.pending_approvals) / a.total_approvals >
            (b.total_approvals - b.pending_approvals) / b.total_approvals
          ) {
            return -1;
          }
          return 0;
        });

        setSortingColumnIndex(index);
        setSortedDocs(sorted);
      } else {
        if (sortOrder === "asc") {
          setSortColumn(columnIdentifier);
          setSortOrder("desc");

          let documentsToSort;

          if (filteredDocs.length > 0) {
            documentsToSort = filteredDocsCopy;
          } else {
            documentsToSort = documentsInReviewCopy;
          }

          let sorted = documentsToSort.sort(function (a, b) {
            if (
              (a.total_approvals - a.pending_approvals) / a.total_approvals <
              (b.total_approvals - b.pending_approvals) / b.total_approvals
            ) {
              return -1;
            }
            if (
              (a.total_approvals - a.pending_approvals) / a.total_approvals >
              (b.total_approvals - b.pending_approvals) / b.total_approvals
            ) {
              return 1;
            }
            return 0;
          });

          setSortedDocs(sorted);
          setSortingColumnIndex(index);
        } else {
          setSortColumn(null);
          setSortOrder(null);
          setSortingColumnIndex(null);
          setSortedDocs([]);
        }
      }
    } else {
      if (columnIdentifier === "title") {
        sortingCharacteristic = "title";
      } else if (columnIdentifier === "due date") {
        sortingCharacteristic = "due_date";
      }
      if (columnIdentifier !== sortColumn) {
        setSortColumn(columnIdentifier);
        setSortOrder("asc");

        let documentsToSort;

        if (filteredDocs.length > 0) {
          documentsToSort = filteredDocsCopy;
        } else {
          documentsToSort = documentsInReviewCopy;
        }

        let sorted = documentsToSort.sort(function (a, b) {
          if (a[sortingCharacteristic] === null) {
            return 1;
          } else if (b[sortingCharacteristic] === null) {
            return -1;
          } else {
            if (a[sortingCharacteristic] < b[sortingCharacteristic]) {
              return -1;
            }
            if (a[sortingCharacteristic] > b[sortingCharacteristic]) {
              return 1;
            }
            return 0;
          }
        });

        setSortingColumnIndex(index);
        setSortedDocs(sorted);
      } else {
        if (sortOrder === "asc") {
          setSortColumn(columnIdentifier);
          setSortOrder("desc");

          let documentsToSort;

          if (filteredDocs.length > 0) {
            documentsToSort = filteredDocsCopy;
          } else {
            documentsToSort = documentsInReviewCopy;
          }

          let sorted = documentsToSort.sort(function (a, b) {
            if (a[sortingCharacteristic] === null) {
              return -1;
            } else if (b[sortingCharacteristic] === null) {
              return 1;
            } else {
              if (a[sortingCharacteristic] < b[sortingCharacteristic]) {
                return 1;
              }
              if (a[sortingCharacteristic] > b[sortingCharacteristic]) {
                return -1;
              }
              return 0;
            }
          });
          setSortedDocs(sorted);
          setSortingColumnIndex(index);
        } else {
          setSortColumn(null);
          setSortOrder(null);
          setSortingColumnIndex(null);
          setSortedDocs([]);
        }
      }
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container fluid className={styles.container}>
      <Header />
      <div className={styles.topContainer}>
        <div className={styles.inReview}>
          {showPreviewModal && selectedPreview && (
            <DashboardPreviewModal
              showDashboardPreviewModal={showPreviewModal}
              setShowDashboardPreviewModal={setShowPreviewModal}
              previewDoc={selectedPreview}
              conversationPreview={conversationPreview}
              setConversationPreview={setConversationPreview}
              documentsInReview={documentsInReview}
              setDocumentsInReview={setDocumentsInReview}
              selectedComment={selectedComment}
              setSelectedComment={setSelectedComment}
            />
          )}
          <div className={styles.searchContainer}>
            <h2 className={styles.subHeading}>In Review</h2>
            {documentsInReview.length > 0 && (
              <div>
                {!showSearch && (
                  <img
                    className={styles.searchExpandIcon}
                    src={SearchIconExpand}
                    onClick={() => {
                      toggleSearch(true);
                    }}
                  />
                )}
                {showSearch && (
                  <div className={styles.searchInputContainer}>
                    <img className={styles.searchIcon} src={SearchIcon} />
                    <input
                      type="text"
                      placeholder={"Search"}
                      onChange={(e) => onSearchChange(e.target.value)}
                      value={inputValue}
                      role="presentation"
                      autocomplete="off"
                    />
                    {inputValue && (
                      <img
                        className={styles.crossIcon}
                        src={CrossIcon}
                        onClick={() => clearSearch()}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {renderInReview()}
        </div>
        <div className={styles.rightContainer}>{renderCommentsPanel()}</div>
      </div>
      {renderRecents()}
    </Container>
  );
};
