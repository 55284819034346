import ActionTypes from "./types";

export default (date, commentor, sort, type) => {
  return (dispatch, getState) => {
    const previousfilters = getState().commentFilters;
    const filters = {};
    if (type === "date") {
      filters["date"] = date;
      filters["commentor"] =
        !previousfilters || previousfilters.commentor === null
          ? null
          : previousfilters.commentor;
      filters["sort"] =
        !previousfilters || previousfilters.sort === null
          ? null
          : previousfilters.sort;
    } else if (type === "commentor") {
      filters["date"] =
        !previousfilters || previousfilters.date === null
          ? null
          : previousfilters.date;
      filters["commentor"] = commentor;
      filters["sort"] =
        !previousfilters || previousfilters.sort === null
          ? null
          : previousfilters.sort;
    } else if (type === "sort") {
      filters["date"] =
        !previousfilters || previousfilters.date === null
          ? null
          : previousfilters.date;
      filters["commentor"] =
        !previousfilters || previousfilters.commentor === null
          ? null
          : previousfilters.commentor;
      filters["sort"] = sort;
    }
    dispatch({
      type: ActionTypes.SET_COMMENT_FILTERS,
      payload: filters,
    });
  };
};
