import React from "react";
import { Col } from "react-grid-system";
import styles from "./SecurityMenu.module.scss";
import { UikDivider, UikButton } from "@uik";

export const NotificationsMenu = (props) => {
  const showManageAlerts = () => {
    props.hideNotificationsMenu();
    props.renderManageAlerts();
  };

  const showDateReminders = () => {
    props.hideNotificationsMenu();
    props.renderDateReminders();
  };

  return (
    <Col sm={12} md={5}>
      <div className={styles.backContainer}>
        <p className={styles.headline}>Notifications</p>
      </div>
      <div className={styles.block}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <div>Content Alerts</div>
            <div className={styles.subHeading}>
              Customize the email notifications you get about your content
            </div>
          </div>
        </div>
        <UikDivider />
        <div className={styles.content}>
          <UikButton
            className={styles.changeButton}
            onClick={(e) => showManageAlerts()}
          >
            Manage
          </UikButton>
        </div>
        <UikDivider />
      </div>
      <div className={styles.block}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <div>Date Reminders</div>
            <div className={styles.subHeading}>
              Custom email reminders for important dates
            </div>
          </div>
        </div>
        <UikDivider />
        <div className={styles.content}>
          <UikButton
            className={styles.changeButton}
            onClick={(e) => showDateReminders()}
          >
            Manage
          </UikButton>
        </div>
        <UikDivider />
      </div>
    </Col>
  );
};
