import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import pluralize from "pluralize";
import styles from "./DraftListItem.module.scss";

import Spinner from "../Common/Spinner";

import errorIcon from " ../../images/icons/16px/error.svg";

const content = (approval) => {
  const { t } = useTranslation();
  return (
    <div className={styles.draftListItem}>
      <div className={styles.draftListItemInner}>
        <div className={styles.documentTitle}>{approval.document.title}</div>
        {approval.document.pending_approvals > 0 && (
          <div className={styles.pendingApprovals}>
            {t("common_phrases.waiting_for")}{" "}
            {approval.document.pending_approvals} {t("common_terms.approval_s")}
          </div>
        )}

        {!approval.document.rejected &&
          !approval.document.approvable &&
          !approval.document.pending_approvals && (
            <div className={styles.pendingApprovals}>
              {t("common_phrases.no_pending_approvals")}
            </div>
          )}
        <div className={styles.wrapper}>
          {approval.document.due_date && (
            <div className={styles.dueDate}>
              {moment().isAfter(moment(approval.document.due_date), "day") &&
                !approval.document.approvable && (
                  <span className={styles.warningBadge}>
                    {t("common_terms.overdue")}
                  </span>
                )}
              {!(
                moment() > moment(approval.document.due_date) &&
                !approval.document.approvable
              ) && <span>{t("common_terms.due")} </span>}
              <span>
                {moment(approval.document.due_date).format("MMMM Do")}
              </span>
            </div>
          )}
          {!approval.document.due_date && (
            <div className={styles.dueDate}>
              {t("common_phrases.no_due_date")}
            </div>
          )}

          {approval.document.approvable && (
            <div className={styles.approvedBadge}>
              {t("common_phrases.everyone_approved")}
            </div>
          )}
          {approval.document.submit_new_version && (
            <div className={styles.rejectedBadge}>
              {t("common_phrases.submit_new_version")}
            </div>
          )}
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

const DraftListItem = ({ approval }) => (
  <div>
    <Link to={`/drafts/${approval.document.id}`}>{content(approval)}</Link>
  </div>
);

DraftListItem.propTypes = {
  approval: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default DraftListItem;
