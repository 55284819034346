/* External libraries */
import React from "react";
import ReactNotification from "react-notifications-component";
import PropTypes from "prop-types";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import security from "../../services/Security";

import httpClient from "../../lib/HttpClient";
import "../../animate.css";

import notificationService from "../../services/Notifications";
import RadioButton from "../Common/RadioButton";
import Tooltip from "../Common/Tooltip";
import Checkbox from "../Common/Checkbox";
import Button from "../Common/Buttons/ButtonPrimary";
import Modal from "../Common/Modal";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox,
  UikToggle,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./ContactEditForm.module.css";

/* Variables */
const animatedComponents = makeAnimated();

class ContactEditFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor(props) {
    super(props);

    let list = [];

    this.props.contact.contact_segments.map((contactSegment, idx) => {
      let contactSegmentNameplate = `Segment: ${contactSegment.name}`;

      list.push({
        value: contactSegment.id,
        label: contactSegmentNameplate,
      });
    });

    this.state = {
      editContactSubscribed: props.contact.subscribed,
      selectedContactSegments: null,
      selectedContactSegmentsList: list,
    };
  }

  componentWillMount = () => {
    //
  };

  handleSave(e) {
    e.preventDefault();

    const { reloadContacts } = this.props;

    this.props.form.validateFields((error, values) => {
      if (!error) {
        let formData = {
          first_name: values[`first_name${this.props.contact.id}`],
          last_name: values[`last_name${this.props.contact.id}`],
          email: values[`email${this.props.contact.id}`],
          title: values[`title${this.props.contact.id}`],
          company: values[`company${this.props.contact.id}`],
          subscribed: this.state.editContactSubscribed,
        };

        if (this.state.selectedContactSegments) {
          formData["selected_contact_segments"] =
            this.state.selectedContactSegments;
        }

        const contactFormData = {
          contact: formData,
        };

        httpClient
          .put(`/contacts/${this.props.contact.id}.json`, contactFormData)
          .then((response) => {
            console.log("saved!");
            notificationService.addNotification(
              "Contact Saved",
              "Your changes to this contact has been saved",
              "success"
            );
            reloadContacts();
          });
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleDelete(e) {
    e.preventDefault();
    const { onDelete, reloadContacts } = this.props;
    if (window.confirm("Are you sure you want to delete this contact?")) {
      httpClient
        .delete(`/contacts/${this.props.contact.id}.json`)
        .then((response) => {
          console.log("deleted!");
          notificationService.addNotification(
            "Contact Deleted",
            "This contact has been deleted.",
            "danger"
          );
          onDelete();
          reloadContacts();
        });
    }
  }

  handleSubscribed = (choice) => {
    this.setState({
      editContactSubscribed: choice,
    });
  };

  handleContactSegmentsSelection = (newValue: any, actionMeta: any) => {
    if (newValue) {
      const segmentsCheck = {};
      let segmentsList = [];

      newValue.forEach((segment) => {
        if (!segmentsCheck[segment["value"]]) {
          // Add it to the segment list
          segmentsList.push(segment.value);
          segmentsCheck[segment["value"]] = true;
        }
      });

      this.setState({ selectedContactSegments: segmentsList });
      this.setState({ selectedContactSegmentsList: newValue });
    }

    if (actionMeta.action === "remove-value") {
      let newValue = this.state.selectedContactSegmentsList.filter(
        (seg) => seg.value !== actionMeta.removedValue.value
      );
      this.setState({ selectedContactSegmentsList: newValue });
    }
  };

  contactSelectedSegments = () => {};

  render() {
    const { contact } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;
    const customSelectStyles = {
      control: (base) => ({
        ...base,
        maxHeight: 100,
        overflow: "auto",
        autoComplete: "off",
        role: "presentation",
      }),
    };

    return (
      <div className={styles.editContactContainer}>
        <UikWidget>
          <div className={styles.editContainer}>
            <UikHeadline>Edit Contact</UikHeadline>
            <div className={styles.ContactEditFormSubtitle}>
              Make changes to this contact’s profile here
            </div>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator(`first_name${contact.id}`, {
                initialValue: contact.first_name,
                rules: [
                  {
                    required: true,
                    message: "Please enter a first name.",
                  },
                ],
              })(
                <UikInput
                  label="FIRST NAME"
                  placeholder="Mary"
                  errorMessage={(
                    getFieldError(`first_name${contact.id}`) || []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`last_name${contact.id}`, {
                initialValue: contact.last_name,
                rules: [
                  {
                    required: true,
                    message: "Please enter a last name.",
                  },
                ],
              })(
                <UikInput
                  label="LAST NAME"
                  placeholder="Smith"
                  errorMessage={(
                    getFieldError(`last_name${contact.id}`) || []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`email${contact.id}`, {
                initialValue: contact.email,
                rules: [
                  {
                    required: true,
                    message: "Please enter an email.",
                  },
                  {
                    type: "email",
                    message: "Must be in the format name@company.com",
                  },
                ],
              })(
                <UikInput
                  label="EMAIL ADDRESS"
                  placeholder="you@company.com"
                  errorMessage={(
                    getFieldError(`email${contact.id}`) || []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`title${contact.id}`, {
                initialValue: contact.title,
                rules: [
                  {
                    required: true,
                    message: "Please enter a title.",
                  },
                ],
              })(
                <UikInput
                  label="TITLE"
                  placeholder="Healthcare Professional"
                  errorMessage={(
                    getFieldError(`title${contact.id}`) || []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`company${contact.id}`, {
                initialValue: contact.company,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <UikInput
                  label="COMPANY"
                  placeholder=""
                  errorMessage={(
                    getFieldError(`company${contact.id}`) || []
                  ).join(", ")}
                />
              )}
              <div>
                <div className={styles.header}>
                  <span
                    className={`uik-content-title__wrapper`}
                    style={{ display: "block" }}
                  >
                    Segments
                  </span>
                </div>
                <Select
                  isMulti
                  isClearable={false}
                  value={this.state.selectedContactSegmentsList || []}
                  components={animatedComponents}
                  styles={customSelectStyles}
                  options={this.props.contactSegmentsSelectList}
                  onChange={this.handleContactSegmentsSelection}
                />
              </div>
              <div className={styles.editActionButtons}>
                <UikToggle
                  defaultChecked={contact.subscribed}
                  label="Subscribed"
                  onChange={(e) => this.handleSubscribed(e.target.checked)}
                />
              </div>
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleSave(e)}
              primary
            >
              Save
            </UikButton>
            <UikButton
              className="floatRight"
              onClick={(e) => this.handleDelete(e)}
              error
            >
              Delete Contact
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

ContactEditFormBuild.propTypes = {
  onDelete: PropTypes.func.isRequired,
  contactSegments: PropTypes.array,
  contactSegmentsSelectList: PropTypes.array,
  contact: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

const ContactEditForm = createForm()(ContactEditFormBuild);
export default ContactEditForm;
