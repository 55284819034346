import React from "react";
import moment from "moment";

import CloseSVG from "../../../../images/icons/svg/close.svg";

import styles from "./Signature.module.scss";

export default (props) => {
  const { signature, setSelectedSignature } = props;

  const onClose = () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    instance.Core.annotationManager.deselectAllAnnotations();

    setSelectedSignature(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.text}>Signed By</div>
        <div className={styles.closeIcon} onClick={onClose}>
          <img src={CloseSVG} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.signedBy}>
          {signature
            ? `${signature.user.first_name} ${signature.user.last_name}`
            : ""}
        </div>
        <div className={styles.signedAt}>
          {signature ? moment(signature.created_at).format("MMM D, YYYY") : ""}
        </div>
      </div>
    </div>
  );
};
