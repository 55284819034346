import DashboardActionTypes from "../../actions/Dashboard/types";

export default (dashboardData = null, action) => {
  switch (action.type) {
    case DashboardActionTypes.FETCH_DASHBOARD_DATA:
      return action.payload;
    default:
      return dashboardData;
  }
};
