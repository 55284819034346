import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import history from "../../../history";

import LinkIcon from "../../../images/icons/svg/external-link-icon.svg";
import InfoIcon from "../../../images/icons/svg/info-tooltip-active.svg";
import ArrowUpIcon from "../../../images/icons/svg/sorting-arrow-up.svg";
import ArrowDownIcon from "../../../images/icons/svg/sorting-arrow-down.svg";
import accountSetting from "../../../services/AccountSetting";

import styles from "./TableViewTable.module.scss";

export default (props) => {
  const {
    documents,
    documentFields,
    sort,
    sortingColumnIndex,
    sortColumn,
    sortOrder,
  } = props;

  const onDocHover = (docId) => {
    const docs = Array.from(document.getElementsByName(docId));
    docs.map((doc) => {
      doc.style.background = "#e2eef1";
    });
  };
  const onDocLeave = (docId) => {
    const docs = Array.from(document.getElementsByName(docId));
    docs.map((doc) => {
      doc.style.background = "#ffffff";
    });
  };

  const onClick = (docId) => {
    history.push({
      pathname: `/documents/${docId}`,
      state: {
        linkingReference: null,
      },
    });
  };

  const renderEmptyState = () => {
    return (
      <div className={styles.tableViewContainer}>
        <div className={styles.emptyStateContainer}>
          <div className={styles.emptyMessageContainer}>
            <img className={styles.InfoIcon} src={InfoIcon} />
            No content found
          </div>
        </div>
      </div>
    );
  };

  const renderTableView = () => {
    return (
      <div className={styles.tableViewContainer}>
        <div className={styles.tableOneContainer}>
          <table className={styles.titleTableContainer}>
            <colgroup>
              <col span="1" className={styles.col} />
            </colgroup>
            <thead className={styles.header}>
              <tr>
                <th className={styles.columnName} onClick={() => sort("title")}>
                  <div className={styles.container}>
                    <div
                      className={`${styles.text} ${
                        sortColumn === "title" && styles.textBold
                      }`}
                    >
                      Title
                    </div>
                    <div className={styles.icon}>
                      <img
                        className={
                          sortColumn === "title" && sortOrder === "desc"
                            ? styles.show
                            : ""
                        }
                        src={ArrowDownIcon}
                      />
                      <img
                        className={`${
                          sortColumn === "title" ? styles.arrowUpShow : ""
                        } ${
                          sortColumn === "title" && sortOrder === "asc"
                            ? styles.show
                            : ""
                        }`}
                        src={ArrowUpIcon}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className={styles.body}>
              {documents.map((doc, index) => (
                <tr
                  key={index}
                  name={doc.id}
                  onMouseEnter={() => onDocHover(doc.id)}
                  onMouseLeave={() => onDocLeave(doc.id)}
                  onClick={() => onClick(doc.id)}
                >
                  <td key={index}>{doc.title || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.tableTwoContainer}>
          <table className={styles.tableContainer}>
            <colgroup>
              {tableTwoProperties.map((column, index) => (
                <col key={index} span="1" className={styles.col} />
              ))}
            </colgroup>
            <thead className={styles.header}>
              <tr>
                {tableTwoProperties.map((column, index) => {
                  if (column !== "") {
                    return (
                      <th
                        className={
                          sortableColumn(column)
                            ? styles.columnName
                            : styles.unsortableColumnName
                        }
                        key={index}
                        onClick={() =>
                          sortableColumn(column) &&
                          sort(sortColumnIdentifierList[index], index)
                        }
                      >
                        <div className={styles.container}>
                          <div
                            className={`${styles.text} ${
                              sortingColumnIndex === index && styles.textBold
                            }`}
                          >
                            {column.label ? column.label : column}
                          </div>
                          <div className={styles.icon}>
                            <img
                              className={
                                sortingColumnIndex === index &&
                                sortOrder === "desc"
                                  ? styles.show
                                  : ""
                              }
                              src={ArrowDownIcon}
                            />
                            <img
                              className={`${
                                sortingColumnIndex === index
                                  ? styles.arrowUpShow
                                  : ""
                              } ${
                                sortingColumnIndex === index &&
                                sortOrder === "asc"
                                  ? styles.show
                                  : ""
                              }`}
                              src={ArrowUpIcon}
                            />
                          </div>
                        </div>
                      </th>
                    );
                  } else {
                    return <th key={index}>{column}</th>;
                  }
                })}
              </tr>
            </thead>
            <tbody className={styles.body}>
              {documents.map((doc, index) => (
                <tr
                  key={index}
                  name={doc.id}
                  onMouseEnter={() => onDocHover(doc.id)}
                  onMouseLeave={() => onDocLeave(doc.id)}
                  onClick={() => onClick(doc.id)}
                >
                  {rows[doc.id].map((cell, index) => {
                    return <td key={index}>{cell}</td>;
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const sortableColumn = (column) => {
    let isSortable = true;
    if (column === "tags" || column === "library") {
      isSortable = false;
    } else if (
      column.field_type &&
      column.field_type === "multi_select_options"
    ) {
      isSortable = false;
    }
    return isSortable;
  };

  let ownerOrUploader =
    accountSetting.getSetting("content_owner") === "TRUE"
      ? "owner"
      : "uploader";
  let tableTwoProperties = [ownerOrUploader, "upload date"];
  let sortColumnIdentifierList = ["full_name", "created_at"];
  documentFields.map((documentField) => {
    if (documentField.field_type !== "textarea") {
      tableTwoProperties.push(documentField);
      sortColumnIdentifierList.push(documentField.id);
    }
  });
  tableTwoProperties.push("tags");
  const rows = {};
  documents.map((doc) => {
    rows[doc.id] = [doc.owner_name];
    rows[doc.id].push(moment(doc.created_at).format("MMM D, YYYY"));
    tableTwoProperties.map((df, index) => {
      if (df === "tags") {
        let tagsString = "";
        doc.tags_list.map((tag, index) => {
          if (index === 0) {
            tagsString += `${tag},`;
          } else if (index === doc.tags_list.length - 1) {
            tagsString += ` ${tag}`;
          } else {
            tagsString += ` ${tag},`;
          }
        });
        if (tagsString) {
          rows[doc.id].push(tagsString);
        } else {
          rows[doc.id].push("-");
        }
      } else if (df.field_type === "multi_select_options") {
        let multiSelectValues;
        let multiSelectString = "";
        doc.field_values.map((fv) => {
          if (fv.document_field_id === df.id) {
            multiSelectValues = JSON.parse(fv.value);
          }
        });
        multiSelectValues &&
          multiSelectValues.map((value, index) => {
            if (index === 0) {
              multiSelectString += `${value},`;
            } else if (index === multiSelectValues.length - 1) {
              multiSelectString += ` ${value}`;
            } else {
              multiSelectString += ` ${value},`;
            }
          });
        if (multiSelectString) {
          rows[doc.id].push(multiSelectString);
        } else {
          rows[doc.id].push("-");
        }
      } else {
        if (doc.field_values && index >= 2) {
          const documentFieldValue = doc.field_values.find(
            (fv) => fv.document_field_id === df.id
          );
          if (documentFieldValue) {
            rows[doc.id].push(
              df.field_type === "date_time"
                ? moment(documentFieldValue.value).format("MMM D, YYYY")
                : documentFieldValue.display
            );
          } else {
            rows[doc.id].push("-");
          }
        }
      }
    });
  });
  return (
    <div>{documents.length > 0 ? renderTableView() : renderEmptyState()}</div>
  );
};
