import React, { useState, useEffect } from "react";

import history from "../../../history";
import httpClient from "../../../lib/HttpClient";
import classNames from "classnames";
import security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";

import Dropzone from "react-dropzone";
import NewModal from "../../Common/Modal";
import UploadIcon from "../../../images/icons/svg/upload.svg";
import CloseIcon from "../../../images/icons/svg/close-grey.svg";
import Button from "../../Common/Buttons/ButtonPrimary";
import styles from "./EditItemModal.module.scss";

export default (props) => {
  const [structuredContentCategory, setStructuredContentCategory] = useState(
    props.selectedCategory
  );
  const [imageFile, setImageFile] = useState(null);
  const [existingImageFile, setExistingImageFile] = useState(null);
  const [imageCaption, setImageCaption] = useState(props.selectedItem.caption);
  const [newStucturedContent, setNewStucturedContent] = useState("");
  const [structuredContent, setStructuredContent] = useState(
    props.selectedItem
  );
  const [updatedSCProperties, setUpdatedSCProperties] = useState({});

  useEffect(() => {
    if (props.selectedCategoryId > 0) {
      httpClient
        .get(`/structured_content_categories/${props.selectedCategoryId}.json`)
        .then((res) => {
          setStructuredContentCategory(res.data);
        });
    }
    if (props.selectedItem.content_type === "image") {
      setExistingImageFile(props.selectedItem.image_url);
    }
  }, []);

  const renderExistingImage = () => {
    return (
      <div className={styles.imageContainer}>
        <img
          onClick={() => {
            removeImage();
          }}
          className={styles.close}
          src={CloseIcon}
        />
        <img
          className={styles.image}
          src={`${process.env.REACT_APP_API_URL}/${props.selectedItem.image_url}`}
        />
      </div>
    );
  };

  const handlePropertyValueChange = (
    e,
    propertyLabel,
    propertyValues,
    propertyId
  ) => {
    let updatedSCPropertiesCopy = { ...updatedSCProperties };

    if (propertyValues.length > 0) {
      let foundValue = propertyValues.find((pv) => {
        return pv.structured_content_property_label === propertyLabel;
      });
      if (foundValue && foundValue.id) {
        updatedSCPropertiesCopy[propertyLabel] = {
          id: foundValue.id,
          value: e.target.value,
        };
      } else {
        updatedSCPropertiesCopy[propertyLabel] = {
          structured_content_id: structuredContent.id,
          structured_content_property_id: propertyId,
          value: e.target.value,
        };
      }
    } else {
      updatedSCPropertiesCopy[propertyLabel] = {
        structured_content_property_id: propertyId,
        value: e.target.value,
      };
    }

    setUpdatedSCProperties(updatedSCPropertiesCopy);
  };

  const renderForm = () => {
    if (structuredContentCategory.column_type === "image") {
      return renderImageForm();
    } else {
      return renderTextForm();
    }
  };

  const onImageDrop = (acceptedFiles, rejectedFiles) => {
    setImageFile(acceptedFiles[0]);
  };

  const renderImageUploader = () => {
    let className = styles.mainContentUploader;
    return (
      <div className={className}>
        <Dropzone onDrop={onImageDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <img src={UploadIcon} />
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop approval document here
                    </span>
                  </div>
                ) : (
                  <div className={styles.uploaderBody}>
                    <div className={styles.contentLeft}>
                      <span className={styles.uploaderPlaceholder}>
                        Drop files here or
                      </span>
                    </div>
                    <div className={styles.contentRight}>
                      <Button transparent text="Browse">
                        Browse
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  const handleClose = () => {
    history.push(`/structured_content/category/${props.selectedCategoryId}`);
    props.setEditItemModal(false);
  };

  const removeImage = () => {
    setImageFile(null);
    setExistingImageFile(null);
  };

  const renderImage = () => {
    return (
      <div className={styles.imageContainer}>
        <img
          onClick={() => {
            removeImage();
          }}
          className={styles.close}
          src={CloseIcon}
        />
        <img className={styles.image} src={URL.createObjectURL(imageFile)} />
      </div>
    );
  };

  const handleImageCaptionChange = (e) => {
    setImageCaption(e.target.value);
  };

  const renderImageForm = () => {
    return (
      <UikFormInputGroup className={styles.formContainer}>
        <div className={styles.newCategoryFormCol}>
          <div className={styles.customInputContainer}>
            <div className={styles.customInputLabel}>Image</div>
            {!imageFile && !existingImageFile && renderImageUploader()}
            {imageFile && renderImage()}
            {existingImageFile && !imageFile && renderExistingImage()}
          </div>
          <div className={styles.customInputContainer}>
            <div className={styles.newCategoryFormCol}>
              <UikInput
                onChange={(e) => {
                  handleImageCaptionChange(e);
                }}
                label="Caption"
                placeholder="Type a caption"
                value={imageCaption}
              />
            </div>
          </div>
        </div>
        <div className={styles.newCategoryFormCol}>
          <div className={styles.columnTwo}>
            <div>
              <UikInput
                label="Label"
                placeholder={`${structuredContentCategory.label_prefix}-${
                  structuredContentCategory.content_count + 1
                }`}
                disabled={true}
                value={props.selectedItem.label}
              />
            </div>
          </div>
        </div>
        <div className={styles.spaceCol}></div>
      </UikFormInputGroup>
    );
  };

  const renderPropertyInput = (propertyLabel) => {
    let property;

    if (structuredContentCategory.structured_content_properties) {
      property = structuredContentCategory.structured_content_properties.find(
        (scp) => scp.label === propertyLabel
      );
    } else {
      property = null;
    }
    if (propertyLabel === "Caption") {
      return (
        <div className={styles.customInputContainer}>
          <div>
            <div className={styles.label}>
              <span className="uik-content-title__wrapper">Caption</span>
            </div>
            <textarea
              className={`uik-input__input `}
              style={{ width: "100%" }}
              rows="5"
              onChange={(e) => {
                handleChangeStructuredContentCaption(e);
              }}
              label="Caption"
              value={structuredContent.fullSCObject.caption}
            ></textarea>
          </div>
        </div>
      );
    } else if (propertyLabel === "Label") {
      return (
        <div>
          <div>
            <UikInput
              label="Label"
              disabled={true}
              value={structuredContent.fullSCObject.label}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <UikInput
              label={`${propertyLabel}`}
              placeholder={`Input ${propertyLabel}`}
              disabled={false}
              onChange={(e) => {
                handlePropertyValueChange(
                  e,
                  propertyLabel,
                  structuredContent.fullSCObject
                    .structured_content_property_values,
                  property.id
                );
              }}
              value={
                updatedSCProperties[propertyLabel]
                  ? updatedSCProperties[propertyLabel].value
                  : structuredContent[propertyLabel]
              }
            />
          </div>
        </div>
      );
    }
  };

  const renderTextForm = () => {
    return (
      <UikFormInputGroup className={styles.formContainer}>
        {structuredContentCategory.all_property_labels.map((propertyLabel) => {
          return renderPropertyInput(propertyLabel);
        })}
      </UikFormInputGroup>
    );
  };

  const handleSave = () => {
    if (structuredContentCategory.column_type === "image") {
      const structuredContentFormData = new FormData();

      structuredContentFormData.append(
        "structured_content[structured_content_category_id]",
        props.selectedCategoryId
      );
      structuredContentFormData.append(
        "structured_content[caption]",
        imageCaption
      );
      if (imageFile) {
        structuredContentFormData.append(
          "structured_content[image_file]",
          imageFile
        );
      }

      const token = security.getToken();
      const postConfig = {
        headers: {
          Authorization: "Bearer " + token,
        },
        // onUploadProgress: (progressEvent) => {
        //   const mainDocumentPercent = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   );
        //   if (mainDocumentPercent >= 100) {
        //     this.setState({ mainDocumentPercent: 100 });
        //   } else {
        //     this.setState({ mainDocumentPercent });
        //   }
        // },
      };

      httpClient
        .put(
          `/structured_contents/${props.selectedItem.id}.json`,
          structuredContentFormData,
          postConfig
        )
        .then((res) => {
          httpClient
            .get(
              `/structured_contents/category/${props.selectedCategory.id}.json`
            )
            .then((res) => {
              handleClose();
              props.setItems(res.data);
              props.setAllItemsForCategory(res.data);
              notificationService.addNotification(
                "Structured Content Updated",
                "Structured Content Updated",
                "success"
              );
            });
          setNewStucturedContent(res.data);
        });
    } else {
      httpClient
        .put(
          `/structured_contents/${structuredContent.fullSCObject.id}.json`,
          structuredContent.fullSCObject
        )
        .then((res) => {
          setNewStucturedContent(res.data);
          let updatedProperties = Object.keys(updatedSCProperties).map(
            (propertyLabel) => {
              let updatedScpv = updatedSCProperties[propertyLabel];
              if (!updatedScpv.structured_content_id) {
                updatedScpv.structured_content_id = res.data.id;
              }
              return updatedScpv;
            }
          );

          let scpvData = {
            structured_content_property_values: updatedProperties,
          };

          httpClient
            .put(
              "/structured_content_property_values/update/batch_update.json",
              scpvData
            )
            .then((response) => {
              httpClient
                .get(
                  `/structured_contents/category/${props.selectedCategory.id}.json`
                )
                .then((res) => {
                  handleClose();
                  props.setItems(res.data);
                  props.setAllItemsForCategory(res.data);
                  notificationService.addNotification(
                    "Structured Content Updated",
                    "Structured Content Updated",
                    "success"
                  );
                });
              history.push(
                `/structured_content/category/${props.selectedCategoryId}`
              );
            });
        });
    }
  };

  const handleChangeStructuredContentCaption = (e) => {
    let structuredContentCopy = { ...structuredContent };

    structuredContentCopy["fullSCObject"] = {
      ...structuredContent.fullSCObject,
      caption: e.target.value,
      structured_content_category_id: props.selectedCategoryId,
    };

    setStructuredContent(structuredContentCopy);
  };

  const handleChangeStructuredContentPropertyValue = (e) => {
    setStructuredContent({
      ...structuredContent,
      display_sc_property_value: e.target.value,
    });
  };

  const handleDelete = (e) => {
    httpClient
      .delete(
        `/structured_contents/${
          structuredContent.fullSCObject
            ? structuredContent.fullSCObject.id
            : props.selectedItem.id
        }.json`
      )
      .then((response) => {
        httpClient
          .get(
            `/structured_contents/category/${props.selectedCategory.id}.json`
          )
          .then((res) => {
            handleClose();
            props.setItems(res.data);
            props.setAllItemsForCategory(res.data);
            notificationService.addNotification(
              "Structured Content Deleted",
              "Structured Content Deleted",
              "success"
            );
          });
      })
      .catch((error) => {});
  };

  return (
    <NewModal
      // hideModal={props.showNewItemModal}
      visible={true}
      type="regular"
    >
      <div className="heading">Edit Item</div>
      <UikDivider />
      <div className="body">
        <div>{renderForm()}</div>
      </div>
      <div className="clear" />
      <div className="dividerContainer">
        <UikDivider />
      </div>
      <div className="buttons">
        <div className={styles.buttonContainer}>
          <div className={styles.leftContainer}>
            <UikButton onClick={(e) => handleDelete(e)} error>
              Delete
            </UikButton>
          </div>
          <div className={styles.rightContainer}>
            <UikButton onClick={() => handleClose()}>Cancel</UikButton>
            <UikButton onClick={(e) => handleSave(e)} primary>
              Save
            </UikButton>
          </div>
        </div>
      </div>
    </NewModal>
  );
};
