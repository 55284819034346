import React from "react";
import { Col } from "react-grid-system";
import styles from "./SecurityMenu.module.scss";
import { UikDivider, UikButton } from "@uik";

export const SecurityMenu = (props) => {
  const showPasswordChangeForm = () => {
    props.hideSecurityMenu();
    props.renderChangePasswordForm();
  };
  return (
    <Col sm={12} md={5}>
      <div className={styles.backContainer}>
        <p className={styles.headline}>Security</p>
      </div>
      <div className={styles.block}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <div>Set your password</div>
            <div className={styles.subHeading}>
              All data you enter is kept secure and private.
            </div>
          </div>
        </div>
        <UikDivider />
        <div className={styles.content}>
          <UikButton
            className={styles.changeButton}
            onClick={(e) => showPasswordChangeForm()}
          >
            Change
          </UikButton>
        </div>
        <UikDivider />
      </div>
    </Col>
  );
};
