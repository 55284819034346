import DocumentActionTypes from "../../actions/document/types";

export default (commentsForMe = null, action) => {
  switch (action.type) {
    case DocumentActionTypes.GET_COMMENTS_FOR_ME:
      return action.payload;
    case DocumentActionTypes.RESET_COMMENTS_FOR_ME:
      return null;
    default:
      return commentsForMe;
  }
};
