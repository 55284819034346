import DocumentActionTypes from "../../actions/document/types";

export default (documentClaims = null, action) => {
  switch (action.type) {
    case DocumentActionTypes.FETCH_DOCUMENT_CLAIMS:
      return action.payload;
    default:
      return documentClaims;
  }
};
