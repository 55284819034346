import React from "react";
import PropTypes from "prop-types";
import styles from "./CommentPanel.module.scss";
import httpClient from "../../lib/HttpClient";
import moment from "moment";
import Checkmark from "../Common/Checkmark";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import "./mentionsStyles.css";
import { connect } from "react-redux";
import resetSearchCommentResults from "../../actions/document/resetSearchCommentResults";
import claimGrey from " ../../images/icons/svg/claims-magic-moment.svg";
import expandIcon from " ../../images/icons/svg/down-carrot-white.svg";
import collapseIcon from " ../../images/icons/svg/up-carrot-white.svg";
import editIconOrange from " ../../images/icons/svg/edit-icon-orange.svg";
import orangeClose from " ../../images/icons/svg/orange-close.svg";
import editIconWhite from " ../../images/icons/svg/pencil-white.svg";
import Confidence from "../Common/Confidence";
import ClaimCard from "../Documents/Panels/Claims/ClaimCard";

/* Papercurve Components */
import Comment from "./Comment";

import commentService from "../../services/Comments";
import security from "../../services/Security";
import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  Uikon,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import "draft-js/dist/Draft.css";

import sendIcon from "../../images/icons/16px/send.svg";
import moreSVG from "../../images/icons/svg/more.svg";
import CloseSVG from "../../images/icons/svg/close.svg";
import leftarrowSVG from "../../images/icons/svg/left-arrow.svg";
import sendButtonSVG from "../../images/icons/svg/send-comment-button-white.svg";
import spinnerSVG from "../../images/icons/svg/loading-white.svg";

/* Variables */

class CommentPanel extends React.Component {
  constructor(props) {
    super(props);

    this.resizeTimeout = null;
    this.myRef = React.createRef();
    this.commentPanelRef = React.createRef();
    this.focusedSuggestionRef = React.createRef();

    let mentions = [];

    if (!this.props.inGuestViewer) {
      this.props.approvers.forEach((approver) => {
        if (approver.active) {
          mentions.push({
            id: approver.id,
            name: `${approver.first_name} ${approver.last_name}`,
            title: `${approver.title}`,
            guest: `${approver.guest}`,
          });
        }
      });
      mentions.push({
        id: this.props.owner.id,
        name: `${this.props.owner.first_name} ${this.props.owner.last_name}`,
        title: `${this.props.owner.title}`,
        guest: `${this.props.owner.guest}`,
      });
    }

    this.state = {
      conversation: {},
      commentBody: "",
      currentUserId: security.getUserId(),
      addTimestamp: false,
      currentTimestamp: 0,
      editorState: EditorState.createEmpty(),
      mentions,
      suggestions: mentions,
      sendButtonDisabled: false,
      showSpinner: false,
      suggestedClaims: [],
      showFoundClaim: false,
      editingComment: null,
      existingMentions: [],
    };

    this.mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: {
        mention: "mention",
        mentionSuggestions: "mentionSuggestions",
        mentionSuggestionsEntry: "mentionSuggestionsEntry",
        mentionSuggestionsEntryText: "mentionSuggestionsEntryText",
        mentionSuggestionsEntryFocused: "mentionSuggestionsEntryFocused",
        mentionSuggestionsEntryAvatar: "mentionSuggestionsEntryAvatar",
      },
    });
    this.setDomEditorRef = (ref) => (this.domEditor = ref);
    this.focus = () => this.domEditor.focus();
  }

  componentWillMount = () => {
    if (
      this.props.legacyDocument ||
      (this.props.is_video && this.props.inGuestViewer)
    ) {
      this.legacyRetrieveConversation();
    } else {
      this.currentRetrieveConversation();
    }
  };

  componentDidMount = () => {
    document.addEventListener("reload_comments", (e) => {
      if (this.props.legacyDocument && e.detail.docId == this.props.docId) {
        this.legacyRetrieveConversation();
      } else {
        !this.props.inGuestViewer &&
          this.retrieveConversation(this.state.conversation.id);
      }
    });

    if (this.props.is_video) {
      document.addEventListener("video_timestamp_update", (e) => {
        this.setState({ currentTimestamp: e.detail.timestamp });
      });
    }

    const caption = localStorage.getItem("currentAnnotationText");

    if (!this.props.inGuestViewer) {
      httpClient
        .post("/paige/recommend_claims.json", {
          document: caption,
          library_id: this.props.libraryId,
        })
        .then((res) => {
          this.setState({
            suggestedClaims: res.data,
            topConfidenceClaim: this.getTopConfidenceClaim(res.data),
          });
        })
        .catch((err) => {
          this.setState({
            suggestedClaims: [],
          });
        });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.conversation &&
      this.state.conversation.id &&
      prevState.conversation &&
      !prevState.conversation.id &&
      this.myRef.current
    ) {
      this.myRef.current.scrollIntoView(true);
    }
    if (
      this.props.currentXfdfHighlightId === null &&
      prevProps.currentXfdfHighlightId !== null
    ) {
      this.setState({ conversation: {} });
    }
    if (
      this.props.currentXfdfHighlightId !== prevProps.currentXfdfHighlightId &&
      this.props.currentXfdfHighlightId !== null
    ) {
      let url = !this.props.inGuestViewer
        ? `/conversations/xfdf/${this.props.currentXfdfHighlightId}.json`
        : `/conversations/xfdf/guest/${this.props.currentXfdfHighlightId}/${this.props.hashUrl}.json`;

      httpClient.get(url).then((response) => {
        this.setState({ conversation: response.data });

        this.retrieveUnsavedComment(response.data.id);
      });
    }

    if (this.commentPanelRef.current) {
      this.commentPanelRef.current.scrollTop =
        this.commentPanelRef.current.scrollHeight;
    }
  };

  canDeleteConversation = (conversation) => {
    let canDelete = false;
    if (security.getUserRole() == "admin") {
      canDelete = true;
    } else if (this.props.inGuestViewer) {
      if (conversation.conversation_type === "strikethrough") {
        if (conversation.started_by_user.id === this.props.guestUserId) {
          canDelete = true;
        }
      } else if (
        conversation.conversation_type !== "strikethrough" &&
        conversation.comments &&
        conversation.comments.length > 0 &&
        conversation.comments[0].conversation.started_by_user_id ===
          this.props.guestUserId
      ) {
        canDelete = true;
      }
    } else {
      if (conversation.conversation_type === "strikethrough") {
        if (conversation.started_by_user.id === security.getUserId()) {
          canDelete = true;
        }
      } else if (
        conversation.conversation_type !== "strikethrough" &&
        conversation.comments &&
        conversation.comments.length > 0 &&
        conversation.comments[0].conversation.started_by_user_id ==
          security.getUserId()
      ) {
        canDelete = true;
      }
    }

    return canDelete;
  };

  retrieveConversation = (conversationId) => {
    if (!conversationId) {
      conversationId = this.state.conversation.id;
    }

    let url = !this.props.inGuestViewer
      ? `/conversations/${conversationId}.json`
      : `guest/conversations/${conversationId}/${this.props.hashUrl}.json`;

    httpClient.get(url).then((response) => {
      this.setState({ conversation: response.data });
    });
  };

  legacyRetrieveConversation = () => {
    const { conversationBlockId } = this.props;

    let url =
      this.props.is_video && this.props.inGuestViewer
        ? `/conversations/legacy/${conversationBlockId}/guest/${this.props.hashUrl}.json`
        : `/conversations/legacy/${conversationBlockId}.json`;

    // Get conversation using block identifier
    httpClient
      .get(url)
      .then((response) => {
        this.setState({
          conversation: response.data,
        });

        this.retrieveUnsavedComment(response.data.id);
      })
      .catch((err) => {
        this.retrieveUnsavedComment();
      })
      .finally(() => this.setState({ conversationLoaded: true }));
  };

  currentRetrieveConversation = () => {
    const { currentXfdfHighlightId } = this.props;
    let url = !this.props.inGuestViewer
      ? `/conversations/xfdf/${currentXfdfHighlightId}.json`
      : `/conversations/xfdf/guest/${currentXfdfHighlightId}/${this.props.hashUrl}.json`;

    // Get conversation using block identifier
    httpClient
      .get(url)
      .then((response) => {
        this.setState({
          conversation: response.data,
        });

        this.retrieveUnsavedComment(response.data.id);
      })
      .catch((err) => {
        this.retrieveUnsavedComment();
      })
      .finally(() => this.setState({ conversationLoaded: true }));
  };

  retrieveUnsavedComment = (id = null) => {
    const conversationComment = localStorage.getItem(`conversationID-${id}`);

    const documentComment = localStorage.getItem(
      `conversationDocumentID-${this.props.docId}`
    );
    if (id && conversationComment) {
      const editorState = EditorState.push(
        this.state.editorState,
        ContentState.createFromText(conversationComment)
      );

      this.setState({ editorState });
    } else if (documentComment) {
      const editorState = EditorState.push(
        this.state.editorState,
        ContentState.createFromText(documentComment)
      );

      this.setState({ editorState });
    }
  };

  addComment = () => {
    if (this.props.legacyDocument) {
      if (this.state.editingComment) {
        this.updateCommentText();
      } else {
        this.legacyAddComment();
      }
    } else if (this.state.editingComment) {
      this.updateCommentText();
    } else {
      this.currentAddComment();
    }
  };

  toggleConversationStatus = () => {
    let status = "resolved";
    if (this.state.conversation.status === "resolved") {
      status = "unresolved";
    }

    let url = !this.props.inGuestViewer
      ? `/conversations/${
          this.state.conversation.xfdf_highlight_id ||
          this.state.conversation.block_identifier
        }.json`
      : `/conversations/${
          this.state.conversation.xfdf_highlight_id ||
          this.state.conversation.block_identifier
        }/guest.json`;

    httpClient
      .put(url, {
        hash_url: this.props.hashUrl,
        conversation: {
          status,
        },
      })
      .then((res) => {
        this.setState({ conversation: res.data });
        this.props.reloadConversations();
      });
  };

  getTopConfidenceClaim = (suggestedClaims) => {
    const topConfidenceClaim = suggestedClaims.sort(function (a, b) {
      if (a.confidence < b.confidence) {
        return 1;
      }
      if (a.confidence > b.confidence) {
        return -1;
      }
      return 0;
    })[0];
    return topConfidenceClaim;
  };

  expandFoundClaim = () => {
    this.setState({ showFoundClaim: true });
  };

  collapseFoundClaim = () => {
    this.setState({ showFoundClaim: false });
  };

  renderFoundClaim = () => {
    return (
      <div className={styles.foundClaimContainer}>
        <div className={styles.foundClaimTop}>
          <div className={styles.foundClaimTitleContainer}>
            <img src={claimGrey} className={styles.foundClaimTitleImg} />
            <div className={styles.foundClaimTitle}>product claim found</div>
          </div>
          {!this.state.showFoundClaim && (
            <img
              src={expandIcon}
              className={styles.expandIcon}
              onClick={() => {
                this.expandFoundClaim();
              }}
            />
          )}
          {this.state.showFoundClaim && (
            <img
              src={collapseIcon}
              className={styles.expandIcon}
              onClick={() => {
                this.collapseFoundClaim();
              }}
            />
          )}
        </div>
        {this.state.showFoundClaim && (
          <div className={styles.dividerContainer} />
        )}
        {!this.state.showFoundClaim && (
          <div className={styles.foundClaimBottom}>
            <div className={styles.helpText}>
              Highlighted text is similar to a product claim.
            </div>
            <Confidence
              percentage={this.state.topConfidenceClaim.confidence * 100}
            />
          </div>
        )}
        {this.state.showFoundClaim && (
          <ClaimCard
            claim={this.state.topConfidenceClaim}
            // setSelectedClaim={setcreatedClaim}
            searchActive={false}
            // linkClaim={linkClaim}
            paigeSuggestedClaim={true}
            inCommentPanel={true}
          />
        )}
      </div>
    );
  };

  updateCommentText = () => {
    let that = this;
    if (this.state.editorState.getCurrentContent().getPlainText() === "") {
      return;
    }

    const contentState = convertToRaw(
      this.state.editorState.getCurrentContent()
    );

    let mentions = Object.values(contentState.entityMap)
      .filter((v) => v.type === "mention")
      .map((v) => v.data.mention);

    let newComment = this.state.editorState.getCurrentContent().getPlainText();

    mentions.map((mention) => {
      newComment = newComment.replace(mention.name, `{{${mention.name}}}`);
    });

    mentions = mentions.filter(
      (mention, index, mentions) =>
        mentions.findIndex((i) => i.id === mention.id) === index
    );
    newComment = this.reFormatExistingMentions(newComment);
    const commentData = {
      comment: {
        body: newComment,
        edited: true,
        mentions: mentions,
      },
    };

    if (mentions.length > 0) {
      commentData.mentions = mentions;
    }

    this.setState({
      sendButtonDisabled: true,
      showSpinner: true,
    });

    let url = `/comments/${this.state.editingComment}`;

    httpClient.put(url, commentData).then((response) => {
      notificationService.addNotification(
        "Your comment has been edited successfully.",
        "Your comment has been edited successfully.",
        "success"
      );

      if (this.state.conversation.id) {
        localStorage.removeItem(`conversationID-${this.state.conversation.id}`);
      } else {
        localStorage.removeItem(`conversationDocumentID-${this.props.docId}`);
      }
      let conversation = this.state.conversation;

      if (!(this.state.conversation.id > 0)) {
        this.props.is_video
          ? this.legacyRetrieveConversation()
          : this.retrieveConversation(response.data.conversation.id);
      } else {
        // conversation.comments.push(response.data);
      }

      this.props.reloadConversations();
      this.setState(
        {
          conversation: conversation,
          editorState: EditorState.createEmpty(),
          sendButtonDisabled: false,
          showSpinner: false,
          editingComment: null,
        },
        () => {
          const commentPanel = document.getElementById("sidePanelOptions");
          commentPanel.scrollTop = commentPanel.scrollHeight;
        }
      );

      commentService.reloadComments(parseInt(this.props.docId));
    });
  };

  currentAddComment = () => {
    let that = this;
    if (this.state.editorState.getCurrentContent().getPlainText() === "") {
      return;
    }

    const { docId, pageNumber } = this.props;

    const contentState = convertToRaw(
      this.state.editorState.getCurrentContent()
    );

    let mentions = Object.values(contentState.entityMap)
      .filter((v) => v.type === "mention")
      .map((v) => v.data.mention);

    let newComment = this.state.editorState.getCurrentContent().getPlainText();

    mentions.map((mention) => {
      newComment = newComment.replace(mention.name, `{{${mention.name}}}`);
    });

    mentions = mentions.filter(
      (mention, index, mentions) =>
        mentions.findIndex((i) => i.id === mention.id) === index
    );

    const removePinStampImage = (xfdfHighlightString) => {
      if (xfdfHighlightString) {
        const pinStampImage = /<imagedata>([^>]*)<\/imagedata>/g;
        const xfdfWithoutPinStampImage = xfdfHighlightString.replace(
          pinStampImage,
          "<imagedata></imagedata>"
        );
        return xfdfWithoutPinStampImage;
      }
    };

    const commentData = {
      hash_url: this.props.hashUrl,
      conversation: {
        document_id: parseInt(docId),
        page_number: parseInt(pageNumber),
        xfdf_highlight_id: this.props.currentXfdfHighlightId,
        xfdf_highlight_string: removePinStampImage(
          this.props.currentXfdfHighlightString
        ),
      },
      comment: {
        body: newComment,
      },
    };

    if (mentions.length > 0) {
      commentData.mentions = mentions;
    }

    if (this.props.is_video && this.props.inGuestViewer) {
      commentData.conversation.block_identifier =
        this.props.conversationBlockId;
    }

    if (this.props.is_video && this.state.addTimestamp) {
      commentData.comment.video_timestamp = this.state.currentTimestamp;
    }

    this.setState({ sendButtonDisabled: true });

    setTimeout(() => {
      if (that.state.sendButtonDisabled) {
        that.setState({
          showSpinner: true,
        });
      }
    }, 400);
    let url = this.props.inGuestViewer
      ? "/comments/guest.json"
      : "/comments.json";
    httpClient.post(url, commentData).then((response) => {
      !this.props.inGuestViewer && this.props.setPreviousConvoId(null);
      let conversation = this.state.conversation;

      if (!(this.state.conversation.id > 0)) {
        this.props.is_video
          ? this.legacyRetrieveConversation()
          : this.retrieveConversation(response.data.conversation.id);
      } else {
        conversation.comments.push(response.data);
      }

      this.props.reloadConversations();

      if (this.state.conversation.id) {
        localStorage.removeItem(`conversationID-${this.state.conversation.id}`);
      } else {
        localStorage.removeItem(`conversationDocumentID-${this.props.docId}`);
      }

      this.setState(
        {
          conversation: conversation,
          editorState: EditorState.createEmpty(),
          sendButtonDisabled: false,
          showSpinner: false,
        },
        () => {
          const commentPanel = document.getElementById("sidePanelOptions");
          commentPanel.scrollTop = commentPanel.scrollHeight;
        }
      );

      commentService.reloadComments(parseInt(this.props.docId));
    });
  };

  legacyAddComment = () => {
    let that = this;
    if (this.state.editorState.getCurrentContent().getPlainText() === "") {
      return;
    }

    const { conversationBlockId, docId, pageNumber } = this.props;

    const contentState = convertToRaw(
      this.state.editorState.getCurrentContent()
    );
    const mentions = Object.values(contentState.entityMap)
      .filter((v) => v.type === "mention")
      .map((v) => v.data.mention);

    let newComment = this.state.editorState.getCurrentContent().getPlainText();

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    mentions.filter(onlyUnique).map((mention) => {
      newComment = newComment.replaceAll(mention.name, `{{${mention.name}}}`);
    });

    const commentData = {
      conversation: {
        document_id: parseInt(docId),
        page_number: parseInt(pageNumber),
        block_identifier: conversationBlockId,
      },
      comment: {
        body: newComment,
      },
    };

    if (mentions.length > 0) {
      commentData.mentions = mentions;
    }

    if (this.props.is_video && this.state.addTimestamp) {
      commentData.comment.video_timestamp = this.state.currentTimestamp;
    }

    this.setState({ sendButtonDisabled: true });

    setTimeout(() => {
      if (that.state.sendButtonDisabled) {
        that.setState({
          showSpinner: true,
        });
      }
    }, 400);

    httpClient.post("/comments.json", commentData).then((response) => {
      let conversation = this.state.conversation;

      if (!(this.state.conversation.id > 0)) {
        this.legacyRetrieveConversation();
      } else {
        conversation.comments.push(response.data);
      }

      this.props.reloadConversations();

      if (this.state.conversation.id) {
        localStorage.removeItem(`conversationID-${this.state.conversation.id}`);
      } else {
        localStorage.removeItem(`conversationDocumentID-${this.props.docId}`);
      }

      this.setState(
        {
          conversation: conversation,
          editorState: EditorState.createEmpty(),
          sendButtonDisabled: false,
          showSpinner: false,
        },
        () => {
          const commentPanel = document.getElementById("sidePanelOptions");
          commentPanel.scrollTop = commentPanel.scrollHeight;
        }
      );

      commentService.reloadComments(parseInt(this.props.docId));
    });
  };

  renderComments = () => {
    let commentFound = false;
    return this.state.conversation.comments.map((i, j) => {
      let commentedAtTime = i.commented_at;
      const twelveHours = 60 * 60 * 1000 * 12;
      let canDelete =
        (!this.props.approved &&
          (security.getUserRole() == "admin" ||
            (i.user.id == security.getUserId() &&
              Date.now() - new Date(commentedAtTime) < twelveHours))) ||
        i.user.id === this.props.guestUserId;
      let searchedCommentIdentified =
        this.props.searchResultLibrary &&
        this.props.searchResultLibrary.searchResults.length > 0 &&
        this.props.searchResultLibrary.searchResults.find(
          (comment) => comment.id === i.id
        );
      if (
        this.props.selectedCommentId &&
        this.props.selectedCommentId === i.id
      ) {
        commentFound = true;
        return (
          <div
            key={i.id}
            ref={searchedCommentIdentified ? this.myRef : null}
            onClick={() => this.props.updateSelectedCommentId(i.id)}
            className={styles.comment}
          >
            <Comment
              comment={i}
              currentUserId={
                this.props.inGuestViewer
                  ? this.props.guestUserId
                  : this.state.currentUserId
              }
              onDeleteComment={this.onDeleteComment}
              canDelete={canDelete}
              editComment={this.editComment}
              editingComment={this.state.editingComment}
            />
          </div>
        );
      } else {
        if (
          this.props.currentXfdfHighlightId &&
          this.props.selectedCommentId &&
          j === this.state.conversation.comments.length - 1 &&
          !commentFound
        ) {
          notificationService.addNotification(
            "Comment removed.",
            "Comment no longer exists.",
            "danger"
          );
        }
        return (
          <div
            key={i.id}
            ref={searchedCommentIdentified ? this.myRef : null}
            onClick={
              !this.props.inGuestViewer
                ? () => this.props.updateSelectedCommentId(i.id)
                : null
            }
            className={styles.comment}
          >
            <Comment
              comment={i}
              currentUserId={
                this.props.inGuestViewer
                  ? this.props.guestUserId
                  : this.state.currentUserId
              }
              onDeleteComment={this.onDeleteComment}
              canDelete={canDelete}
              editComment={this.editComment}
              editingComment={this.state.editingComment}
            />
          </div>
        );
      }
    });
  };

  onDeleteComment = (id) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      if (this.state.editingComment) {
        this.cancelEditing();
      }
      let url = this.props.inGuestViewer
        ? `/comments/guest/${id}/${this.props.hashUrl}.json`
        : `/comments/${id}.json`;
      httpClient.delete(url).then((response) => {
        if (!this.props.inGuestViewer) {
          this.props.updateSelectedCommentId(null);
        } else {
          this.props.reloadConversations();
          if (response.data.comment_count > 0) {
            this.retrieveConversation(this.props.selectedConversationId);
          }
        }
        notificationService.addNotification(
          "Comment removed.",
          "Comment has been deleted.",
          "danger"
        );
        if (response.data.comment_count > 0) {
          commentService.reloadComments(parseInt(this.props.docId));
        } else {
          const webViewerEl = document.getElementById("webViewer");
          const annotationManager =
            window.WebViewer.getInstance(webViewerEl).Core.annotationManager;
          const annot = annotationManager.getAnnotationById(
            this.props.inGuestViewer
              ? this.props.conversationSelected
              : this.props.currentXfdfHighlightId
          );

          annotationManager.deleteAnnotation(annot);
          this.props.closeConversation();
        }
        this.props.reloadConversations();
      });
    }
  };

  formatExistingMentions = (commentText) => {
    const mention = new RegExp("{{(.*?)}}", "gi");

    let parts = commentText ? commentText.split(mention) : [];

    const names = commentText.match(mention);

    let existingMentionsArray = [];

    const formattedText = parts
      .map((part, i) => {
        const found = names && names.find((name) => name === `{{${part}}}`);

        if (found) {
          existingMentionsArray.push(`@${part}`);
          return `@${part}`;
        } else {
          return part;
        }
      })
      .join("");

    this.setState({ existingMentions: existingMentionsArray });
    return formattedText;
  };

  reFormatExistingMentions = (editedCommentText) => {
    let formattedText = editedCommentText;

    this.state.existingMentions.map((existingMention) => {
      const mention = new RegExp(`${existingMention}`, "gi");

      let splitMention = existingMention.split("");

      splitMention.shift();

      let mentionWithDelimiters = `{{${splitMention.join("")}}}`;

      formattedText = formattedText.replace(mention, mentionWithDelimiters);
    });

    return formattedText;
  };

  editComment = (id, text) => {
    const editorState = EditorState.push(
      this.state.editorState,
      ContentState.createFromText(this.formatExistingMentions(text))
    );

    this.setState({
      editingComment: id,
      editorState,
    });
  };

  cancelEditing = () => {
    if (this.state.conversation.id) {
      localStorage.removeItem(`conversationID-${this.state.conversation.id}`);
    } else {
      localStorage.removeItem(`conversationDocumentID-${this.props.docId}`);
    }
    this.setState({
      editingComment: null,
      editorState: EditorState.createEmpty(),
    });
  };

  onDeleteConversation = () => {
    const { conversationBlockId, currentXfdfHighlightId } = this.props;

    let conversationIdentifier = currentXfdfHighlightId;

    if (conversationBlockId) {
      conversationIdentifier = conversationBlockId;
    }

    let url = !this.props.inGuestViewer
      ? `/conversations/${conversationIdentifier}.json`
      : `delete/guest/conversation/${this.props.currentXfdfHighlightId}/${this.props.hashUrl}.json`;

    if (window.confirm("Are you sure you want to delete this Conversation?")) {
      httpClient.delete(url).then((response) => {
        if (currentXfdfHighlightId === conversationIdentifier) {
          const webViewerEl = document.getElementById("webViewer");
          const annotationManager =
            window.WebViewer.getInstance(webViewerEl).Core.annotationManager;
          const annot = annotationManager.getAnnotationById(
            conversationIdentifier
          );

          annotationManager.deleteAnnotation(annot);
        }

        this.props.reloadConversations();
        this.props.closeConversation();
        notificationService.addNotification(
          "Conversation removed.",
          "Conversation has been deleted.",
          "danger"
        );
      });
    }
  };

  addTimestamp = (e) => {
    this.setState({ addTimestamp: e.target.checked });
  };

  onChange = (editorState) => {
    const text = editorState.getCurrentContent().getPlainText();
    this.setState({ editorState });
    if (this.state.conversation.id) {
      localStorage.setItem(
        `conversationID-${this.state.conversation.id}`,
        text
      );
    } else if (this.state.conversationLoaded) {
      localStorage.setItem(`conversationDocumentID-${this.props.docId}`, text);
    }
  };

  onAddAtMention = () => {
    const newContentState = Modifier.insertText(
      this.state.editorState.getCurrentContent(),
      this.state.editorState.getSelection(),
      "@"
    );

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      "insert-fragment"
    );

    this.setState(
      {
        editorState: newEditorState,
      },
      () => {
        setTimeout(() => {
          this.focus();
        }, 200);
      }
    );
  };

  customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) =>
      list.constructor.name === "List" ? list.size : list.length;

    const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        !value || get(suggestion, "name").toLowerCase().indexOf(value) > -1
    );
    const length =
      size(filteredSuggestions) < 10 ? size(filteredSuggestions) : 50;
    return filteredSuggestions.slice(0, length);
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: this.customSuggestionsFilter(value, this.state.mentions),
    });
  };

  entryComponent = (props) => {
    const { mention, theme, searchValue, isFocused, ...parentProps } = props;

    if (mention.guest === "true") {
      return <span style={{ display: "none" }}></span>;
    }

    let name = mention.name.split(" ");

    return (
      <div {...parentProps}>
        <div className={theme.mentionSuggestionsEntryAvatar}>
          {`${name[0].charAt(0)}${name[1].charAt(0)}`}
        </div>
        <div className="mentionSuggestionsTextBox">
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name}
          </div>

          <div className="mentionSuggestionsTitle">{mention.title}</div>
        </div>
      </div>
    );
  };

  mentionsSuggestionsComponent = () => {
    return (
      <div
        className="hello"
        onSearchChange={this.onSearchChange}
        suggestions={this.state.suggestions}
        entryComponent={this.entryComponent}
      ></div>
    );
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    const actionsDropDown = ({ onClick }) => {
      return (
        <span onClick={onClick}>
          <img className={styles.moreIcon} src={moreSVG} />
        </span>
      );
    };

    return (
      <div
        id="conversationPanel"
        className={
          this.props.showMobileSidePanel
            ? styles.mobileCommentPanel
            : styles.commentPanel
        }
        ref={this.commentPanelRef}
      >
        <div className={styles.sidePanelHeader} id="sidepanelheader">
          {!this.props.is_video && (
            <span
              className={styles.backArrow}
              onClick={() => {
                if (
                  this.state.conversation &&
                  Object.keys(this.state.conversation).length > 0 &&
                  this.state.conversation.comments.length > 0
                ) {
                  this.props.closeConversation(false, false);
                } else {
                  this.props.closeConversation(true, true);
                }
              }}
            >
              <img src={leftarrowSVG} />
            </span>
          )}
          <span className={styles.sidePanelHeadingTitle}>Comments</span>
          <span
            className={styles.closeComment}
            onClick={() => {
              this.props.resetSearchCommentResults();
              if (
                this.state.conversation &&
                Object.keys(this.state.conversation).length > 0
              ) {
                this.props.closeConversation(true);
              } else {
                this.props.closeConversation(true, true);
              }
            }}
          >
            <img src={CloseSVG} />
          </span>
          {!this.props.approved &&
            this.state.conversation &&
            !this.state.conversation.block_identifier &&
            (this.props.viewingCurrentVersion || this.props.inGuestViewer) &&
            this.canDeleteConversation(this.state.conversation) && (
              <div className={styles.conversationActionsDropDownContainer}>
                <UikDropdown
                  DisplayComponent={actionsDropDown}
                  position="bottomRight"
                >
                  <UikDropdownItem
                    onClick={(e) => this.onDeleteConversation(e)}
                  >
                    Delete
                  </UikDropdownItem>
                </UikDropdown>
              </div>
            )}
          {!this.props.is_video &&
            !this.props.inGuestViewer &&
            this.state.conversation &&
            this.state.conversation.comments &&
            this.state.conversation.comments.length > 0 && (
              <div className={styles.checkmark}>
                <Checkmark
                  disabled={this.props.readOnly}
                  status={this.state.conversation.status}
                  onClick={() => {
                    this.toggleConversationStatus();
                  }}
                />
              </div>
            )}
        </div>
        {this.state.suggestedClaims.length > 0 && this.renderFoundClaim()}
        <div
          className={styles.sidePanelOptions}
          id="sidePanelOptions"
          ref={this.sidePanelOptionsCallback}
        >
          <div>
            {this.state.conversation &&
              this.state.conversation.id > 0 &&
              this.renderComments()}
          </div>
        </div>
        {this.state.editingComment && (
          <div className={styles.cancelEdit}>
            <div className={styles.cancelEditLeft}>
              <img className={styles.editIcon} src={editIconOrange} />
              <p>Editing</p>
            </div>
            <img
              className={styles.closeIconOrange}
              src={orangeClose}
              onClick={() => {
                this.cancelEditing();
              }}
            />
          </div>
        )}
        {!this.props.readOnly && (
          <div className={styles.messageInput} id="messageInput">
            {this.props.is_video && (
              <div class={styles.addTimestamp}>
                <input type="checkbox" onChange={this.addTimestamp} />
                Add Timestamp (
                {moment()
                  .startOf("day")
                  .seconds(this.state.currentTimestamp)
                  .format("H:mm:ss")}
                )
              </div>
            )}
            <div className={styles.editor}>
              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
                placeholder="Type a message..."
                plugins={plugins}
                ref={this.setDomEditorRef}
              />
              <MentionSuggestions
                onOpenChange={true}
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
                entryComponent={this.entryComponent}
                onAddMention={this.addMention}
              />
            </div>
          </div>
        )}
        {!this.props.readOnly && (
          <div
            className={
              !this.props.inGuestViewer
                ? styles.messageInputSubmit
                : styles.messageInputSubmitGuest
            }
          >
            {!this.props.inGuestViewer && (
              <button
                disabled={this.state.sendButtonDisabled}
                onClick={this.onAddAtMention}
                className={styles.atButton}
              >
                @<p className={styles.buttonTextMention}>Mention</p>
              </button>
            )}
            <button
              disabled={this.state.sendButtonDisabled}
              onClick={this.addComment}
              className={
                this.state.editingComment
                  ? styles.sendButtonUpdate
                  : styles.sendButton
              }
            >
              {!this.state.editingComment && (
                <div className={styles.sendContainer}>
                  {this.state.showSpinner && (
                    <img className={styles.loading} src={spinnerSVG} />
                  )}
                  <img
                    className={`${
                      this.state.showSpinner
                        ? styles.hideSendButton
                        : styles.showSendButton
                    }`}
                    src={sendButtonSVG}
                  />
                  <p className={styles.buttonText}>Send</p>
                </div>
              )}
              {this.state.editingComment && (
                <div className={styles.sendContainer}>
                  {this.state.showSpinner && (
                    <img className={styles.loading} src={spinnerSVG} />
                  )}
                  <img
                    className={`${
                      this.state.showSpinner
                        ? styles.hideSendButton
                        : styles.showEditSubmitButton
                    }`}
                    src={editIconWhite}
                  />
                  <p className={styles.buttonText}>Edit</p>
                </div>
              )}
            </button>
          </div>
        )}
      </div>
    );
  }
}

CommentPanel.propTypes = {
  closeConversation: PropTypes.func,
  conversationBlockId: PropTypes.string,
  docId: PropTypes.number,
  pageNumber: PropTypes.number,
  legacyDocument: PropTypes.bool,
  currentXfdfHighlightId: PropTypes.string,
  currentXfdfHighlightString: PropTypes.string,
};

const mapStateToProps = (state) => {
  return { searchResultLibrary: state.commentSearchResults };
};

const actionCreators = {
  resetSearchCommentResults,
};

export default connect(mapStateToProps, actionCreators)(CommentPanel);
