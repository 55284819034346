import ActionTypes from "./types";
import httpClient from "../../lib/HttpClient";

export default (libraryId) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await httpClient.get(
        `/documents/${libraryId}/document_templates.json`
      );

      dispatch({
        type: ActionTypes.FETCH_DOCUMENT_TEMPLATES,
        payload: response.data,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
