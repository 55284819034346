import React, { useState, useEffect } from "react";
import { Container } from "react-grid-system";
import httpClient from "../../../lib/HttpClient";
import Header from "../../shared/Header/Header";
import ShareLinksTable from "./ShareLinksTable";
import ShareLinkAnalyticsModal from "./ShareLinkAnalyticsModal";
import TopShareLinks from "./TopShareLinks";
import useDebouncer from "../../../hooks/useDebouncer";
import closeBlack from "../../../images/icons/svg/close-black.svg";
import { useTranslation } from "react-i18next";

import queryString from "query-string";

import { Chart, registerables } from "chart.js";

import { UikInput } from "@uik";

import styles from "./ShareLinkAnalytics.module.scss";
import moment from "moment";

export default (props) => {
  const [summaryData, setSummaryData] = useState({});
  const [shareLinkData, setShareLinkData] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [topLinks, setTopLinks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [noSearchResults, setNoSearchResults] = useState(false);

  const { t } = useTranslation();

  Chart.register(...registerables);

  let chartRef = React.createRef();

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    httpClient
      .get(`/share_link_analytics_chart_data.json`)
      .then((res) => {
        setSummaryData({
          totalClicks: res.data.events_per_day.clicks_count,
          totalDownloads: res.data.events_per_day.downloads_count,
        });

        new Chart(myChartRef, {
          type: "line",
          data: {
            labels: res.data.events_per_day.date_range,
            datasets: [
              {
                label: "Clicks",
                data: res.data.events_per_day.clicks,
                backgroundColor: "#7b61ff",
                borderColor: "#7b61ff",
                tension: 0.7,
              },
              {
                label: "Downloads",
                data: res.data.events_per_day.downloads,
                backgroundColor: "#1b8c96",
                borderColor: "#1b8c96",
                tension: 0.7,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              yAxes: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: "Clicks & Downloads",
                  font: {
                    size: 12,
                    family: "Montserrat",
                    color: "black",
                  },
                },
                ticks: {
                  precision: 0,
                },
              },
              xAxes: {
                ticks: {
                  precision: 0,
                  font: {
                    size: 10,
                    family: "Montserrat",
                  },
                },
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 20,
                  boxHeight: 1,
                  font: {
                    size: 12,
                    family: "Montserrat",
                    color: "black",
                  },
                },
                position: "bottom",
              },
            },
          },
        });
      })
      .then(() => {
        const queryParams = queryString.parse(props.location.search);

        if (queryParams.label) {
          setSearchTerm(queryParams.label);
        }
      });
  }, []);

  const debouncedInputValue = useDebouncer(searchTerm);

  useEffect(() => {
    searchShareLinks(debouncedInputValue);
  }, [debouncedInputValue]);

  const clearSearch = () => {
    setSearchTerm("");
  };

  const searchShareLinks = (searchTerm) => {
    const data = {
      search_term: searchTerm,
    };

    httpClient.post(`/share_links/search.json`, data).then((res) => {
      setShareLinkData(res.data);
      if (res.data.length === 0 && searchTerm !== "") {
        setNoSearchResults(true);
      } else {
        setNoSearchResults(false);
      }
      if (topLinks.length === 0 && searchTerm === "") {
        const topLinks = [];
        res.data.map((link) => {
          if (moment(link.last_accessed).isAfter(moment().subtract(7, "d"))) {
            topLinks.push(link);
          }
        });

        setTopLinks(topLinks);
      }
    });
  };

  const showActivityLog = (link) => {
    setSelectedLink(link);
    setShowActivityModal(true);
  };

  return (
    <Container fluid className={styles.container}>
      <Header />
      {selectedLink && (
        <ShareLinkAnalyticsModal
          link={selectedLink}
          showActivityModal={showActivityModal}
          setShowActivityModal={setShowActivityModal}
        />
      )}
      <div className={styles.pageHeader}>{t("header.share_links")}</div>
      <div className={styles.top}>
        <div className={styles.chartContainer}>
          <div className={styles.summaryContainer}>
            <div className={styles.titleContainer}>
              {t("share_link_analytics.activity_in_last_thirty_days")}
            </div>
            <div className={styles.totalsContainer}>
              <div className={styles.clicksContainer}>
                <div className={styles.clicksTitle}>
                  {t("share_link_analytics.total_clicks")}
                </div>
                <div className={styles.clicksTotal}>
                  {summaryData.totalClicks}
                </div>
              </div>
              <div className={styles.downloadsContainer}>
                <div className={styles.downloadsTitle}>
                  {t("share_link_analytics.total_downloads")}
                </div>
                <div className={styles.downloadsTotal}>
                  {summaryData.totalDownloads}
                </div>
              </div>
            </div>
          </div>
          <div>
            <canvas id="myChart" ref={chartRef} />
          </div>
        </div>
        <div className={styles.topLinksContainer}>
          <TopShareLinks
            shareLinkData={shareLinkData}
            topLinks={topLinks}
            showActivityLog={showActivityLog}
          />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomHeaderContainer}>
          <div className={styles.bottomHeader}>
            {t("share_link_analytics.share_link_activity")}
          </div>
          <div className={styles.shareLinkSearchContainer}>
            <UikInput
              id="shareLink-search"
              placeholder={"Search"}
              className={
                searchTerm.length === 0
                  ? styles.searchInputFieldEmpty
                  : styles.searchInputFieldActive
              }
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            ></UikInput>
            <img
              className={
                searchTerm.length === 0
                  ? styles.closeIconHide
                  : styles.closeIconShow
              }
              onClick={() => clearSearch()}
              src={closeBlack}
            />
          </div>
        </div>
        <ShareLinksTable
          shareLinkData={shareLinkData}
          noSearchResults={noSearchResults}
          showActivityLog={showActivityLog}
        />
      </div>
    </Container>
  );
};
