import ActionTypes from "./types";
import httpClient from "../../lib/HttpClient";

export default (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await httpClient.get(
        `/documents/${id}/document_claims.json`
      );
      dispatch({
        type: ActionTypes.FETCH_DOCUMENT_CLAIMS,
        payload: response.data,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
