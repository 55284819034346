import React from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";

export const Modal = ({ visible, hideModal, type, children }) => {
  if (!visible) {
    return null;
  }

  let className = `modal-body modal-body-${type}`;

  return ReactDOM.createPortal(
    <div onClick={hideModal} className="modal">
      <div onClick={(e) => e.stopPropagation()} className={className}>
        {children}
      </div>
    </div>,
    document.querySelector("#modal")
  );
};
