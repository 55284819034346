import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";

import setGlobalSearchValue from "../../../../actions/GlobalSearch/setGlobalSearchValue";
import fetchGlobalSearchResults from "../../../../actions/GlobalSearch/fetchGlobalSearchResults";
import resetGlobalSearchResults from "../../../../actions/GlobalSearch/resetGlobalSearchResults";

import SearchIcon from "../../../../images/icons/svg/search.svg";
import CrossIcon from "../../../../images/icons/svg/cross-light-grey.svg";

import styles from "./GlobalSearch.module.scss";
import dropdownStyles from "./GlobalSearchDropdown.module.scss";
import viewMoreStyles from "./ViewMore.module.scss";
import GlobalSearchDropdown from "./GlobalSearchDropdown";
import useDebouncer from "../../../../hooks/useDebouncer";

export default () => {
  const { searchValue, searchResults } = useSelector((state) => {
    return {
      searchValue: state.globalSearchValue,
      searchResults: state.globalSearchResults,
    };
  });

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchFilter, setSearchFilter] = useState(null);
  const [inputValue, setInputValue] = useState(searchValue);
  const inputElement = useRef(null);

  const dispatch = useDispatch();
  const actionCreators = bindActionCreators(
    {
      setGlobalSearchValue,
      fetchGlobalSearchResults,
      resetGlobalSearchResults,
    },
    dispatch
  );

  const debouncedInputValue = useDebouncer(inputValue);
  const { t } = useTranslation();

  useEffect(() => {
    if (searchValue) {
      actionCreators.fetchGlobalSearchResults(searchValue, searchFilter);
    }
  }, [searchValue, searchFilter]);

  useEffect(() => {
    if (!debouncedInputValue) {
      actionCreators.resetGlobalSearchResults();
    }
    actionCreators.setGlobalSearchValue(debouncedInputValue);
  }, [debouncedInputValue]);

  const handleBlur = (e) => {
    if (e.relatedTarget && e.relatedTarget.id === "global-search-dropdown") {
      inputElement.current.focus();
      return;
    } else if (
      e.relatedTarget &&
      (e.relatedTarget.className === dropdownStyles.link ||
        e.relatedTarget.className === viewMoreStyles.link)
    ) {
      return;
    }
    setSearchFilter(null);
    setShowDropdown(false);
  };

  return (
    <div className={styles.container}>
      <img className={styles.searchIcon} src={SearchIcon} />
      <input
        ref={inputElement}
        type="text"
        placeholder={t("common_phrases.search_workspace")}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
        onFocus={() => setShowDropdown(true)}
        onBlur={handleBlur}
        role="presentation"
        autocomplete="off"
      />
      {inputValue && (
        <img
          className={styles.crossIcon}
          src={CrossIcon}
          onClick={() => setInputValue("")}
        />
      )}
      {showDropdown && (
        <GlobalSearchDropdown
          activeFilter={searchFilter}
          setFilter={setSearchFilter}
          searchResults={searchResults}
          hideDropdown={() => setShowDropdown(false)}
          inputValue={inputValue}
        />
      )}
    </div>
  );
};
