/* External libraries */
import React from "react";
import { createForm, formShape } from "rc-form";
import Tooltip from "../../Common/Tooltip";
import check from "../../../images/icons/svg/Check_thick_gray.svg";
import security from "../../../services/Security";
import httpClient from "../../../lib/HttpClient";
import "../../../animate.css";
import notificationService from "../../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikDivider,
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";
import styles from "./PersonalInfoEditForm.module.scss";

/* Variables */

class PersonalInfoEditFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentUserId: security.getUserId(),
      currentUser: null,
      libraries: null,
      role: security.getUserRole(),
    };
  }

  componentWillMount = () => {
    httpClient.get("/users.json").then((response) => {
      response.data.map((user, index) => {
        if (user.id == this.state.currentUserId) {
          this.setState({ currentUser: user });
          this.setUserLibraries();
        }
      });
    });
  };

  setUserLibraries() {
    this.setState({
      libraries: this.state.currentUser.libraries.map(
        (library) => library.name
      ),
    });
  }

  handleSave(e) {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        let formData = {
          first_name: values[`first_name${this.state.currentUser.id}`],
          last_name: values[`last_name${this.state.currentUser.id}`],
          title: values[`title${this.state.currentUser.id}`],
        };
        if (this.state.currentUser.role === "admin") {
          formData.email = values[`email${this.state.currentUser.id}`];
        }
        const userFormData = {
          user: formData,
        };
        if (this.state.role) {
          userFormData.user.role = this.state.role;
          console.log(userFormData);
        }
        httpClient
          .put(`/users/${this.state.currentUser.id}.json`, userFormData)
          .then((response) => {
            console.log("saved!");
            notificationService.addNotification(
              "User Saved",
              "Your profile changes have been saved",
              "success"
            );
          });
      } else {
        console.log("error", error, values);
      }
    });
  }

  render() {
    if (!this.state.currentUser) {
      return false;
    }
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.editUserContainer}>
        <UikWidget className={styles.widget}>
          <div className={styles.editActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator(`first_name${this.state.currentUser.id}`, {
                initialValue: this.state.currentUser.first_name,
                rules: [
                  {
                    required: true,
                    message: "Please enter a first name.",
                  },
                ],
              })(
                <UikInput
                  label="FIRST NAME"
                  placeholder="Mary"
                  errorMessage={(
                    getFieldError(`first_name${this.state.currentUser.id}`) ||
                    []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`last_name${this.state.currentUser.id}`, {
                initialValue: this.state.currentUser.last_name,
                rules: [
                  {
                    required: true,
                    message: "Please enter a last name.",
                  },
                ],
              })(
                <UikInput
                  label="LAST NAME"
                  placeholder="Smith"
                  errorMessage={(
                    getFieldError(`last_name${this.state.currentUser.id}`) || []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`title${this.state.currentUser.id}`, {
                initialValue: this.state.currentUser.title,
                rules: [
                  {
                    required: true,
                    message: "Please enter a title.",
                  },
                ],
              })(
                <UikInput
                  label="TITLE"
                  placeholder="Medical Writer"
                  errorMessage={(
                    getFieldError(`title${this.state.currentUser.id}`) || []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`email${this.state.currentUser.id}`, {
                initialValue: this.state.currentUser.email,
                rules: [
                  {
                    required: true,
                    message: "Please enter an email.",
                  },
                  {
                    type: "email",
                    message: "Must be in the format name@company.com",
                  },
                ],
              })(
                <UikInput
                  disabled={true}
                  label="EMAIL ADDRESS"
                  placeholder="you@company.com"
                  errorMessage={(
                    getFieldError(`email${this.state.currentUser.id}`) || []
                  ).join(", ")}
                />
              )}
            </UikFormInputGroup>
          </div>
          <div className={styles.editActionButtons}>
            <div className={styles.formLabel}>PERMISSIONS</div>
            <div className={styles.permissionsContainer}>
              <p className={styles.role}>{this.state.role}</p>
              {this.state.currentUser.role === "admin" && (
                <Tooltip
                  type="permissions"
                  allowedPermissions={[
                    "View Draft and Approved content",
                    "Leave comments and link references",
                    "Submit approval",
                    "Edit list of Reviewers, and release content",
                    "Upload, Edit and delete content",
                    "Add and edit users",
                    "Manage Library access and deactivate users",
                    "Add, edit and delete Libraries",
                  ]}
                  disallowedPermissions={[]}
                />
              )}
              {this.state.currentUser.role === "author" && (
                <Tooltip
                  type="permissions"
                  allowedPermissions={[
                    "View Draft and Approved content",
                    "Leave comments and link references",
                    "Submit approval",
                    "Edit list of Reviewers, and release content",
                    "Upload, edit and delete content",
                    "Add and edit users",
                  ]}
                  disallowedPermissions={[
                    "Manage Library access and deactivate users",
                    "Add, edit and delete Libraries",
                  ]}
                />
              )}
              {this.state.currentUser.role === "viewer" && (
                <Tooltip
                  type="permissions"
                  allowedPermissions={[
                    "View Draft and Approved content",
                    "Leave comments and link references",
                    "Submit approval",
                  ]}
                  disallowedPermissions={[
                    "Edit list of Reviewers, and release content",
                    "Upload, edit and delete content",
                    "Add and edit users",
                    "Manage Library access and deactivate users",
                    "Add, edit and delete Libraries",
                  ]}
                />
              )}
            </div>
          </div>
          {this.state.libraries && (
            <div className={styles.editActionButtons}>
              <div className={styles.formLabel}>LIBRARY ACCESS</div>
              <ul>
                {this.state.libraries.map((libraryName) => (
                  <li className={styles.libraryCheckbox}>
                    <img className={styles.libraryCheck} src={check} />
                    <p className={styles.libraryName}>{libraryName}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <UikDivider className={styles.lastDivider} />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleSave(e)}
              primary
            >
              Save
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

export const PersonalInfoEditForm = createForm()(PersonalInfoEditFormBuild);
