class References {
  constructor() {}
  reloadReferences = (payload = {}) => {
    document.dispatchEvent(new CustomEvent("reload_references", payload));
  };
  decrementReferences = () => {
    document.dispatchEvent(new CustomEvent("decrement_references", {}));
  };
}

export default new References();
