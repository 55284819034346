/* External libraries */
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";
import RadioButton from "../Common/RadioButton";
import Tooltip from "../Common/Tooltip";
import Checkbox from "../Common/Checkbox";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox,
  UikToggle,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./ContactCreateForm.module.css";
import Modal from "../Common/Modal";
import Button from "../Common/Buttons/ButtonPrimary";

/* Variables */
const animatedComponents = makeAnimated();

class ContactCreateFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor() {
    super();

    this.state = {
      createContactSubscribed: true,
    };
  }

  componentWillMount() {
    //
  }

  handleCreate = () => {
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const contactFormData = {
          contact: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            title: values.title,
            company: values.company,
            subscribed: this.state.createContactSubscribed,
          },
        };

        if (this.state.selectedContactSegments) {
          contactFormData["contact"]["selected_contact_segments"] =
            this.state.selectedContactSegments;
        }

        this.props.handleContactCreate(contactFormData);
      } else {
        console.log("error", error, values);
      }
    });
  };

  handleCancel = (e) => {
    history.push("/contacts");
  };

  handleSubscribed = (choice) => {
    this.setState({
      createContactSubscribed: choice,
    });
  };

  handleContactSegmentsSelection = (newValue: any, actionMeta: any) => {
    if (newValue) {
      const segmentsCheck = {};
      let segmentsList = [];

      newValue.forEach((segment) => {
        if (!segmentsCheck[segment["value"]]) {
          // Add it to the segment list
          segmentsList.push(segment.value);
          segmentsCheck[segment["value"]] = true;
        }
      });

      this.setState({ selectedContactSegments: segmentsList });
      this.setState({ selectedContactSegmentsList: newValue });
    }
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;
    const customSelectStyles = {
      control: (base) => ({
        ...base,
        maxHeight: 100,
        overflow: "auto",
        autoComplete: "off",
        role: "presentation",
      }),
    };

    return (
      <div className={styles.inviteContactContainer}>
        <UikWidget>
          <div className={styles.inviteContainer}>
            <UikHeadline>Create Contact</UikHeadline>
          </div>
          <UikDivider />
          <div className={styles.inviteActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a first name.",
                  },
                ],
              })(
                <UikInput
                  label="FIRST NAME"
                  placeholder="Mary"
                  errorMessage={(getFieldError("first_name") || []).join(", ")}
                />
              )}
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a last name.",
                  },
                ],
              })(
                <UikInput
                  label="LAST NAME"
                  placeholder="Smith"
                  errorMessage={(getFieldError("last_name") || []).join(", ")}
                />
              )}
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Please enter an email.",
                  },
                  {
                    type: "email",
                    message: "Must be in the format name@company.com",
                  },
                ],
              })(
                <UikInput
                  label="EMAIL ADDRESS"
                  placeholder="you@company.com"
                  errorMessage={(getFieldError("email") || []).join(", ")}
                />
              )}
              {getFieldDecorator("title", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a title.",
                  },
                ],
              })(
                <UikInput
                  label="TITLE"
                  placeholder="Healthcare Professional"
                  errorMessage={(getFieldError("title") || []).join(", ")}
                />
              )}
              {getFieldDecorator("company", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <UikInput
                  label="COMPANY"
                  placeholder=""
                  errorMessage={(getFieldError("company") || []).join(", ")}
                />
              )}
              <div>
                <div className={styles.header}>
                  <span
                    className={`uik-content-title__wrapper`}
                    style={{ display: "block" }}
                  >
                    Segments
                  </span>
                </div>
                <Select
                  isMulti
                  isClearable={false}
                  components={animatedComponents}
                  styles={customSelectStyles}
                  options={this.props.contactSegmentsSelectList}
                  onChange={this.handleContactSegmentsSelection}
                />
              </div>
              <div className={styles.editActionButtons}>
                <UikToggle
                  defaultChecked={true}
                  label="Subscribed"
                  onChange={(e) => this.handleSubscribed(e.target.checked)}
                />
              </div>
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.inviteActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleCreate(e)}
              primary
            >
              Create Contact
            </UikButton>
            <UikButton
              className="floatRight"
              onClick={(e) => this.handleCancel(e)}
            >
              Cancel
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

const ContactCreateForm = createForm()(ContactCreateFormBuild);
export default ContactCreateForm;
