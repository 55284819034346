import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./LibraryListItem.module.css";

const LibraryListItem = ({ library, selectedId, onSelect }) => (
  <Link to={`/libraries/${library.id}`}>
    <div
      className={`${styles.userListItem} ${
        selectedId == library.id ? styles.selected : ""
      }`}
      onClick={() => onSelect(library)}
    >
      <div className={styles.userListItemNameplate}>
        <span className={styles.userListItemNameplateName}>{library.name}</span>
        <br />
        <span className={styles.userListItemNameplateTitle}>
          {library.number_of_users ? library.number_of_users : "0"} users
        </span>
      </div>
    </div>
  </Link>
);

LibraryListItem.propTypes = {
  library: PropTypes.shape({
    name: PropTypes.number,
  }).isRequired,
  selectedId: PropTypes.string,
};

export default LibraryListItem;
