/* External libraries */
import React from "react";
import { Container, Row, Col, Hidden } from "react-grid-system";
import { createForm, formShape } from "rc-form";
import notificationService from "../../services/Notifications";

import axios from "../../lib/axios-client";
import history from "../../history";
import HubspotChat from "../Common/HubspotChat";

/* UI Kit */
import { UikFormInputGroup, UikInput, UikButton } from "@uik";
import "@uik/styles.css";

/* Papercurve Components */

/* Assets */
import styles from "./PasswordForgot.module.scss";
import "./PasswordForgot.scss";
import papercurveLogo from "../../images/logos/papercurve-logo-colour.svg";
import Button from "../Common/Buttons/ButtonPrimary";
import loginImageDesktop1x from "../../images/authentication/images/login-image-desktop.png";
import loginImageDesktop2x from "../../images/authentication/images/login-image-desktop@2x.png";
import loginImageDesktop3x from "../../images/authentication/images/login-image-desktop@3x.png";

class PasswordForgotBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      invalidEmail: false,
    };
  }

  componentWillMount = () => {
    this.emailDecorator = this.props.form.getFieldDecorator("email", {
      initialValue: "",
      rules: [
        {
          required: true,
          message: "Please enter your email.",
        },
      ],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      this.setState({ invalidEmail: false });
      if (!error) {
        const { email } = values;

        let response;

        axios
          .post("/password.json", { user: { email } })
          .then((response) => {
            this.props.history.push({
              pathname: "/login",
              state: {
                resetPassword: true,
              },
            });
          })
          .catch((error) => {
            console.log("error", error);
            notificationService.addNotification(
              "Incorrect email.",
              "Email address is invalid",
              "warning"
            );
          });
      } else {
        console.log("error", error, values);
      }
    });
  };

  render() {
    const { getFieldError } = this.props.form;

    return (
      <div className="password-forgot-page">
        <div className="hs-chat-container">
          <HubspotChat />
        </div>
        <div className="body">
          <div className="container">
            <img src={papercurveLogo} />
            <div className="email-content">
              <div className="title">Reset your password</div>
              <div className="description">
                Enter your email address and we’ll send you an email with
                instructions to reset your password
              </div>
              <UikFormInputGroup>
                {this.emailDecorator(
                  <UikInput
                    label="Email address"
                    placeholder="email@company.com"
                    onKeyPress={this.handleKeyPress}
                    errorMessage={(getFieldError("email") || []).join(", ")}
                  />
                )}
                <Button
                  original
                  text="Send email"
                  onClick={this.handleSubmit}
                />
              </UikFormInputGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PasswordForgot = createForm()(PasswordForgotBuild);
export default PasswordForgot;
