/* External libraries */
import React from "react";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../../history";

import httpClient from "../../../lib/HttpClient";

import security from "../../../services/Security";
import RadioButton from "../../Common/RadioButton";
import Tooltip from "../../Common/Tooltip";
import Checkbox from "../../Common/Checkbox";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox,
  UikToggle,
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";
import styles from "./SegmentCreateForm.module.css";
import Modal from "../../Common/Modal";
import Button from "../../Common/Buttons/ButtonPrimary";

/* Variables */

class SegmentCreateFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      invalidSegmentCreateForm: false,
      currentSegment: {
        role: currentUserRole,
      },
      roles: [
        { value: "viewer", label: "Viewer" },
        { value: "author", label: "Author" },
        { value: "admin", label: "Admin" },
      ],
    };
  }

  componentWillMount() {
    //
  }

  handleCreateButtonClicked(e) {
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const segmentFormData = {
          contact_segment: {
            name: values.name,
          },
        };

        this.props.handleSegmentCreate(segmentFormData);
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleCancel = (e) => {
    history.push("/contacts/segments");
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.inviteSegmentContainer}>
        <UikWidget>
          <div className={styles.inviteContainer}>
            <UikHeadline>Create Segment</UikHeadline>
          </div>
          <UikDivider />
          <div className={styles.inviteActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a segment name.",
                  },
                ],
              })(
                <UikInput
                  label="NAME"
                  placeholder=""
                  errorMessage={(getFieldError("name") || []).join(", ")}
                />
              )}
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.inviteActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleCreateButtonClicked(e)}
              primary
            >
              Create Segment
            </UikButton>
            <UikButton
              className="floatRight"
              onClick={(e) => this.handleCancel(e)}
            >
              Cancel
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

const SegmentCreateForm = createForm()(SegmentCreateFormBuild);
export default SegmentCreateForm;
