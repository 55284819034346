import GlobalSearchActionTypes from "../../actions/GlobalSearch/types";

export default (globalSearchValue = "", action) => {
  switch (action.type) {
    case GlobalSearchActionTypes.SET_GLOBAL_SEARCH_VALUE:
      return action.payload;
    default:
      return globalSearchValue;
  }
};
