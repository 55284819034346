import Cookies from "universal-cookie";
import httpClient from "../lib/HttpClient";

class AccountSetting {
  constructor() {
    this.cookies = new Cookies();
    this.settings = {};
    this.getSettings();
  }

  getSettings = async () => {
    await httpClient.get(`/account_settings.json`).then((res) => {
      this.settings = { settings: res.data };
      this.cookies.set("account_settings", res.data, { path: "/" });
    });
  };

  getSetting = (name) => {
    let setting = false;

    if (this.cookies.get("account_settings")) {
      setting = this.cookies
        .get("account_settings")
        .find((setting) => setting["name"] === name);
    }

    return setting ? setting.value : undefined;
  };

  all = () => {
    return this.cookies.get("account_settings");
  };
}

export default new AccountSetting();
