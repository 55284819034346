/* External libraries */
import React from "react";
import Cookies from "universal-cookie";
import { Container, Row, Col, Hidden } from "react-grid-system";
import { createForm, formShape } from "rc-form";
import Setup2FA from "../2FA/Setup2FA";
import Verify2FA from "../2FA/Verify2FA";

import history from "../../history";
import { apiUrl } from "../../lib/axios-client";
import axios from "axios";

import security from "../../services/Security";
import notificationService from "../../services/Notifications";

/* UI Kit */
import { UikFormInputGroup, UikInput, UikButton } from "@uik";
import "@uik/styles.css";

/* Assets */
import "./Login.css";
import "./Login.scss";
import HubspotChat from "../Common/HubspotChat";
import papercurveLogo from "../../images/logos/papercurve-logo-colour.svg";
import warningLogo from "../../images/icons/svg/warning-white.svg";
import Button from "../Common/Buttons/ButtonPrimary";
import externalLink from "../../images/icons/svg/external-link-icon-3.svg";
import needHelp from "../../images/icons/svg/need-help-icon.svg";

/* Variables */
const cookies = new Cookies();

class LoginForm extends React.Component {
  static propTypes = {
    form: formShape,
  };

  componentWillMount() {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    this.setState({
      step: "confirmUsernamePassword",
      isIE: isIE,
    });
    this.emailDecorator = this.props.form.getFieldDecorator("email", {
      initialValue: "",
      rules: [
        {
          required: true,
          message: "Please enter your email.",
        },
        {
          message: "Must be in email format ex. name@company.com",
          pattern:
            /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
        },
      ],
    });

    this.passwordDecorator = this.props.form.getFieldDecorator("password", {
      initialValue: "",
      rules: [
        {
          required: true,
          message: "Please enter your password.",
        },
      ],
    });
  }

  componentDidMount() {
    // <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/3422830.js"></script>
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.src = "//js.hs-scripts.com/3422830.js";
    script.async = "true";
    script.defer = "true";

    document.body.appendChild(script);
    console.log(this.props);
    if (this.props.location.state && this.props.location.state.resetPassword) {
      notificationService.addNotification(
        "Password reset link sent.",
        "A password reset link has been sent to your email address.",
        "success"
      );
    } else if (
      this.props.location.state &&
      this.props.location.state.passwordCreated
    ) {
      notificationService.addNotification(
        "Password created successfully.",
        "Your password was successfully created.",
        "success"
      );
    } else if (
      this.props.location.state &&
      this.props.location.state.inviteSent
    ) {
      notificationService.addNotification(
        "New invitation email sent",
        "A new invitation has been successfully sent to your email.",
        "success"
      );
    }
  }

  handleLogin = () => {
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const { email, password } = values;

        let response;

        axios
          .post(apiUrl + "/2fa/status", {
            user: { email: email.trim(), password },
          })
          .then((response) => {
            this.setState({ email: email.trim(), password });
            if (response.data.twofa_enabled === "true") {
              if (response.data.twofa_registered) {
                this.setState({
                  step: "verify2FA",
                  phoneNumber: response.data.phone_number,
                  countryCode: response.data.country_code,
                  hideUpdatePhoneNumber: true,
                });
              } else {
                this.setState({ step: "setup2FA" });
              }
            } else {
              axios
                .post(
                  apiUrl + "/login.json",
                  { user: { email: this.state.email, password } },
                  { withCredentials: true }
                )
                .then((response) => {
                  const session = response.headers.authorization.replace(
                    /Bearer /,
                    ""
                  );
                  security.setToken(session);
                  security.redirectAfterLogin();
                });
            }
          })
          .catch((error) => {
            let message = "There is a problem with your login or password";
            if (error.response.data.error) {
              message = error.response.data.error;
            }
            notificationService.addNotification(
              "Login failed.",
              message,
              "warning"
            );
          });
      } else {
        console.log("error", error, values);
      }
    });
  };

  onSetup2faSuccess = (countryCode, phoneNumber) => {
    this.setState({
      countryCode,
      phoneNumber,
      step: "verify2FA",
    });
  };

  updatePhoneNumber = () => {
    axios
      .post(apiUrl + "/2fa/delete", {
        user: {
          email: this.state.email,
          password: this.state.password,
        },
      })
      .then((res) => {
        this.setState({
          step: "setup2FA",
        });
      });
  };

  loginUser = (jwt) => {
    security.setToken(jwt);
    security.redirectAfterLogin();
  };

  handleKeyPress = (e) => {
    if (e.key == "Enter") {
      this.handleLogin();
    }
  };

  renderIeMessage = () => {
    if (this.state.isIE) {
      return (
        <div className="messageContainer">
          <div className="headingContainer">
            <img src={warningLogo} />
            <h3 className="warningHeading">Your browser is not supported</h3>
          </div>
          <p className="warningMessage">
            Unfortunately, we do not support Internet Explorer. For a better
            experience, please use Chrome, Safari, Firefox or Edge.
          </p>
        </div>
      );
    }
  };

  render() {
    const { getFieldError, getFieldValue } = this.props.form;

    const parts = window.location.hostname.split(".");
    const subdomain = parts.shift();

    return (
      <div className="login-page">
        <div className="hs-chat-container">
          <HubspotChat />
        </div>
        <div className="body">
          {this.renderIeMessage()}
          <div className="container">
            <div className="logoContainer">
              <img src={papercurveLogo} />
            </div>
            {this.state.step === "confirmUsernamePassword" && (
              <div id="loginPrompt" className="email-password-content">
                <UikFormInputGroup className="loginForm">
                  {this.emailDecorator(
                    <UikInput
                      className="emailInput"
                      disabled={this.state.isIE ? true : false}
                      label="Email address"
                      placeholder="email@company.com"
                      onKeyPress={this.handleKeyPress}
                      errorMessage={(getFieldError("email") || []).join(", ")}
                    />
                  )}
                  {this.passwordDecorator(
                    <UikInput
                      role="presentation"
                      autocomplete="off"
                      disabled={this.state.isIE ? true : false}
                      autocapitalize="none"
                      label="Password"
                      placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                      type="password"
                      onKeyPress={this.handleKeyPress}
                      errorMessage={(getFieldError("password") || []).join(
                        ", "
                      )}
                    />
                  )}
                  <Button
                    disabled={this.state.isIE ? true : false}
                    original
                    text="Log in"
                    onClick={this.handleLogin}
                  />
                </UikFormInputGroup>
                <div className="forgot-password">
                  <button
                    onClick={() => (window.location.href = "/password/forgot")}
                  >
                    Forgot your password?
                  </button>
                </div>
              </div>
            )}
            {this.state.step === "setup2FA" && (
              <div className="setup-2fa">
                <div className="title">Enable two factor authentication</div>
                <div className="description">
                  Whenever you sign in with your password, we'll send you a code
                  via SMS.
                </div>
                <Setup2FA
                  email={this.state.email}
                  password={this.state.password}
                  previousPhoneNumber={`${this.state.countryCode}${this.state.phoneNumber}`}
                  onSuccess={this.onSetup2faSuccess}
                />
              </div>
            )}
            {this.state.step === "verify2FA" && (
              <div className="verify-2fa">
                <div className="title">Verify your account</div>
                <div className="description">
                  {`We sent a verification code to phone number`}
                  <div>
                    {`+${
                      this.state.countryCode
                    } (\u2022\u2022\u2022) \u2022\u2022\u2022 ${this.state.phoneNumber.replace(
                      /.*(?=\d{4})/g,
                      ""
                    )} `}
                  </div>
                  {!this.state.hideUpdatePhoneNumber && (
                    <span
                      className="update-phone-number"
                      onClick={this.updatePhoneNumber}
                    >
                      Use a different phone number?
                    </span>
                  )}
                </div>
                <Verify2FA
                  email={this.state.email}
                  password={this.state.password}
                  onSuccess={this.loginUser}
                />
              </div>
            )}
          </div>
          <div className="container kbContainer">
            <div className="header">
              <img src={needHelp} className="needHelpIcon" />
              Need Help?
            </div>
            Visit our Knowledge Base website for guides and walk throughs!
            <div className="linkToKb">
              <a
                href={`https://help.papercurve.com?utm_source=${subdomain}&utm_medium=app&utm_campaign=knowledge_base_inapp&utm_content=Login+Screen`}
                target="_blank"
                className="link"
              >
                <span className="linkText">View Knowledge Base</span>
                <img src={externalLink} className="externalLinkIcon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Login = createForm()(LoginForm);
export default Login;
