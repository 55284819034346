/* External libraries */
import React from "react";
import { createForm, formShape } from "rc-form";
import Select, { StylesConfig } from "react-select";
import Tooltip from "../../Common/Tooltip";
import check from "../../../images/icons/svg/Check_thick_gray.svg";
import security from "../../../services/Security";
import httpClient from "../../../lib/HttpClient";
import history from "../../../history";
import "../../../animate.css";
import notificationService from "../../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikDivider,
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";
import styles from "./TeamsChangeForm.module.scss";

/* Variables */

class TeamsChangeFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentUserId: security.getUserId(),
      currentTeam: null,
      availableTeams: null,
      availableTeamsSelectList: [],
      role: security.getUserRole(),
    };
  }

  componentWillMount = () => {
    httpClient
      .get(`/users/${this.state.currentUserId}.json`)
      .then((response) => {
        let teamsList = [];
        let userTeams = response.data.teams;

        userTeams.map((team, index) => {
          teamsList.push({
            value: team.id,
            label: team.name,
          });

          if (team.id == response.data.current_team_id) {
            this.setState({
              currentSelectTeam: {
                value: team.id,
                label: team.name,
              },
            });
          }
        });

        this.setState({ availableTeams: response.data });
        this.setState({ availableTeamsSelectList: teamsList });
      });
  };

  onChange(selectedTeam) {
    this.setState({
      currentSelectTeam: {
        value: selectedTeam.value,
        label: selectedTeam.label,
      },
    });
  }

  handleSave(e) {
    httpClient
      .put(`/users/${this.state.currentUserId}/change_team.json`, {
        user: { current_team_id: this.state.currentSelectTeam.value },
      })
      .then((response) => {
        notificationService.addNotification(
          "Team changed",
          "Your team has been changed",
          "success"
        );
      });
  }

  backToCRM() {
    history.push("/contacts");
  }

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.editUserContainer}>
        <UikWidget className={styles.widget}>
          <div className={styles.editActionButtons}>
            <UikFormInputGroup>
              <Select
                value={
                  this.state.currentSelectTeam
                    ? [
                        {
                          value: this.state.currentSelectTeam.value,
                          label: this.state.currentSelectTeam.label,
                        },
                      ]
                    : []
                }
                options={this.state.availableTeamsSelectList}
                onChange={(e) => this.onChange(e)}
                //isDisabled={this.props.readOnly}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    borderColor: "#D6DCE8",
                    fontSize: "14px",
                  }),
                }}
              />
            </UikFormInputGroup>
          </div>
          <UikDivider className={styles.lastDivider} />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleSave(e)}
              primary
            >
              Save
            </UikButton>
            <UikButton
              className={styles.changeButton}
              onClick={(e) => this.backToCRM()}
            >
              Back to CRM
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

export const TeamsChangeForm = createForm()(TeamsChangeFormBuild);
