import React, { useState } from "react";
import Select from "react-select";
import styles from "./VersionComparePanel.module.scss";
import swap from "../../../images/icons/svg/swap.svg";

export default (props) => {
  const {
    changeVersionsToCompare,
    swapComparisonOrder,
    versions,
    v1,
    v2,
    error,
  } = props;
  const [versionToCompareOne, setVersionToCompareOne] = useState(v1);
  const [versionToCompareTwo, setVersionToCompareTwo] = useState(v2);

  const onChange = (versionOne, versionTwo) => {
    if (
      versionOne !== versionToCompareOne ||
      versionTwo !== versionToCompareTwo
    ) {
      if (versionOne) {
        setVersionToCompareOne(versionOne);
        if (versionToCompareTwo) {
          changeVersionsToCompare(versionOne, versionToCompareTwo);
        }
      } else if (versionTwo) {
        setVersionToCompareTwo(versionTwo);
        if (versionToCompareOne) {
          changeVersionsToCompare(versionToCompareOne, versionTwo);
        }
      }
    }
  };
  const swapComparison = () => {
    swapComparisonOrder();
    setVersionToCompareOne(versionToCompareTwo);
    setVersionToCompareTwo(versionToCompareOne);
  };

  const allExceptSelectedVersions = () => {
    let activeVersionsExcluded = [];

    versions.map((version) => {
      if (version.value.toString() !== versionToCompareOne.toString()) {
        if (version.value.toString() !== versionToCompareTwo.toString()) {
          activeVersionsExcluded.push(version);
        }
      }
    });

    return activeVersionsExcluded;
  };

  return (
    <div id="versionComparePanel" className={styles.container}>
      <div className={styles.selectVersionsContainer}>
        <h3 className={styles.heading}>Compare Versions</h3>
        <div className={styles.selectVersions}>
          <Select
            className={styles.versionSelectField}
            placeholder="Select Version"
            value={versions.find(
              (v) => v.value.toString() === versionToCompareOne.toString()
            )}
            options={allExceptSelectedVersions()}
            onChange={(e) => onChange(e.value, null)}
            isDisabled={error}
            styles={{
              control: (styles) => ({
                ...styles,
                borderColor: "#2c3041",
                fontSize: "14px",
                backgroundColor: "#2c3041",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#ffffff",
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#ffffff",
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                backgroundColor: "#2c3041",
              }),
              group: (styles) => ({
                ...styles,
                borderColor: "#2c3041",
              }),
            }}
          />
          <img
            className={styles.swapButton}
            onClick={(e) => swapComparison()}
            src={swap}
          />
          <Select
            className={styles.versionSelectField}
            placeholder="Select Version"
            value={versions.find(
              (v) => v.value.toString() === versionToCompareTwo.toString()
            )}
            options={allExceptSelectedVersions()}
            onChange={(e) => onChange(null, e.value)}
            isDisabled={error}
            styles={{
              control: (styles) => ({
                ...styles,
                borderColor: "#2c3041",
                fontSize: "14px",
                backgroundColor: "#2c3041",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#ffffff",
              }),
              placeholder: (styles) => ({
                ...styles,
                color: "#ffffff",
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                backgroundColor: "#2c3041",
              }),
            }}
          />
        </div>
      </div>
    </div>
  );
};
