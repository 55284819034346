import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";

import styles from "./Paginator.module.scss";

export default (props) => {
  const { handlePageChange, pageCount } = props;

  const handlePageClicked = (event) => {
    handlePageChange(event.selected + 1);
  };

  return (
    <div className={styles.pagination}>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={(e) => handlePageClicked(e)}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination-container"}
        activeClassName={"active-page"}
      />
    </div>
  );
};
