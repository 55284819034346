import React, { useState, useEffect } from "react";
import { Container, Row } from "react-grid-system";
import Header from "../shared/Header/Header";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import StructuredContentNav from "../shared/StructuredContentNav/StructuredContentNav";
import TableView from "../Common/TableView/TableView";
import NewItemModal from "./NewItemModal/NewItemModal";
import EditItemModal from "./EditItemModal/EditItemModal";
import EditCategoryModal from "./EditCategoryModal/EditCategoryModal";
import history from "../../history";
import security from "../../services/Security";
import httpClient from "../../lib/HttpClient";
import gearIcon from "../../images/icons/svg/gear-icon-dark.svg";
import SearchIcon from "../../images/icons/svg/search.svg";
import useDebouncer from "../../hooks/useDebouncer";
import searchStructuredContents from "../../actions/structuredContents/search";
import Lottie from "react-lottie";
import loadingAnimation from "../../images/lotties/loading.json";

import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";

import styles from "./StructuredContent.module.scss";

export const StructuredContent = (props) => {
  const [items, setItems] = useState([]);
  const [allItemsForCategory, setAllItemsForCategory] = useState([]);
  const [structuredContentCategory, setStructuredContentCategory] = useState(
    {}
  );
  const [selectedCategoryName, setSelectedCategoryName] = useState([]);
  const [showLoader, setShowLoader] = useState(null);
  const [showNewItemModal, setShowNewItemModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [categories, setCategories] = useState([]);
  const [structuredContentSearchValue, setStructuredContentSearchValue] =
    useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const selectedCategoryId = props.match.params.id;

  useEffect(() => {
    setItems([]);
    setStructuredContentSearchValue("");
    httpClient.get(`/structured_content_categories.json`).then((response) => {
      setCategories(response.data);
    });
    setShowLoader(true);
    if (selectedCategoryId > 0) {
      httpClient
        .get(`/structured_content_categories/${selectedCategoryId}.json`)
        .then((res) => {
          setStructuredContentCategory(res.data);
          setSelectedCategoryName(res.data.name);
          httpClient
            .get(`/structured_contents/category/${selectedCategoryId}.json`)
            .then((res) => {
              setShowLoader(false);
              setAllItemsForCategory(res.data);
              setItems(res.data);
            });
        });
    } else {
      setStructuredContentCategory({});
      setSelectedCategoryName("Product Claims");
      httpClient.get(`/claims.json`).then((res) => {
        setShowLoader(false);
        setAllItemsForCategory(res.data);
        setItems(res.data);
      });
    }
  }, [selectedCategoryId]);

  const debouncedSearchValue = useDebouncer(structuredContentSearchValue);

  useEffect(() => {
    handleStructuredContentSearch(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const dispatch = useDispatch();

  const boundActionCreators = bindActionCreators(
    {
      searchStructuredContents,
    },
    dispatch
  );

  const handleStructuredContentSearch = () => {
    if (debouncedSearchValue === "") {
      setItems(allItemsForCategory);
    } else {
      boundActionCreators
        .searchStructuredContents(
          debouncedSearchValue,
          structuredContentCategory.lookup
            ? structuredContentCategory.lookup
            : "product_claims"
        )
        .then((scr) => {
          setItems(scr);
        });
    }
  };

  const onSearchValueChange = (value) => {
    setStructuredContentSearchValue(value);
  };

  const handleNewCategoryItem = () => {
    history.push(`/structured_content/category/${selectedCategoryId}/new_item`);
    setShowNewItemModal(true);
  };

  const handleEditCategoryItem = () => {
    setShowEditCategoryModal(true);
  };

  const creatingNewItem = window.location.pathname.match(/new_item/)
    ? true
    : false;

  if (creatingNewItem && !showNewItemModal) {
    setShowNewItemModal(true);
  }

  const getContentProperties = () => {
    let propertiesByStructuredContent = [];
    items.forEach((sc) => {
      let propertyObj = {};

      propertyObj["fullSCObject"] = sc;
      propertyObj["Caption"] = sc.caption;
      propertyObj["Label"] = sc.label;

      if (sc.structured_content_property_values) {
        sc.structured_content_property_values.map((scpv) => {
          propertyObj[scpv.structured_content_property_label] = scpv.value;
        });
      }

      propertiesByStructuredContent.push(propertyObj);
    });
    return propertiesByStructuredContent;
  };

  const itemFields = () => {
    if (
      structuredContentCategory &&
      structuredContentCategory.column_type === "image"
    ) {
      return ["image", "caption", "label", "created_at"];
    } else {
      if (structuredContentCategory.all_property_labels) {
        return structuredContentCategory.all_property_labels;
      } else {
        if (structuredContentCategory.display_sc_property_label) {
          return [
            "caption",
            `${structuredContentCategory.display_sc_property_label}`,
            "label",
            "created_at",
          ];
        } else {
          return ["caption", "label", "created_at"];
        }
      }
    }
  };

  const editItem = (item) => {
    if (
      (structuredContentCategory &&
        structuredContentCategory.column_type === "image") ||
      (structuredContentCategory &&
        structuredContentCategory.column_type === "text")
    ) {
      setSelectedItem(item);
      setShowEditItemModal(true);
    }
  };

  return (
    <Container fluid className={styles.container}>
      <Header />
      {showNewItemModal && (
        <NewItemModal
          selectedCategoryId={selectedCategoryId}
          showNewItemModal={showNewItemModal}
          setShowNewItemModal={setShowNewItemModal}
          setItems={setItems}
          setAllItemsForCategory={setAllItemsForCategory}
        />
      )}
      {showEditItemModal && (
        <EditItemModal
          selectedCategoryId={selectedCategoryId}
          selectedCategory={structuredContentCategory}
          setEditItemModal={setShowEditItemModal}
          selectedItem={selectedItem}
          setItems={setItems}
          setAllItemsForCategory={setAllItemsForCategory}
        />
      )}
      {showEditCategoryModal && (
        <EditCategoryModal
          selectedCategoryId={selectedCategoryId}
          setShowEditCategoryModal={setShowEditCategoryModal}
          selectedItem={selectedItem}
          setCategories={setCategories}
        />
      )}
      <div className={styles.midContainer}>
        <div className={styles.row}>
          <StructuredContentNav
            categories={categories}
            setCategories={setCategories}
            selectedCategoryId={selectedCategoryId}
          />
          <div className={styles.body}>
            {!showLoader && (
              <div className={styles.header}>
                <div className={styles.searchInputContainer}>
                  <img className={styles.searchIcon} src={SearchIcon} />
                  <input
                    type="text"
                    placeholder={"Search"}
                    onChange={(e) => onSearchValueChange(e.target.value)}
                    value={structuredContentSearchValue}
                  />
                </div>
                <div>
                  {selectedCategoryId && selectedCategoryId !== 0 && (
                    <img
                      onClick={() => {
                        handleEditCategoryItem();
                      }}
                      className={styles.gearIcon}
                      src={gearIcon}
                    />
                  )}
                  {selectedCategoryId && selectedCategoryId !== 0 && (
                    <UikButton
                      onClick={() => {
                        handleNewCategoryItem();
                      }}
                      primary
                    >
                      New
                    </UikButton>
                  )}
                </div>
              </div>
            )}
            {showLoader && (
              <div className={styles.loaderContainer}>
                <Lottie options={defaultOptions} height={92} width={82} />
              </div>
            )}
            {!showLoader && (
              <TableView
                label={selectedCategoryName}
                items={
                  structuredContentCategory.column_type === "text"
                    ? getContentProperties()
                    : items
                }
                itemFields={itemFields()}
                itemClickFunction={editItem}
                largeColumns={
                  structuredContentCategory &&
                  structuredContentCategory.display_sc_property_label
                    ? [structuredContentCategory.display_sc_property_label]
                    : null
                }
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
