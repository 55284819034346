/* External libraries */
import React from "react";
import { createForm } from "rc-form";
import Button from "../Common/Buttons/ButtonPrimary";
import HubspotChat from "../Common/HubspotChat";
import httpClient from "../../lib/HttpClient";
import notificationService from "../../services/Notifications";

/* UI Kit */
import { UikFormInputGroup, UikInput } from "@uik";
import "@uik/styles.css";

/* Papercurve Components */

/* Assets */
import styles from "./PasswordLinkExpired.module.scss";
import papercurveLogo from "../../images/logos/papercurve-logo-colour.svg";

class PasswordLinkExpiredBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      resetPasswordToken: null,
    };
  }

  componentWillMount = () => {
    this.emailDecorator = this.props.form.getFieldDecorator("email", {
      initialValue: "",
      rules: [
        {
          required: true,
          message: "Please enter your email.",
        },
        {
          message: "Must be in email format ex. name@company.com",
          pattern:
            /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
        },
      ],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const { email } = values;

        const emailFormData = {
          user: {
            email: email,
          },
        };
        httpClient
          .put("/password/send_new_invite_email.json", emailFormData)
          .then(() => {
            this.props.history.push({
              pathname: "/login",
              state: {
                inviteSent: true,
              },
            });
          })
          .catch((error) => {
            notificationService.addNotification(
              "Incorrect email address",
              "The email address you entered doesn't match our records",
              "warning"
            );
          });
      } else {
        console.log("error", error, values);
      }
    });
  };

  render() {
    const { getFieldError } = this.props.form;

    return (
      <div className={styles.linkExpiredPage}>
        <div className={styles.hsChatContainer}>
          <HubspotChat />
        </div>
        <div className={styles.body}>
          <div className={styles.container}>
            <div className={styles.logoContainer}>
              <img src={papercurveLogo} />
            </div>
            <div className={styles.emailFormContainer}>
              <div className={styles.title}>
                Your invitation link has expired
              </div>
              <div className={styles.description}>
                To get a new invitation link, enter your email address and we'll
                send you a new one with instructions to set your password
              </div>
              <UikFormInputGroup className="inputGroup">
                {this.emailDecorator(
                  <UikInput
                    label="Email address"
                    placeholder="email@company.com"
                    onKeyPress={this.handleKeyPress}
                    errorMessage={(getFieldError("email") || []).join(", ")}
                  />
                )}
                <Button
                  original
                  text="Send email"
                  onClick={this.handleSubmit}
                />
              </UikFormInputGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PasswordLinkExpired = createForm()(PasswordLinkExpiredBuild);
export default PasswordLinkExpired;
