import React from "react";

import clickBlack from "../../../images/icons/svg/click-black.svg";
import downloadBlack from "../../../images/icons/svg/download-black.svg";
import shareLinkGrey from "../../../images/icons/svg/share-link-grey.svg";
import { UikButton } from "@uik";
import notificationService from "../../../services/Notifications";
import { useTranslation } from "react-i18next";

import externalLinkIcon from "../../../images/icons/svg/external-link-icon.svg";
import CopySVG from "../../../images/icons/svg/copy-icon.svg";
import FullScreen from "../../../images/icons/svg/full-screen.svg";

import styles from "./TopShareLinks.module.scss";

export default (props) => {
  const { topLinks, shareLinkData, showActivityLog } = props;

  const { useState, useEffect, createRef } = React;

  const [elRefs, setElRefs] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (topLinks.length > 0) {
      let refs = [];
      topLinks.map((link, index) => {
        refs.push(createRef());
      });

      setElRefs(refs);
    }
  }, [topLinks]);

  const onLinkHover = (linkIndex) => {
    if (elRefs[linkIndex] && elRefs[linkIndex].current) {
      elRefs[linkIndex].current.style.display = "flex";
      elRefs[linkIndex].current.style.visibility = "visible";
      elRefs[linkIndex].current.style.background = "#f7fbfc";
    }
  };

  const onLinkLeave = (linkIndex) => {
    if (elRefs[linkIndex] && elRefs[linkIndex].current) {
      elRefs[linkIndex].current.style.display = "none";
    }
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/share_links/view/${link.hash_url}`
    );

    notificationService.addNotification(
      "Link copied",
      `Your link “${link.label}” has been copied to clipboard!`,
      "success"
    );
  };

  const emptyStateStatement = () => {
    if (shareLinkData.length !== 0 && topLinks.length === 0) {
      return "No share links have been accessed this week";
    } else {
      return "There are no share links created";
    }
  };

  const renderEmptyState = () => {
    return (
      <div className={styles.emptyStateContainer}>
        {
          <div className={styles.emptyMessageContainer}>
            <img className={styles.shareLinkGrey} src={shareLinkGrey} />
            {emptyStateStatement()}
          </div>
        }
      </div>
    );
  };

  const viewContent = (docId) => {
    window.open(`/documents/${docId}`);
  };

  const renderTopShareLinks = () => {
    return (
      <div className={styles.topShareLinksContainer}>
        <div className={styles.topLinksHeaderContainer}>
          <h1 className={styles.titleContainer}>
            {t("share_link_analytics.top_share_links_this_week")}
          </h1>
        </div>
        {topLinks.length === 0 && renderEmptyState()}
        {topLinks
          .sort((a, b) => b.link_popularity_score - a.link_popularity_score)
          .slice(0, 5)
          .map((link, index) => {
            return (
              <div
                key={index}
                className={styles.eventDetails}
                onMouseEnter={() => onLinkHover(index)}
                onMouseLeave={() => onLinkLeave(index)}
              >
                <div className={styles.linkDetailsContainer}>
                  <div className={styles.linkLabelContainer}>{link.label}</div>
                  <div className={styles.linkDocumentNameContainer}>
                    {link.document_name}
                  </div>
                </div>
                <div className={styles.linkAnalyticsContainer}>
                  <div className={styles.linkDownloadsContainer}>
                    <img
                      className={styles.linkStatIcon}
                      src={downloadBlack}
                    ></img>
                    {link.share_link_analytics.downloads.length}
                  </div>
                  <div className={styles.linkClicksContainer}>
                    <img className={styles.linkStatIcon} src={clickBlack}></img>
                    {link.share_link_analytics.views.length}
                  </div>
                </div>
                <div className={styles.hoverStateContainer} ref={elRefs[index]}>
                  <div className={styles.linksContainer}>
                    <UikButton
                      className={styles.viewContentButton}
                      onClick={() => viewContent(link.document_id)}
                    >
                      {t("share_link_analytics.view_content")}
                      <img
                        className={styles.externalLink}
                        src={externalLinkIcon}
                      />
                    </UikButton>
                    <UikButton
                      className={styles.exportButton}
                      onClick={(e) => copyLink(link)}
                    >
                      {t("share_link_analytics.copy_link")}
                      <img className={styles.externalLink} src={CopySVG} />
                    </UikButton>
                    <UikButton
                      className={styles.exportButton}
                      onClick={(e) => showActivityLog(link)}
                    >
                      {t("share_link_analytics.view_activity_log")}
                      <img className={styles.externalLink} src={FullScreen} />
                    </UikButton>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };
  return (
    <div className={styles.topShareLinksContainer}>{renderTopShareLinks()}</div>
  );
};
