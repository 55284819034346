import React from "react";
import { Col } from "react-grid-system";
import styles from "./ManageAlerts.module.scss";
import { UikDivider, Uikon, UikToggle } from "@uik";
import { Link } from "react-router-dom";
import Security from "../../../services/Security";
import httpClient from "../../../lib/HttpClient";

class ManageAlerts extends React.Component {
  constructor() {
    super();
    this.state = {
      userId: Security.getUserId(),
      notificationPreferences: null,
    };
  }

  componentDidMount = () => {
    this.getNotificationPreferences();
  };

  getNotificationPreferences = () => {
    httpClient
      .get(`/user_notifications_settings/${this.state.userId}.json`)
      .then((response) => {
        this.setState({ notificationPreferences: response.data });
      });
  };

  updateNotificationPreference = (notification, preference) => {
    const notificationDictionary = this.getNotificationDictionary();
    const notificationData = {};
    notificationData[notificationDictionary[notification]] = preference;

    httpClient
      .put(
        `/user_notifications_settings/${this.state.userId}.json`,
        notificationData
      )
      .then((response) => {
        this.setState({ notificationPreferences: response.data });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getNotificationDictionary = () => {
    return {
      "Content deleted": "deleted_content",
      "New version requested": "rejected_content",
      "Content released to a library": "released_content_to_library",
      "All requested approvals completed": "completed_all_requested_approvals",
      "Share link opened / downloaded": "share_link_activity",
    };
  };

  renderNotificationToggle = (notification) => {
    const notificationDictionary = this.getNotificationDictionary();
    let notificationKey;
    notificationKey = notificationDictionary[notification];
    return (
      <div className={styles.toggleContainer}>
        <UikToggle
          className={"toggle"}
          label={notification}
          checked={
            this.state.notificationPreferences &&
            this.state.notificationPreferences[notificationKey]
          }
          onClick={(e) => {
            this.updateNotificationPreference(notification, e.target.checked);
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <Col sm={12} md={5}>
        <div className={styles.backContainer}>
          <Uikon>
            <Link
              to={"/notifications"}
              onClick={() => {
                this.props.renderNotificationsMenu();
              }}
              className={styles.backLink}
            >
              arrow_left
            </Link>
          </Uikon>
          <p className={styles.screenSubHeading}>Notifications</p>
        </div>
        <div className={styles.block}>
          <div className={styles.content}>
            <div className={styles.heading}>
              <div>Content Alerts</div>
              <div className={styles.description}>
                Customize the email notifications you get about your content
              </div>
            </div>
          </div>
          <UikDivider />
          <div className={styles.notificationsContainer}>
            {Object.keys(this.getNotificationDictionary()).map(
              (notification) => {
                return this.renderNotificationToggle(notification);
              }
            )}
          </div>
        </div>
      </Col>
    );
  }
}

export default ManageAlerts;
