/* External libraries */
import React from "react";
import ReactNotification from "react-notifications-component";
import PropTypes from "prop-types";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import security from "../../services/Security";

import httpClient from "../../lib/HttpClient";
import "../../animate.css";

import notificationService from "../../services/Notifications";
import RadioButton from "../Common/RadioButton";
import Tooltip from "../Common/Tooltip";
import Checkbox from "../Common/Checkbox";
import Button from "../Common/Buttons/ButtonPrimary";
import Modal from "../Common/Modal";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox,
  UikToggle,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./UserEditForm.module.css";

/* Variables */

class UserEditFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor(props) {
    super(props);

    const currentUserRole = security.getUserRole();
    const currentUserSupport = security.isPapercurveUser();

    this.state = {
      currentUser: {
        role: currentUserRole,
        papercurveUser: currentUserSupport,
      },
      roles: [
        { value: "viewer", label: "Viewer" },
        { value: "author", label: "Author" },
        { value: "admin", label: "Admin" },
      ],
      libraries: [],
      selectedLibraries: [],
      role: props.user.role,
      invitationSent: false,
      showReset2faModal: false,
      editUserPapercurveEmployee: props.user.papercurve_user,
    };
  }

  componentWillMount = () => {
    // Only Authors and Admins can see users screen
    if (
      this.state.currentUser.role !== "author" &&
      this.state.currentUser.role !== "admin"
    ) {
      history.push("/dashboard");
    }

    this.setInitialUserLibraries();

    httpClient.get(`/libraries.json`).then((response) => {
      this.setState({
        libraries: response.data,
      });
    });
  };

  handleSave(e) {
    e.preventDefault();
    const { reloadUsers } = this.props;
    this.props.form.validateFields((error, values) => {
      //this.setState({ invalidUserEditForm: false });
      if (!error) {
        let formData = {
          first_name: values[`first_name${this.props.user.id}`],
          last_name: values[`last_name${this.props.user.id}`],
          title: values[`title${this.props.user.id}`],
          papercurve_user: this.state.editUserPapercurveEmployee,
          library_ids: this.state.selectedLibraries,
        };
        if (this.state.currentUser.role === "admin") {
          formData.email = values[`email${this.props.user.id}`];
        }
        const userFormData = {
          user: formData,
        };

        if (this.state.role) {
          userFormData.user.role = this.state.role;
          console.log(userFormData);
        }

        httpClient
          .put(`/users/${this.props.user.id}.json`, userFormData)
          .then((response) => {
            console.log("saved!");
            notificationService.addNotification(
              "User Saved",
              "Your changes to this user has been saved",
              "success"
            );
            reloadUsers();
          });
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleDelete(e) {
    e.preventDefault();
    const { onDelete, reloadUsers } = this.props;
    if (window.confirm("Are you sure you want to deactivate this user?")) {
      httpClient
        .delete(`/users/${this.props.user.id}.json`)
        .then((response) => {
          console.log("deleted!");
          notificationService.addNotification(
            "User Deleted",
            "This user has been deactivated.",
            "danger"
          );
          onDelete();
          reloadUsers();
        });
    }
  }

  handleRoleChange = (e) => {
    this.setState({ role: e.target.value });
  };

  userRoleSelectValue = (role) => {
    if (role === "viewer") {
      return this.state.roles[0];
    } else if (role === "author") {
      return this.state.roles[1];
    } else if (role === "admin") {
      return this.state.roles[2];
    }
  };

  setInitialUserLibraries() {
    this.setState({
      selectedLibraries: this.props.user.libraries.map((library) => library.id),
    });
  }

  handleLibraryChange = (e) => {
    if (e.target.checked) {
      // add library
      this.setState({
        selectedLibraries: [
          ...this.state.selectedLibraries,
          parseInt(e.target.name),
        ],
      });
    } else {
      // remove library
      this.setState({
        selectedLibraries: this.state.selectedLibraries.filter(
          (i) => i !== parseInt(e.target.name)
        ),
      });
    }
  };

  resendInvitation = () => {
    this.setState({ invitationSent: true });
    httpClient
      .post(`/users/${this.props.user.id}/resend_invitation`)
      .then((response) => {
        notificationService.addNotification(
          "Invitation sent",
          "User was sent a new invite email.",
          "success"
        );
      });
  };

  resetUser2fa = () => {
    httpClient.put(`/2fa/reset/${this.props.user.id}`).then((res) => {
      this.props.reloadUsers();
      this.setState({ showReset2faModal: false });
      notificationService.addNotification(
        "Reset successful",
        "This user's 2FA has been reset",
        "success"
      );
    });
  };

  handlePapercurveEmployee = (choice) => {
    this.setState({
      editUserPapercurveEmployee: choice,
    });
  };

  render() {
    const { user } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;
    const emailDisabled =
      this.state.currentUser.role === "admin" ? false : true;

    return (
      <div className={styles.editUserContainer}>
        {this.state.currentUser.role === "admin" && !user.invitation_accepted && (
          <div className={styles.resendInviteContainer}>
            <div className={styles.invitationText}>
              This invitation is still pending because they have not created
              their password.
            </div>
            <div className={styles.invitationButton}>
              <Button
                onClick={this.resendInvitation}
                disabled={this.state.invitationSent}
                original
                text={`${!this.state.invitationSent ? `Send Invite` : `Sent`}`}
              />
            </div>
          </div>
        )}
        <UikWidget>
          <div className={styles.editContainer}>
            <UikHeadline>Edit User</UikHeadline>
            <div className={styles.UserEditFormSubtitle}>
              Make changes to this user’s profile here
            </div>
          </div>
          {this.state.currentUser.papercurveUser && (
            <div>
              <UikDivider />
              <div className={styles.editActionButtons}>
                <UikToggle
                  defaultChecked={user.papercurve_user}
                  label="Papercurve Employee"
                  onChange={(e) =>
                    this.handlePapercurveEmployee(e.target.checked)
                  }
                />
              </div>
            </div>
          )}
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator(`first_name${user.id}`, {
                initialValue: user.first_name,
                rules: [
                  {
                    required: true,
                    message: "Please enter a first name.",
                  },
                ],
              })(
                <UikInput
                  label="FIRST NAME"
                  placeholder="Mary"
                  errorMessage={(
                    getFieldError(`first_name${user.id}`) || []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`last_name${user.id}`, {
                initialValue: user.last_name,
                rules: [
                  {
                    required: true,
                    message: "Please enter a last name.",
                  },
                ],
              })(
                <UikInput
                  label="LAST NAME"
                  placeholder="Smith"
                  errorMessage={(
                    getFieldError(`last_name${user.id}`) || []
                  ).join(", ")}
                />
              )}
              {getFieldDecorator(`email${user.id}`, {
                initialValue: user.email,
                rules: [
                  {
                    required: true,
                    message: "Please enter an email.",
                  },
                  {
                    type: "email",
                    message: "Must be in the format name@company.com",
                  },
                ],
              })(
                <UikInput
                  label="EMAIL ADDRESS"
                  disabled={emailDisabled}
                  placeholder="you@company.com"
                  errorMessage={(getFieldError(`email${user.id}`) || []).join(
                    ", "
                  )}
                  className={`${
                    emailDisabled ? `${styles.emailDisabled}` : ``
                  }`}
                />
              )}
              {getFieldDecorator(`title${user.id}`, {
                initialValue: user.title,
                rules: [
                  {
                    required: true,
                    message: "Please enter a title.",
                  },
                ],
              })(
                <UikInput
                  label="TITLE"
                  placeholder="Medical Writer"
                  errorMessage={(getFieldError(`title${user.id}`) || []).join(
                    ", "
                  )}
                />
              )}
              {this.state.currentUser.role === "admin" &&
                this.props.client2FAEnabled && (
                  <div className={styles.reset2faContainer}>
                    <div className={styles.reset2fatitle}>
                      Two factor authentication
                    </div>
                    <div className={styles.reset2faContent}>
                      {this.props.user.authy_id ? (
                        <Button
                          transparent
                          text="Reset"
                          onClick={() =>
                            this.setState({ showReset2faModal: true })
                          }
                        />
                      ) : (
                        <div className={styles.notEnabled2fa}>
                          This user does not have two factor authentication
                          enabled
                        </div>
                      )}
                    </div>
                  </div>
                )}
              {this.state.currentUser.role === "admin" && (
                <div>
                  <div className={styles.permissionsTitle}>Permissions</div>
                  <div className={styles.radiobuttonContainer}>
                    <RadioButton
                      name="role"
                      label="Viewer"
                      value="viewer"
                      onChange={this.handleRoleChange}
                      checked={this.state.role === "viewer"}
                    />
                    <Tooltip
                      type="permissions"
                      allowedPermissions={[
                        "View Draft and Approved content",
                        "Leave comments and link references",
                        "Submit approval",
                      ]}
                      disallowedPermissions={[
                        "Edit list of Reviewers, and release content",
                        "Upload, edit and delete content",
                        "Add and edit users",
                        "Manage Library access and deactivate users",
                        "Add, edit and delete Libraries",
                      ]}
                    />
                  </div>
                  <div className={styles.radiobuttonContainer}>
                    <RadioButton
                      name="role"
                      label="Author"
                      value="author"
                      onChange={this.handleRoleChange}
                      checked={this.state.role === "author"}
                    />
                    <Tooltip
                      type="permissions"
                      allowedPermissions={[
                        "View Draft and Approved content",
                        "Leave comments and link references",
                        "Submit approval",
                        "Edit list of Reviewers, and release content",
                        "Upload, edit and delete content",
                        "Add and edit users",
                      ]}
                      disallowedPermissions={[
                        "Manage Library access and deactivate users",
                        "Add, edit and delete Libraries",
                      ]}
                    />
                  </div>
                  <div className={styles.radiobuttonContainer}>
                    <RadioButton
                      name="role"
                      label="Admin"
                      value="admin"
                      onChange={this.handleRoleChange}
                      checked={this.state.role === "admin"}
                    />
                    <Tooltip
                      type="permissions"
                      allowedPermissions={[
                        "View Draft and Approved content",
                        "Leave comments and link references",
                        "Submit approval",
                        "Edit list of Reviewers, and release content",
                        "Upload, Edit and delete content",
                        "Add and edit users",
                        "Manage Library access and deactivate users",
                        "Add, edit and delete Libraries",
                      ]}
                      disallowedPermissions={[]}
                    />
                  </div>
                </div>
              )}

              {this.state.currentUser.role === "admin" && (
                <div>
                  <div className={styles.formLabel}>LIBRARY ACCESS</div>

                  <ul>
                    {this.state.libraries.map((library) => (
                      <li className={styles.libraryCheckbox} key={library.id}>
                        <Checkbox
                          label={library.name}
                          name={library.id}
                          onChange={this.handleLibraryChange}
                          checked={this.state.selectedLibraries.includes(
                            library.id
                          )}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleSave(e)}
              primary
            >
              Save
            </UikButton>
            {security.getUserId() != user.id &&
              this.state.currentUser.role === "admin" && (
                <UikButton
                  className="floatRight"
                  onClick={(e) => this.handleDelete(e)}
                  error
                >
                  Deactivate User
                </UikButton>
              )}
            <div className="clear" />
          </div>
        </UikWidget>
        <Modal
          hideModal={() => this.setState({ showReset2faModal: false })}
          visible={this.state.showReset2faModal}
          type="alert"
        >
          <div className="heading">Confirm Reset</div>
          <UikDivider />
          <div className="body">
            Are you sure you want to reset this user's two factor
            authentication?
          </div>
          <UikDivider />
          <div className="buttons">
            <Button original text="Reset" onClick={() => this.resetUser2fa()} />
            <Button
              transparent
              text="Cancel"
              onClick={() => this.setState({ showReset2faModal: false })}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

UserEditFormBuild.propTypes = {
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

const UserEditForm = createForm()(UserEditFormBuild);
export default UserEditForm;
