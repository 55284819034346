import React, { Component } from "react";

import { UikButton } from "@uik";

import styles from "./DocumentFilterItem.module.scss";

class DocumentFilterItem extends Component {
  constructor() {
    super();
    this.dropdownMenu = null;
    this.state = {
      showMenu: false,
    };
  }

  showMenu = (event) => {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
      this.props.toggleMenuOpen(true);
    });
  };

  closeMenu = (event) => {
    if (
      event &&
      this.dropdownMenu != event.target &&
      !this.dropdownMenu.contains(event.target) &&
      !(
        event.target.classList.contains("react-datepicker__day") ||
        event.target.classList.contains("react-datepicker__current-month") ||
        event.target.classList.contains("css-dpec0i-option") ||
        event.target.className.baseVal == "css-19bqh2r"
      )
    ) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener("click", this.closeMenu);
        this.props.toggleMenuOpen(false);
      });
      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  };

  _closeMenu = (event) => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
      this.props.toggleMenuOpen(false);
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <div
        className={styles.filterItemContainer}
        ref={(element) => {
          this.filterItemContainer = element;
        }}
      >
        <UikButton
          className={
            styles.dropDownButton +
            (this.props.active ? " " + styles.buttonActive : "")
          }
          onClick={this.showMenu}
          transparent
        >
          {this.props.field.label}
        </UikButton>

        <div
          className={
            styles.menu +
            " " +
            (this.state.showMenu ? styles.menuShow : styles.menuHide)
          }
          ref={(element) => {
            this.dropdownMenu = element;
          }}
        >
          <div>{this.props.children}</div>
          <div className={styles.filterItemFooter}>
            <div
              className={
                styles.clearButton +
                " " +
                (!this.props.active ? styles.clearButtonInactive : "")
              }
              onClick={() => {
                this._closeMenu();
                this.props.clearFilter(this.props.field.key);
              }}
            >
              clear
            </div>
            <div>
              <UikButton
                primary
                onClick={() => {
                  this._closeMenu();
                }}
              >
                Save
              </UikButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentFilterItem;
