import React from "react";
import PropTypes from "prop-types";
import styles from "./ApproverListItem.module.scss";
import Select from "react-select";
import Modal from "../Common/Modal";
import Button from "../Common/Buttons/ButtonPrimary";

/* UI Kit */
import { UikCheckbox, Uikon, UikToggle, UikDivider } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

import notificationService from "../../services/Notifications";
import emailIcon from "../../images/icons/svg/email-icon.svg";
import deleteIcon from "../../images/icons/16px/delete.svg";
import CloseIcon from "../../images/icons/svg/icon_close_white.svg";
import style from "react-awesome-modal/lib/style";

class ApproverListItem extends React.Component {
  constructor() {
    super();
    this.state = {
      showApprovalResetModal: false,
    };
  }

  componentWillUpdate = () => {
    return true;
  };

  getAprovalStatus = (status) => {
    const { approvalStatuses } = this.props;
    for (let i in approvalStatuses) {
      if (approvalStatuses[i].value == status) {
        return approvalStatuses[i];
      }
    }
    return approvalStatuses[status];
  };

  handleShowApprovalResetModal = () => {
    this.setState({
      showApprovalResetModal: true,
    });
  };

  resetApprovalStatus = (user) => {
    this.props.onApprovalStatusChange(user, "pending");
    this.setState({ showApprovalResetModal: false });
  };

  render() {
    const {
      user,
      suggestMode,
      approvedMode,
      onApproved,
      onObserver,
      onDelete,
      onApproveWithChanges,
      approvalStatuses,
      onCopyReviewLink,
      onResendReviewLinkEmail,
    } = this.props;

    let className = approvedMode ? styles.approvedDocument : "";
    className +=
      " " + styles.approverListItem + " " + styles[this.props.extraClass];

    className += this.props.readOnly ? " " + styles.readOnly : "";

    className += user.active ? "" : " " + styles.deactive;

    let resetBadgeClassName =
      !user.active || user.observer || user.status == "pending"
        ? styles.resetApprovalBadgePassive
        : styles.resetApprovalBadgeActive;
    let label = null;

    if (!suggestMode && user.observer) {
      resetBadgeClassName += " " + styles.observerResetBadge;
      label = "observer";
    } else if (!suggestMode && !user.observer && user.status == "approved") {
      resetBadgeClassName += " " + styles.approvedResetBadge;
      label = "approved";
    } else if (
      !suggestMode &&
      !user.observer &&
      user.status == "approve_with_changes"
    ) {
      resetBadgeClassName += " " + styles.approvedResetBadge;
      label = "approved with changes";
    } else if (!suggestMode && !user.observer && user.status == "pending") {
      resetBadgeClassName += " " + styles.waitingResetBadge;
      label = "waiting";
    } else if (
      !suggestMode &&
      !user.observer &&
      user.status == "submit_new_version"
    ) {
      resetBadgeClassName += " " + styles.rejectedResetBadge;
      label = "submit new version";
    } else if (
      !suggestMode &&
      !user.observer &&
      user.status == "change_requested"
    ) {
      resetBadgeClassName += " " + styles.changeRequested;
      label = "change requested";
    }

    let out;

    if (user.guest === true || user.id === -1) {
      out = (
        <div className={className}>
          <div className={styles.approverListItemInner}>
            <div className={styles.approverListItemAvatarContainer}>
              <div className={styles.approverListItemAvatar}>
                <span className={styles.approverListItemInitials}>
                  <img src={emailIcon} />
                </span>
              </div>
            </div>
            <div className={styles.approverListItemNameplate}>
              <span className={styles.approverListItemNameplateName}>
                {user.email}
              </span>
              <br />
              <span className={styles.approverListItemNameplateTitle}>
                Outside Workspace
                {user.id > 0 && (
                  <span>
                    <span
                      className={styles.copyLink}
                      onClick={(v) => this.props.onCopyReviewLink(user)}
                    >
                      <div className={styles.text}>Copy Link</div>
                    </span>
                    &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                    <span
                      className={styles.copyLink}
                      onClick={(v) => this.props.onResendReviewLinkEmail(user)}
                    >
                      <div className={styles.text}>Resend Email</div>
                    </span>
                  </span>
                )}
              </span>
            </div>
            <div className={styles.actionsContainer}>
              {this.props.readOnly && !suggestMode && approvedMode && (
                <div
                  className={`${resetBadgeClassName} ${styles.approvalBadge}`}
                >
                  {<span>{label}</span>}
                </div>
              )}
              {!this.props.readOnly && !suggestMode && !approvedMode && (
                <div className={styles.actions}>
                  <div
                    className={
                      styles.actionsInner +
                      (user.observer ? " " + styles.isObserver : "")
                    }
                  >
                    <div
                      onClick={
                        user.active &&
                        !user.observer &&
                        user.status !== "pending"
                          ? this.handleShowApprovalResetModal
                          : null
                      }
                      className={resetBadgeClassName}
                    >
                      {!suggestMode && <span>{label}</span>}
                      {user.active &&
                        !user.observer &&
                        user.status !== "pending" && (
                          <img
                            className={styles.resetApprovalIcon}
                            src={CloseIcon}
                          />
                        )}
                    </div>
                    {user.active && (
                      <div className={styles.actionObserverToggle}>
                        <UikToggle
                          defaultChecked={user.observer}
                          color="blue"
                          label="Observer"
                          onChange={(v) => this.props.onObserver(user, v)}
                        />
                      </div>
                    )}
                    {!this.props.readOnly && !suggestMode && !approvedMode && (
                      <div className={styles.actionDelete}>
                        <span
                          className={styles.deleteApprover}
                          onClick={(v) => this.props.onDelete(user)}
                        >
                          <img src={deleteIcon} alt={"delete"} />
                        </span>
                      </div>
                    )}
                    <Modal
                      type="alert"
                      visible={this.state.showApprovalResetModal}
                      hideModal={() =>
                        this.setState({ showApprovalResetModal: false })
                      }
                    >
                      <div className="heading">Confirm reset</div>
                      <UikDivider />
                      <div className="body">
                        <div>
                          Are you sure you want to reset this review status?
                        </div>
                      </div>
                      <div className="clear" />
                      <UikDivider />
                      <div className="buttons">
                        <Button
                          original
                          text="Reset"
                          onClick={() => this.resetApprovalStatus(user)}
                        />
                        <Button
                          transparent
                          text="Cancel"
                          onClick={() =>
                            this.setState({ showApprovalResetModal: false })
                          }
                        />
                      </div>
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      out = (
        <div className={className}>
          <div className={styles.approverListItemInner}>
            <div className={styles.approverListItemAvatarContainer}>
              <div className={styles.approverListItemAvatar}>
                <span className={styles.approverListItemInitials}>
                  {user.first_name.charAt(0)}
                  {user.last_name.charAt(0)}
                </span>
              </div>
            </div>
            <div className={styles.approverListItemNameplate}>
              <span className={styles.approverListItemNameplateName}>
                {user.first_name} {user.last_name}
                {!user.active ? " (Deactivated)" : ""}
              </span>
              <br />
              <span className={styles.approverListItemNameplateTitle}>
                {user.title}
                &nbsp;
              </span>
            </div>
            <div className={styles.actionsContainer}>
              {this.props.readOnly && !suggestMode && approvedMode && (
                <div
                  className={`${resetBadgeClassName} ${styles.approvalBadge}`}
                >
                  {<span>{label}</span>}
                </div>
              )}
              {!this.props.readOnly && !suggestMode && !approvedMode && (
                <div className={styles.actions}>
                  <div
                    className={
                      styles.actionsInner +
                      (user.observer ? " " + styles.isObserver : "")
                    }
                  >
                    <div
                      onClick={
                        user.active &&
                        !user.observer &&
                        user.status !== "pending"
                          ? this.handleShowApprovalResetModal
                          : null
                      }
                      className={resetBadgeClassName}
                    >
                      {!suggestMode && <span>{label}</span>}
                      {user.active &&
                        !user.observer &&
                        user.status !== "pending" && (
                          <img
                            className={styles.resetApprovalIcon}
                            src={CloseIcon}
                          />
                        )}
                    </div>
                    {user.active && (
                      <div className={styles.actionObserverToggle}>
                        <UikToggle
                          defaultChecked={user.observer}
                          color="blue"
                          label="Observer"
                          onChange={(v) => this.props.onObserver(user, v)}
                        />
                      </div>
                    )}
                    {!this.props.readOnly && !suggestMode && !approvedMode && (
                      <div className={styles.actionDelete}>
                        <span
                          className={styles.deleteApprover}
                          onClick={(v) => this.props.onDelete(user)}
                        >
                          <img src={deleteIcon} alt={"delete"} />
                        </span>
                      </div>
                    )}
                    <Modal
                      type="alert"
                      visible={this.state.showApprovalResetModal}
                      hideModal={() =>
                        this.setState({ showApprovalResetModal: false })
                      }
                    >
                      <div className="heading">Confirm reset</div>
                      <UikDivider />
                      <div className="body">
                        <div>
                          Are you sure you want to reset this review status?
                        </div>
                      </div>
                      <div className="clear" />
                      <UikDivider />
                      <div className="buttons">
                        <Button
                          original
                          text="Reset"
                          onClick={() => this.resetApprovalStatus(user)}
                        />
                        <Button
                          transparent
                          text="Cancel"
                          onClick={() =>
                            this.setState({ showApprovalResetModal: false })
                          }
                        />
                      </div>
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return out;
  }
}

ApproverListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  suggestMode: PropTypes.bool,
  approvedMode: PropTypes.bool,
  onApproved: PropTypes.func,
  onObserver: PropTypes.func,
  onDelete: PropTypes.func,
  onCopyReviewLink: PropTypes.func,
  onResendReviewLinkEmail: PropTypes.func,
};

export default ApproverListItem;
