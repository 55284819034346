import DocumentActionTypes from "../../actions/document/types";

export default (documentTemplates = null, action) => {
  switch (action.type) {
    case DocumentActionTypes.FETCH_DOCUMENT_TEMPLATES:
      return action.payload;
    default:
      return documentTemplates;
  }
};
