import GlobalSearchActionTypes from "../../actions/GlobalSearch/types";

export default (globalSearchResults = null, action) => {
  switch (action.type) {
    case GlobalSearchActionTypes.FETCH_GLOBAL_SEARCH_RESULTS:
      return action.payload;
    case GlobalSearchActionTypes.RESET_GLOBAL_SEARCH_RESULTS:
      return null;
    default:
      return globalSearchResults;
  }
};
