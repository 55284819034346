import React, { useState } from "react";

import CloseSVG from "../../../../images/icons/svg/close.svg";
import ShareLinkWhiteSVG from "../../../../images/icons/svg/share-link-icon-white.svg";
import httpClient from "../../../../lib/HttpClient";
import security from "../../../../services/Security";
import Button from "../../../Common/Buttons/ButtonPrimary";
import AdminShareLinkContainer from "./AdminShareLinkContainer";
import CreateModal from "./CreateModal";
import Link from "./Link";

import styles from "./ShareLink.module.scss";

const ShareLink = (props) => {
  const {
    links,
    numShareLinks,
    docId,
    retrieveShareLinks,
    closePanel,
    setShowActivityModal,
    setSelectedShareLink,
  } = props;

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [userRole] = useState(security.getUserRole());

  const createLink = async (label) => {
    const response = await httpClient.post(`/share_links.json`, {
      document_id: docId,
      label,
    });

    retrieveShareLinks();
    setShowCreateModal(false);
  };

  const launchAnalyticsDasboard = (e) => {
    window.open(
      `${
        window.location.protocol + "//" + window.location.host + "/share_links"
      }`
    );
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Share Links</div>
          <div className={styles.closeButton} onClick={closePanel}>
            <img src={CloseSVG} />
          </div>
        </div>
        {numShareLinks !== 0 && (
          <div className={styles.dashBoardLinkContainer}>
            <div
              className={styles.dashBoardLink}
              onClick={() => launchAnalyticsDasboard()}
            >
              Open Share Link Analytics
            </div>
          </div>
        )}
        <div className={styles.body}>
          {numShareLinks === 0 ? (
            <div className={styles.emptyStateContainer}>
              <div className={styles.emptyState}>
                <div className={styles.shareLinkIcon}>
                  <img src={ShareLinkWhiteSVG} />
                </div>
                <div className={styles.text}>
                  <div className={styles.textOne}>There are no share links</div>
                  <div className={styles.textTwo}>
                    To create one, click the button below
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              {userRole === "admin" ? (
                <React.Fragment>
                  <div className={styles.shareLinksAdminContainer}>
                    <AdminShareLinkContainer type="MY_LINKS">
                      {links &&
                        links.my_share_links.map((link) => (
                          <Link
                            key={link.id}
                            link={link}
                            retrieveShareLinks={retrieveShareLinks}
                            setShowActivityModal={setShowActivityModal}
                            setSelectedShareLink={setSelectedShareLink}
                          />
                        ))}
                    </AdminShareLinkContainer>
                  </div>
                  <div className={styles.shareLinksAdminContainer}>
                    <AdminShareLinkContainer type="OTHERS_LINKS">
                      {links &&
                        links.others_share_links.map((link) => (
                          <Link
                            key={link.id}
                            link={link}
                            retrieveShareLinks={retrieveShareLinks}
                            setShowActivityModal={setShowActivityModal}
                            setSelectedShareLink={setSelectedShareLink}
                          />
                        ))}
                    </AdminShareLinkContainer>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {links &&
                    links.my_share_links.map((link) => (
                      <Link
                        key={link.id}
                        link={link}
                        retrieveShareLinks={retrieveShareLinks}
                        setShowActivityModal={setShowActivityModal}
                        setSelectedShareLink={setSelectedShareLink}
                      />
                    ))}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={styles.createNewShareLinkContainer}>
          <Button
            original
            text="Create Share Link"
            onClick={() => setShowCreateModal(true)}
          />
        </div>
      </div>
      {showCreateModal && (
        <CreateModal
          closeModal={() => setShowCreateModal(false)}
          createLink={createLink}
        />
      )}
    </React.Fragment>
  );
};

export default ShareLink;
