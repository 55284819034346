import React, { useEffect, useState } from "react";
import { Col } from "react-grid-system";
import { Uikon, UikButton } from "@uik";
import { Link } from "react-router-dom";
import httpClient from "../../../lib/HttpClient";
import Security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import { UikDivider } from "@uik";
import Modal from "../../Common/Modal";
import Button from "../../Common/Buttons/ButtonPrimary";

import GhostIcon from "../../../images/icons/svg/ghost.svg";
import styles from "./DateReminders.module.scss";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";

export const DateReminders = (props) => {
  const { renderNotificationsMenu } = props;

  const [dateReminders, setDateReminders] = useState(null);
  const [selectedDateReminder, setSelectedDateReminder] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const fetchDateReminders = async () => {
    const userID = Security.getUserId();
    const response = await httpClient.get(
      `/users/${userID}/date_reminders.json`
    );
    setDateReminders(response.data);
  };

  useEffect(() => {
    fetchDateReminders();
  }, []);

  const selectDateReminder = (dateReminder) => {
    if (!showCreateForm) {
      setShowCreateForm(false);
      setSelectedDateReminder(dateReminder);
      setShowEditForm(true);
    } else {
      setShowCancelModal(true);
      setSelectedDateReminder(dateReminder);
    }
  };

  const deleteDateReminder = async (id) => {
    await httpClient.delete(`/date_reminders/${id}.json`);
    await fetchDateReminders();

    setShowEditForm(false);
    setShowDeleteModal(false);

    notificationService.addNotification(
      "Reminder deleted",
      "Reminder has been deleted",
      "success"
    );
  };

  const createDateReminder = async (dateReminder) => {
    try {
      await httpClient.post("/date_reminders.json", {
        date_reminder: dateReminder,
      });
      await fetchDateReminders();

      setShowCreateForm(false);

      notificationService.addNotification(
        "Reminder saved",
        "Your reminder has been saved",
        "success"
      );
    } catch (e) {
      notificationService.addNotification(
        "Failed to save",
        "Invalid input! This reminder already exists.",
        "warning"
      );
    }
  };

  const updateDateReminder = async (id, daysBefore) => {
    try {
      await httpClient.put(`/date_reminders/${id}.json`, {
        date_reminder: { days_before: daysBefore },
      });
      await fetchDateReminders();

      setShowEditForm(false);
      notificationService.addNotification(
        "Reminder saved",
        "Your reminder has been saved",
        "success"
      );
    } catch (e) {
      notificationService.addNotification(
        "Failed to save",
        "Invalid input! This reminder already exists.",
        "warning"
      );
    }
  };

  const onCreateReminderButtonClick = () => {
    setShowCreateForm(true);
    setShowEditForm(false);
    setSelectedDateReminder(null);
  };

  const confirmCancelCreation = () => {
    setShowCreateForm(false);
    setShowCancelModal(false);
    if (selectedDateReminder) {
      setShowEditForm(true);
    }
  };

  return (
    <React.Fragment>
      <Col className={styles.leftPanel} sm={12} md={5}>
        <div className={styles.backContainer}>
          <Uikon>
            <Link
              to={"/notifications"}
              onClick={renderNotificationsMenu}
              className={styles.backLink}
            >
              arrow_left
            </Link>
          </Uikon>
          <div className={styles.backText}>Notifications</div>
        </div>
        <div className={styles.dateRemindersListContainer}>
          <div className={styles.header}>
            <div className={styles.text}>
              <div className={styles.title}>Date Reminders</div>
              <div className={styles.description}>
                Custom email reminders for important dates
              </div>
            </div>
            <div className={styles.createButton}>
              <UikButton onClick={onCreateReminderButtonClick}>
                Create Reminder
              </UikButton>
            </div>
          </div>
          <div className={styles.body}>
            {dateReminders && dateReminders.length === 0 && (
              <div className={styles.emptyContainer}>
                <img src={GhostIcon} />
                <div className={styles.textOne}>
                  You have no date reminders set
                </div>
                <div className={styles.textTwo}>
                  Date reminders lets you create custom alerts for your date
                  fields
                </div>
                <div className={styles.knowledgeBaseLink}>
                  <a
                    href="https://help.papercurve.com/knowledge-base/date-reminders"
                    target="_blank"
                  >
                    Learn More About Date Reminders
                  </a>
                </div>
              </div>
            )}
            {dateReminders && dateReminders.length > 0 && (
              <div className={styles.list}>
                {dateReminders.map((libraryDateReminders, index) => (
                  <div
                    key={index}
                    className={styles.libraryDateRemindersContainer}
                  >
                    <div className={styles.libraryName}>
                      {libraryDateReminders.library_name}
                    </div>
                    <div className={styles.dateReminders}>
                      {libraryDateReminders.date_reminders.map(
                        (dateReminder, index) => (
                          <div
                            key={index}
                            className={`${styles.dateReminderContainer} ${
                              selectedDateReminder &&
                              selectedDateReminder.id === dateReminder.id
                                ? styles.dateReminderContainerSelected
                                : ""
                            }`}
                            onClick={() => selectDateReminder(dateReminder)}
                          >
                            <div className={styles.label}>
                              {dateReminder.document_field_label}
                            </div>
                            <div className={styles.daysBefore}>
                              {dateReminder.days_before} Days Before
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Col>
      {showCreateForm && (
        <CreateForm
          createDateReminder={createDateReminder}
          setShowCancelModal={setShowCancelModal}
        />
      )}
      {showEditForm && (
        <EditForm
          dateReminder={selectedDateReminder}
          deleteDateReminder={deleteDateReminder}
          updateDateReminder={updateDateReminder}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      <Modal
        visible={showDeleteModal}
        type="alert"
        hideModal={() => setShowDeleteModal(false)}
      >
        <div className="heading">Confirm Delete</div>
        <UikDivider />
        <div className="body">
          Are you sure you want to delete this reminder?
        </div>
        <UikDivider />
        <div className="buttons">
          <Button
            onClick={() => deleteDateReminder(selectedDateReminder.id)}
            original
            text="Confirm"
          />
          <Button
            onClick={() => setShowDeleteModal(false)}
            transparent
            text="Cancel"
          />
        </div>
      </Modal>
      <Modal
        visible={showCancelModal}
        type="alert"
        hideModal={() => setShowCancelModal(false)}
      >
        <div className="heading">Confirm Cancel</div>
        <UikDivider />
        <div className="body">
          Are you sure you want to cancel creating a reminder?
        </div>
        <UikDivider />
        <div className="buttons">
          <Button onClick={confirmCancelCreation} original text="Confirm" />
          <Button
            onClick={() => {
              setShowCancelModal(false);
              setSelectedDateReminder(null);
            }}
            transparent
            text="Cancel"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
