import React, { useState } from "react";
import "./NewAccount.scss";
import papercurveLogo from "../../../images/logos/papercurve-logo-colour.svg";
import Step from "../../Common/Step";
import Setpassword from "./Setpassword";
import Setup2FA from "../Setup2FA";
import Verify2FA from "../Verify2FA";
import axios from "axios";
import { apiUrl } from "../../../lib/axios-client";
import security from "../../../services/Security";
import Button from "../../Common/Buttons/ButtonPrimary";
import HubspotChat from "../../Common/HubspotChat";

const steps = [
  {
    title: "Set your password",
    description: "All data you enter is kept secure and private.",
  },
  {
    title: "Secure your account",
    description:
      "Whenever you sign in with your password, we'll send you a code via SMS.",
  },
  {
    title: "Verify your account",
    description: "We sent a verification code to phone number",
  },
  {
    title: "Successfully verified",
    description:
      "Thank you for verifying your account, you are now ready to access Papercurve.",
  },
];

export const NewAccount = (props) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [jwt, setJwt] = useState(null);

  const setPasswordSuccess = (email, password) => {
    setEmail(email);
    setPassword(password);
    setCurrentStep(1);
  };

  const setup2FASuccess = (countryCode, phoneNumber) => {
    setPhoneNumber(phoneNumber);
    setCountryCode(countryCode);
    setCurrentStep(2);
  };

  const update2FANumber = () => {
    axios
      .post(apiUrl + "/2fa/delete", {
        user: {
          email,
          password,
        },
      })
      .then((res) => {
        setCurrentStep(1);
      });
  };

  const verify2FASuccess = (jwt) => {
    setJwt(jwt);
    setCurrentStep(3);
  };

  const loginUser = () => {
    security.setToken(jwt);
    security.redirectAfterLogin();
  };

  return (
    <div className="two-fa-new-account">
      <div className="hs-chat-container">
        <HubspotChat />
      </div>
      <div className="container">
        <div>
          <img src={papercurveLogo} />
        </div>
        <div className="steps">
          <Step number={1} completed={currentStep > 0} text="Set password" />
          <div
            className={`divider ${currentStep > 0 ? ` divider-complete` : ``}`}
          ></div>
          <Step number={2} completed={currentStep > 1} text="Secure password" />
          <div
            className={`divider ${currentStep > 1 ? ` divider-complete` : ``}`}
          ></div>
          <Step number={3} completed={currentStep > 2} text="Verify account" />
        </div>
        <div className="content">
          <div className="title">{steps[currentStep].title}</div>
          <div className="description">
            {currentStep !== 2 && steps[currentStep].description}
            {currentStep === 2 && (
              <div>
                {`${
                  steps[currentStep].description
                } +${countryCode} (\u2022\u2022\u2022) \u2022\u2022\u2022 ${
                  phoneNumber && phoneNumber.replace(/.*(?=\d{4})/g, "")
                }`}
                <div className="update-phone-number" onClick={update2FANumber}>
                  Use a different phone number?
                </div>
              </div>
            )}
          </div>
          {currentStep === 0 && (
            <Setpassword
              onSuccess={setPasswordSuccess}
              token={props.match.params.token}
            />
          )}
          {currentStep === 1 && (
            <Setup2FA
              onSuccess={setup2FASuccess}
              email={email}
              password={password}
              previousPhoneNumber={phoneNumber}
              previousCountryCode={countryCode}
            />
          )}
          {currentStep === 2 && (
            <Verify2FA
              email={email}
              password={password}
              onSuccess={verify2FASuccess}
            />
          )}
          {currentStep === 3 && (
            <div className="verification-success">
              <Button original text="Go to Papercurve" onClick={loginUser} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
