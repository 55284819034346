import React from "react";
import PropTypes from "prop-types";
import styles from "./ApproverListItem.module.scss";

/* UI Kit */
import { UikCheckbox, Uikon, UikToggle } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

import emailIcon from "../../images/icons/svg/email-icon.svg";

class ApproverAddedItem extends React.Component {
  render() {
    const { suggestion } = this.props.added;

    return (
      <div
        className={styles.approverListItem + " " + styles.stylesApproverAddItem}
      >
        <div className={styles.approverListItemInner}>
          <div className={styles.approverListItemAvatarContainer}>
            <div className={styles.approverListItemAvatar}>
              <span className={styles.approverListItemInitials}>
                {suggestion.id === -1 && (
                  <span>
                    <img src={emailIcon} />
                  </span>
                )}
                {suggestion.id > 0 && (
                  <span>
                    {suggestion.first_name.charAt(0)}
                    {suggestion.last_name.charAt(0)}
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className={styles.approverListItemNameplate}>
            <span className={styles.approverListItemNameplateName}>
              {suggestion.id === -1 && <span>{suggestion.email}</span>}
              {suggestion.id > 0 && (
                <span>
                  {suggestion.first_name} {suggestion.last_name}
                </span>
              )}
            </span>
            <br />
            <span className={styles.approverListItemNameplateTitle}>
              {suggestion.id === -1 && <span>Outside Workspace</span>}
              {suggestion.id > 0 && (
                <span>{this.props.added.suggestion.title}</span>
              )}
            </span>
          </div>
          <div className={styles.actionObserverToggleOn}>
            <UikToggle
              className={styles.addItemObserverToggle}
              color="blue"
              label="Observer"
              onChange={(v) =>
                this.props.onChangeObserverStatus(
                  this.props.added.suggestion,
                  v
                )
              }
            />
          </div>
          <div className={styles.actionDelete}>
            <span
              className={styles.deleteApprover}
              onClick={(v) => this.props.onDeleteAdded(this.props.added.value)}
            >
              &times;
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ApproverAddedItem;
