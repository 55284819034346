import React, { useRef, useEffect } from "react";
import moment from "moment";
import Modal from "../../Common/Modal";
import { useDispatch, useSelector } from "react-redux";

import editIcon from "../../../images/icons/svg/pencil-grey.svg";
import deleteIcon from "../../../images/icons/svg/trash-grey.svg";
import infoIcon from "../../../images/icons/svg/transparent-info.svg";

import { UikDivider } from "@uik";

import styles from "./DocumentTemplatesTable.module.scss";

export default (props) => {
  const {
    setSelectedTemplate,
    handleRenameTemplateModalOpen,
    handleDeleteTemplateModalOpen,
    setShowTemplatePreviewModal,
    selectedTemplate,
  } = props;

  const documentTemplates = useSelector((state) => {
    return state.documentTemplates;
  });

  useEffect(() => {
    if (selectedTemplate) {
      function selectTargetTemplate() {
        document.getElementById(selectedTemplate.id).focus();
      }

      const observer = new MutationObserver(function (
        mutations,
        mutationInstance
      ) {
        const targetTemplate = document.getElementById(selectedTemplate.id);
        if (targetTemplate) {
          selectTargetTemplate();
          mutationInstance.disconnect();
        }
      });

      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    }
  }, []);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  const deselectTemplate = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.target.childNodes[0] &&
            event.target.childNodes[0].innerHTML
          ) {
            if (event.target.childNodes[0].innerHTML !== "Next") {
              setSelectedTemplate(null);
            }
          } else if (event.target.innerHTML !== "Next") {
            setSelectedTemplate(null);
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  deselectTemplate(wrapperRef);

  const renderTemplatesList = () => {
    if (documentTemplates && documentTemplates.length > 0) {
      return (
        <div ref={wrapperRef} className={styles.container}>
          <div className={styles.activityLogSubheadingContainer}>
            <div className={styles.activityLogSubheadingOne}>title</div>
          </div>
          <UikDivider />
          <div>
            <div>{generateTemplatesList()}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.emptyContainer}>
          <img className={styles.infoIcon} src={infoIcon} />
          <div className={styles.emptyStateText}>No templates found</div>
        </div>
      );
    }
  };

  const generateTemplatesList = () => {
    return documentTemplates.map((template, index) => {
      return (
        <div
          id={template.id}
          onClick={() => {
            setSelectedTemplate(template);
          }}
          tabindex={index}
          className={
            index === documentTemplates.length - 1
              ? styles.lastTemplate
              : styles.templateDetails
          }
        >
          <div className={styles.templateTitle}>{template.title}</div>
          <div className={styles.rightContainer}>
            <a
              onClick={() => {
                setShowTemplatePreviewModal(true);
              }}
              className={styles.previewLink}
            >
              Preview
            </a>
            <img
              onClick={(e) => {
                handleRenameTemplateModalOpen(e, template.title, template.id);
              }}
              src={editIcon}
              className={styles.editIcon}
            ></img>
            <img
              onClick={(e) => {
                handleDeleteTemplateModalOpen(e, template.title, template.id);
              }}
              className={styles.deleteIcon}
              src={deleteIcon}
            ></img>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.allContainer}>
      {renderTemplatesList()}
      {documentTemplates && documentTemplates.length > 3 && (
        <div className={styles.scrollIndicator}></div>
      )}
    </div>
  );
};
