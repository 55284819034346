import React from "react";
import PropTypes from "prop-types";
import styles from "./ContactListItemsHeader.module.css";

const ContactListItemsHeader = ({ letter }) => (
  <div className={styles.contactListLetterHeader}>{letter}</div>
);

ContactListItemsHeader.propTypes = {
  letter: PropTypes.string.isRequired,
};

export default ContactListItemsHeader;
