import React, { useState } from "react";

import CloseSVG from "../../../../images/icons/svg/close.svg";
import Button from "../../../Common/Buttons/ButtonPrimary";

import styles from "./RenameModal.module.scss";

const RenameModal = (props) => {
  const { closeModal, updateLink, link } = props;

  const [label, setLabel] = useState(link.label);

  const onLabelChange = (e) => {
    setLabel(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.title}>Rename Share Link</div>
          <div className={styles.closeButton} onClick={closeModal}>
            <img src={CloseSVG} />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.labelInput}>
            <div className={styles.text}>link label</div>
            <input
              placeholder="Input Label"
              value={label}
              onChange={onLabelChange}
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button original text="Save" onClick={() => updateLink(label)} />
        </div>
      </div>
    </div>
  );
};

export default RenameModal;
