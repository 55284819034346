import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./UserListItem.module.css";

import security from "../../services/Security";

const UserListItem = ({ user, selectedUserId }) => (
  <Link to={`/users/${user.id}`}>
    <div
      className={
        styles.userListItem +
        (selectedUserId == user.id ? " " + styles.selected : "") +
        (user.active ? "" : " " + styles.deactiveUserListItemAvatar)
      }
    >
      <div className={styles.userListItemAvatar}>
        <span className={styles.userListItemInitials}>
          {user.first_name.charAt(0)}
          {user.last_name.charAt(0)}
        </span>
      </div>

      <div className={styles.userListItemNameplate}>
        <span className={styles.userListItemNameplateName}>
          {user.first_name}
          &nbsp;
          {user.last_name}
          {!user.active ? " (Deactivated)" : ""}
          {security.getUserRole() === "admin" &&
          user.active &&
          !user.invitation_accepted
            ? " (Pending)"
            : ""}
        </span>
        <br />
        <span className={styles.userListItemNameplateTitle}>
          {user.title}
          &nbsp;
        </span>
      </div>
    </div>
  </Link>
);

UserListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default UserListItem;
