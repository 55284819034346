import React from "react";
import moment from "moment";

import papercurveApprovalSeal from "../../images/icons/svg/papercurve-approval-seal.svg";

/* UI Kit */
import { UikButton } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

import styles from "./ApprovalsExport.module.scss";

class ApprovalsExport extends React.Component {
  constructor() {
    super();
    this.state = {
      documentData: null,
    };
  }

  componentWillMount = () => {
    document.title = `${window.data.title} - ${moment().format(
      "DD MMM, YYYY"
    )}`;
    this.setState({
      documentData: window.data,
    });
  };

  renderApproverColumn = (secondColumnStart, subcontainer) => {
    let approverArray;
    if (subcontainer === 1) {
      approverArray = this.state.documentData.approvers.slice(
        0,
        secondColumnStart
      );
    } else {
      approverArray =
        this.state.documentData.approvers.slice(secondColumnStart);
    }
    return (
      <div
        className={
          subcontainer === 1
            ? styles.approverSubContainerOne
            : styles.approverSubContainerTwo
        }
      >
        {approverArray.map((approver, index) => {
          let resetBadgeClassName =
            !approver.active ||
            approver.observer ||
            approver.status == "pending"
              ? styles.resetApprovalBadgePassive
              : styles.resetApprovalBadgeActive;
          let label = null;

          if (approver.observer) {
            resetBadgeClassName += " " + styles.observerResetBadge;
            label = "observer";
          } else if (!approver.observer && approver.status == "approved") {
            resetBadgeClassName += " " + styles.approvedResetBadge;
            label = "approved";
          } else if (
            !approver.observer &&
            approver.status == "approve_with_changes"
          ) {
            resetBadgeClassName += " " + styles.approvedResetBadge;
            label = "approved with changes";
          } else if (!approver.observer && approver.status == "pending") {
            resetBadgeClassName += " " + styles.waitingResetBadge;
            label = "waiting";
          } else if (
            !approver.observer &&
            approver.status == "submit_new_version"
          ) {
            resetBadgeClassName += " " + styles.rejectedResetBadge;
            label = "submit new version";
          }
          return (
            <div className={styles.approverListItemNameplate}>
              <div>
                <span className={styles.approverListItemNameplateName}>
                  {!approver.guest && (
                    <span>
                      {approver.first_name} {approver.last_name}
                    </span>
                  )}
                  {approver.guest && <span>{approver.email}</span>}
                  {!approver.active ? " (Deactivated)" : ""}
                </span>
                <span className={styles.approverListItemNameplateTitle}>
                  {approver.title}
                  &nbsp;
                </span>
              </div>
              <div className={`${resetBadgeClassName} ${styles.approvalBadge}`}>
                {<span>{label}</span>}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderApprovers = () => {
    const secondColumnStart = Math.ceil(
      this.state.documentData.approvers.length / 2
    );
    return (
      <div className={styles.approverContainer}>
        {this.renderApproverColumn(secondColumnStart, 1)}
        {this.renderApproverColumn(secondColumnStart, 2)}
      </div>
    );
  };

  renderDocumentFieldValuesColumn = (secondColumnStart, subcontainer) => {
    let metadataArray;
    if (subcontainer === 1) {
      metadataArray = this.state.documentData.metadata.slice(
        0,
        secondColumnStart
      );
    } else {
      metadataArray = this.state.documentData.metadata.slice(secondColumnStart);
    }
    return (
      <div
        className={
          subcontainer === 1
            ? styles.propertySubContainerOne
            : styles.propertySubContainerTwo
        }
      >
        {subcontainer === 1 && (
          <span className={styles.tagFieldLabel}>Library</span>
        )}
        <div className={styles.tagsContainer}>
          {subcontainer === 1 && (
            <div className={styles.tagField}>
              <span className={styles.metadataFieldValue}>
                {this.state.documentData.library}
              </span>
            </div>
          )}
        </div>
        {metadataArray.map((metadata, idx) => {
          return (
            <div key={metadata.id} className={styles.metadataField}>
              <span className={styles.metadataFieldLabel}>
                {metadata.document_field.label}
              </span>
              <span
                className={
                  metadata.document_field.label === "Description"
                    ? styles.description
                    : styles.metadataFieldValue
                }
              >
                {metadata.display}
              </span>
            </div>
          );
        })}
        {((subcontainer !== 1 && this.state.documentData.tags.length > 0) ||
          (this.state.documentData.tags.length > 0 &&
            this.state.documentData.metadata.length === 0)) && (
          <span className={styles.tagFieldLabel}>Tags</span>
        )}
        <div className={styles.tagsContainer}>
          {((subcontainer !== 1 && this.state.documentData.tags.length > 0) ||
            (this.state.documentData.tags.length > 0 &&
              this.state.documentData.metadata.length === 0)) &&
            this.state.documentData.tags.map((tag, idx) => {
              return (
                <div key={idx} className={styles.tagField}>
                  <span className={styles.metadataFieldValue}>{tag}</span>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  renderDocumentFieldValues = () => {
    let secondColumnStart;
    if (this.state.documentData.tags.length > 0) {
      secondColumnStart = Math.ceil(
        this.state.documentData.metadata.length / 2
      );
    } else {
      secondColumnStart = Math.ceil(
        (this.state.documentData.metadata.length - 1) / 2
      );
    }
    return (
      <div className={styles.approverContainer}>
        {this.renderDocumentFieldValuesColumn(secondColumnStart, 1)}
        {this.state.documentData.metadata.length !== 0 &&
          this.renderDocumentFieldValuesColumn(secondColumnStart, 2)}
      </div>
    );
  };

  render() {
    return (
      <div className={styles.exportPageContainer}>
        <div className={styles.certificateHeader}>
          <span>Certificate of Approval</span>
          <div className={styles.buttonContainer}>
            <UikButton
              className="uik-btn__base uik-btn__primary"
              onClick={() => window.print()}
            >
              Print this page
            </UikButton>
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <h1 className={styles.sectionTitle}>Title</h1>
          <h2 className={styles.documentTitle}>
            {this.state.documentData.title}
          </h2>
        </div>
        <div className={styles.sectionContainer}>
          <h1 className={styles.sectionTitle}>Content Properties</h1>
          {this.renderDocumentFieldValues()}
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.approvalHeader}>
            <h1
              className={
                styles.sectionTitle + ` ` + styles.approvalSectionTitle
              }
            >
              Approvals
            </h1>
          </div>
          <div className={styles.approvalHeader}>
            {this.state.documentData.everyone_approved &&
              !this.state.documentData.allApproversObservers && (
                <h2 className={styles.approvalStatus}>
                  <b>Completed on</b>{" "}
                  {moment(this.state.documentData.approvals_date).format(
                    "MMM D, YYYY"
                  )}
                </h2>
              )}
            {!this.state.documentData.everyone_approved && (
              <h2 className={styles.approvalStatus}>
                <b>
                  {this.state.documentData.pending_approvals} of{" "}
                  {this.state.documentData.total_approvals} remaining
                </b>
              </h2>
            )}
          </div>
          {this.renderApprovers()}
        </div>
        <div className={styles.certificateFooter}>
          <img src={papercurveApprovalSeal} />
          <p className={styles.footerTextContainer}>
            <span className={styles.dateDescriptor}>
              Date of Certificate:&nbsp;
            </span>
            {moment().format("DD MMM, YYYY")}
          </p>
        </div>
      </div>
    );
  }
}

export default ApprovalsExport;
