import React from "react";
import { Link } from "react-router-dom";

import LinkIcon from "../../../images/icons/svg/external-link-icon.svg";
import ArrowUpIcon from "../../../images/icons/svg/sorting-arrow-up.svg";
import ArrowDownIcon from "../../../images/icons/svg/sorting-arrow-down.svg";

import styles from "./Table.module.scss";

export default (props) => {
  const { columns, rows, sort, sortingColumn, sorting, openDraft } = props;

  return (
    <table className={styles.tableContainer}>
      <colgroup>
        {columns.map((column, index) => (
          <col
            key={index}
            span="1"
            className={`${
              column === "Title" ||
              column === "Uploader" ||
              column === "Library"
                ? styles.colLarge
                : styles.col
            }`}
          />
        ))}
      </colgroup>
      <thead className={styles.header}>
        <tr>
          {columns.map((column, index) => {
            if (column !== "") {
              return (
                <th
                  className={styles.columnName}
                  key={index}
                  onClick={() => sort(index)}
                >
                  <div className={styles.container}>
                    <div
                      className={`${styles.text} ${
                        sortingColumn === columns.length - 1 - index &&
                        styles.textBold
                      }`}
                    >
                      {column}
                    </div>
                    <div className={styles.icon}>
                      <img
                        className={
                          sortingColumn === columns.length - 1 - index &&
                          sorting === "desc"
                            ? styles.show
                            : ""
                        }
                        src={ArrowDownIcon}
                      />
                      <img
                        className={`${
                          sortingColumn !== columns.length - 1 - index
                            ? styles.arrowUpShow
                            : ""
                        } ${
                          sortingColumn === columns.length - 1 - index &&
                          sorting === "asc"
                            ? styles.show
                            : ""
                        }`}
                        src={ArrowUpIcon}
                      />
                    </div>
                  </div>
                </th>
              );
            } else {
              return <th key={index}>{column}</th>;
            }
          })}
        </tr>
      </thead>
      <tbody className={styles.body}>
        {rows.map((row, index) => (
          <tr key={index} onClick={() => openDraft(row[row.length - 1])}>
            {row.map((cell, index) => {
              if (index != row.length - 1) {
                return <td key={index}>{cell || "-"}</td>;
              } else {
                return (
                  <td key={index} className={styles.link}>
                    <div className={styles.container}>
                      <div className={styles.text}> View</div>
                      <img src={LinkIcon} />
                    </div>
                  </td>
                );
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
