import React from "react";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../../history";
import styles from "./DraftUpdate.module.scss";

import httpClient from "../../../lib/HttpClient";

import security from "../../../services/Security";

/* Papercurve Components */
import Header from "../../shared/Header/Header";
import DraftUpdateForm from "./DraftUpdateForm";

/* Variables */

class DraftUpdate extends React.Component {
  constructor() {
    super();

    this.state = {
      document: null,
    };
  }

  componentWillMount = () => {
    const currentUserRole = security.getUserRole();
    const docId = this.props.match.params.id;

    // Only Authors and Admins can upload and create drafts.
    if (currentUserRole !== "author" && currentUserRole !== "admin") {
      history.push("/dashboard");
    }

    httpClient.get(`/documents/${docId}.json`).then((response) => {
      this.setState({ document: response.data });
    });
  };

  render() {
    return this.state.document ? (
      <Container fluid className={styles.draftUpload}>
        <Header />
        <Row>
          <Col sm={12}>
            <div className={styles.draftUploadHeading}>
              <h1 className={styles.mainHeadline}>Upload a new version</h1>
              <span>
                Replace the current version of {this.state.document.title} with
                a new version.
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <DraftUpdateForm
              docId={this.props.match.params.id}
              showNotes={this.state.document.approvals.length > 0}
              approvals={this.state.document.approvals}
              showCopyReferencesToggle={this.state.document.has_references}
              referencesCount={this.state.document.references_count}
              documentTemplateId={this.state.document.document_template_id}
              convoCount={this.state.document.unresolved_conversations}
            />
          </Col>
        </Row>
      </Container>
    ) : null;
  }
}

export default DraftUpdate;
