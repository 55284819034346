import React from "react";

import styles from "./Confidence.module.scss";

export default (props) => {
  const { percentage } = props;

  let className = null;

  if (percentage < 33) {
    className = styles.red;
  } else if (percentage < 66) {
    className = styles.yellow;
  } else {
    className = styles.green;
  }

  return (
    <div className={styles.container}>
      <div className={styles.pill}>
        <div className={`${styles.indicator} ${className}`} />
        <div className={styles.percentage}>{`${Math.round(percentage)}%`}</div>
      </div>
      <div className={styles.hoverContainer}>
        <div className={styles.arrow}></div>
        <div className={styles.body}>
          {`${Math.round(percentage)}% Confident`}
        </div>
      </div>
    </div>
  );
};
