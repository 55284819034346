import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./SegmentListItem.module.css";

import security from "../../../services/Security";

const SegmentListItem = ({ segment, selectedSegmentId }) => (
  <Link to={`/contacts/segments/${segment.id}`}>
    <div
      className={
        styles.segmentListItem +
        (selectedSegmentId == segment.id ? " " + styles.selected : "") +
        (segment.active ? "" : " " + styles.deactiveSegmentListItemAvatar)
      }
    >
      <div className={styles.segmentListItemNameplate}>
        <span className={styles.segmentListItemNameplateName}>
          {segment.name}
          &nbsp;
        </span>
        <br />
        <span className={styles.segmentListItemNameplateTitle}>
          {segment.number_of_contacts}&nbsp;Contacts
        </span>
      </div>
    </div>
  </Link>
);

SegmentListItem.propTypes = {
  segment: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default SegmentListItem;
