import { combineReducers } from "redux";

import referenceDocumentsReducer from "./document/referenceDocumentsReducer";
import documentReferencesReducer from "./document/documentReferencesReducer";
import documentConversationsReducer from "./document/documentConversationsReducer";
import claimsReducer from "./document/claimsReducer";
import documentClaimsReducer from "./document/documentClaimsReducer";
import claimsSearchReducer from "./document/claimsSearchReducer";
import documentDynamicFieldsReducer from "./document/documentDynamicFieldsReducer";
import documentTemplatesReducer from "./document/documentTemplatesReducer";
import commentsReducer from "./document/commentsReducer";
import commentsForMeReducer from "./document/commentsForMeReducer";
import dashboardDataReducer from "./dashboard/dashboardDataReducer";
import commentFiltersReducer from "./document/commentFiltersReducer";
import globalSearchResultsReducer from "./globalsearch/globalSearchResultsReducer";
import globalSearchValueReducer from "./globalsearch/globalSearchValueReducer";

export default combineReducers({
  referenceDocuments: referenceDocumentsReducer,
  commentSearchResults: commentsReducer,
  commentFilters: commentFiltersReducer,
  commentsForMe: commentsForMeReducer,
  globalSearchResults: globalSearchResultsReducer,
  globalSearchValue: globalSearchValueReducer,
  claims: claimsReducer,
  documentClaims: documentClaimsReducer,
  libraryClaimsSearchResults: claimsSearchReducer,
  documentDynamicFields: documentDynamicFieldsReducer,
  documentTemplates: documentTemplatesReducer,
  dashboardData: dashboardDataReducer,
  documentReferences: documentReferencesReducer,
  documentConversations: documentConversationsReducer,
});
