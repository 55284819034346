import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { UikDivider } from "@uik";

import Header from "../shared/Header/Header";
import SettingsLeftMenu from "../shared/SettingsLeftMenu/SettingsLeftMenu";

import styles from "./Language.module.scss";
import LanguageEditForm from "./LanguageEditForm/LanguageEditForm";

export const Language = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className={styles.container}>
      <Header />
      <Row className={styles.row}>
        <SettingsLeftMenu />
        <Col sm={12} md={5}>
          <div className={styles.block}>
            <div className={styles.content}>
              <div className={styles.heading}>
                <div>{t("common_terms.language")}</div>
                <div className={styles.subHeading}>
                  {t("messaging.language_subheading")}
                </div>
              </div>
            </div>
            <UikDivider />
            <LanguageEditForm />
            <UikDivider />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
