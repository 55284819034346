import React from "react";

import styles from "./Divider.module.scss";

export default (props) => {
  const { title, count } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}> {title}</div>
      <div className={styles.horizontalLine}></div>
      <div className={styles.count}>{count}</div>
    </div>
  );
};
