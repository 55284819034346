import ActionTypes from "./types";
import httpClient from "../../lib/HttpClient";

export default (docId, date) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const commentData = {
        conversation: {
          document_id: parseInt(docId),
        },
        q: {
          created_at_gteq: date,
        },
      };
      const response = await httpClient.post(
        "/comments/for_me.json",
        commentData
      );
      dispatch({
        type: ActionTypes.GET_COMMENTS_FOR_ME,
        payload: response.data,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
