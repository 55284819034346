/* External libraries */
import React from "react";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";
import "../../animate.css";

import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./TagCreateForm.module.scss";

class TagCreateFormBuild extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      currentTag: {
        role: currentUserRole,
      },
      roles: [
        { value: "viewer", label: "Viewer" },
        { value: "author", label: "Author" },
        { value: "admin", label: "Admin" },
      ],
      libraries: [],
      selectedLibraries: [],
    };
  }

  componentWillMount = () => {
    // Only Authors and Admins can see tags screen
    if (
      this.state.currentTag.role !== "author" &&
      this.state.currentTag.role !== "admin"
    ) {
      history.push("/dashboard");
    }
  };

  handleTagCreation(e) {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const tagFormData = {
          name: values.name,
        };

        if (this.state.role) {
          tagFormData.tag.role = this.state.role;
        }

        this.props.handleTagCreate(tagFormData);
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleCancel = (e) => {
    history.push("/tags");
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.editTagContainer}>
        <UikWidget>
          <div className={styles.editContainer}>
            <UikHeadline>Create Tag</UikHeadline>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator(`name`, {
                rules: [
                  {
                    required: true,
                    message: "Please enter a name.",
                  },
                ],
              })(
                <UikInput
                  label="NAME"
                  placeholder="tag"
                  errorMessage={(getFieldError(`name`) || []).join(", ")}
                />
              )}
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={(e) => this.handleTagCreation(e)}
              primary
            >
              Create Tag
            </UikButton>
            <UikButton
              className="floatRight"
              onClick={(e) => this.handleCancel(e)}
            >
              Cancel
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

const TagCreateForm = createForm()(TagCreateFormBuild);
export default TagCreateForm;
