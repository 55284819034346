import React, { useState, useEffect } from "react";

import history from "../../../history";
import httpClient from "../../../lib/HttpClient";
import classNames from "classnames";
import security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";

import Dropzone from "react-dropzone";
import NewModal from "../../Common/Modal";
import UploadIcon from "../../../images/icons/svg/upload.svg";
import CloseIcon from "../../../images/icons/svg/close-grey.svg";
import Button from "../../Common/Buttons/ButtonPrimary";
import styles from "./NewItemModal.module.scss";

export default (props) => {
  const [structuredContentCategory, setStructuredContentCategory] = useState(
    {}
  );
  const [imageFile, setImageFile] = useState(null);
  const [imageCaption, setImageCaption] = useState(null);
  const [newStucturedContent, setNewStucturedContent] = useState("");
  const [structuredContent, setStructuredContent] = useState(null);
  const [editStructuredContent, setEditStructuredContent] = useState(null);
  const [SCProperties, setSCProperties] = useState({});

  useEffect(() => {
    if (props.selectedCategoryId > 0) {
      httpClient
        .get(`/structured_content_categories/${props.selectedCategoryId}.json`)
        .then((res) => {
          setStructuredContentCategory(res.data);
        });
    }
  }, []);

  const renderForm = () => {
    if (structuredContentCategory.column_type === "image") {
      return renderImageForm();
    } else {
      return renderTextForm();
    }
  };

  const onImageDrop = (acceptedFiles, rejectedFiles) => {
    setImageFile(acceptedFiles[0]);
  };

  const renderImageUploader = () => {
    let className = styles.mainContentUploader;
    return (
      <div className={className}>
        <Dropzone onDrop={onImageDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <img src={UploadIcon} />
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop approval document here
                    </span>
                  </div>
                ) : (
                  <div className={styles.uploaderBody}>
                    <div className={styles.contentLeft}>
                      <span className={styles.uploaderPlaceholder}>
                        Drop files here or
                      </span>
                    </div>
                    <div className={styles.contentRight}>
                      <Button transparent text="Browse">
                        Browse
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  const handleClose = () => {
    history.push(`/structured_content/category/${props.selectedCategoryId}`);
    props.setShowNewItemModal(false);
  };

  const renderImage = () => {
    return (
      <div className={styles.imageContainer}>
        <img
          onClick={() => {
            setImageFile(null);
          }}
          className={styles.close}
          src={CloseIcon}
        />
        <img className={styles.image} src={URL.createObjectURL(imageFile)} />
      </div>
    );
  };

  const handleImageCaptionChange = (e) => {
    setImageCaption(e.target.value);
  };

  const renderImageForm = () => {
    return (
      <UikFormInputGroup className={styles.formContainer}>
        <div className={styles.newCategoryFormCol}>
          <div className={styles.customInputContainer}>
            <div className={styles.customInputLabel}>Image</div>
            {!imageFile && renderImageUploader()}
            {imageFile && renderImage()}
          </div>
          <div className={styles.customInputContainer}>
            <div className={styles.newCategoryFormCol}>
              <UikInput
                onChange={(e) => {
                  handleImageCaptionChange(e);
                }}
                label="Caption"
                placeholder="Type a caption"
              />
            </div>
          </div>
        </div>
        <div className={styles.newCategoryFormCol}>
          <div className={styles.columnTwo}>
            <div>
              <UikInput
                label="Label"
                placeholder={`${structuredContentCategory.label_prefix}-${
                  structuredContentCategory.content_count + 1
                }`}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.spaceCol}></div>
      </UikFormInputGroup>
    );
  };

  const handlePropertyValueChange = (e, propertyId) => {
    let SCPropertiesCopy = { ...SCProperties };

    SCPropertiesCopy[propertyId] = {
      structured_content_id: null,
      structured_content_property_id: propertyId,
      value: e.target.value,
    };

    setSCProperties(SCPropertiesCopy);
  };

  const renderPropertyInput = (propertyLabel) => {
    let property;

    if (structuredContentCategory.structured_content_properties) {
      property = structuredContentCategory.structured_content_properties.find(
        (scp) => scp.label === propertyLabel
      );
    } else {
      property = null;
    }

    if (propertyLabel === "Caption") {
      return (
        <div className={styles.customInputContainer}>
          <div>
            <div className={styles.label}>
              <span className="uik-content-title__wrapper">Caption</span>
            </div>
            <textarea
              className={`uik-input__input `}
              style={{ width: "100%" }}
              rows="5"
              onChange={(e) => {
                handleChangeStructuredContentCaption(e);
              }}
              label="Caption"
              placeholder="Type some text"
            ></textarea>
          </div>
        </div>
      );
    } else if (propertyLabel === "Label") {
      return (
        <div>
          <div>
            <UikInput
              label="Label"
              placeholder={`${structuredContentCategory.label_prefix}-${
                structuredContentCategory.content_count + 1
              }`}
              disabled={true}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <UikInput
              label={`${propertyLabel}`}
              placeholder={`Input ${propertyLabel}`}
              disabled={false}
              onChange={(e) => {
                handlePropertyValueChange(e, property.id);
              }}
            />
          </div>
        </div>
      );
    }
  };

  const renderTextForm = () => {
    return (
      <UikFormInputGroup className={styles.formContainer}>
        {structuredContentCategory.all_property_labels.map((propertyLabel) => {
          return renderPropertyInput(propertyLabel);
        })}
      </UikFormInputGroup>
    );
  };

  const handleSave = () => {
    if (structuredContentCategory.column_type === "image") {
      const structuredContentFormData = new FormData();

      structuredContentFormData.append(
        "structured_content[structured_content_category_id]",
        props.selectedCategoryId
      );
      structuredContentFormData.append(
        "structured_content[caption]",
        imageCaption ? imageCaption : ""
      );
      structuredContentFormData.append(
        "structured_content[image_file]",
        imageFile
      );

      const token = security.getToken();
      const postConfig = {
        headers: {
          Authorization: "Bearer " + token,
        },
        // onUploadProgress: (progressEvent) => {
        //   const mainDocumentPercent = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   );
        //   if (mainDocumentPercent >= 100) {
        //     this.setState({ mainDocumentPercent: 100 });
        //   } else {
        //     this.setState({ mainDocumentPercent });
        //   }
        // },
      };

      httpClient
        .post(
          `/structured_contents.json`,
          structuredContentFormData,
          postConfig
        )
        .then((res) => {
          setNewStucturedContent(res.data);
          httpClient
            .get(
              `/structured_contents/category/${props.selectedCategoryId}.json`
            )
            .then((res) => {
              props.setItems(res.data);
              props.setAllItemsForCategory(res.data);
              notificationService.addNotification(
                "Structured Content Created",
                "Structured Content Created",
                "success"
              );
            });
          handleClose();
        });
    } else {
      httpClient
        .post("/structured_contents.json", structuredContent)
        .then((response) => {
          let newSCProperties = Object.keys(SCProperties).map((propertyId) => {
            let updatedScpv = SCProperties[propertyId];
            updatedScpv.structured_content_id = response.data.id;
            return updatedScpv;
          });
          let scpvData = {
            structured_content_property_values: newSCProperties,
          };
          httpClient
            .post("/structured_content_property_values.json", scpvData)
            .then((response) => {
              handleClose();
              httpClient
                .get(
                  `/structured_contents/category/${props.selectedCategoryId}.json`
                )
                .then((res) => {
                  props.setItems(res.data);
                  props.setAllItemsForCategory(res.data);
                  notificationService.addNotification(
                    "Structured Content Created",
                    "Structured Content Created",
                    "success"
                  );
                });
              history.push(
                `/structured_content/category/${props.selectedCategoryId}`
              );
            });
        });
    }
  };

  const handleChangeStructuredContentCaption = (e) => {
    setStructuredContent({
      ...structuredContent,
      caption: e.target.value,
      structured_content_category_id: props.selectedCategoryId,
    });
  };

  return (
    <NewModal
      // hideModal={props.showNewItemModal}
      visible={true}
      type="regular"
    >
      <div className="heading">New Item</div>
      <UikDivider />
      <div className="body">
        <div>
          {Object.keys(structuredContentCategory).length > 0 && renderForm()}
        </div>
      </div>
      <div className="clear" />
      <div className="dividerContainer">
        <UikDivider />
      </div>
      <div className="buttons">
        <UikButton onClick={(e) => handleSave(e)} primary>
          Save
        </UikButton>
        <UikButton onClick={() => handleClose()}>Cancel</UikButton>
      </div>
    </NewModal>
  );
};
