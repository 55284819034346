import ActionTypes from "./types";
import httpClient from "../../lib/HttpClient";

export default (search, fieldName) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      let searchData = {
        q: {
          search: search,
          structured_content_category_lookup: fieldName,
        },
      };

      const response = await httpClient.post(
        "/structured_contents/search.json",
        searchData
      );

      const resultLibrary = {
        searchTerm: search,
        searchResults: response.data,
      };

      dispatch({
        type: ActionTypes.SEARCH_STRUCTURED_CONTENTS,
        payload: resultLibrary,
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
