import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import axios from "../../lib/axios-client";
import HubspotChat from "../Common/HubspotChat";

import loginImageDesktop1x from "../../images/authentication/images/login-image-desktop.png";
import loginImageDesktop2x from "../../images/authentication/images/login-image-desktop@2x.png";
import loginImageDesktop3x from "../../images/authentication/images/login-image-desktop@3x.png";
import papercurveLogo from "../../images/logos/papercurve-logo-white.svg";

/* UI Kit */
import { UikButton } from "@uik";
import "@uik/styles.css";

import "./ActivateAccount.scss";

export const ActivateAccount = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(`/users/${props.match.params.user_id}/active`)
      .then((response) => {
        if (response.data) {
          props.history.push("/login");
        }
        setLoaded(true);
      })
      .catch(() => props.history.push("/login"));
  }, []);

  const sendResetEmail = () => {
    axios
      .post(`/users/${props.match.params.user_id}/send_password_reset`)
      .then(() =>
        props.history.push({
          pathname: "/login",
          state: {
            resetPassword: true,
          },
        })
      )
      .catch(() => props.history.push("/login"));
  };

  return loaded ? (
    <div className="authentication-screen">
      <div className="authentication-header">
        <a href="/">
          <img className="authentication-logo" src={papercurveLogo} />
        </a>
        <div className="hs-chat-container">
          <HubspotChat />
        </div>
      </div>
      <Container>
        <Row>
          <Col sm={12} md={4}>
            <div className="authentication-login-form">
              <h2 className="main-headline">
                Oops, you have not activated your account
              </h2>
              <div className="body">
                To create a password, a link will be sent to your email address.
              </div>
              <UikButton
                className="uik-btn__base uik-btn__primary reset-button"
                onClick={(e) => sendResetEmail(e)}
                primary
              >
                Send password reset link
              </UikButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <div className="authentication-login-image">
              <img
                src={loginImageDesktop1x}
                srcSet={`${loginImageDesktop1x} 1x, ${loginImageDesktop2x} 2x, ${loginImageDesktop3x} 3x`}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};
