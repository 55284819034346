class Comments {
  constructor() {}
  reloadComments = (docId) => {
    document.dispatchEvent(
      new CustomEvent("reload_comments", { detail: { docId: docId } })
    );
  };
}

export default new Comments();
