import React from "react";
import ReactNotification from "react-notifications-component";
import PropTypes from "prop-types";

import moment from "moment";
import { createForm, formShape } from "rc-form";
import { Container, Row, Col } from "react-grid-system";
import httpClient from "../../lib/HttpClient";

import styles from "./DraftApprovalForm.module.scss";
import "react-notifications-component/dist/theme.css";
import "../../animate.css";

import notificationService from "../../services/Notifications";
import DownChevron from "../../images/icons/svg/down-chevron.svg";

/* UI Kit */
import {
  UikButton,
  UikCheckbox,
  UikFormInputGroup,
  UikRadio,
  UikInput,
} from "@uik";
import Button from "../Common/Buttons/ButtonPrimary";
import "@uik/styles.css";

/* Variables */
const approvalStatuses = [
  {
    label: "Submit new version",
    value: "submit_new_version",
    id: 0,
  },
  {
    label: "Approved",
    value: "approved",
    id: 1,
  },
  {
    label: "Approved with changes",
    value: "approve_with_changes",
    id: 2,
  },
  {
    label: "Waiting for your approval",
    value: "pending",
    id: 3,
  },
];

class DraftApprovalForm extends React.Component {
  static propTypes = {
    form: formShape,
  };

  constructor() {
    super();
    this.state = {
      approval: null,
      password: "",
      showApprovalBox: false,
      passwordInvalid: false,
    };
  }

  enterPressed(event) {
    const code = event.keyCode || event.which;
    if (code === 13) {
      this.handleSubmit();
    }
  }

  handleSubmit = () => {
    const draftApprovalFormData = {
      approval: {
        status: this.state.approval,
      },
    };

    httpClient
      .post(`/users/validate`, {
        user: {
          password: this.state.password,
        },
      })
      .then((response) => {
        if (response.data) {
          httpClient
            .put(
              `/approvals/${this.props.approval.id}.json`,
              draftApprovalFormData
            )
            .then((response) => {
              this.props.updateApprovalsRemaining(
                response.data.document.pending_approvals
              );
              this.props.updateApproval(response.data);
              if (draftApprovalFormData.approval.status === "approved") {
                this.addApprovedNotification();
              } else if (
                draftApprovalFormData.approval.status === "approve_with_changes"
              ) {
                this.addApproveWithChangesNotification();
              } else {
                this.addRejectNotification();
              }
              this.setState({
                approval: null,
                password: "",
                showApprovalBox: false,
              });
            });
        } else {
          this.setState({ passwordInvalid: true });
        }
      });
  };

  setApproval = (e) => {
    this.setState({ approval: e.target.value });
  };

  addApprovedNotification = () => {
    notificationService.addNotification(
      "Document Approved",
      "You have submitted your approval for this document.",
      "success"
    );
  };

  addApproveWithChangesNotification = () => {
    notificationService.addNotification(
      "Document Approved",
      "You have submitted your approval with changes for this document.",
      "success"
    );
  };

  addRejectNotification = () => {
    notificationService.addNotification(
      "Document Rejected",
      "You have requested a new version for approval for this document.",
      "success"
    );
  };

  toggleShowApprovalBox = () => {
    this.setState({ showApprovalBox: !this.state.showApprovalBox });
  };

  render() {
    const { approval, readOnly, dueDate } = this.props;

    const passwordDisabled =
      this.state.password === "" || this.state.approval === null;

    return (
      <Container fluid className={styles.approvalForm}>
        <Row>
          <Col sm={12}>
            <h4 className={styles.approvalFormTitle}>MY APPROVAL</h4>
            {dueDate && (
              <span className={styles.approvalFormDueDate}>
                Due{" "}
                {moment(dueDate).calendar(null, {
                  lastDay: "[Yesterday]",
                  sameDay: "[Today]",
                  nextDay: "[Tomorrow]",
                  lastWeek: "[last] dddd",
                  nextWeek: "dddd",
                  sameElse: "L",
                })}
              </span>
            )}
            <div
              className={`${styles.approvalSelect} ${
                readOnly ? `${styles.approvalSelectReadOnly}` : ``
              } ${
                this.state.showApprovalBox
                  ? ` ${styles.approvalSelectFocused}`
                  : ``
              }`}
              onClick={!readOnly ? this.toggleShowApprovalBox : null}
            >
              <span className={styles.approvalLabel}>
                {
                  approvalStatuses.find(
                    (status) => status.value === approval.status
                  ).label
                }
              </span>
              <img className={styles.dropdownChevron} src={DownChevron} />
            </div>
            {this.state.showApprovalBox && (
              <div className={styles.approvalBoxContainer}>
                <div className={styles.approvalBoxContent}>
                  <div className={styles.approvalOption}>
                    <UikRadio
                      defaultChecked={approval.status == "approved"}
                      label="Approved"
                      value="approved"
                      name="status"
                      color="red"
                      disabled={readOnly}
                      onChange={this.setApproval}
                    />
                  </div>
                  <div className={styles.approvalOption}>
                    <UikRadio
                      defaultChecked={approval.status == "approve_with_changes"}
                      label="Approved with changes"
                      value="approve_with_changes"
                      name="status"
                      color="red"
                      disabled={readOnly}
                      onChange={this.setApproval}
                    />
                  </div>
                  <div className={styles.approvalOption}>
                    <UikRadio
                      defaultChecked={approval.status == "submit_new_version"}
                      label="Submit new version"
                      value="submit_new_version"
                      name="status"
                      color="red"
                      disabled={readOnly}
                      onChange={this.setApproval}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.passwordConfirmation} ${
                    this.state.passwordInvalid
                      ? `${styles.passwordInvalid}`
                      : ``
                  }`}
                >
                  <div className={styles.confirmPasswordLabel}>
                    confirm password
                  </div>
                  <UikInput
                    role="presentation"
                    autocomplete="off"
                    type="password"
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    onFocus={() => this.setState({ passwordInvalid: false })}
                    onKeyPress={(e) => this.enterPressed(e)}
                  ></UikInput>
                  {this.state.passwordInvalid && (
                    <div className={styles.passwordInvalidLabel}>
                      Password is invalid
                    </div>
                  )}
                  <Button
                    original
                    disabled={passwordDisabled}
                    text="Submit"
                    onClick={this.handleSubmit}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

DraftApprovalForm.propTypes = {
  dueDate: PropTypes.any,
  approval: PropTypes.shape({
    id: PropTypes.number,
    document_id: PropTypes.number,
    observer: PropTypes.bool,
    status: PropTypes.string,
    user_id: PropTypes.number,
  }).isRequired,
  readOnly: PropTypes.bool,
};

export default DraftApprovalForm;
