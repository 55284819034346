import React, { useState } from "react";
import Divider from "./Divider";
import { withRouter } from "react-router-dom";

import draftSVG from "../../../../images/icons/svg/draft-icon.svg";
import approvedSVG from "../../../../images/icons/svg/approved-icon.svg";
import referenceSVG from "../../../../images/icons/svg/reference-document-icon.svg";
import attachmentSVG from "../../../../images/icons/svg/attachment-document-icon.svg";
import templateSVG from "../../../../images/icons/svg/template-small-grey.svg";
import Button from "../../../Common/Buttons/ButtonPrimary";

import styles from "./GlobalSearchDropdown.module.scss";
import { Link } from "react-router-dom";
import ViewMore from "./ViewMore";

const GlobalSearchDropdown = (props) => {
  const [viewMore, setViewMore] = useState(null);

  const { hideDropdown, setFilter, activeFilter, inputValue } = props;

  const highlightSearchTerm = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text ? text.split(new RegExp(`(${highlight})`, "gi")) : [];
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase()
                ? styles.highlight
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  const approved_documents = props.searchResults
    ? props.searchResults.approved_documents
    : [];
  const document_attachments = props.searchResults
    ? props.searchResults.document_attachments
    : [];
  const draft_documents = props.searchResults
    ? props.searchResults.draft_documents
    : [];
  const reference_documents = props.searchResults
    ? props.searchResults.reference_documents
    : [];
  const document_templates = props.searchResults
    ? props.searchResults.document_templates
    : [];

  const empty =
    approved_documents.length === 0 &&
    document_attachments.length === 0 &&
    draft_documents.length === 0 &&
    reference_documents.length === 0 &&
    document_templates.length === 0;

  const loadSearchedTemplateUploadScreen = (template) => {
    hideDropdown();

    let url = `/drafts/upload#templateId=${template.id},libraryId=${template.library_id}`;
    window.location.href = url;
  };

  const handleViewMoreClicked = (section) => {
    switch (section) {
      case "draft":
        setViewMore({
          label: "draft",
          records: draft_documents,
          icon: draftSVG,
        });
        break;
      case "approved":
        setViewMore({
          label: "approved",
          records: approved_documents,
          icon: approvedSVG,
        });
        break;
      case "reference":
        setViewMore({
          label: "reference",
          records: reference_documents,
          icon: referenceSVG,
        });
        break;
      case "attachments":
        setViewMore({
          label: "attachments",
          records: document_attachments,
          icon: attachmentSVG,
        });
        break;
      case "templates":
        setViewMore({
          label: "templates",
          records: document_templates,
          icon: templateSVG,
        });
        break;
      default:
        break;
    }
  };

  const handleFilterToggle = (option) => {
    setViewMore(false);
    if (!activeFilter || (activeFilter && activeFilter !== option)) {
      setFilter(option);
    } else if (option === activeFilter) {
      setFilter(null);
    }
  };

  return (
    <div id="global-search-dropdown" className={styles.container} tabIndex="0">
      <div className={styles.searchOptions}>
        <p className={styles.searchDescriptor}>
          Search for content, references, people, and more
        </p>
        <h3 className={styles.searchSubheading}>quick search</h3>
        <div className={styles.filtersContainer}>
          <div
            className={
              activeFilter && activeFilter === "title"
                ? styles.filterButton
                : styles.filterButtonInactive
            }
            onClick={() => handleFilterToggle("title")}
          >
            Title
          </div>
          <div
            className={
              activeFilter && activeFilter === "owner"
                ? styles.filterButton
                : styles.filterButtonInactive
            }
            onClick={() => handleFilterToggle("owner")}
          >
            Owner
          </div>
          <div
            className={
              activeFilter && activeFilter === "sequential"
                ? styles.filterButton
                : styles.filterButtonInactive
            }
            onClick={() => handleFilterToggle("sequential")}
          >
            Job ID
          </div>
        </div>
      </div>
      {!viewMore ? (
        empty && props.inputValue ? (
          <div className={styles.emptyState}>
            There is no content matching your search
          </div>
        ) : (
          <div className={styles.nonEmptyState}>
            {draft_documents.length !== 0 && (
              <div className={styles.section}>
                <Divider title="draft" count={draft_documents.length} />
                {draft_documents.slice(0, 5).map((draftDocument, index) => (
                  <Link
                    className={styles.link}
                    key={index}
                    to={{
                      pathname: `/drafts/${draftDocument.id}`,
                      state: {
                        backTo: props.location.pathname,
                      },
                    }}
                    onClick={hideDropdown}
                  >
                    <div className={styles.recordContainer}>
                      <div className={styles.logo}>
                        <img src={draftSVG} />
                      </div>
                      <div className={styles.documentDescriptor}>
                        <div className={styles.title}>
                          {activeFilter === "title" || !activeFilter
                            ? highlightSearchTerm(
                                draftDocument.title,
                                inputValue
                              )
                            : draftDocument.title}
                        </div>
                        <div className={styles.documentMetadata}>
                          {draftDocument.owner_name && (
                            <div className={styles.documentDetails}>
                              {" "}
                              Owner:{" "}
                              {activeFilter === "owner" || !activeFilter
                                ? highlightSearchTerm(
                                    draftDocument.owner_name,
                                    inputValue
                                  )
                                : draftDocument.owner_name}
                            </div>
                          )}
                          {draftDocument.sequential &&
                            draftDocument.sequential.value && (
                              <div className={styles.documentDetails}>
                                {draftDocument.sequential.label}:{" "}
                                {activeFilter === "sequential" || !activeFilter
                                  ? highlightSearchTerm(
                                      draftDocument.sequential.value,
                                      inputValue
                                    )
                                  : draftDocument.sequential.value}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
                {draft_documents.length > 5 && (
                  <div
                    className={styles.viewMore}
                    onClick={() => handleViewMoreClicked("draft")}
                  >
                    View More
                  </div>
                )}
              </div>
            )}
            {approved_documents.length !== 0 && (
              <div className={styles.section}>
                <Divider title="approved" count={approved_documents.length} />
                {approved_documents
                  .slice(0, 5)
                  .map((approvedDocument, index) => (
                    <Link
                      className={styles.link}
                      key={index}
                      to={{
                        pathname: `/documents/${approvedDocument.id}`,
                        state: {
                          backTo: props.location.pathname,
                        },
                      }}
                      onClick={hideDropdown}
                    >
                      <div className={styles.recordContainer}>
                        <div className={styles.logo}>
                          <img src={approvedSVG} />
                        </div>
                        <div className={styles.documentDescriptor}>
                          <div className={styles.title}>
                            {activeFilter === "title" || !activeFilter
                              ? highlightSearchTerm(
                                  approvedDocument.title,
                                  inputValue
                                )
                              : approvedDocument.title}
                          </div>
                          <div className={styles.documentMetadata}>
                            {approvedDocument.owner_name && (
                              <div className={styles.documentDetails}>
                                {" "}
                                Owner:{" "}
                                {activeFilter === "owner" || !activeFilter
                                  ? highlightSearchTerm(
                                      approvedDocument.owner_name,
                                      inputValue
                                    )
                                  : approvedDocument.owner_name}
                              </div>
                            )}
                            {approvedDocument.sequential &&
                              approvedDocument.sequential.value && (
                                <div className={styles.documentDetails}>
                                  {approvedDocument.sequential.label}:{" "}
                                  {activeFilter === "sequential" ||
                                  !activeFilter
                                    ? highlightSearchTerm(
                                        approvedDocument.sequential.value,
                                        inputValue
                                      )
                                    : approvedDocument.sequential.value}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                {approved_documents.length > 5 && (
                  <div
                    className={styles.viewMore}
                    onClick={() => handleViewMoreClicked("approved")}
                  >
                    View More
                  </div>
                )}
              </div>
            )}
            {reference_documents.length !== 0 && (
              <div className={styles.section}>
                <Divider
                  title="references"
                  count={reference_documents.length}
                />
                {reference_documents
                  .slice(0, 5)
                  .map((referenceDocument, index) => (
                    <Link
                      className={styles.link}
                      key={index}
                      to={{
                        pathname: `/references/${referenceDocument.id}/library`,
                        state: {
                          backTo: props.location.pathname,
                        },
                      }}
                      onClick={hideDropdown}
                    >
                      <div className={styles.recordContainer}>
                        <div className={styles.logo}>
                          <img src={referenceSVG} />
                        </div>
                        <div className={styles.title}>
                          {highlightSearchTerm(
                            referenceDocument.title,
                            inputValue
                          )}
                        </div>
                      </div>
                    </Link>
                  ))}
                {reference_documents.length > 5 && (
                  <div
                    className={styles.viewMore}
                    onClick={() => handleViewMoreClicked("reference")}
                  >
                    View More
                  </div>
                )}
              </div>
            )}
            {document_attachments.length !== 0 && (
              <div className={styles.section}>
                <Divider
                  title="attachments"
                  count={document_attachments.length}
                />
                {document_attachments
                  .slice(0, 5)
                  .map((documentAttachment, index) => (
                    <Link
                      className={styles.link}
                      key={index}
                      to={{
                        pathname: documentAttachment.approved
                          ? `/documents/${documentAttachment.document_id}`
                          : `/drafts/${documentAttachment.document_id}`,
                        state: {
                          backTo: props.location.pathname,
                        },
                        hash: "#attachments",
                      }}
                      onClick={hideDropdown}
                    >
                      <div className={styles.recordContainer}>
                        <div className={styles.logo}>
                          <img src={attachmentSVG} />
                        </div>
                        <div className={styles.title}>
                          {highlightSearchTerm(
                            documentAttachment.title,
                            inputValue
                          )}
                        </div>
                      </div>
                    </Link>
                  ))}
                {document_attachments.length > 5 && (
                  <div
                    className={styles.viewMore}
                    onClick={() => handleViewMoreClicked("attachments")}
                  >
                    View More
                  </div>
                )}
              </div>
            )}
            {document_templates.length !== 0 && (
              <div className={styles.section}>
                <Divider title="templates" count={document_templates.length} />
                {document_templates
                  .slice(0, 5)
                  .map((documentTemplate, index) => (
                    <div
                      className={styles.link}
                      key={index}
                      to={{
                        pathname: "/drafts/upload",
                        state: {
                          backTo: props.location.pathname,
                        },
                        hash: `templateId=${documentTemplate.id},libraryId=${documentTemplate.library_id}`,
                      }}
                      onClick={() =>
                        loadSearchedTemplateUploadScreen(documentTemplate)
                      }
                    >
                      <div className={styles.recordContainer}>
                        <div className={styles.logo}>
                          <img src={templateSVG} />
                        </div>
                        <div className={styles.title}>
                          {documentTemplate.title}
                        </div>
                      </div>
                    </div>
                  ))}
                {document_templates.length > 5 && (
                  <div
                    className={styles.viewMore}
                    onClick={() => handleViewMoreClicked("templates")}
                  >
                    View More
                  </div>
                )}
              </div>
            )}
          </div>
        )
      ) : (
        <ViewMore
          label={viewMore.label}
          icon={viewMore.icon}
          records={viewMore.records}
          backToResults={() => setViewMore(null)}
          hideDropdown={hideDropdown}
          inputValue={inputValue}
          activeFilter={activeFilter}
        />
      )}
    </div>
  );
};

export default withRouter(GlobalSearchDropdown);
