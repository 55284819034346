import React, { useState } from "react";

import dimple from "../../../images/icons/svg/toolbox-dimple-blue.svg";

import styles from "./Tooltip.module.scss";

export default (props) => {
  const {
    defaultIcon,
    hoverIcon,
    clickedIcon,
    position,
    children,
    className = null,
  } = props;
  const [imgSrc, setImgSrc] = useState(defaultIcon);

  const onMouseEnter = () => {
    if (imgSrc !== clickedIcon) setImgSrc(hoverIcon);
  };

  const onMouseLeave = () => {
    if (imgSrc !== clickedIcon) setImgSrc(defaultIcon);
  };

  const onClick = () => {
    if (imgSrc === hoverIcon) {
      setImgSrc(clickedIcon);
    } else {
      setImgSrc(hoverIcon);
    }
  };

  let boxClassName = styles.box;

  switch (position) {
    case "right":
      boxClassName += ` ${styles.boxRight}`;
      break;
    default:
      break;
  }

  if (imgSrc === hoverIcon || imgSrc === clickedIcon) {
    boxClassName += ` ${styles.boxVisible}`;
  }

  return (
    <div className={`${styles.tooltipContainer} ${className ? className : ""}`}>
      <div className={styles.icon}>
        <img
          src={imgSrc}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
        />
      </div>
      <div className={boxClassName}>
        <div className={styles.dimple}>
          <img src={dimple} />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
