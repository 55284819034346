import React, { useState, useEffect } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

import styles from "./LinkClaimDiff.module.scss";

export default (props) => {
  const { claim } = props;

  const customStyles = {
    variables: {
      dark: {
        addedBackground: "#fbfbfd",
        removedBackground: "#fbfbfd",
      },
      light: {
        addedBackground: "#393d50",
        addedColor: "white",
        removedBackground: "#393d50",
        removedColor: "white",
        wordAddedBackground: "#4c6c5f",
        wordRemovedBackground: "#5F3434",
      },
    },
    diffAdded: {
      fontFamily: "Montserrat",
    },
  };

  return (
    <div className={styles.claimDiffContainer}>
      <h3 className={styles.diffTitle}>suggested product claim differences</h3>
      <ReactDiffViewer
        styles={customStyles}
        compareMethod={DiffMethod.WORDS}
        oldValue={claim.caption}
        newValue={claim.claim.caption}
        splitView={false}
        hideLineNumbers={true}
      />
    </div>
  );
};
