import React, { useState } from "react";
import { Container, Row } from "react-grid-system";
import Header from "../shared/Header/Header";
import SettingsLeftMenu from "../shared/SettingsLeftMenu/SettingsLeftMenu";
import NotificationsMenu from "./NotificationsMenu";
import styles from "./SetupNotifications.module.scss";
import ManageAlerts from "./ManageAlerts/ManageAlerts";
import DateReminders from "./DateReminders";

export const SetupNotifications = () => {
  const [ShowNotificationsMenu, setShowNotificationsMenu] = useState(true);
  const [ShowManageAlerts, setShowManageAlerts] = useState(false);
  const [showDateReminders, setShowDateReminders] = useState(false);

  const renderNotificationsMenu = () => {
    setShowNotificationsMenu(true);
    setShowManageAlerts(false);
    setShowDateReminders(false);
  };
  const hideNotificationsMenu = () => {
    setShowNotificationsMenu(false);
  };
  const renderManageAlerts = () => {
    setShowManageAlerts(true);
  };
  const renderDateReminders = () => {
    setShowDateReminders(true);
  };

  return (
    <Container fluid className={styles.container}>
      <Header />
      <Row className={styles.row}>
        <SettingsLeftMenu />
        {ShowNotificationsMenu && (
          <NotificationsMenu
            hideNotificationsMenu={hideNotificationsMenu}
            renderManageAlerts={renderManageAlerts}
            renderDateReminders={renderDateReminders}
          />
        )}
        {ShowManageAlerts && (
          <ManageAlerts renderNotificationsMenu={renderNotificationsMenu} />
        )}
        {showDateReminders && (
          <DateReminders renderNotificationsMenu={renderNotificationsMenu} />
        )}
      </Row>
    </Container>
  );
};
