import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Document as Pdf, Page, pdfjs } from "react-pdf";
import styles from "./Document.module.scss";
import { apiUrl } from "../../lib/axios-client";

import "react-pdf/dist/Page/AnnotationLayer.css";

import security from "../../services/Security";

/* UI Kit */
import { Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import ConversationIndicators from "./ConversationIndicators";
import DocumentReferenceIndicators from "./DocumentReferenceIndicators";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/* Variables */

class Document extends React.Component {
  constructor(props) {
    super();

    this.state = {
      docId: props.doc.id,
      generatedIds: false,
      documentPage1: false,
      highlightedBlockId: null,
      currentConversation: {},
      versionId: null,
    };
  }

  componentDidMount() {
    if (!this.state.generatedIds) {
      const { doc } = this.props;
      const numberOfPages = doc.number_of_pages;

      this.setState({ generatedIds: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.doc.id != this.props.doc.id) {
      return true;
    } else if (
      nextProps.version &&
      this.props.versionId != nextProps.versionId
    ) {
      return true;
    }

    return false;
  }

  componentUpdate = (prevProps, prevState, snapshot) => {};

  getAllBetweenSelectionElements = (firstEl, lastEl) => {
    const firstElement = firstEl;
    const lastElement = lastEl;
    const unhighlightElements = document.getElementsByClassName(
      "selectedDocumentText"
    );

    while (unhighlightElements[0]) {
      unhighlightElements[0].classList.remove("selectedDocumentText");
    }

    let blockIdentifier = "";

    let collection = [];
    collection.push(firstElement);

    let currentElement = firstElement;

    if (currentElement !== null && currentElement.innerHTML) {
      let htmlstring = currentElement.innerHTML;
      htmlstring = htmlstring.trim
        ? htmlstring.trim()
        : htmlstring.replace(/^\s+/, "");

      if (htmlstring !== "") {
        currentElement.classList.add("selectedDocumentText");
        blockIdentifier = currentElement.id;
      }
    }

    // Loop through all elements until we reach the end and store it in our collection.
    while (
      currentElement !== null &&
      currentElement !== lastElement &&
      typeof currentElement.nextSibling !== "undefined"
    ) {
      if (currentElement !== null) {
        collection.push(currentElement.nextSibling);
        currentElement = currentElement.nextSibling;

        if (currentElement !== null && currentElement.innerHTML) {
          let htmlstring = currentElement.innerHTML;
          htmlstring = htmlstring.trim
            ? htmlstring.trim()
            : htmlstring.replace(/^\s+/, "");

          if (htmlstring !== "") {
            currentElement.classList.add("selectedDocumentText");
            blockIdentifier = blockIdentifier + "--" + currentElement.id;
          }
        }
      }
    }

    this.setState({ highlightedBlockId: blockIdentifier });
    this.props.highlightedBlock(blockIdentifier);
  };

  getSelectionElements = () => {
    if (this.props.readOnly) {
      return;
    }
    if (window.getSelection) {
      // non-IE
      const userSelection = window.getSelection();
      if (!userSelection.isCollapsed) {
        const rangeObject = userSelection.getRangeAt(0);

        if (rangeObject.startContainer == rangeObject.endContainer) {
          this.getAllBetweenSelectionElements(
            rangeObject.startContainer.parentNode,
            rangeObject.endContainer.parentNode
          );
        } else {
          this.getAllBetweenSelectionElements(
            rangeObject.startContainer.parentNode,
            rangeObject.endContainer.parentNode
          );
          //alert(getAllBetween(
          //  rangeObject.startContainer.parentNode,
          //  rangeObject.endContainer.parentNode));
        }

        if (window.getSelection) {
          window.getSelection().removeAllRanges();
        } else if (document.selection) {
          document.selection.empty();
        }
      }
    } else if (document.selection) {
      // IE lesser
      const userSelection = document.selection.createRange();
      var ids = new Array();

      if (userSelection.htmlText.toLowerCase().indexOf("span") >= 0) {
        //$(userSelection.htmlText).filter('span').each(function(index, span) {
        //  ids.push(span.id);
        //});
        //alert(ids);
      } else {
        alert(userSelection.parentElement());
      }
    }
  };

  handlePdfPageWidth = () => {
    if (window.innerWidth > 1280) {
      return 900;
    } else {
      return 800;
    }
  };

  handlePdfPageWidth = () => {
    if (window.innerWidth > 1280) {
      return 900;
    } else {
      return 800;
    }
  };

  renderPages = (numberOfPages) => {
    const pages = [];

    for (let i = 0; i < numberOfPages; i++) {
      pages.push(i + 1);
    }
    return pages.map((i, j) => (
      <div
        key={i}
        id={`documentPage${i}`}
        ref={`documentPage${i}`}
        onMouseUp={this.getSelectionElements}
      >
        <div className={styles.documentPageContainer}>
          <Page
            width={this.handlePdfPageWidth()}
            pageNumber={i}
            onLoadSuccess={this.handlePdfPageLoaded(i)}
            className={styles.pdfDocumentPage}
          />
        </div>
        <ConversationIndicators
          loadConversation={this.props.loadConversation}
          docId={this.props.doc.id}
          pageNumber={i}
          versionId={this.props.versionId}
          versionNumber={this.props.versionNumber}
        />
        <DocumentReferenceIndicators
          loadReference={this.props.loadReference}
          docId={this.props.doc.id}
          pageNumber={i}
          versionId={this.props.versionId}
          versionNumber={this.props.versionNumber}
        />
      </div>
    ));
  };

  handlePdfPageLoaded = (i) => {
    const { doc } = this.props;
    const myInterval = setInterval(function () {
      const documentPageId = "documentPage" + i;
      if (document.getElementById(documentPageId)) {
        const textSpans = document
          .getElementById(documentPageId)
          .getElementsByTagName("span");

        for (let j = 0; j < textSpans.length; j++) {
          if (textSpans[j]) {
            const textId = doc.id + "-" + "p-" + i + "-b-" + j;
            textSpans[j].setAttribute("id", textId);
          }
        }
      }
    }, 100);

    setTimeout(function () {
      clearInterval(myInterval);
    }, 5000);
  };

  render() {
    const { doc } = this.props;
    if (doc.id == undefined) {
      return <div></div>;
    }
    return (
      <div className={styles.pdfDocumentContainer}>
        <Pdf
          id="asdf"
          file={{
            url: `${apiUrl}/documents/${doc.id}/file`,
            httpHeaders: { Authorization: `Bearer ${security.getToken()}` },
          }}
          className={styles.pdfDocument}
          ref="documentPages"
        >
          {this.renderPages(doc.number_of_pages)}
        </Pdf>
      </div>
    );
  }
}

Document.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    filename: PropTypes.string,
    thumbnail: PropTypes.string,
  }).isRequired,
  conversations: PropTypes.object,
  highlightedBlock: PropTypes.func,
  loadConversation: PropTypes.func,
  loadReference: PropTypes.func,
};

export default Document;
