/* External libraries */
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../../history";
import httpClient from "../../../lib/HttpClient";

import security from "../../../services/Security";
import notificationService from "../../../services/Notifications";

/* UI Kit */
import { UikInput, UikButton, Uikon } from "@uik";
import "@uik/styles.css";
import "../../../font.scss";
import "./Segments.css";

/* Papercurve Components */
import Header from "../../shared/Header/Header";
import ContactsLeftMenu from "../ContactsLeftMenu";
import SegmentListItem from "./SegmentListItem";
import SegmentListItemsHeader from "./SegmentListItemsHeader";
import SegmentEditForm from "./SegmentEditForm";
import SegmentCreateForm from "./SegmentCreateForm";

/* Assets */
import styles from "./Segments.module.scss";

/* Variables */

class Segments extends React.Component {
  constructor() {
    super();

    this.state = {
      segments: [],
      filteredSegments: [],
      selectedSegment: null,
      segmentSearchQuery: null,
    };
  }

  componentWillMount = () => {
    this.loadSegments();
  };

  loadSegments = () => {
    const selectedSegmentId = this.props.match.params.id;

    httpClient.get("/contact_segments.json").then((response) => {
      let filteredSegments = [];

      this.setState({ segments: response.data });

      response.data.map((segment, index) => {
        filteredSegments.push(segment);
      });

      this.setState({ filteredSegments: filteredSegments });

      response.data.map((segment, index) => {
        if (segment.id == selectedSegmentId) {
          this.setState({ selectedSegment: segment });
        }
      });
    });
  };

  componentWillReceiveProps = (nextProps) => {
    const selectedSegmentId = nextProps.match.params.id;
    this.state.segments.map((segment, index) => {
      if (segment.id == selectedSegmentId) {
        this.setState({ selectedSegment: segment });
      }
    });
  };

  renderSegmentList = () => {
    const selectedSegmentId = this.props.match.params.id;
    const letterHeaders = [];
    return (
      <div>
        {this.state.filteredSegments.map((segment, index) => {
          const letter = segment.name.charAt(0).toUpperCase();
          const headerExists = letterHeaders.includes(letter);

          if (!headerExists) {
            letterHeaders.push(letter);
          }

          return (
            <div key={segment.id}>
              <SegmentListItem
                segment={segment}
                selectedSegmentId={selectedSegmentId}
              />
            </div>
          );
        })}
      </div>
    );
  };

  renderSegmentEditForm = () => {
    return (
      <div>
        {this.state.selectedSegmentId != "create" &&
          this.state.selectedSegment && (
            <SegmentEditForm
              key={this.state.selectedSegment.id}
              segment={this.state.selectedSegment}
              handleSegmentEdit={this.handleSegmentEdit}
              handleSegmentDelete={this.handleSegmentDelete}
            />
          )}
      </div>
    );
  };

  handleSegmentCreate = (segmentFormData) => {
    httpClient
      .post("/contact_segments.json", segmentFormData)
      .then((response) => {
        console.log("created!", response);
        history.push("/contacts/segments");
        this.loadSegments();
        notificationService.addNotification(
          "Segment created",
          "New segment has been added.",
          "success"
        );
      })
      .catch((e) => {
        let message = "";
        if (e.response && e.response.data) {
          for (let key in e.response.data) {
            message += `${key.charAt(0).toUpperCase() + key.slice(1)}  ${
              e.response.data[key]
            }`;
          }
        }
        notificationService.addNotification("Error", message, "danger");
      });
  };

  handleSegmentEdit = (segmentFormData) => {
    httpClient
      .put(
        `/contact_segments/${this.state.selectedSegment.id}.json`,
        segmentFormData
      )
      .then((response) => {
        console.log("edited!", response);
        this.loadSegments();
        notificationService.addNotification(
          "Segment saved",
          "Segment has been saved.",
          "success"
        );
      })
      .catch((e) => {
        let message = "";
        if (e.response && e.response.data) {
          for (let key in e.response.data) {
            message += `${key.charAt(0).toUpperCase() + key.slice(1)}  ${
              e.response.data[key]
            }`;
          }
        }
        notificationService.addNotification("Error", message, "danger");
      });
  };

  handleSegmentDelete = () => {
    if (window.confirm("Are you sure you want to delete this segment?")) {
      httpClient
        .delete(`/contact_segments/${this.state.selectedSegment.id}.json`)
        .then((response) => {
          history.push("/contacts/segments");
          this.setState({ selectedSegment: null });
          notificationService.addNotification(
            "Segment Deleted",
            "This segment has been deleted.",
            "danger"
          );
          this.loadSegments();
        })
        .catch((error) => {
          notificationService.addNotification(
            `${error.response.data.contact_segment[0]}`,
            `${error.response.data.contact_segment[0]}`,
            "danger"
          );
        });
    }
  };

  renderSegmentCreateForm = () => {
    return (
      <div>
        <SegmentCreateForm handleSegmentCreate={this.handleSegmentCreate} />
      </div>
    );
  };

  handleSegmentSearch = (e) => {
    e.preventDefault();

    let segments = this.state.segments;
    let segmentSearchQuery = e.target.value.toLowerCase();
    if (segmentSearchQuery.trim() === "") {
      this.setState({ filteredSegments: this.state.segments });
      return;
    }
    segments = segments.filter(function (segment) {
      let matchingSegment = false;
      if (segment.name.toLowerCase().indexOf(segmentSearchQuery) != -1) {
        matchingSegment = true;
      }
      return matchingSegment;
    });
    this.setState({ filteredSegments: segments });
  };

  render() {
    return (
      <Container fluid className="segments-screen">
        <Header />
        <Row className="segments-screen__container">
          <ContactsLeftMenu />
          <Col
            sm={12}
            md={5}
            className="segments-screen-segment-list-container"
          >
            <div className="segments-screen-segment-list">
              <div className="segments-screen-segment-search">
                <Uikon>search_left</Uikon>
                <div className="segment-search-input-container">
                  <UikInput
                    className="segments-screen-segment-search-input"
                    placeholder="Search Segment"
                    onChange={(e) => this.handleSegmentSearch(e)}
                  />
                </div>
                <div className="segment-invite-button-container">
                  <Link to="/contacts/segments/create">
                    <UikButton>Create Segment</UikButton>
                  </Link>
                </div>
              </div>
              {this.renderSegmentList()}
            </div>
          </Col>
          <Col
            sm={12}
            md={5}
            className="segments-screen-segment-form-container"
          >
            {this.props.match.params.id == "create"
              ? this.renderSegmentCreateForm()
              : this.renderSegmentEditForm()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Segments;
