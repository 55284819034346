import ActionTypes from "./types";
import httpClient from "../../lib/HttpClient";

export default (search, filter) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await httpClient.post("/documents/global_search.json", {
        search,
        filter,
      });

      dispatch({
        type: ActionTypes.FETCH_GLOBAL_SEARCH_RESULTS,
        payload: response.data,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
