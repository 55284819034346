import React from "react";
import Checkmark from "../../../images/icons/svg/checkmark-white.svg";

import "./Step.scss";

export const Step = ({ number, completed = false, text }) => {
  let className = "step-container";

  if (completed) {
    className += " step-container__completed";
  }

  return (
    <div className={className}>
      <div className="step-circle">
        {!completed ? number : <img src={Checkmark} />}
      </div>
      <div className="text">{text}</div>
    </div>
  );
};
