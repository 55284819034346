import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

class Security {
  constructor() {
    this.cookies = new Cookies();
    this.redirectName = "redirect";
    this.tokenName = "jwt";
  }

  getToken = () => {
    return this.cookies.get(this.tokenName)
      ? this.cookies.get(this.tokenName)
      : "";
  };

  setToken = (token) => {
    this.cookies.set(this.tokenName, token, { path: "/" });
  };

  getUser = () => {
    return this.getToken() ? jwtDecode(this.getToken()) : "";
  };

  getUserId = () => {
    return this.getUser() ? this.getUser().user_id : "";
  };

  getUserFirstName = () => {
    return this.getUser() ? this.getUser().user_first_name : "";
  };

  getUserLastName = () => {
    return this.getUser() ? this.getUser().user_last_name : "";
  };

  getUserRole = () => {
    return this.getUser() ? this.getUser().user_role : "";
  };

  isPapercurveUser = () => {
    return this.getUser() ? this.getUser().user_papercurve_user : false;
  };

  setRedirect = (path) => {
    this.cookies.set(this.redirectName, path, { path: "/" });
  };

  getRedirect = () => {
    return this.cookies.get(this.redirectName);
  };

  clearRedirect = (path) => {
    this.cookies.remove(this.redirectName);
  };

  redirectAfterLogin = () => {
    let redirect = "";
    if ((redirect = this.getRedirect())) {
      this.clearRedirect();
      window.location.href = redirect;
    } else {
      window.location.href = "/dashboard";
    }
  };

  redirectAfterSetup = (pathName) => {
    window.location.href = pathName;
  };

  checkAuth = (error) => {
    if (error && error.response && error.response.status == 401) {
      if (
        window.location.pathname != "/" &&
        window.location.pathname != "" &&
        window.location.pathname != "/login"
      ) {
        this.setToken("");
        this.setRedirect(window.location.pathname);
      }
      window.location = "/login";
    } else {
      if (this.getToken() == "") {
        if (
          window.location.pathname != "/" &&
          window.location.pathname != "" &&
          window.location.pathname != "/login"
        ) {
          this.setRedirect(window.location.href);
        }
        return false;
      }
    }

    return true;
  };

  logout = () => {
    this.cookies.remove(this.tokenName, { path: "/" });
  };
}

export default new Security();
