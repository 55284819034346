import DocumentActionTypes from "../../actions/document/types";

export default (commentSearchResults = null, action) => {
  switch (action.type) {
    case DocumentActionTypes.SEARCH_COMMENTS:
      return action.payload;
    case DocumentActionTypes.RESET_SEARCH_COMMENTS_RESULTS:
      return null;
    default:
      return commentSearchResults;
  }
};
