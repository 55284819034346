export default {
  //Reset Document
  RESET_DOCUMENT_STATE: "RESET_DOCUMENT_STATE",
  //Reference Documents
  FETCH_REFERENCE_DOCUMENTS: "FETCH_REFERENCE_DOCUMENTS",
  //Search Comments
  SEARCH_COMMENTS: "SEARCH_COMMENTS",
  // Reset Search Comments
  RESET_SEARCH_COMMENTS_RESULTS: "RESET_SEARCH_COMMENTS_RESULTS",
  // Store commentor filter state
  SET_COMMENT_FILTERS: "SET_COMMENT_FILTERS",
  // Reset commentor filter state
  RESET_COMMENT_FILTERS: "RESET_COMMENT_FILTERS",
  // Store forMe filter state
  GET_COMMENTS_FOR_ME: "GET_COMMENTS_FOR_ME",
  // Reset forMe filter state
  RESET_COMMENTS_FOR_ME: "RESET_COMMENTS_FOR_ME",
  // Fetch global search results
  FETCH_GLOBAL_SEARCH_RESULTS: "FETCH_GLOBAL_SEARCH_RESULTS",
  // Fetch claims
  FETCH_CLAIMS: "FETCH_CLAIMS",
  // Fetch document claims
  FETCH_DOCUMENT_CLAIMS: "FETCH_DOCUMENT_CLAIMS",
  // Search claims
  SEARCH_CLAIMS: "SEARCH_CLAIMS",
  // Reset Claims Search
  RESET_SEARCH_CLAIMS_RESULTS: "RESET_SEARCH_CLAIMS_RESULTS",
  // Fetch document dynamic fields
  FETCH_DOCUMENT_DYNAMIC_FIELDS: "FETCH_DOCUMENT_DYNAMIC_FIELDS",
  // Fetch document templates
  FETCH_DOCUMENT_TEMPLATES: "FETCH_DOCUMENT_TEMPLATES",
  // Fetch document references
  FETCH_DOCUMENT_REFERENCES: "FETCH_DOCUMENT_REFERENCES",
  // Fetch document conversations
  FETCH_DOCUMENT_CONVERSATIONS: "FETCH_DOCUMENT_CONVERSATIONS",
};
