import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { createForm, formShape } from "rc-form";
import DatePicker from "react-datepicker";

import Tooltip from "../../Common/TooltipV2";

import DefaultImg from "../../../images/icons/svg/bell-default.svg";
import HoverImg from "../../../images/icons/svg/bell-hover.svg";
import ClickedImg from "../../../images/icons/svg/bell-clicked.svg";
import ExternalLink from "../../../images/icons/svg/external-link-icon-2.svg";

import styles from "./DateField.module.scss";
import { Link } from "react-router-dom";

/* Variables */
const cookies = new Cookies();

class DateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { defaultValue } = nextProps;

    if (nextProps.values[nextProps.name] != this.state.value) {
      this.setState({ value: defaultValue });
    }
  };

  handleInputChange = (date) => {
    const { name, values } = this.props;

    values[name] = date ? date.toISOString() : null;

    this.setState({ value: values[name] });
  };

  render() {
    const { label, name, placeholder, required, defaultValue, values } =
      this.props;

    let defaultDate;

    if (this.state.value) {
      defaultDate = new Date(this.state.value);
    }

    return (
      <div>
        <div className={styles.header}>
          <span
            className={`uik-content-title__wrapper ${styles.label}`}
            style={{ display: "block" }}
          >{`${label}`}</span>
          <Tooltip
            defaultIcon={DefaultImg}
            hoverIcon={HoverImg}
            clickedIcon={ClickedImg}
            position="right"
            className={styles.tooltip}
          >
            <div className={styles.tooltipContent}>
              <div className={styles.title}>
                {this.props.dateReminders.length === 0
                  ? "You have no reminders for this date field."
                  : "You setup reminders on this date field for"}
              </div>
              {this.props.dateReminders.length > 0 && (
                <div className={styles.dateRemindersList}>
                  <ul>
                    {this.props.dateReminders.map((dateReminder, index) => (
                      <li key={index}>
                        <span className={styles.daysBefore}>
                          {dateReminder.days_before}
                        </span>{" "}
                        days before
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Link to="/notifications" target="_blank">
                <div className={styles.link}>
                  <div className={styles.text}>
                    {this.props.dateReminders.length === 0
                      ? "Create Date Reminders"
                      : "Manage Date Reminders"}
                  </div>
                  <div className={styles.icon}>
                    <img src={ExternalLink} />
                  </div>
                </div>
              </Link>
            </div>
          </Tooltip>
        </div>
        <div className="customDatePickerWidth">
          <DatePicker
            className={`uik-input__input ${styles.metadataField}`}
            style={{ width: "100%" }}
            selected={defaultDate}
            isClearable
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    );
  }
}

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
  values: PropTypes.object,
};

export default DateField;
