import axios from "axios";

export const apiUrl =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_API_URL}`
    : "http://localhost:3000";

export const pdftronServerUrl =
  process.env.NODE_ENV === "production"
    ? "https://viewer.papercurve.com"
    : "http://localhost:8090";

// loadDocumentUrl is the url that webviewer will load the document from.
// In development if WEBVIEWER_SERVER is true, it needs to be 'http://host.docker.internal:3000'
// In development if WEBVIEWER_SERVER is false, it needs to be 'http://localhost:3000'
// In production if WEBVIEWER_SERVER is true, it needs to be 'https://api.*.papercurve.com'
// In production if WEBVIEWER_SERVER is false, it needs to be 'https://api.*.papercurve.com'
export const loadDocumentUrl = () => {
  if (
    process.env.REACT_APP_WEBVIEWER_SERVER === "true" &&
    process.env.NODE_ENV === "production"
  ) {
    return apiUrl;
  } else if (process.env.REACT_APP_WEBVIEWER_SERVER === "true") {
    return "http://host.docker.internal:3000";
  } else {
    return apiUrl;
  }
};

const axiosClient = axios.create({ baseURL: apiUrl });

export default axiosClient;
