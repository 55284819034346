import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import BackArrowIcon from "../../../../images/icons/svg/back-arrow-icon.svg";

import styles from "./ViewMore.module.scss";

const ViewMore = (props) => {
  const {
    label,
    icon,
    records,
    backToResults,
    hideDropdown,
    inputValue,
    activeFilter,
  } = props;

  const getPath = (record) => {
    switch (label) {
      case "draft":
        return `/drafts/${record.id}`;
      case "approved":
        return `/documents/${record.id}`;
      case "reference":
        return `/references/${record.id}/library`;
      case "attachments":
        if (record.approved) {
          return `/documents/${record.document_id}`;
        } else {
          return `/drafts/${record.document_id}`;
        }
      default:
        break;
    }
  };

  const highlightSearchTerm = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text ? text.split(new RegExp(`(${highlight})`, "gi")) : [];
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase()
                ? styles.highlight
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.backLink} onClick={backToResults}>
        <div className={styles.backArrow}>
          <img src={BackArrowIcon} />
        </div>
        <div className={styles.text}>Back to All Search Results</div>
      </div>
      <div className={styles.label}>{label}</div>
      <div className={styles.records}>
        {records.map((record, index) =>
          label === "draft" || label === "approved" ? (
            <Link
              className={styles.link}
              key={index}
              to={{
                pathname: getPath(record),
                state: {
                  backTo: props.location.pathname,
                },
                hash: label === "attachments" ? "#attachments" : null,
              }}
              onClick={hideDropdown}
            >
              <div className={styles.recordContainer}>
                <div className={styles.logo}>
                  <img src={icon} />
                </div>
                <div className={styles.documentDescriptor}>
                  <div className={styles.title}>
                    {activeFilter === "title" || !activeFilter
                      ? highlightSearchTerm(record.title, inputValue)
                      : record.title}
                  </div>
                  <div className={styles.documentMetadata}>
                    {record.owner_name && (
                      <div className={styles.documentDetails}>
                        {" "}
                        Owner:{" "}
                        {activeFilter === "owner" || !activeFilter
                          ? highlightSearchTerm(record.owner_name, inputValue)
                          : record.owner_name}
                      </div>
                    )}
                    {record.sequential && record.sequential.value && (
                      <div className={styles.documentDetails}>
                        {record.sequential.label}:{" "}
                        {activeFilter === "sequential" || !activeFilter
                          ? highlightSearchTerm(
                              record.sequential.value,
                              inputValue
                            )
                          : record.sequential.value}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          ) : !activeFilter || activeFilter === "title" ? (
            <Link
              className={styles.link}
              key={index}
              to={{
                pathname: getPath(record),
                state: {
                  backTo: props.location.pathname,
                },
                hash: label === "attachments" ? "#attachments" : null,
              }}
              onClick={hideDropdown}
            >
              <div className={styles.recordContainer}>
                <div className={styles.logo}>
                  <img src={icon} />
                </div>
                <div className={styles.title}>
                  {highlightSearchTerm(record.title, inputValue)}
                </div>
              </div>
            </Link>
          ) : (
            <Link
              className={styles.link}
              key={index}
              to={{
                pathname: getPath(record),
                state: {
                  backTo: props.location.pathname,
                },
                hash: label === "attachments" ? "#attachments" : null,
              }}
              onClick={hideDropdown}
            >
              <div className={styles.recordContainer}>
                <div className={styles.logo}>
                  <img src={icon} />
                </div>
                <div className={styles.title}>{record.title}</div>
              </div>
            </Link>
          )
        )}
      </div>
    </div>
  );
};

export default withRouter(ViewMore);
