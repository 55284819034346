import React, { useState, useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";
import httpClient from "../../lib/HttpClient";
import axiosClient from "../../lib/axios-client";
import download from "downloadjs";
import security from "../../services/Security";
import axios from "../../lib/axios-client";
import { CSSTransition } from "react-transition-group";
import { Container, Row, Col } from "react-grid-system";
import { apiUrl } from "../../lib/axios-client";

/* UI Kit */
import { UikDivider, UikToggle } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

import styles from "./Unsubscribe.module.scss";

import { set } from "date-fns";

const Unsubscribe = (props) => {
  const contactEmail = props.match.params.contact_email;
  const contactId = props.match.params.id;
  const beforeunload = async () => {
    //
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [contact, setContact] = useState({});
  const [contactSegments, setContactSegments] = useState([]);
  const [contactSegmentSubscriptions, setContactSegmentSubscriptions] =
    useState([]);
  const [subscriptionStatuses, setSubscriptionStatuses] = useState({});

  useEffect(() => {
    httpClient.get(`/subscriptions/${contactId}.json`).then((res) => {
      setContact(res.data);
      setContactSegments(res.data.contact_segments);
      setContactSegmentSubscriptions(res.data.contact_segment_contacts);

      // Store subscription statuses separately for user modification.
      let subStatuses = {};

      res.data.contact_segment_contacts.map((csc) => {
        subStatuses[csc.id] = csc.subscribed;
      });

      setSubscriptionStatuses(subStatuses);
    });
  }, []);

  const unsubscribeContact = () => {
    httpClient.put(`/unsubscribe/${contactId}.json`, {
      contact: {
        email: contactEmail,
      },
    });
    setShowSuccess(true);
  };

  const subscribeContact = () => {
    httpClient.put(`/subscribe/${contactId}.json`, {
      contact: {
        email: contactEmail,
      },
    });
    setShowSuccess(true);
  };

  const getSubscriptionStatus = (subscription) => {
    return (
      subscription &&
      subscriptionStatuses[subscription.id] &&
      subscriptionStatuses[subscription.id]
    );
  };

  const handleAllEmailsChange = (checked) => {
    if (checked) {
      subscribeContact();
    } else {
      unsubscribeContact();
    }
  };

  const handleSubscriptionChange = (sid, checked) => {
    if (checked) {
      httpClient.put(`/subscriptions/${contactId}/${sid}/subscribe.json`, {
        contact: {
          email: contactEmail,
        },
      });
      setShowSuccess(true);
    } else {
      httpClient.put(`/subscriptions/${contactId}/${sid}/unsubscribe.json`, {
        contact: {
          email: contactEmail,
        },
      });
      setShowSuccess(true);
    }
  };

  return (
    <div className={styles.reviewerScreenContainer}>
      <div id="usersScreenHeader"></div>
      <div className={styles.headerContainer}>
        <textarea
          className={`uik-input__input `}
          style={{ width: "30%", margin: "20px" }}
          rows="1"
          label="Caption"
          placeholder="Type some text"
          readonly="readonly"
        >
          {contactEmail}
        </textarea>
        <div className={styles.subscriptionContainer}>
          <UikToggle
            disabled={false}
            defaultChecked={contact.subscribed}
            label="All Emails"
            onClick={(e) => handleAllEmailsChange(e.target.checked)}
          />
        </div>
        <UikDivider className={styles.divider} />
        {contactSegments.map((segment, idx) => {
          let subscription = contactSegmentSubscriptions.find((css) => {
            return css.contact_segment_id === segment.id;
          });

          return (
            <div key={segment.id} className={styles.subscriptionContainer}>
              <UikToggle
                defaultChecked={subscription && subscription.subscribed}
                label={segment.name}
                onClick={(e) =>
                  handleSubscriptionChange(subscription.id, e.target.checked)
                }
              />
            </div>
          );
        })}
        {showSuccess && <span className={styles.successMessage}>Done!</span>}
      </div>
    </div>
  );
};

const useStateRef = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
};

export default Unsubscribe;
