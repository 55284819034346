import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { withTranslation } from "react-i18next";
import history from "../../history";
import styles from "./ContactsLeftMenu.module.scss";

import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";

import accountSetting from "../../services/AccountSetting";
import contactsIcon from "../../images/icons/svg/person.svg";
import emailIcon from "../../images/icons/svg/email-icon-black.svg";
import calendarIcon from "../../images/icons/svg/calendar.svg";
import segmentsIcon from "../../images/icons/svg/segments.svg";

class ContactsLeftMenuBuild extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();
    const currentUserId = security.getUserId();

    httpClient.get(`/users/${currentUserId}.json`).then((response) => {
      this.setState({
        currentTeamName: response.data.current_team.name,
        currentTeamContactsCount: response.data.current_team_contacts_count,
        currentTeamSegmentsCount: response.data.current_team_segments_count,
        currentTeamCampaignsCount: response.data.current_team_campaigns_count,
      });
    });

    this.state = {
      currentUser: {
        id: currentUserId,
        role: currentUserRole,
      },
      currentTeamName: null,
      currentTeamContactsCount: null,
      currentTeamSegmentsCount: null,
      currentTeamCampaignsCount: null,
    };
  }

  renderCategoryIcon = (category) => {
    if (category === "contacts") {
      return <img className={styles.categoryIcon} src={contactsIcon} />;
    } else if (category === "verifiedEmails") {
      return <img className={styles.categoryIcon} src={emailIcon} />;
    } else if (category === "segments") {
      return <img className={styles.categoryIcon} src={segmentsIcon} />;
    }
  };

  render() {
    const { t } = this.props;

    const segments = window.location.pathname.match(/^\/contacts\/segments/)
      ? true
      : false;
    const verified_emails = window.location.pathname.match(
      /^\/contacts\/mailer/
    )
      ? true
      : false;
    const analytics = window.location.pathname.match(/^\/contacts\/analytics/)
      ? true
      : false;
    const contacts =
      window.location.pathname.match(/^\/contacts/) &&
      !segments &&
      !verified_emails &&
      !analytics
        ? true
        : false;

    return (
      <Col md={2} className={styles.leftSideMenuContainer}>
        {this.state.currentUser.role !== "viewer" && (
          <div className={styles.leftSideMenu}>
            <div className={[styles.leftSideMenuHeader]}>{t("crm.crm")}</div>
            <div
              className={`${contacts ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
              onClick={() => history.push("/contacts")}
            >
              <div>
                {this.renderCategoryIcon("contacts")}
                {t("crm.contacts")}
              </div>
              <div className={styles.countPill}>
                {this.state.currentTeamContactsCount}
              </div>
            </div>
            <div
              className={`${segments ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
              onClick={() => history.push("/contacts/segments")}
            >
              <div>
                {this.renderCategoryIcon("segments")}
                {t("crm.segments")}
              </div>
              <div className={styles.countPill}>
                {this.state.currentTeamSegmentsCount}
              </div>
            </div>
            <div
              className={`${verified_emails ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
              onClick={() => history.push("/contacts/mailer")}
            >
              <div>
                {this.renderCategoryIcon("verifiedEmails")}
                {t("crm.verified_emails")}
              </div>
              <div className={styles.countPill}>
                {this.state.currentTeamCampaignsCount}
              </div>
            </div>
            {this.state.currentTeamName && (
              <div
                className={`${styles.team} ${
                  analytics ? styles.selected : ""
                } ${styles.leftSideMenuItem}`}
              >
                <a href="/teams">
                  Team:{" "}
                  <span className={styles.currentTeam}>
                    {this.state.currentTeamName}
                  </span>
                </a>
              </div>
            )}
          </div>
        )}
      </Col>
    );
  }
}

const ContactsLeftMenu = withTranslation()(ContactsLeftMenuBuild);

export default ContactsLeftMenu;
