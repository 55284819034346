import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import security from "../../services/Security";
import CloseSVG from "../../images/icons/png/close.png";
import ConversationPanelSearch from "./ConversationPanelSearch.js";
import ConversationCard from "./ConversationCard.js";
import CommentCard from "./CommentCard.js";
import ThreadCard from "./ThreadCard.js";
import resetSearchCommentResults from "../../actions/document/resetSearchCommentResults";
import resetCommentFilters from "../../actions/document/resetCommentFilters";
import styles from "./ConversationsPanel.module.scss";
import resetCommentsForMe from "../../actions/document/resetCommentsForMe";
import searchComments from "../../actions/document/searchComments";
import { bindActionCreators } from "redux";
import httpClient from "../../lib/HttpClient";
import getCommentsForMe from "../../actions/document/getCommentsForMe";
import loadingAnimation from "../../images/lotties/loader.json";
import commentIcon from "../../images/icons/svg/comments-panel-icon.svg";
import Button from "../Common/Buttons/ButtonPrimary";
import { UikButton } from "@uik";

const ConversationsPanel = (props) => {
  const {
    inGuestViewer,
    conversations,
    closeConversationsPanel,
    showGeneralComments,
    showAnnotationComments,
    reloadConversations,
    readOnly,
    toggleStatus,
    commentorUsers,
    docId,
    versionId,
  } = props;

  const [filteredConversations, setFilteredConversations] =
    useState(conversations);
  const [sortby, setSortby] = useState("page");
  const [allResolved, setAllResolved] = useState(true);
  const [noneResolved, setNoneResolved] = useState(true);
  const [searchResults, setSearchResults] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [forMeActive, setForMeActive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const filters = useSelector((state) => {
    return state.commentFilters;
  });

  const boundActionCreators = bindActionCreators(
    { searchComments, getCommentsForMe },
    useDispatch()
  );

  useEffect(() => {
    setFilteredConversations(conversations);
    establishConversationStatus(conversations);
  }, [conversations]);

  const commentsForMe = useSelector((state) => {
    if (state.commentsForMe) {
      return state.commentsForMe;
    }
  });

  const dispatch = useDispatch();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const establishConversationStatus = async (conversations) => {
    let conversationResolved = true;
    let conversationNotResolved = true;
    await conversations.map((conversation) => {
      if (conversation.comments.length > 0) {
        if (conversation.status === "unresolved") {
          conversationResolved = false;
          return setAllResolved(null);
        } else if (conversation.status === "resolved") {
          conversationNotResolved = false;
          return setNoneResolved(null);
        }
      }
    });
    if (conversationResolved) {
      setAllResolved(true);
    } else if (conversationNotResolved) {
      setNoneResolved(true);
    }
  };

  const close = () => {
    closeConversationsPanel();
    dispatch(resetSearchCommentResults());
    dispatch(resetCommentFilters());
    dispatch(resetCommentsForMe());
  };

  const updateConversationList = (list, sort) => {
    if (!sort || sort === "Page") {
      setSearchResults(list);
      setSortby("page");
    } else if (sort === "NewestToOldest") {
      setSearchResults(list);
      setSortby("NewestToOldest");
    } else if (sort === "OldestToNewest") {
      setSearchResults(list);
      setSortby("OldestToNewest");
    } else if (sort === "Status") {
      setSearchResults(list);
      setSortby("resolved");
    }
  };

  const forMeIsActive = (option) => {
    setForMeActive(option);
  };

  const toggleConversationStatus = (conversation) => {
    let status = "resolved";
    if (conversation.status === "resolved") {
      status = "unresolved";
    }
    httpClient
      .put(
        `/conversations/${
          conversation.xfdf_highlight_id || conversation.block_identifier
        }.json`,
        {
          conversation: {
            status,
          },
        }
      )
      .then((res) => {
        reloadConversations();
        toggleStatus();
        if (filters) {
          boundActionCreators.searchComments(
            docId,
            searchValue,
            filters.commentor,
            filters.date,
            filters.sort
          );
        } else {
          if (forMeActive) {
            boundActionCreators.getCommentsForMe(docId);
          } else {
            boundActionCreators.searchComments(docId, searchValue);
          }
        }
      });
  };

  const formatMentions = (text) => {
    const mention = new RegExp("{{(.*?)}}", "gi");
    const parts = text ? text.split(mention) : [];

    return (
      <span>
        {" "}
        {parts.map((part, i) => {
          return <span key={i}>{part}</span>;
        })}{" "}
      </span>
    );
  };

  const renderOrderedResults = () => {
    return (
      <div className={styles.conversationsContainer}>
        <div className={styles.page}>
          <div className={styles.sortedTopDividerContainer} />
          {searchResults.map((result) => {
            return (
              <div>
                <CommentCard
                  convo={result}
                  showGeneralComments={showGeneralComments}
                  showAnnotationComments={showAnnotationComments}
                  readOnly={readOnly}
                  reloadConversations={reloadConversations}
                  formatMentions={formatMentions}
                  toggleStatus={toggleStatus}
                  toggleConversationStatus={toggleConversationStatus}
                />
                <div className={styles.dividerContainer} />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderEmtpyState = () => {
    return (
      <div className={styles.emptyStateContainer}>
        <div className={styles.emptyStateHeader}>
          We Could Not Find Any Results
        </div>
        <div className={styles.emptyStateBody}>
          Try checking your spelling or entering another input or filter
        </div>
      </div>
    );
  };

  const showingLoader = (option) => {
    setShowLoader(option);
  };

  const firstCommentOfCurrentUser = (conversationComments, currentUserId) => {
    let currentUserComments = [];
    conversationComments.map((comment) => {
      if (comment.user.id === currentUserId) {
        currentUserComments.push(comment);
      }
    });
    return currentUserComments;
  };

  const renderCommentsForMe = () => {
    const currentUserId = security.getUserId();
    const mentions = [];
    const threadStartedByOthers = [];
    const threadStartedByMe = [];
    commentsForMe.map((result) => {
      if (result.user.id === currentUserId) {
        if (result.conversation.started_by_user_id === currentUserId) {
          threadStartedByMe.push(result);
        } else {
          const allCommentsInConvo = conversations.find(
            (convo) => convo.id === result.conversation.id
          ).comments;
          let firstCommentInConvo = allCommentsInConvo[0];
          let currentUsersFirstComment = firstCommentOfCurrentUser(
            allCommentsInConvo,
            currentUserId
          )[0];
          const representativeComments = {
            currentUserComment: currentUsersFirstComment,
            firstCommentor: firstCommentInConvo,
          };
          threadStartedByOthers.push(representativeComments);
        }
      } else {
        mentions.push(result);
      }
    });

    return (
      <div>
        <div className={styles.conversationsContainer}>
          <div className={styles.page}>
            <div className={styles.title}>Mentions</div>
            <div className={styles.dividerContainer} />
            {mentions.map((mention) => {
              return (
                <div>
                  <CommentCard
                    convo={mention}
                    showGeneralComments={showGeneralComments}
                    showAnnotationComments={showAnnotationComments}
                    readOnly={readOnly}
                    reloadConversations={reloadConversations}
                    formatMentions={formatMentions}
                    toggleStatus={toggleStatus}
                    currentUserId={security.getUserId()}
                    toggleConversationStatus={toggleConversationStatus}
                  />
                  <div className={styles.dividerContainer} />
                </div>
              );
            })}
            {mentions.length === 0 && (
              <div className={styles.forMeEmptyStateContainer}>
                <div className={styles.forMeEmptyState}>
                  No one has mentioned you on this content
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.conversationsContainer}>
          <div className={styles.page}>
            <div className={styles.title}>my comments and replies</div>
            <div className={styles.dividerContainer} />
            {threadStartedByOthers.map((thread) => {
              return (
                <div>
                  <ThreadCard
                    convo={thread.currentUserComment}
                    firstCommentorsComment={thread.firstCommentor}
                    showGeneralComments={showGeneralComments}
                    showAnnotationComments={showAnnotationComments}
                    readOnly={readOnly}
                    reloadConversations={reloadConversations}
                    formatMentions={formatMentions}
                    toggleStatus={toggleStatus}
                  />
                  <div className={styles.dividerContainer} />
                </div>
              );
            })}
            {threadStartedByMe.map((thread) => {
              return (
                <div>
                  <CommentCard
                    convo={thread}
                    showGeneralComments={showGeneralComments}
                    showAnnotationComments={showAnnotationComments}
                    readOnly={readOnly}
                    reloadConversations={reloadConversations}
                    formatMentions={formatMentions}
                    toggleStatus={toggleStatus}
                    toggleConversationStatus={toggleConversationStatus}
                  />
                  <div className={styles.dividerContainer} />
                </div>
              );
            })}
            {threadStartedByMe.length === 0 &&
              threadStartedByOthers.length === 0 && (
                <div className={styles.forMeEmptyStateContainer}>
                  <div className={styles.forMeEmptyState}>
                    You have not started or replied to any comment threads
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };

  const renderSearchResults = () => {
    let generalCommentsConversation = [];
    let conversationsObj = {};

    searchResults.forEach((conv) => {
      if (conv.conversation.block_identifier) {
        generalCommentsConversation.push(conv);
      } else {
        if (!conversationsObj[conv.annotation_page.page_number]) {
          conversationsObj[conv.annotation_page.page_number] = [];
        }
        conversationsObj[conv.annotation_page.page_number].push(conv);
      }
    });

    if (sortby === "page" || !sortby) {
      return (
        <div className={styles.conversationsContainer}>
          {generalCommentsConversation.length > 0 && (
            <div className={styles.page}>
              <div className={styles.title}>general comments</div>
              <div className={styles.dividerContainer} />
              {generalCommentsConversation.map((genConv) => {
                return (
                  <div>
                    <CommentCard
                      convo={genConv}
                      showGeneralComments={showGeneralComments}
                      showAnnotationComments={showAnnotationComments}
                      readOnly={readOnly}
                      reloadConversations={reloadConversations}
                      formatMentions={formatMentions}
                      toggleStatus={toggleStatus}
                      toggleConversationStatus={toggleConversationStatus}
                    />
                    <div className={styles.dividerContainer} />
                  </div>
                );
              })}
            </div>
          )}
          {Object.entries(conversationsObj).map(
            ([key, conversations], index) => (
              <div key={index} className={styles.page}>
                <div className={styles.title}>{`page ${key}`}</div>
                <div className={styles.dividerContainer} />
                {conversations.map((convo) => (
                  <div>
                    <CommentCard
                      convo={convo}
                      showGeneralComments={showGeneralComments}
                      showAnnotationComments={showAnnotationComments}
                      readOnly={readOnly}
                      reloadConversations={reloadConversations}
                      formatMentions={formatMentions}
                      toggleStatus={toggleStatus}
                      toggleConversationStatus={toggleConversationStatus}
                    />
                    <div className={styles.dividerContainer} />
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      );
    } else if (sortby === "resolved") {
      const resolvedComments = [];
      const notResolvedComments = [];
      searchResults.map((result) => {
        if (result.conversation.status === "resolved") {
          resolvedComments.push(result);
        } else {
          notResolvedComments.push(result);
        }
      });

      return (
        <div className={styles.conversationsContainer}>
          {filteredConversations.length > 0 && (
            <div className={styles.page}>
              <div className={styles.title}>not resolved</div>
              {notResolvedComments.length === 0 && (
                <div>
                  <div className={styles.dividerContainer} />
                  <div className={styles.messageContainer}>
                    <div className={styles.emptyMessage}>
                      All comments are resolved.
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.dividerContainer} />
              {notResolvedComments.map((result) => (
                <CSSTransition
                  in={notResolvedComments}
                  unmountOnExit
                  classNames={"conversation"}
                  timeout={700}
                >
                  <div>
                    <CommentCard
                      convo={result}
                      showGeneralComments={showGeneralComments}
                      showAnnotationComments={showAnnotationComments}
                      readOnly={readOnly}
                      reloadConversations={reloadConversations}
                      formatMentions={formatMentions}
                      toggleStatus={toggleStatus}
                      toggleConversationStatus={toggleConversationStatus}
                    />
                    <div className={styles.dividerContainer} />
                  </div>
                </CSSTransition>
              ))}
            </div>
          )}
          {searchResults.length > 0 && (
            <div className={styles.page}>
              <div className={styles.title}>resolved</div>
              {resolvedComments.length === 0 && (
                <div>
                  <div className={styles.dividerContainer} />
                  <div className={styles.messageContainer}>
                    <div className={styles.emptyMessage}>
                      No comments are resolved.
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.dividerContainer} />
              {resolvedComments.map((result) => (
                <CSSTransition
                  in={resolvedComments}
                  unmountOnExit
                  classNames={"conversation"}
                  timeout={700}
                >
                  <div>
                    <CommentCard
                      convo={result}
                      showGeneralComments={showGeneralComments}
                      showAnnotationComments={showAnnotationComments}
                      readOnly={readOnly}
                      reloadConversations={reloadConversations}
                      formatMentions={formatMentions}
                      toggleStatus={toggleStatus}
                      toggleConversationStatus={toggleConversationStatus}
                    />
                    <div className={styles.dividerContainer} />
                  </div>
                </CSSTransition>
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  const renderNoCommentsEmptyState = () => {
    return (
      <div>
        <div className={styles.noCommentsDivider} />
        <div
          id="sidePanelAttachmentOptions"
          className={
            props.inGuestViewer
              ? styles.emptySidePanelOptionsGuestViewer
              : styles.emptySidePanelOptions
          }
        >
          <div className={styles.emptyStateMessage}>
            <img src={commentIcon}></img>
            <span className={styles.emptyStateMessageLineOne}>
              There are no comments.
            </span>
            {!readOnly && (
              <span className={styles.emptyStateMessageLineTwo}>
                {props.inGuestViewer
                  ? "To create one, highlight some text and select the comment icon"
                  : "To create one, highlight some text and select the comment icon, or"}
              </span>
            )}
            {!readOnly && !props.inGuestViewer && (
              <div className={styles.addGeneralCommentButton}>
                <Button
                  original
                  text="Create a General Comment"
                  onClick={() => {
                    showGeneralComments();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderNoAnnotatedCommentsEmptyState = () => {
    return (
      <div>
        <div
          id="sidePanelAttachmentOptions"
          className={styles.noAnnotatedCommentsSidePanelOptions}
        >
          <div className={styles.emptyStateMessage}>
            <img src={commentIcon}></img>
            <span className={styles.emptyStateMessageNoAnnotatedComments}>
              To create a comment, highlight some text and select the comment
              icon
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderNoGeneralCommentsEmptyState = () => {
    if (!inGuestViewer) {
      return (
        <div>
          <div className={styles.noGeneralCommentsTitle}>general comments</div>
          <div className={styles.noCommentsDivider} />
          <div
            id="sidePanelAttachmentOptions"
            className={styles.noGeneralCommentsSidePanelOptions}
          >
            <div className={styles.emptyStateMessage}>
              <span className={styles.emptyStateMessageNoGeneralComments}>
                There are no general comments
              </span>
              <UikButton
                className={styles.exportButton}
                onClick={(e) => showGeneralComments()}
              >
                Create General Comment
              </UikButton>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderConversationsList = () => {
    if (sortby === "page") {
      let conversationsObj = {};
      let generalCommentsConversation = null;
      let generalCommentsExist = false;

      filteredConversations.forEach((conv) => {
        if (conv.block_identifier) {
          generalCommentsConversation = conv;
          if (generalCommentsConversation.comments.length > 0) {
            generalCommentsExist = true;
          }
        } else {
          if (!conversationsObj[conv.page.page_number]) {
            conversationsObj[conv.page.page_number] = [];
          }
          conversationsObj[conv.page.page_number].push(conv);
        }
      });

      return (
        <div className={styles.conversationsContainer}>
          {!generalCommentsExist &&
            Object.keys(conversationsObj).length === 0 &&
            renderNoCommentsEmptyState()}
          {generalCommentsConversation &&
            generalCommentsExist &&
            !inGuestViewer && (
              <div className={styles.page}>
                <div className={styles.title}>general comments</div>
                <div className={styles.dividerContainer} />
                <ConversationCard
                  convo={generalCommentsConversation}
                  showGeneralComments={showGeneralComments}
                  showAnnotationComments={showAnnotationComments}
                  readOnly={readOnly}
                  reloadConversations={reloadConversations}
                  formatMentions={formatMentions}
                  toggleStatus={toggleStatus}
                />
                <div className={styles.dividerContainer} />
              </div>
            )}
          {!readOnly &&
            !generalCommentsExist &&
            Object.keys(conversationsObj).length > 0 &&
            renderNoGeneralCommentsEmptyState()}
          {!readOnly &&
            generalCommentsExist &&
            Object.keys(conversationsObj).length === 0 &&
            renderNoAnnotatedCommentsEmptyState()}
          {Object.entries(conversationsObj).map(
            ([key, conversations], index) => (
              <div key={index} className={styles.page}>
                <div className={styles.title}>{`page ${key}`}</div>
                <div className={styles.dividerContainer} />
                {conversations.map((convo) => (
                  <div>
                    <ConversationCard
                      convo={convo}
                      showGeneralComments={showGeneralComments}
                      showAnnotationComments={showAnnotationComments}
                      readOnly={readOnly}
                      reloadConversations={reloadConversations}
                      formatMentions={formatMentions}
                      toggleStatus={toggleStatus}
                      inGuestViewer={inGuestViewer}
                      hashUrl={props.hashUrl}
                    />
                    <div className={styles.dividerContainer} />
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      );
    } else if (sortby === "resolved") {
      return (
        <div className={styles.conversationsContainer}>
          {filteredConversations.length > 0 && (
            <div className={styles.page}>
              <div className={styles.title}>not resolved</div>
              {allResolved === true && (
                <div>
                  <div className={styles.dividerContainer} />
                  <div className={styles.messageContainer}>
                    <div className={styles.emptyMessage}>
                      All comments are resolved.
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.dividerContainer} />
              {filteredConversations.map(
                (convo) =>
                  convo.comments.length > 0 && (
                    <CSSTransition
                      in={filteredConversations.find(
                        (conv) =>
                          convo.id === conv.id && conv.status === "unresolved"
                      )}
                      unmountOnExit
                      classNames={"conversation"}
                      timeout={700}
                    >
                      <div>
                        <ConversationCard
                          convo={convo}
                          showGeneralComments={showGeneralComments}
                          showAnnotationComments={showAnnotationComments}
                          readOnly={readOnly}
                          reloadConversations={reloadConversations}
                          formatMentions={formatMentions}
                          toggleStatus={toggleStatus}
                        />
                        <div className={styles.dividerContainer} />
                      </div>
                    </CSSTransition>
                  )
              )}
            </div>
          )}
          {filteredConversations.length > 0 && (
            <div className={styles.page}>
              <div className={styles.title}>resolved</div>
              {noneResolved === true && (
                <div>
                  <div className={styles.dividerContainer} />
                  <div className={styles.messageContainer}>
                    <div className={styles.emptyMessage}>
                      No comments are resolved.
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.dividerContainer} />
              {filteredConversations.map(
                (convo) =>
                  convo.comments.length > 0 && (
                    <CSSTransition
                      in={filteredConversations.find(
                        (conv) =>
                          convo.id === conv.id && conv.status === "resolved"
                      )}
                      unmountOnExit
                      classNames={"conversation"}
                      timeout={700}
                    >
                      <div>
                        <ConversationCard
                          convo={convo}
                          showGeneralComments={showGeneralComments}
                          showAnnotationComments={showAnnotationComments}
                          readOnly={readOnly}
                          reloadConversations={reloadConversations}
                          formatMentions={formatMentions}
                          toggleStatus={toggleStatus}
                        />
                        <div className={styles.dividerContainer} />
                      </div>
                    </CSSTransition>
                  )
              )}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div
      className={
        props.showMobileSidePanel
          ? styles.mobileConversationsPanel
          : styles.conversationsPanel
      }
    >
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.title}>Comments</div>
          </div>
          <div className={styles.rightContainer}>
            <div onClick={close} className={styles.closeButton}>
              <img className={styles.closeIcon} src={CloseSVG} />
            </div>
          </div>
        </div>
        {!inGuestViewer && (
          <ConversationPanelSearch
            updateConversationList={updateConversationList}
            commentorUsers={commentorUsers}
            docId={docId}
            readOnly={readOnly}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            forMeIsActive={forMeIsActive}
            showLoader={showingLoader}
            versionId={versionId}
          />
        )}
      </div>
      <div className={styles.body}>
        {!showLoader &&
          !searchResults &&
          !commentsForMe &&
          filteredConversations &&
          renderConversationsList(conversations)}
        {!showLoader &&
          searchResults &&
          searchResults.length > 0 &&
          (!sortby || sortby === "page") &&
          renderSearchResults()}
        {!showLoader &&
          searchResults &&
          searchResults.length > 0 &&
          sortby === "NewestToOldest" &&
          renderOrderedResults()}
        {!showLoader &&
          searchResults &&
          searchResults.length > 0 &&
          sortby === "OldestToNewest" &&
          renderOrderedResults()}
        {!showLoader &&
          searchResults &&
          searchResults.length > 0 &&
          sortby === "resolved" &&
          renderSearchResults()}
        {!showLoader &&
          searchResults &&
          searchResults.length === 0 &&
          renderEmtpyState()}
        {commentsForMe && commentsForMe.length === 0 && renderEmtpyState()}
        {commentsForMe && commentsForMe.length > 0 && renderCommentsForMe()}
      </div>
      {showLoader && (
        <div className={styles.loaderContainer}>
          <Lottie options={defaultOptions} height={92} width={82} />
          <p className={styles.loaderText}>Looking For Comments ...</p>
        </div>
      )}
    </div>
  );
};

export default ConversationsPanel;
