import DocumentActionTypes from "../../actions/document/types";

export default (claims = null, action) => {
  switch (action.type) {
    case DocumentActionTypes.FETCH_CLAIMS:
      return action.payload;
    default:
      return claims;
  }
};
