import React, { Component } from "react";
import { createForm } from "rc-form";
import Button from "../../Common/Buttons/ButtonPrimary";
import axios from "../../../lib/axios-client";

import "./Setpassword.scss";

import { UikFormInputGroup, UikInput, UikButton } from "@uik";

class SetpasswordBuild extends Component {
  componentWillMount = () => {
    console.log(this.props);
    this.setState({ resetPasswordToken: this.props.token });

    this.passwordDecorator = this.props.form.getFieldDecorator("password", {
      initialValue: "",
      rules: [
        {
          required: true,
          message: "Please enter your desired password.",
        },
        {
          min: 6,
          message: "Must be at least 6 characters.",
        },
      ],
    });

    this.passwordConfirmationDecorator = this.props.form.getFieldDecorator(
      "passwordConfirmation",
      {
        initialValue: "",
        rules: [
          {
            required: true,
            message: "Please re-enter your password.",
          },
          {
            validator: this.compareToFirstPassword,
          },
        ],
      }
    );
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords must match.");
    } else {
      callback();
    }
  };

  handleSubmit = () => {
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const { password, passwordConfirmation } = values;

        const passwordFormData = {
          user: {
            password: password,
            password_confirmation: passwordConfirmation,
            reset_password_token: this.state.resetPasswordToken,
          },
        };

        axios
          .put("/password.json", passwordFormData)
          .then((response) => {
            this.props.onSuccess(response.data.email, password);
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        console.log("error", error, values);
      }
    });
  };

  render() {
    const { getFieldError, getFieldValue } = this.props.form;
    const disabled =
      getFieldValue("password") === "" ||
      getFieldValue("passwordConfirmation") === "" ||
      getFieldError("password") ||
      getFieldError("passwordConfirmation");
    return (
      <div className="setpassword-container">
        <UikFormInputGroup>
          {this.passwordDecorator(
            <UikInput
              label="PASSWORD"
              type="password"
              errorMessage={(getFieldError("password") || []).join(", ")}
            />
          )}
          {this.passwordConfirmationDecorator(
            <UikInput
              label="CONFIRM PASSWORD"
              type="password"
              errorMessage={(getFieldError("passwordConfirmation") || []).join(
                ", "
              )}
            />
          )}
          <Button
            original
            disabled={disabled}
            text="Set password"
            onClick={this.handleSubmit}
          />
        </UikFormInputGroup>
      </div>
    );
  }
}

const Setpassword = createForm()(SetpasswordBuild);
export default Setpassword;
