import React from "react";

import "./ButtonPrimary.scss";

export const ButtonPrimary = (props) => {
  const { text, onClick, disabled = false, wide } = props;
  let className = wide ? "button-primary-wide" : "button-primary";

  if (props.transparent) {
    className += " button-primary__transparent";
  } else if (props.original) {
    className += " button-primary__original";
  }

  if (disabled) {
    className += " disabled";
  }

  if (props.className) {
    className += " " + props.className;
  }

  return (
    <button disabled={disabled} className={className} onClick={onClick}>
      {text}
    </button>
  );
};
