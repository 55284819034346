import React from "react";
import PropTypes from "prop-types";
import styles from "./ReferenceIndicators.module.scss";

import referenceIcon from "../../images/icons/16px/link.svg";

import httpClient from "../../lib/HttpClient";

/* UI Kit */
import { Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Variables */

class ReferenceIndicators extends React.Component {
  constructor() {
    super();

    this.state = {
      references: [],
      clicked: false,
    };
  }

  componentWillMount = () => {
    const { docId, pageNumber } = this.props;

    // Retrieve the document references.
    httpClient
      .get(`/references/${docId}/${pageNumber}.json`)
      .then((response) => {
        this.setState({
          references: response.data,
        });
      });
  };

  shouldComponentUpdate() {
    return true;
  }

  highlightBlock = (blockId) => {
    const range = blockId.split("--");
    const firstElement = document.getElementById(range[0]);
    const lastElement = document.getElementById(range[range.length - 1]);
    const unhighlightElements =
      document.getElementsByClassName("referencedText");

    while (unhighlightElements[0]) {
      unhighlightElements[0].classList.remove("referencedText");
    }

    let blockIdentifier = "";

    let collection = [];
    collection.push(firstElement);

    let currentElement = firstElement;

    if (currentElement !== null && currentElement.innerHTML) {
      let htmlstring = currentElement.innerHTML;
      htmlstring = htmlstring.trim
        ? htmlstring.trim()
        : htmlstring.replace(/^\s+/, "");

      if (htmlstring !== "") {
        currentElement.classList.add("referencedText");
        blockIdentifier = currentElement.id;
      }
    }

    // Loop through all elements until we reach the end and store it in our collection.
    while (
      currentElement !== null &&
      currentElement !== lastElement &&
      typeof currentElement.nextSibling !== "undefined"
    ) {
      if (currentElement !== null) {
        collection.push(currentElement.nextSibling);
        currentElement = currentElement.nextSibling;

        if (currentElement !== null && currentElement.innerHTML) {
          let htmlstring = currentElement.innerHTML;
          htmlstring = htmlstring.trim
            ? htmlstring.trim()
            : htmlstring.replace(/^\s+/, "");

          if (htmlstring !== "") {
            currentElement.classList.add("referencedText");
          }
        }
      }
    }
  };

  unHighlightBlock = (e) => {
    if (!this.state.clicked) {
      const unhighlightElements =
        document.getElementsByClassName("referencedText");

      while (unhighlightElements[0]) {
        unhighlightElements[0].classList.remove("referencedText");
      }
    }
  };

  clickedBlock = (blockId) => {
    this.setState({ clicked: true });
    this.props.loadReference(blockId);
  };

  render() {
    const references = this.state.references;

    return (
      <div className={styles.indicatorContainer}>
        {references &&
          references.map((reference, index) => (
            <div
              key={reference.id}
              className={styles.referenceIndicators}
              onMouseOver={(e) =>
                this.highlightBlock(reference.block_identifier)
              }
              onClick={(e) => this.clickedBlock(reference.block_identifier)}
              onMouseOut={(e) => this.unHighlightBlock(e)}
            >
              <div className={styles.referenceIndicatorContainer}>
                <span className={styles.consersationIcon}>
                  <img src={referenceIcon} />
                </span>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

ReferenceIndicators.propTypes = {
  docId: PropTypes.number,
  pageNumber: PropTypes.number,
  loadReference: PropTypes.func,
};

export default ReferenceIndicators;
