import React, { useState } from "react";
import { UikDropdown, UikDropdownItem, UikDivider, UikButton } from "@uik";
import moment from "moment";

import DownloadSVG from "../../../../images/icons/svg/download-icon.svg";
import ViewsSVG from "../../../../images/icons/svg/views-icon.svg";
import CopySVG from "../../../../images/icons/svg/copy-icon.svg";

import styles from "./Link.module.scss";
import notificationService from "../../../../services/Notifications";
import RenameModal from "./RenameModal";
import httpClient from "../../../../lib/HttpClient";
import Modal from "../../../Common/Modal";

const Link = (props) => {
  const {
    link,
    retrieveShareLinks,
    setShowActivityModal,
    setSelectedShareLink,
  } = props;

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const actionsDropDown = ({ onClick }) => {
    return (
      <div className={styles.threeDotMenu} onClick={onClick}>
        &#9679;&#9679;&#9679;
      </div>
    );
  };

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/share_links/view/${link.hash_url}`
    );

    notificationService.addNotification(
      "Link copied",
      `Your link “${link.label}” has been copied to clipboard!`,
      "success"
    );
  };

  const updateLink = async (label) => {
    await httpClient.put(`/share_links/${link.id}.json`, {
      label,
    });
    retrieveShareLinks();
    setShowRenameModal(false);
  };

  const deleteLink = async () => {
    await httpClient.delete(`/share_links/${link.id}.json`);
    retrieveShareLinks();
    setShowDeleteModal(false);
  };

  const reactivateLink = async () => {
    await httpClient.post(`/share_links/reactivate/${link.id}.json`);
    retrieveShareLinks();
  };

  return (
    <div className={styles.container}>
      <div className={styles.rowOne}>
        <div className={styles.leftContainer}>
          <div className={styles.downloadsPill}>
            <div className={styles.icon}>
              <img src={DownloadSVG} />
            </div>
            <div className={styles.counter}>
              {link.share_link_analytics.downloads.length}
            </div>
            <div className={styles.hoverContainer}>
              <div className={styles.text}>Downloads</div>
              <div className={styles.tip} />
            </div>
          </div>
          <div className={styles.viewsPill}>
            <div className={styles.icon}>
              <img src={ViewsSVG} />
            </div>
            <div className={styles.counter}>
              {link.share_link_analytics.views.length}
            </div>
            <div className={styles.hoverContainer}>
              <div className={styles.text}>Clicks</div>
              <div className={styles.tip} />
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <UikDropdown
            DisplayComponent={actionsDropDown}
            position="bottomRight"
          >
            <UikDropdownItem onClick={() => setShowRenameModal(true)}>
              Rename
            </UikDropdownItem>
            <UikDropdownItem
              onClick={() => {
                setShowActivityModal(true);
                setSelectedShareLink(link);
              }}
            >
              View Activity Log
            </UikDropdownItem>
            <UikDropdownItem onClick={() => setShowDeleteModal(true)}>
              Delete
            </UikDropdownItem>
            {moment().isAfter(moment(link.expiration_date), "day") && (
              <UikDropdownItem onClick={reactivateLink}>
                Reactivate
              </UikDropdownItem>
            )}
          </UikDropdown>
        </div>
      </div>
      <div className={styles.label}>{link.label}</div>
      <div className={styles.rowThree}>
        <div className={styles.expirationDate}>
          {moment().isAfter(moment(link.expiration_date), "day")
            ? "Expired"
            : `Expires ${moment(link.expiration_date).calendar(null, {
                sameDay: "[Today]",
                nextDay: "[Tomorrow]",
                nextWeek: "MMM D, YYYY",
                sameElse: "MMM D, YYYY",
              })}`}
        </div>
        <div className={styles.copyLink} onClick={copyLink}>
          <div className={styles.text}>Copy Link</div>
          <div className={styles.icon}>
            <img src={CopySVG} />
          </div>
        </div>
      </div>
      {showRenameModal && (
        <RenameModal
          closeModal={() => setShowRenameModal(false)}
          updateLink={updateLink}
          link={link}
        />
      )}
      <Modal
        hideModal={() => setShowDeleteModal(false)}
        visible={showDeleteModal}
        type="alert"
      >
        <div className="heading">Edit Content Properties</div>
        <UikDivider />
        <div className="body">
          Are you sure you want to delete this share link?
        </div>
        <div className="clear" />
        <div className="dividerContainer">
          <UikDivider />
        </div>
        <div className="buttons">
          <UikButton onClick={deleteLink} primary>
            Confirm
          </UikButton>
          <UikButton onClick={() => setShowDeleteModal(false)}>
            Cancel
          </UikButton>
        </div>
      </Modal>
    </div>
  );
};

export default Link;
