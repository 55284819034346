import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./DocumentVersion.module.scss";
import dimpleSVG from "../../images/icons/svg/toolbox-dimple-blue.svg";

class DocumentVersion extends React.Component {
  constructor(props) {
    super();

    this.resizeTimeout = null;

    this.state = {
      docId: props.docId,
    };
  }

  render() {
    const isActive =
      (this.props.docId == this.props.version.id && !this.props.versionId) ||
      this.props.versionId == this.props.version.id;
    return (
      <div>
        <div
          className={
            styles.versionHeader + (isActive ? " " + styles.active : "")
          }
          onClick={() =>
            this.props.onClickedVersion(
              this.props.version.id,
              this.props.index,
              this.props.version.file_extension
            )
          }
        >
          <div
            className={
              styles.versionNamePlateContainer +
              (this.props.version.approved === true ? " " + styles.active : "")
            }
          >
            <span className={styles.versionNamePlate}>
              Version {this.props.index}
            </span>
          </div>
          <div className={styles.versionDate}>
            <span className={styles.uploadDate}>
              Uploaded By {this.props.version.version_uploaded_by_user_name}{" "}
              <span>&#183;</span>{" "}
              {moment(this.props.version.created_at).format("MMM D")}
            </span>
            <div className={styles.descriptionBox}>
              <img className={styles.descriptionBoxImage} src={dimpleSVG} />
              <div className={styles.descriptionText}>
                {moment(this.props.version.created_at).format("MMMM D, YYYY")}{" "}
                at {moment(this.props.version.created_at).format("h:mm a")}
              </div>
            </div>
          </div>
          {isActive && <div className={styles.versionHeaderActive}></div>}
        </div>
      </div>
    );
  }
}

DocumentVersion.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  version: PropTypes.shape({
    id: PropTypes.number,
    version: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default DocumentVersion;
