import React, { useState } from "react";

import DownChervronSVG from "../../../../images/icons/svg/down-chevron-grey.svg";
import UpChervronSVG from "../../../../images/icons/svg/up-chevron-grey.svg";

import styles from "./AdminShareLinkContainer.module.scss";

const AdminShareLinkContainer = (props) => {
  const { type, children } = props;

  let title = "";
  let initialShowLinks = false;

  if (type === "MY_LINKS") {
    title = "my share links";
    initialShowLinks = true;
  } else if (type === "OTHERS_LINKS") {
    title = "other share links";
  }

  const [showLinks, setShowLinks] = useState(initialShowLinks);
  const [chevronSVG, setChevronSVG] = useState(DownChervronSVG);

  const toggleShowLinks = () => {
    if (!showLinks) {
      setShowLinks(true);
      setChevronSVG(UpChervronSVG);
    } else {
      setShowLinks(false);
      setChevronSVG(DownChervronSVG);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {type === "OTHERS_LINKS" && (
          <div
            className={styles.collapseExpandButton}
            onClick={toggleShowLinks}
          >
            <img src={chevronSVG} />
          </div>
        )}
      </div>
      {showLinks && children}
    </div>
  );
};

export default AdminShareLinkContainer;
